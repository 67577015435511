import FeatureFlag from 'js/model/launchDarkly/FeatureFlag';
import { isAU, isNZ } from 'js/constants/crux';
import { UNSUBSCRIBED } from 'js/constants/tooltipText';
import Flags from 'js/constants/featureFlags';
import Entitlements from 'js/helpers/Entitlements';
import Roles from 'js/constants/entitlementsRole';

/**
 * @param state - redux store
 * @param featureFlagId - launchdarkly id we want to get
 * @param defaultValue - fallback when launchdarkly feature flags are not available
 * @param hasNewZealandFlag - has specific flag for new zealand
 * @returns {*}
 */
function featureFlagSelector(
    state,
    featureFlagId,
    defaultValue = false,
    hasNewZealandFlag = false,
) {
    const featureFlags = state.launchDarkly.get('featureFlag');
    if (featureFlags) {
        return featureFlags[`${featureFlagId}${hasNewZealandFlag && isNZ ? '.nz' : ''}`];
    }

    return defaultValue;
}

function localizedFeatureFlagSelector(
    state,
    featureFlagId,
    defaultValue = false,
) {
    return featureFlagSelector(state, featureFlagId, defaultValue, true);
}

function featureFlagCheckerWithEntitlements(
    featureFlag = {},
    featureFlagKey,
    isEntitled,
    tooltipMessage = '',
) {
    let isEnabled = false;
    let tooltip = tooltipMessage;
    let isHidden = false;
    const _featureFlag = new FeatureFlag(featureFlag[featureFlagKey]);
    if (_featureFlag.isTrue()) {
        isEnabled = true;
    } else if (_featureFlag.isEntitlements()) {
        isEnabled = isEntitled;
        tooltip = isEnabled ? tooltipMessage : UNSUBSCRIBED;
    } else {
        isHidden = true;
    }
    return {
        tooltip,
        isEnabled,
        isHidden,
    };
}

const getProposalsPointReportState = (featureFlag, userDetail) => {
    const _featureFlag = new FeatureFlag(featureFlag[Flags.RP_PROPOSALS_REPORT]);
    if (_featureFlag.isTrue()) {
        return {
            visible: isAU,
            entitled: true,
            disabled: false,
        }
    } else if (_featureFlag.isEntitlements()) {
        let entitled = false;
        if (Entitlements.hasRole(userDetail, Roles.PROPOSAL_POINT)) {
            entitled = true;
        }
        return {
            visible: isAU,
            entitled,
            disabled: !entitled
        }
    }
    return {
        visible: false
    };
};

export default {
    featureFlagSelector,
    localizedFeatureFlagSelector,
    featureFlagCheckerWithEntitlements,
    getProposalsPointReportState,
};
