import { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { MapContext } from '@react-google-maps/api';
import { createPortal } from 'react-dom';

const CustomControl = ({ position, className, children }) => {
    // Get the map context
    const mapContext = useContext(MapContext);

    // Create a div for the control and assign it the provided className
    const controlDiv = document.createElement('div');
    controlDiv.classList.add(className);

    // Use useEffect to add the control to the map only once after the component is mounted
    useEffect(() => {
        // Check if the map context is available and push the control to the correct position
        if (mapContext && mapContext.controls && mapContext.controls[position]) {
            mapContext.controls[position].push(controlDiv);
        }
    }, [mapContext, position, controlDiv]); // Dependency array ensures the effect runs when the mapContext or position changes

    // Return the children inside the control div using createPortal
    return createPortal(children, controlDiv);
};

// Define propTypes to ensure proper type checking
CustomControl.propTypes = {
    position: PropTypes.number.isRequired,
    className: PropTypes.string,
    children: PropTypes.node.isRequired,
};

export default CustomControl;
