export const suggestionLabel = {
    address: 'address',
    state: 'state',
    street: 'street',
    postcode: 'postcode',
    councilArea: 'council area',
    locality: 'suburb',
    territorialAuthority: 'territorial authority',
    suburb: 'suburb',
};

export default {
    ADDRESS: 'address',
    STATE: 'state',
    STREET: 'street',
    POSTCODE: 'postcode',
    COUNCIL_AREA: 'councilArea',
    LOCALITY: 'locality',
    SCHOOL: 'school',
    TERRITORIAL_AUTHORITY: 'territorialAuthority',
    SUBURB: 'suburb',
    PARCEL: 'parcel',
    LEGAL_DESC: 'legalDesc',
    TITLE_REF: 'titleRef',
};
