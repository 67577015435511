import { Map as IMap } from 'immutable';
import * as CruxAction from 'js/actions/crux';
import * as ClapiAction from 'js/actions/clapi';
import PropertyDetailsCommons from 'js/model/PropertyDetailCommonsResponseBody';

export const initialState = IMap({
    property: IMap({
        commons: null,
        isRetryingCommons: false,
    }),
    properties: IMap({}),
});

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case CruxAction.PROPERTY_COMMONS_SUCCESS: {
            const commons = state.get('property').get('commons');
            return state.mergeDeep(IMap({
                property: IMap({
                    commons: PropertyDetailsCommons.merge(commons, action.payload),
                    isRetryingCommons: false,
                }),
            }));
        }
        case CruxAction.PROPERTY_COMMONS:
        case CruxAction.CLEAR_PROPERTY_COMMONS:
        case ClapiAction.GET_PARTIAL_PROPERTY_DETAILS: {
            return state.mergeDeep(IMap({
                property: IMap({
                    commons: null,
                    // should not set isRetryingCommons here to keep the retry logic.
                    // these actions are triggered on retry and first call
                }),
            }));
        }
        case CruxAction.PROPERTY_COMMONS_FAIL: {
            const _commons = new PropertyDetailsCommons();
            _commons.propertyId = action.payload.propertyId;
            return state.mergeDeep(IMap({
                property: IMap({
                    commons: JSON.parse(JSON.stringify(_commons)),
                    isRetryingCommons: false,
                }),
            }));
        }
        case CruxAction.PROPERTY_COMMONS_RETRY: {
            return state.mergeDeep(IMap({
                property: IMap({
                    isRetryingCommons: true,
                }),
            }));
        }
        case CruxAction.RESET_PROPERTY_COMMONS_RETRY_STATE: {
            return state.mergeDeep(IMap({
                property: IMap({
                    isRetryingCommons: false,
                }),
            }));
        }
        case CruxAction.PROPERTY_ACTIVE_IMAGE: {
            return state.mergeDeep(IMap({
                properties: IMap({
                    [action.payload.propertyId]: {
                        activeImageIndex: action.payload.index
                    },
                }),
            }));
        }
        default:
            return state;
    }
}
