import { call, put, takeLatest, select } from 'redux-saga/effects';
import { selectSelectedAccount } from '../selectors/linkedAccount';
import * as SavedTerritoriesAction from '../actions/territories';
import UserPreferences from '../api/userPreferences';

const getSavedTerritoriesFn = () => function* (action) {
    try {
        const { selectedClAppAccountUserGuid } = yield select(selectSelectedAccount);
        const savedTerritories = yield call(
            UserPreferences.getSavedTerritories,
            selectedClAppAccountUserGuid,
            action?.payload?.params,
        );

        yield put({
            type: SavedTerritoriesAction.GET_SAVED_TERRITORIES_SUCCESS,
            payload: savedTerritories,
        });
    } catch (error) {
        yield put({ type: SavedTerritoriesAction.GET_SAVED_TERRITORIES_FAIL });
    }
};

export const getSavedTerritories = getSavedTerritoriesFn();

export function* getSavedTerritoriesWatcher() {
    yield takeLatest(
        SavedTerritoriesAction.GET_SAVED_TERRITORIES,
        getSavedTerritories,
    );
};

export default [
    getSavedTerritoriesWatcher(),
];
