import FullPageMapModal from 'js/components/global/fullPageModal';
import CreateTerritoryMap from 'js/components/userFavorites/savedTerritories/CreateTerritoryMap';
import FullPageModalContext from 'js/components/context/FullPageModalContext';

const TerritoryMapModal = (props) => {
    return (
        <FullPageModalContext>
            {props.children}
            <FullPageMapModal>
                <CreateTerritoryMap />
            </FullPageMapModal>
        </FullPageModalContext>
    );
}

export default TerritoryMapModal;
