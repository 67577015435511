export const GO_TO_TITLES = 'GO_TO_TITLES';

export function goToTitles({ propertyId, state, selectedClAppAccountUserGuid }) {
    return {
        type: GO_TO_TITLES,
        payload: {
            propertyId,
            selectedClAppAccountUserGuid,
            state,
        },
    };
}
