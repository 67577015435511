import Constant from '../../constants/constant';
import Commons from '.././Commons';
import Report from '../../constants/reports';
import ValuationHelper from '.././ValuationHelper';
import { isNZ } from '../../constants/crux';

const isConfidenceLvlGood = (property) => {
    const confidenceBasis = Constant.FSD.Intellival;
    if (property) {
        if (!property.avmDetails) {
            return false;
        }
        const { avmDetails } = property;
        if (avmDetails && avmDetails.fsd < confidenceBasis.maxFsd && !avmDetails.errors) {
            return true;
        }
    }
    return false;
};
const isAVMDetailToBeDisplayed = (property) => {
    if (property && property.avmDetails) {
        const { avmDetails } = property;
        return !!((avmDetails
            && !avmDetails.status
            && !ValuationHelper.hasNullEstimateValues(avmDetails))
            && isConfidenceLvlGood(property));
    }
    return false;
};

const isRentalEstimateToBeDisplayed = (rentalAvm) => {
    if (rentalAvm
        && !rentalAvm.isLoading
        && rentalAvm.rentalAvmEstimateFsdScore) {
        if (rentalAvm.rentalAvmEstimateFsdScore < 30) {
            return true;
        }
    }
    return false;
};

const getReportRoles = (clapiUsrDetail) => {
    const entitlementRoles = Commons.get(clapiUsrDetail, 'entitlements.roles');
    return entitlementRoles && entitlementRoles.filter(reports =>
        Report.REPORT_ENTITLEMENTS.includes(reports.toLowerCase()));
};

/**
 * Returns the list of <b>reportRoles</b>. Removes roles if <b>excludedRoles</b> param is provided.
 * @param clapiUsrDetail
 * @param excludedRoles Roles to be excluded from the list <b>eg. 'Valuation' etc</b>
 * @returns {Array} reportRoles
 */
const getReportRolesExclude = (clapiUsrDetail, ...excludedRoles) => {
    const reportRoles = getReportRoles(clapiUsrDetail);
    if (!excludedRoles && excludedRoles.length === 0) {
        return reportRoles;
    }
    return reportRoles && reportRoles.filter(role => !excludedRoles.includes(role));
};

const getRolesWithExcludedRoles = (clapiUsrDetail) => (
    getReportRolesExclude(
        clapiUsrDetail,
        ...isNZ ? ['Valuation', 'Profiler Suburb'] : [],
    ) || []
);

const getUserContactDetail = clapiUsrDetail => ({
    phoneNumber: Commons.get(clapiUsrDetail, 'userAccountDetail.phoneNumber') ||
            Commons.get(clapiUsrDetail, 'userAccountDetail.mobileNumber') || '',
    email: Commons.get(clapiUsrDetail, 'userAccountDetail.emailAddress') || '',
});

const printByClassname = (className) => {
    // select all canvas inside pdf container
    const canvas = document.querySelectorAll(className);
    let imgs = '';

    for (let i = 0; i < canvas.length; i++) {
        imgs += `<img style="width: auto; height: 100%; display: block; margin: 0 auto;" src="${canvas[i].toDataURL()}" />`;
    }

    // open a new window
    imgs = window.safari ? imgs : Commons.printTemplate(imgs);
    const printWin = window.open('', '', '');
    printWin.document.open();
    printWin.document.write(imgs);
    printWin.document.close();

    // set the img to load first so it doesn't display blank pages
    if (window.safari) {
        printWin.focus();
        printWin.print();
        setTimeout(() => { printWin.close(); }, 100);
    } else {
        setTimeout(() => {
            printWin.focus();
            printWin.print();
            printWin.close();
        }, 500);
    }
};

const downloadFileByUrl = (filename, urlToDownload) => {
    const download = (url) => {
        const link = document.createElement('a');
        link.setAttribute('download', filename);
        link.setAttribute('href', url);
        link.click();
    };

    const downloadBlob = blob => download(URL.createObjectURL(blob));
    fetch(urlToDownload)
        .then(response => response.blob())
        .then(downloadBlob);
};

// use filename else use title
const getFilename = (title, filename) => {
    return (filename && `${filename.replace(/\s/g, '_')}.pdf`) || (title && `${title.replace(/\s/g, '_')}.pdf`);
};

export const hasEntitlementInReportRoles = (entitlements = false, reportRoles) => {
    if (!reportRoles) {
        return false;
    }

    // if entitlement == falsy, it means its entitled
    return !entitlements || reportRoles.includes(entitlements);
}

export default {
    isAVMDetailToBeDisplayed,
    isRentalEstimateToBeDisplayed,
    getReportRoles,
    getReportRolesExclude,
    getUserContactDetail,
    printByClassname,
    downloadFileByUrl,
    getFilename,
    getRolesWithExcludedRoles,
    hasEntitlementInReportRoles,
};
