import { Map as IMap } from 'immutable';
import * as TYPE from '../actions/genericHtmlToPdf';

export const initialState = IMap({
    report: {
        fetching: true,
        error: false,
        pdfBytes: null,
    },
});

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case TYPE.CONVERT_GENERIC_HTML_TO_PDF:
            return state.merge(IMap({
                report: {
                    ...JSON.parse(JSON.stringify(state.get('report'))),
                    fetching: true,
                },
            }));
        case TYPE.CONVERT_GENERIC_HTML_TO_PDF_SUCCESS:
            return state.merge(IMap({
                report: {
                    fetching: false,
                    error: false,
                    pdfBytes: action.payload,
                },
            }));
        case TYPE.CONVERT_GENERIC_HTML_TO_PDF_FAIL:
            return state.merge(IMap({
                report: {
                    fetching: false,
                    error: true,
                    pdfBytes: null,
                    failedRequest: action.failedRequest,
                },
            }));
        default:
            return state;
    }
};

