import moment from 'moment/moment';
import Commons from 'js/helpers/Commons';
import { STATE } from 'js/constants/states';
import Address from 'js/helpers/Address';


const AGENT_NOT_WITHHELD = 'agent not withheld';
const AGENT_WITHHELD = 'agent withheld';
const isRapidAgentAdvice = (salesLastSaleSource) => {
    if (salesLastSaleSource) {
        const loweredLastSaleSource = salesLastSaleSource.toLowerCase();
        return loweredLastSaleSource === AGENT_NOT_WITHHELD ||
            loweredLastSaleSource === AGENT_WITHHELD;
    }
    return false;
};

const PENDING_SETTLEMENT_ADVICE = 'Pending Settlement Advice';

const displayRapidSaleType = (listingType, isAgentAdviceFlag) => {
    if (isAgentAdviceFlag) {
        return PENDING_SETTLEMENT_ADVICE;
    }
    return listingType && listingType !== 'Unknown' ? listingType : '-';
};

const isRecentlySold = (contractDate) => {
    if (contractDate) {
        const currentDate = new Date();
        const recentDateDeadLine = moment(contractDate).add(3, 'M');
        if (moment(currentDate).isBefore(recentDateDeadLine)) {
            return true;
        }
    }
    return false;
};

const getSaleDate = (saleDetail, state = '') => {
    if (!saleDetail) return '';

    if (state.toLowerCase() === STATE.SA) {
        return Commons.formatDate(saleDetail.settlementDate, 'DD MMM YYYY');
    }
    return Commons.formatDate(saleDetail.contractDate, 'DD MMM YYYY');
};

const displayRapidSaleDate = (contractDate, settlementDate, state) => {
    if (contractDate) {
        return getSaleDate({
            contractDate,
            settlementDate,
        }, state) || '';
    }
    return '-';
};

const hasAverageHoldTrend = (response = {}) => {
    const seriesResponseList = response?.seriesResponseList || [];
    return seriesResponseList.some(series => !!series.seriesDataList.length);
}

const groupByCoordinates = (searchResults) => searchResults?.reduce((filteredByUnit, property) => {
    if (property?.addressLocation?.lat && property?.addressLocation?.lon) {
        const coordinate = JSON.stringify(property.addressLocation);
        if (coordinate in filteredByUnit) {
            filteredByUnit[coordinate].push(property);
        } else {
            filteredByUnit[coordinate] = [property];
        }
    }
    return filteredByUnit;
}, {}) ?? {};

const groupByAddress = (searchResults) => searchResults?.reduce((filteredByUnit, property) => {
    if (property?.addressLocation?.lat && property?.addressLocation?.lon) {
        const unit = Address.excludeUnit(property.addressComplete);
        if (unit in filteredByUnit) {
            filteredByUnit[unit].push(property);
        } else {
            filteredByUnit[unit] = [property];
        }
    }
    return filteredByUnit;
}, {}) ?? {};

export default {
    displayRapidSaleType,
    isRecentlySold,
    isRapidAgentAdvice,
    displayRapidSaleDate,
    getSaleDate,
    hasAverageHoldTrend,
    groupByCoordinates,
    groupByAddress,
};
