import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm, reset, formValueSelector } from 'redux-form';
import moment from 'moment';
import { getPropertyProfileReport } from 'js/actions/pdfReports';
import Reports from 'js/constants/reports';
import ReportForm from 'js/components/reports/ReportForm';
import ReportFormFields, { ReportSwitch } from 'js/components/reports/ReportFormFields';
import ReportHelper from 'js/helpers/report/ReportHelper';
import { PROPERTY_PROFILE } from 'js/helpers/Localization';

const formSelector = formValueSelector('propertyReportForm');

export class PropertyReport extends Component {
    static propTypes = {
        propertyAddress: PropTypes.string,
        route: PropTypes.object,
        dispatch: PropTypes.func,
        propertyReportWithoutAvmId: PropTypes.any,
        propertyReportWithAvmId: PropTypes.any,
        toggleReportModal: PropTypes.func,
        clapiUsrDetail: PropTypes.object,
        entryPoint: PropTypes.string,
        propertyId: PropTypes.number,
    };

    static defaultProps = {
        entryPoint: null,
    };

    constructor(props) {
        super(props);
        this.state = {
            id: null,
            filename: null,
            includeAVMEstimate: true,
            includeEmail: true,
            includePhone: true,
        };

        const { email, phoneNumber } = ReportHelper.getUserContactDetail(props.clapiUsrDetail);
        this.email = email;
        this.phoneNumber = phoneNumber;
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.propertyId !== prevState.id) {
            const dateNow = moment().format('dd-mm-yyyy');
            return {
                id: nextProps.propertyId,
                filename: `Property_Report_${nextProps.propertyAddress && nextProps.propertyAddress.replace(/\s/g, '_')}_${dateNow}`,
            };
        }
        return null;
    }

    getPropertyProfileReport(propertyId, route, reportType, reportId, entryPoint, customParams) {
        const { dispatch } = this.props;
        dispatch(getPropertyProfileReport(propertyId, route, reportType, reportId, entryPoint, customParams));
    }

    handler = () => {
        const {
            route,
            propertyReportWithoutAvmId,
            propertyReportWithAvmId,
            preparedFor,
            preparedBy,
            valid,
            entryPoint,
        } = this.props;
        const {
            id, includeAVMEstimate, includeEmail, includePhone,
        } = this.state;
        if (valid) {
            this.getPropertyProfileReport(
                id,
                route,
                Reports.PROPERTY_REPORT,
                includeAVMEstimate ? propertyReportWithAvmId : propertyReportWithoutAvmId,
                entryPoint,
                {
                    customerInput: {
                        customerFirstName: preparedFor,
                    },
                    requesterInput: {
                        requesterFirstName: preparedBy,
                        requesterEmail: includeEmail ? this.email : null,
                        requesterPhone: includePhone ? this.phoneNumber : null,
                        includeAVMEstimate: includeAVMEstimate,
                    },
                },
            );
        }
        return valid;
    };

    resetForm() {
        this.props.dispatch(reset('propertyReportForm'));
    }

    handleKeyPress = (e) => {
        if (e.key === 'Enter') e.preventDefault();
    };

    handleIOSToggle = (e, changedState) => {
        this.setState({ [changedState]: e.target.checked });
    };

    handleResetForm = () => {
        this.props.toggleReportModal();
        this.resetForm();
    };

    renderFields = () => (
        <ReportFormFields
            label="Edit Report Cover Page Details"
            handleKeyPress={this.handleKeyPress}
            includeEmail={this.state.includeEmail}
            includePhone={this.state.includePhone}
            handleIOSToggle={this.handleIOSToggle}
            email={this.email}
            phoneNumber={this.phoneNumber}
        >
            <ReportSwitch
                field="include corelogic automated valuation"
                stateToChange="includeAVMEstimate"
                onChange={this.handleIOSToggle}
                checked={this.state.includeAVMEstimate}
                id="valuationSwitch"
            />
        </ReportFormFields>
    );

    render() {
        const { entryPoint, propertyId } = this.props;
        const fields = this.renderFields();
        return (
            <div data-testid="property-report-cont">
                <ReportForm
                    propertyId={propertyId}
                    icon="property-report-white.png"
                    title="Property Report"
                    fields={fields}
                    toggleReportModal={this.handleResetForm}
                    triggerHandler={this.handler}
                    reportType={Reports.PROPERTY_REPORT}
                    reportName={this.props.propertyAddress}
                    filename={this.state.filename}
                    manualOpenModal
                    entryPoint={entryPoint}
                />
            </div>
        );
    }
}

const ConnectedPropertyReport = connect(state => ({
    preparedFor: formSelector(state, 'preparedFor'),
    preparedBy: formSelector(state, 'preparedBy'),
    clapiUsrDetail: state.clapi.get('usrDetail'),
    propertyReportWithoutAvmId: PROPERTY_PROFILE.reportWithoutAvmId,
    propertyReportWithAvmId: PROPERTY_PROFILE.reportWithAvmId,
}))(reduxForm({
    form: 'propertyReportForm',
    initialValues: {
        preparedFor: '',
        preparedBy: '',
    },
    destroyOnUnmount: true,
})(PropertyReport));
ConnectedPropertyReport.displayName = 'ConnectedPropertyReport';
export default ConnectedPropertyReport;
