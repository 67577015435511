import PropTypes from 'prop-types';
import DialogContent from '@mui/material/DialogContent';
import Alert from '@mui/material/Alert';
import DialogActions from '@mui/material/DialogActions';
import ConnectButton from 'js/components/property/reiForms/ConnectButton';
import ReiForms from 'js/constants/reiForms';

const FormsError = (props) => (
    <>
        <DialogContent>
            <Alert
                severity="error"
                sx={{
                    fontSize: 14,
                    letterSpacing: '0.15px',
                }}
            >
                {ReiForms.CONNECTION_ERROR_MESSAGE[props.formType]}
            </Alert>
        </DialogContent>
        <DialogActions
            sx={{
                paddingRight: '24px',
                paddingBottom: '24px',
                paddingTop: 0,
            }}
        >
            <ConnectButton />
        </DialogActions>
    </>
);

FormsError.propTypes = {
    formType: PropTypes.string,
};

export default FormsError;
