import React from 'react';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import { useDispatch } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { styled } from '@mui/material';
import { useFullPageModalContext } from 'js/components/context/FullPageModalContext';
import CruxDialogBox from 'js/components/common/modals/CruxDialogBox';
import { setSelectedTerritory } from 'js/actions/territories';


const StyledFullPageModal = styled('div')({
    position: 'relative',
});

const Transition = React.forwardRef(function Transition(
    props,
    ref,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const FullPageModal = (props) => {
    const { open, setOpen, confirmDialogOpen, setConfirmDialogOpen } = useFullPageModalContext();
    const dispatch = useDispatch();

    const handleClose = () => {
        setOpen(false);
        dispatch(setSelectedTerritory(null));
    };

    const handleConfirmDialog = () => {
        setConfirmDialogOpen(true);
    };

    return (
        <StyledFullPageModal>
            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
                className="full-page-modal"
                sx={{
                    '& .block-ui': {
                        height: '100%',
                    },
                }}
            >
                <IconButton
                    onClick={handleConfirmDialog}
                    aria-label="close"
                    sx={{
                        fontSize: 10,
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        zIndex: 99,
                    }}
                >
                    <CloseIcon />
                </IconButton>
                { props.children }
            </Dialog>
            <CruxDialogBox
                open={confirmDialogOpen}
                id="close-saved-territory-modal"
                title="Are you sure you want to close the modal?"
                subTitle="This will remove the changes and cannot be undone"
                type="close"
                onClose={() => setConfirmDialogOpen(false)}
                onConfirm={handleClose}
            />
        </StyledFullPageModal>
    );
}

export default FullPageModal;
