import OTM from './otm';

const REPORT_PROPERTY_LIST = 'Report Property List';
const REPORT_SALES = 'Report Sales';
const REPORT_OTM_FOR_SALE = 'Report OTM For Sale';
const REPORT_OTM_FOR_RENT = 'Report OTM For Rent';
const PROSPECTOR = 'Prospector';
const MARKETING_FULL = 'Marketing Full';
const SUBURB_STATISTICS = 'Suburb Statistics';
const EXPORT_LIMIT_PRO = 'EXPORT_LIMIT_PRO';
export default {
    LGA_SEARCH: 'LGA Search',
    SEARCH_NAME: 'Search Name',
    SEARCH_BUILDING_NAME: 'SEARCH_BUILDING_NAME',
    SEARCH_PARCEL: 'Search Parcel',
    MAP_ADVANCED: 'MAP_ADVANCED',
    UNDISCLOSED_SALES_ACCESS: 'Undisclosed Sales Access',
    SAVE_SEARCH: 'SAVED_SEARCHES',
    SAVED_TERRITORY: 'SAVED_TERRITORY',
    NOTIFICATIONS: 'NOTIFICATIONS',
    RADIUS_SEARCH: 'RADIUS_SEARCH',
    ADVANCED_FILTERS: 'ADVANCED_FILTERS',
    REPORTS: 'ADVANCED_FILTERS',
    SAVED_LISTS: 'SAVED_LISTS',
    REIWA_TITLES: 'REIWA Titles',
    PROPOSAL_POINT: 'PROPOSAL_POINT',
    PROPERTY_CMA: 'Property CMA',
    PROFILER_PROPERTY_ONE_PAGE: 'Profiler Property 1 Page',
    VALUATION: 'Valuation',
    PROPERTY_PROFILER: 'Property Profiler',
    PROFILER_SUBURB: 'Profiler Suburb',
    RENTAL_AVM_FULL: 'Rental Avm Full',
    CMA_GENERIC_REPORT_ENGINE_FULL: 'CMA Generic Report Engine Full',
    RENTAL_CMA: 'Rentals CMA',
    TITLES_REPORT: 'TITLES_REPORT',
    DIGITAL_PROPERTY_PROFILE: 'DIGITAL_PROPERTY_PROFILE',
    REI_FORMS: 'Forms Integration DM',
    MAP: 'Map',
    PROSPECTOR,
    MARKETING_FULL,
    SUBURB_STATISTICS,
    EXPORT_LIMIT_PRO,
};

export const REPORT_ROLES = {
    [OTM.ALL]: REPORT_PROPERTY_LIST,
    [OTM.FOR_SALE]: REPORT_OTM_FOR_SALE,
    [OTM.FOR_RENT]: REPORT_OTM_FOR_RENT,
    [OTM.RECENT_SALE]: REPORT_SALES,
    [OTM.AGED]: PROSPECTOR,
    [OTM.WITHDRAWN]: PROSPECTOR,
    [OTM.TENURE]: PROSPECTOR,
};
