import PropTypes from 'prop-types';
import Fade from '@mui/material/Fade';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Popper from '@mui/material/Popper';
import { styled } from '@mui/material';
import Typography from '@mui/material/Typography';
import { withRouter } from 'react-router';

import ReiForms from 'js/constants/reiForms';
import ConnectButton from 'js/components/property/reiForms/ConnectButton';
import { getAssetDomain } from '../../../../constants/assets';

const StyledButtonContainer = styled('div')({
    display: 'flex',
    justifyContent: 'flex-end',
});
const UnconnectedForm = (props) => (
    <Popper
        open={props.formsActive}
        anchorEl={props.anchorEl}
        transition
        disablePortal
        placement="bottom-end"
        sx={{
            zIndex: 3,
        }}
    >
        {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
                <Paper
                    sx={{
                        borderRadius: '4px',
                        p: 1,
                        mt: 1,
                        width: 350,
                        marginTop: 1,
                        marginBottom: 1,
                        ...props.paperStyle || {},
                    }}
                >
                    <Box p={1}>
                        <img
                            className="img-icon"
                            src={getAssetDomain(ReiForms.FORM_LOGOS[props.formType])}
                            alt="forms-integration"
                        />
                        <Typography
                            sx={{
                                color: '#0d3d61',
                                fontSize: 14,
                                letterSpacing: '0.15px',
                                mt: 2,
                                whiteSpace: 'normal',
                            }}
                        >
                            {ReiForms.FORM_UNCONNECTED_MESSAGES[props.formType]}
                        </Typography>
                        <StyledButtonContainer>
                            <ConnectButton
                                sx={{ mt: 2 }}
                            />
                        </StyledButtonContainer>
                    </Box>
                </Paper>
            </Fade>
        )}
    </Popper>
);

UnconnectedForm.propTypes = {
    formType: PropTypes.string,
    anchorEl: PropTypes.any,
    formsActive: PropTypes.bool,
    paperStyle: PropTypes.object,
}

UnconnectedForm.displayName = 'UnconnectedForm';

export default withRouter(UnconnectedForm);
