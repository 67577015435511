import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Modal, Paper, styled, Typography } from '@mui/material';
import Validate from '../../../helpers/Validate';
import MultiValueTextField from '../../forms/MultiValueTextField';
import Colors from '../../../constants/colors';

const TITLE = 'Add new user';
const PARAGRAPH_1 = 'You can add a new or existing users to your account using their email address.';

const StyledButton = styled(Button)({
    fontWeight: 500,
    fontSize: 14,
    letterSpacing: 0.4,
    lineHeight: '24px',
    margin: '0 5px',
    '&:hover:first-child': {
        background: Colors.BLACK_4_OPAQUE,
    },
    '&.Mui-disabled:last-child':{
        color: 'rgba(0, 0, 0, 0.26)',
        background: Colors.BLACK_12_OPAQUE,
    }
});

const SendInvitesModal = (props) => {
    const {
        open,
        onClose,
        onConfirm,
    } = props;
    const [disableBtn, setDisableBtn] = useState(true);
    const [emails, setEmails] = useState([]);
    const handleConfirm = () => {
        setDisableBtn(true);
        onConfirm(emails);
    };
    const handleUpdateChips = chips => setEmails(chips);
    const handleError = error => setDisableBtn(error);
    return (
        <Modal data-testid="send-invites-modal" open={open} onClose={onClose}>
            <Box height="100%" display="flex" alignItems="center" justifyContent="center">
                <Box
                    component={Paper}
                    elevation={3}
                    square
                    sx={{
                        minHeight: 160,
                        width: 600,
                        boxSizing: 'border-box',
                        padding: '16px 24px',
                    }}
                >
                    <Box mb={2}>
                        <Typography sx={{ color: Colors.BLACK_87_OPAQUE }} variant="h6">{TITLE}</Typography>
                    </Box>
                    <Box mb={2}>
                        <Typography variant="body_1_color_60">{PARAGRAPH_1}</Typography>
                    </Box>
                    <Box>
                        <Typography variant="body_1_color_60">
                            To add a <b>single user</b> hit 'Enter' after typing the email.
                        </Typography>
                    </Box>
                    <Box mb={3}>
                        <Typography variant="body_1_color_60">
                            Or to add <b>multiple users</b>, separate the emails with a comma.
                        </Typography>
                    </Box>
                    <Box mb={2}>
                        <MultiValueTextField
                            label="Email address"
                            placeholder="Email address"
                            onError={handleError}
                            onUpdateChips={handleUpdateChips}
                            validator={Validate.EMAIL_AND_LIMIT_LIST}
                        />
                    </Box>
                    <Box mt={2} display="flex" justifyContent="flex-end">
                        <StyledButton
                            data-testid="send-invites-cancel-btn"
                            size="medium"
                            onClick={onClose}
                            sx={{
                                color: Colors.BLACK_60_OPAQUE,
                            }}
                        >
                            Cancel
                        </StyledButton>
                        <StyledButton
                            size="medium"
                            variant="contained"
                            color="primary"
                            disabled={emails.length === 0 || disableBtn}
                            onClick={handleConfirm}
                        >
                            Send Invites
                        </StyledButton>
                    </Box>
                </Box>
            </Box>
        </Modal>
    );
};

SendInvitesModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
};
export default SendInvitesModal;
