import React, { Component } from 'react';
import PanelError from '../common/PanelError';
import Bugsnag from './../../bugsnag';

/**
 * The purpose of this H.O.C. is to prevent propagation
 * of error at Panel level to parent component/s
 * @param WrappedComponent
 * @param hidePanelWhenError
 * @returns PanelError when error is encounter otherwise return WrappedComponent
 */
export default function withPanelErrorHandler(WrappedComponent, hidePanelWhenError) {
    return class extends Component {
        constructor(props) {
            super(props);
            this.state = {
                hasError: false,
            };
        }

        static getDerivedStateFromError() {
            return { hasError: true };
        }

        componentDidCatch(error) {
            Bugsnag.notify(error);
        }

        render() {
            const { panelErrorMsg, panel, ...restProps } = this.props;
            if (this.state.hasError) {
                return hidePanelWhenError ?
                    null :
                    <div className="fill-container">
                        <PanelError message={panelErrorMsg} panel={panel} />
                    </div>;
            }

            return <WrappedComponent {...restProps} />;
        }
    };
}
