import Reports from 'js/constants/reports';

export const CLEAR_REPORT = 'CLEAR_REPORT';
export const PDF_REPORT_SUCCESS = 'PDF_REPORT_SUCCESS';
export const PDF_REPORT_FAIL = 'PDF_REPORT_FAIL';
export const GET_ONE_PAGE_REPORT = 'GET_ONE_PAGE_REPORT';
export const GET_ONE_PAGE_REPORT_FAIL = 'GET_ONE_PAGE_REPORT_FAIL';
export const GET_PROPERTY_PROFILE = 'GET_PROPERTY_PROFILE';
export const GET_PROPERTY_PROFILE_FAIL = 'GET_PROPERTY_PROFILE_FAIL';
export const GET_SUBURB_PROFILE_REPORT = 'GET_SUBURB_PROFILE_REPORT';
export const GET_SUBURB_PROFILE_REPORT_FAIL = 'GET_SUBURB_PROFILE_REPORT_FAIL';
export const GET_INTELLIVAL_AVM_REPORT = 'GET_INTELLIVAL_AVM_REPORT';
export const GET_INTELLIVAL_AVM_REPORT_FAIL = 'GET_INTELLIVAL_AVM_REPORT_FAIL';
export const GET_PROPERTY_PROFILE_REPORT = 'GET_PROPERTY_PROFILE_REPORT';
export const GET_PROPERTY_PROFILE_REPORT_FAIL = 'GET_PROPERTY_PROFILE_REPORT_FAIL';
export const GET_PROPERTY_INVESTMENT_REPORT = 'GET_PROPERTY_INVESTMENT_REPORT';
export const GET_PROPERTY_INVESTMENT_REPORT_FAIL = 'GET_PROPERTY_INVESTMENT_REPORT_FAIL';
export function getOnePageReport(propertyId, isOwnerNameAllowed, pageContext, entryPoint = null) {
    return {
        type: GET_ONE_PAGE_REPORT,
        payload: {
            propertyId,
            isOwnerNameAllowed,
            reportType: Reports.ONE_PAGE,
            entryPoint,
            pageContext,
        },
    };
}

export function clearReports() {
    return {
        type: CLEAR_REPORT,
    };
}

export function getPropertyProfile(propertyId, pageContext, reportType, reportId, entryPoint, customParams = {}) {
    return {
        type: GET_PROPERTY_PROFILE,
        payload: {
            propertyId,
            reportType,
            reportId,
            entryPoint,
            customParams,
            pageContext,
        }
    };
}

export function getPropertyProfileReport(propertyId, route, reportType, reportId, entryPoint, customParams = {}) {
    return {
        type: GET_PROPERTY_PROFILE_REPORT,
        payload: {
            propertyId,
            reportType,
            reportId,
            entryPoint,
            customParams,
            pageContext: route.pageContext,
        },
    };
}

export function getSuburbProfileReport(
    propertyId,
    locationId,
    route,
    reportType,
    entryPoint = null,
    suburbName = null,
    customParams = {},
) {
    return {
        type: GET_SUBURB_PROFILE_REPORT,
        payload: {
            propertyId,
            locationId,
            reportType,
            entryPoint,
            customParams,
            suburbName,
            pageContext: route.pageContext,
        },
    };
}
export function getIntellivalAvmReport(propertyId) {
    return {
        type: GET_INTELLIVAL_AVM_REPORT,
        payload: { propertyId },
    };
}
export function getPropertyInvestmentReport({
    propertyId,
    leaseStartDate,
    leaseEndDate,
    letAmount,
    entryPoint,
    pageContext,
}) {
    return {
        type: GET_PROPERTY_INVESTMENT_REPORT,
        payload: {
            propertyId,
            leaseStartDate,
            leaseEndDate,
            letAmount,
            entryPoint,
            pageContext,
        },
    };
}
