import React from 'react';
import PropTypes from 'prop-types';
import { Button, DialogContentText, DialogActions, styled } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';

const StyledDialogContent = styled(DialogContent)({
    overflowY: 'auto',
    maxHeight: 500,
});
const DialogMessage = (props) => {
    const {
        id,
        open,
        onClose,
        title,
        message,
    } = props;
    return (
        <Dialog
            id={`${id}-dialog-message`}
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            {
                title &&
                <DialogTitle data-testid="dialog-title">
                    {title}
                </DialogTitle>
            }
            <StyledDialogContent>
                <DialogContentText>
                    {message}
                </DialogContentText>
            </StyledDialogContent>
            <DialogActions>
                <Button onClick={onClose} autoFocus>Close</Button>
            </DialogActions>
        </Dialog>
    );
};

DialogMessage.propTypes = {
    open: PropTypes.bool.isRequired,
    id: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    message: PropTypes.string.isRequired,
    title: PropTypes.string,
};

export default DialogMessage;
