import Bugsnag from '../../bugsnag';
import Commons from '../../helpers/Commons';

const isUseOriginUrl = (origin, remoteUrl) => {
    return Commons.isUrl(origin) && !remoteUrl;
};
const hasRemoteOrOriginUrl = (origin, remoteUrl, base64Url) => {
    return (Commons.isUrl(origin) && !remoteUrl) || (!Commons.isUrl(origin) && !base64Url);
}
const isIE = (ua) => {
    return ua.indexOf('Trident/7.0') !== -1 || ua.indexOf('Edge/') !== -1;
}
const isSafari = () => {
    return window.safari;
}
const isNotValidUrl = (url) => {
    return url && !Commons.isUrl(url);
}
const getBlobLink = (origin, setState) => {
    fetch(origin).then((response) => {
        const resClone = response.clone();
        response.blob().then((data) => {
            setState({ remoteUrl: URL.createObjectURL(data) });
        }).catch(error => Bugsnag.notify({
            name: 'Failed to load PDF',
            message: error.message,
        }));
        resClone.arrayBuffer().then((data) => {
            setState({ pdfBytes: data });
        }).catch(error => Bugsnag.notify({
            name: 'Failed to load PDF',
            message: error.message,
        }));
    }).catch(error => Bugsnag.notify({
        name: 'Failed to fetch PDF',
        message: error.message,
    }));
}

export default {
    getBlobLink,
    isUseOriginUrl,
    hasRemoteOrOriginUrl,
    isIE,
    isSafari,
    isNotValidUrl,
}