const states = [
    'QLD', 'NSW', 'ACT', 'TAS', 'SA', 'WA', 'VIC', 'NT',
];

export const STATE = {
    QLD: 'qld',
    NSW: 'nsw',
    ACT: 'act',
    TAS: 'tas',
    SA: 'sa',
    WA: 'wa',
    VIC: 'vic',
    NT: 'nt',
};

export const STATE_UPPERCASE = {
    QLD: 'QLD',
    NSW: 'NSW',
    ACT: 'ACT',
    TAS: 'TAS',
    SA: 'SA',
    WA: 'WA',
    VIC: 'VIC',
    NT: 'NT',
};

export default states;
