import { useState, useEffect } from 'react';

// This custom hook is responsible for trigger fetching disclaimer.
const useDisclaimer = (initialState, action, dispatch) => {
    const [disclaimer, setDisclaimer] = useState(initialState);
    useEffect(() => {
        if (action) {
            dispatch(action);
        }
    }, [action]);

    useEffect(() => {
        setDisclaimer(initialState);
    }, [initialState]);

    return disclaimer;
};

export default useDisclaimer;
