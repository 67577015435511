import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import Select from 'react-select';
import attributes, { PROPERTY_TYPE_ATTR_VAL } from '../../../constants/attributes';
import Commons from '../../../helpers/Commons';
import { allPropertyTypes } from '../../../constants/propertyTypes';
import DropdownIndicator from '../../common/SelectComponent';
import style from '../../../constants/dropdownStyle';

export const getAttribute = (props, key) => {
    const attribute = parseInt(Commons.get(props, `property.attributes.${key}`));
    return attribute || undefined;
};

export const getPropertyType = ({ property }) => {
    const propertyType = Commons.get(property, 'attributes.propertyType') || Commons.get(property, 'propertyType');
    if (propertyType && propertyType !== 'OTHER') {
        return {
            label: Commons.capitalizeFirstLetter(propertyType).toString(),
            value: propertyType.toLowerCase(),
        };
    }
    return { label: '', value: '' };
};

const getCustomStyles = () => ({
    indicatorSeparator: styles => ({
        ...styles,
        display: 'none',
    }),
    control: (styles, {
        isFocused,
    }) => ({
        ...styles,
        marginTop: '9px',
        height: '37px',
        width: '115px',
        borderRadius: '2px',
        boxShadow: 'none',
        borderColor: isFocused ?
            style.normalStyle.borderColorOnFocus :
            style.normalStyle.borderColor,
        backgroundColor: isFocused ?
            style.normalStyle.backgroundColorOnFocus :
            style.normalStyle.backgroundColor,
        '&:hover': {
            borderColor: style.normalStyle.borderColorOnHover,
        },
    }),
    menu: styles => ({
        ...styles,
        width: '115px',
    }),
    menuList: styles => ({
        ...styles,
        width: '115px',
        maxHeight: '200px',
        minHeight: '45px',
    }),
});

export const renderField = ({
    input, type, className, maxLength, datatestid,
}) => (
    <div>
        <input {...input} type={type} data-testid={datatestid} className={className} maxLength={maxLength} autoComplete="off" />
    </div>
);
export const renderSelect = ({
     input, customOptions, id, customStyles, label,
 }) => (
    <Select
        aria-label={label}
        name={id}
        options={customOptions}
        value={input.value}
        onChange={input.onChange}
        components={{ DropdownIndicator }}
        isOptionDisabled={option => option.disabled}
        styles={customStyles || getCustomStyles()}
        isSearchable={false}
    />
);

const isLandOrFloorArea = attribute => attribute.value === attributes[3].value ||
        attribute.value === attributes[4].value;

const getMaxLength = (attribute) => {
    const { value } = attribute;
    switch (value) {
        case 'landArea':
        case 'floorArea':
            return 6;
        case 'carSpaces':
            return 3;
        default:
            return 2;
    }
};

const handleKeyPress = (e) => {
    if (e.key === 'Enter') e.preventDefault();
};

const validateFields = (e, field, setErrors) => {
    const { value } = e.target;
    const roomfields = ['bedrooms', 'bathrooms'];
    const roomIndex = roomfields.findIndex(k => k === field);
    const areafields = ['landArea', 'floorArea'];
    const areaIndex = areafields.findIndex(k => k === field);

    let error = null;

    if (!Commons.isPositiveNumber(value)) {
        error = 'Must be a number';
    } else if (roomIndex >= 0 && !Commons.isZeroToTwenty(value)) {
        error = 'Please enter a value between 0 and 20';
    } else if (field === 'carSpaces' && !Commons.isZeroToOneHundred(value)) {
        error = 'Please enter a value between 0 and 100';
    } else if (areaIndex >= 0 && !Commons.isZeroToHundredThousand(value)) {
        error = 'Please enter a value between 0 and 100000';
    }

    setErrors(field, error);
};

const renderLabelAndField = ({
    label, field, maxLength, hasUnit, errors, setFocusedField, setErrors,
}) => (
    <div className="attribute-container" key={field}>
        <span className="attribute-label">{label}</span>
        <div data-testid="attribute-field" className={hasUnit ? 'attribute-field-with-unit' : ''}>
            <Field
                datatestid={field}
                name={field}
                id={field}
                key={field}
                component={renderField}
                type="text"
                className={`form-control attribute-field${errors[field] ? ' has-error' : ''}`}
                onKeyPress={handleKeyPress}
                onChange={
                    validateFields && setErrors ?
                        e => validateFields(e, field, setErrors)
                        : null}
                onFocus={
                    setFocusedField ?
                        () => setFocusedField({ label, field })
                        : null}
                onBlur={
                    setFocusedField ?
                        () => setFocusedField({ label: null, field: null })
                        : null}
                maxLength={maxLength}
                parse={value => (value && Commons.isAllDigits(value) ? parseInt(value, 10) : value)}
            />
            {
                hasUnit &&
                    <div className="attribute-unit">m<sup>2</sup></div>
            }
        </div>
    </div>
);

const displayError = field => attributes.find(o => o.value === field);

const UpdateAttributesFields = ({
    errors,
    setFocusedField,
    setErrors,
    focusedField = {},
    excludeFields = [],
}) => {
    const customOptions = [];
    const isPropertyTypeExcluded = excludeFields.includes(PROPERTY_TYPE_ATTR_VAL);

    if (!isPropertyTypeExcluded) {
        allPropertyTypes.forEach((propertyType) => {
            customOptions.push({ label: propertyType, value: propertyType.toLowerCase() });
        });
    }

    const errorMsg = focusedField.label && errors[focusedField.field] ? `${focusedField.label}: ${errors[focusedField.field]}` : '';

    return (
        <React.Fragment>
            <div className="update-attributes-container">
                {
                    !isPropertyTypeExcluded &&
                    (
                        <div className="attribute-container">
                            <span className="attribute-label">Property Type</span>
                            <Field
                                label="propertyType"
                                name="propertyType"
                                id="propertyType"
                                key="propertyType"
                                component={renderSelect}
                                customOptions={customOptions}
                            />
                        </div>
                    )
                }
                {attributes.map((attribute) => {
                    if (!excludeFields.includes(attribute.value)) {
                        const hasUnit = isLandOrFloorArea(attribute);
                        const maxLength = getMaxLength(attribute);
                        return renderLabelAndField({
                            label: attribute.label,
                            field: attribute.value,
                            maxLength,
                            hasUnit,
                            errors,
                            setFocusedField,
                            setErrors,
                        });
                    }
                    return null;
                })}

            </div>
            <p className="text-danger mb-0 error-message">{displayError(focusedField.field) && errorMsg}</p>
        </React.Fragment>
    );
};

renderField.propTypes = {
    input: PropTypes.object,
    type: PropTypes.string,
    className: PropTypes.string,
    maxLength: PropTypes.number,
};
renderSelect.propTypes = {
    id: PropTypes.string,
    input: PropTypes.object,
    customOptions: PropTypes.array,
    customStyles: PropTypes.object,
};

renderLabelAndField.propTypes = {
    label: PropTypes.string,
    field: PropTypes.any,
    maxLength: PropTypes.number,
    hasUnit: PropTypes.bool,
    errors: PropTypes.object,
    setFocusedField: PropTypes.func,
    setErrors: PropTypes.func,
};

UpdateAttributesFields.propTypes = {
    errors: PropTypes.object,
    setFocusedField: PropTypes.func,
    setErrors: PropTypes.func,
    focusedField: PropTypes.object,
    excludeFields: PropTypes.arrayOf(PropTypes.string),
};

export default UpdateAttributesFields;
