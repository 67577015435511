export const registered = [];

export const register = (fn) => {
    if (registered.indexOf(fn) < 0) {
        registered.push(fn);
    }
};
export const unregister = (fn) => {
    const index = registered.indexOf(fn);
    if (index > -1) {
        registered.splice(index, 1);
    }
};

const loggerMiddleware = store => next => action => {
    registered.forEach((cb) => {
        if (typeof cb === 'function') {
            cb(action);
        }
    });
    // Pass the action to the next middleware in the chain
    return next(action);
};
export default loggerMiddleware;