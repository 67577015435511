export const GO_TO_SIGNATURE_REPORT = 'GO_TO_SIGNATURE_REPORT';
export const GO_TO_SIGNATURE_REPORT_SUCCESS = 'GO_TO_SIGNATURE_REPORT_SUCCESS';
export const GO_TO_SIGNATURE_REPORT_FAIL = 'GO_TO_SIGNATURE_REPORT_FAIL';
export const REMOVE_SIGNATURE_ERROR = 'REMOVE_SIGNATURE_ERROR';

export function goToSignatureReport(
    propertyId,
    reportType,
    subType,
    entryPoint = null,
    pageContext = null,
) {
    return {
        type: GO_TO_SIGNATURE_REPORT,
        payload: {
            propertyId,
            reportType,
            entryPoint,
            pageContext,
            subType,
        },
    };
}

export function removeSignatureError() {
    return {
        type: REMOVE_SIGNATURE_ERROR,
    };
}
