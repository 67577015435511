import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import { getAssetDomain } from '../../../constants/assets';

export default class SingleSelectCheckBox extends Component {
    static propTypes = {
        options: PropTypes.object.isRequired,
        customClassName: PropTypes.string,
        onSelectionChange: PropTypes.func,
        hideCheckBox: PropTypes.bool,
        id: PropTypes.string,
    };

    state = {
        selected: '',
    };

    static getDerivedStateFromProps(nextProps) {
        if (nextProps.selectOption) {
            return { selected: nextProps.selectOption };
        }
        return null;
    }

    isChecked = (type) => {
        const { selected } = this.state;
        return type === selected;
    }

    selectChanged = (type) => {
        this.props.onSelectionChange && this.props.onSelectionChange(type);
        this.setState({ selected: type });
    }

    render() {
        const {
            options, customClassName, hideCheckBox, id = '0',
        } = this.props;
        return (
            <div>
                {
                    options &&
                    Object.keys(options).map(type => (
                        <Fragment key={`${type}-${id}`}>
                            <label
                                className={`option ${customClassName}${this.isChecked(type) ? ' label-selected' : ''}${options[type].isDisabled ? ' disabled' : ''}`}
                                htmlFor={`${type}-option`}
                                data-tip={options[type].isDisabled ? options[type].tooltipText : ''}
                                data-for={`disabled-option-${type}-${id}`}
                            >
                                <input
                                    data-testid="single-select-checkbox"
                                    type="radio"
                                    data-name={type}
                                    checked={this.isChecked(type)}
                                    onChange={() => this.selectChanged(type)}
                                    name="radio"
                                    id={`${type}-option`}
                                    disabled={options[type].isDisabled}
                                />
                                <span className={`check-icon ${hideCheckBox && 'hidden'}`}>
                                    <img src={getAssetDomain('tick-icon.png')} alt="" />
                                </span>
                                <span
                                    data-testid="single-select-checkbox-label" className={`checkbox-label${this.isChecked(type) ? ' selected' : ''}`}
                                >
                                    {options[type].label}
                                </span>
                            </label>
                            <ReactTooltip
                                id={`disabled-option-${type}-${id}`}
                                className="custom-tool-tip"
                                place="right"
                                effect="solid"
                                event="mouseover"
                                eventOff="mouseleave"
                            />
                        </Fragment>
                    ))
                }
            </div>
        );
    }
}
