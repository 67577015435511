import { memo } from 'react';
import PropTypes from 'prop-types';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import MenuList from '@mui/material/MenuList';
import MapTypeConstant from '../../../../../constants/mapTypeConstant';
import MapType from './MapType';

const {
    ESRI_SATELLITE, ESRI_ROAD, ROADMAP, MAP_TYPES,
} = MapTypeConstant;

const EsriMapTypes = memo(({
    handleClose,
    setMapTypeIdState,
    mapTypeIdState,
    triggerMapViewChangeEvent,
}) => {
    const roadMap = mapTypeIdState === ESRI_ROAD;
    const satellite = mapTypeIdState === ESRI_SATELLITE;

    // put in checkbox boolean value and switch on/off the esri base map layer
    const switchEsriMapTypes = (checked, esriMapType) => {
        if (checked) {
            setMapTypeIdState(esriMapType);
        } else {
            setMapTypeIdState(ROADMAP);
        }
    };

    const onChangeSatellite = (event) => {
        const isChecked = !satellite;
        switchEsriMapTypes(isChecked, ESRI_SATELLITE);
        handleClose(event);
        triggerMapViewChangeEvent(ESRI_SATELLITE);
    };

    const onChangeRoadMap = (event) => {
        const isChecked = !roadMap;
        switchEsriMapTypes(isChecked, ESRI_ROAD);
        handleClose(event);
        triggerMapViewChangeEvent(ESRI_ROAD);
    };

    return (
        <ClickAwayListener onClickAway={handleClose}>
            <MenuList>
                <MapType
                    data-testid="esri-map-type-satellite"
                    onClick={onChangeSatellite}
                    checked={satellite}
                >
                    { MAP_TYPES[ESRI_SATELLITE].label }
                </MapType>
                <MapType
                    data-testid="esri-map-type-road"
                    onClick={onChangeRoadMap}
                    checked={roadMap}
                >
                    { MAP_TYPES[ESRI_ROAD].label }
                </MapType>
            </MenuList>
        </ClickAwayListener>
    );
});
EsriMapTypes.propTypes = {
    handleClose: PropTypes.func.isRequired,
    setMapTypeIdState: PropTypes.func.isRequired,
    mapTypeIdState: PropTypes.string.isRequired,
    triggerMapViewChangeEvent: PropTypes.func,
};
export default EsriMapTypes;
