export const ErrorType = {
    PAGE_ERROR: 'PAGE_ERROR', // error that affects the whole page or the page itself
};

export const Titles = {
    SEARCH_STRING: '/titles/',
    NZ_TITLES_URL: '/titles?propertyId='
};

const URI = {
    LOGOUT: '/logout.html',
    LOGIN: '/login.html',
    PROPERTY: (rootUrl, propertyId) => `${rootUrl}/property/detail.html?propertyId=${propertyId}`,
    SEARCH_RESULT: rootUrl => `${rootUrl}/`,
    DASHBOARD: rootUrl => `${rootUrl}/`,
};

export default URI;
