import React from 'react';
import PropTypes from 'prop-types';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const DropdownArrowIcon = props => (props.isOpen ? <ArrowDropUpIcon sx={props.sx} /> : <ArrowDropDownIcon sx={props.sx} />);

DropdownArrowIcon.propTypes = {
    isOpen: PropTypes.bool,
    sx: PropTypes.object,
};

export default DropdownArrowIcon;
