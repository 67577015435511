import * as BATCH_PARAMS from '../../constants/batchSearch/batchSearch';
import Builder from './SavedSearchV1Builder';
import SearchFilterHelper from './SearchFilterHelper';
const ALL = 'all';
const IS_RECENTLY_SOLD = 'isRecentlySold';
const IS_FOR_SALE = 'isForSale';
const IS_FOR_RENT = 'isForRent';
const tabs = [ALL, IS_RECENTLY_SOLD, IS_FOR_SALE, IS_FOR_RENT];
const ARRAY_TYPE_FIELDS = [
    BATCH_PARAMS.SUB_TYPE_PARAM,
    BATCH_PARAMS.LAND_USE_PARAM,
    BATCH_PARAMS.DEVELOPMENT_ZONE_DESCRIPTION_PARAM,
    BATCH_PARAMS.SALES_LAST_CAMPAIGN_LISTED_TYPE_PARAM,
    BATCH_PARAMS.LATEST_CONSUMER_SALE_CONFIDENCE_PARAM,
];
const v1Builders = {
    [BATCH_PARAMS.TYPE_PARAM]: Builder.joinStringV1Builder,
    [BATCH_PARAMS.IS_ACTIVE_PARAM]: Builder.joinStringV1Builder, // 'True,False'
    [BATCH_PARAMS.SALES_LAST_SALE_SOURCE_PARAM]: Builder.joinStringV1Builder,
    [BATCH_PARAMS.BEDS_PARAM]: Builder.rangeToV1Builder,
    [BATCH_PARAMS.BATHS_PARAM]: Builder.rangeToV1Builder,
    [BATCH_PARAMS.CAR_SPACES_PARAM]: Builder.rangeToV1Builder,
    [BATCH_PARAMS.DINING_ROOMS]: Builder.rangeToV1Builder,
    [BATCH_PARAMS.LAND_AREA_PARAM]: Builder.rangeToV1Builder,
    [BATCH_PARAMS.FAMILY_RUMPUS_ROOMS]: Builder.rangeToV1Builder,
    [BATCH_PARAMS.FLOOR_LEVELS_INSIDE]: Builder.rangeToV1Builder,
    [BATCH_PARAMS.LOUNGE_ROOMS]: Builder.rangeToV1Builder,
    [BATCH_PARAMS.LOUNGE_DINING_ROOMS_COMBINED]: Builder.rangeToV1Builder,
    [BATCH_PARAMS.TOILETS]: Builder.rangeToV1Builder,
    [BATCH_PARAMS.NUMBER_OF_STUDY_ROOMS]: Builder.rangeToV1Builder,
    [BATCH_PARAMS.KITCHENS]: Builder.rangeToV1Builder,
    [BATCH_PARAMS.LOCKUP_GARAGES]: Builder.rangeToV1Builder,
    [BATCH_PARAMS.NUMBER_OF_TENNIS_COURTS]: Builder.rangeToV1Builder,
    [BATCH_PARAMS.DISTANCE_HOSPITAL]: Builder.rangeToV1Builder,
    [BATCH_PARAMS.DISTANCE_RIVER_FRONTAGE]: Builder.rangeToV1Builder,
    [BATCH_PARAMS.DISTANCE_SCHOOL]: Builder.rangeToV1Builder,
    [BATCH_PARAMS.DISTANCE_SEA_RIVER_CANAL]: Builder.rangeToV1Builder,
    [BATCH_PARAMS.DISTANCE_SHOPS]: Builder.rangeToV1Builder,
    [BATCH_PARAMS.FACTORY_AREA_PARAM]: Builder.rangeToV1Builder,
    [BATCH_PARAMS.MEZZANINE_AREA_PARAM]: Builder.rangeToV1Builder,
    [BATCH_PARAMS.OFFICE_AREA_PARAM]: Builder.rangeToV1Builder,
    [BATCH_PARAMS.OFFICE_SHOWROOM_AREA_PARAM]: Builder.rangeToV1Builder,
    [BATCH_PARAMS.OTHER_AREA_PARAM]: Builder.rangeToV1Builder,
    [BATCH_PARAMS.SHOP_AREA_PARAM]: Builder.rangeToV1Builder,
    [BATCH_PARAMS.SHOW_ROOM_AREA_PARAM]: Builder.rangeToV1Builder,
    [BATCH_PARAMS.WAREHOUSE_AREA_PARAM]: Builder.rangeToV1Builder,
    [BATCH_PARAMS.WORKSHOP_AREA_PARAM]: Builder.rangeToV1Builder,
    [BATCH_PARAMS.HOUSES_ON_FARM_PARAM]: Builder.rangeToV1Builder,
    [BATCH_PARAMS.IRRIGATED_AREA_PARAM]: Builder.rangeToV1Builder,
    [BATCH_PARAMS.DAMS_BORES_PARAM]: Builder.rangeToV1Builder,
    [BATCH_PARAMS.BUILDING_AREA]: Builder.rangeToV1Builder,
    [BATCH_PARAMS.EQUIVALENT_BUILDING_AREA]: Builder.rangeToV1Builder,
    [BATCH_PARAMS.FLOOR_LEVEL_OF_UNIT]: Builder.rangeToV1Builder,
    [BATCH_PARAMS.STREET_FRONTAGE]: Builder.rangeToV1Builder,
    [BATCH_PARAMS.FLOOR_AREA]: Builder.rangeToV1Builder,
    [BATCH_PARAMS.FLOOR_LEVEL_IS_INSIDE_UNIT]: Builder.rangeToV1Builder,
    [BATCH_PARAMS.FLATS]: Builder.rangeToV1Builder,
    [BATCH_PARAMS.TOTAL_FLOORS_IN_BUILDING]: Builder.rangeToV1Builder,
    [BATCH_PARAMS.PADDOCKS_YARDS_PARAM]: Builder.rangeToV1Builder,
    [BATCH_PARAMS.YEAR_BUILT_PARAM]: Builder.rangeToV1Builder,
    [BATCH_PARAMS.YEAR_BUILDING_REFURBISHED]: Builder.rangeToV1Builder,
    [BATCH_PARAMS.YEAR_ESTABLISHED]: Builder.rangeToV1Builder,
    [BATCH_PARAMS.CAPITAL_VALUE_PARAM]: Builder.rangeToV1Builder,
    [BATCH_PARAMS.VALUATION_IMPROVEMENTS_VALUE]: Builder.rangeToV1Builder,
    [BATCH_PARAMS.VALUATION_LAND_VALUE]: Builder.rangeToV1Builder,
    [BATCH_PARAMS.LATEST_CONSUMER_SALE_ESTIMATE_PARAM]: Builder.rangeToV1Builder,
    [BATCH_PARAMS.CURRENT_UNIMPROVED_LAND_VALUE_PARAM]: Builder.rangeToV1Builder,
    [BATCH_PARAMS.UNIMPROVED_CAPITAL_VALUE_PARAM]: Builder.rangeToV1Builder,
    [BATCH_PARAMS.SALES_LAST_SOLD_PRICE_PARAM]: Builder.rangeToV1Builder,
    [BATCH_PARAMS.SALES_LAST_CAMPAIGN_LAST_LISTED_PRICE_PARAM]: Builder.rangeToV1Builder,
    [BATCH_PARAMS.RENTAL_LAST_CAMPAIGN_LAST_LISTED_PRICE_PARAM]: Builder.rangeToV1Builder,
    [BATCH_PARAMS.FEE_CODE_PARAM]: Builder.defaultV1Builder,
    [BATCH_PARAMS.SALES_CLASSIFICATION_PARAM]: Builder.defaultV1Builder,
    [BATCH_PARAMS.MATERIALS_IN_EXTERNAL_WALLS]: Builder.defaultV1Builder,
    [BATCH_PARAMS.MATERIALS_IN_ROOF]: Builder.defaultV1Builder,
    [BATCH_PARAMS.HERITAGE_OTHER_CLASSIFICATION]: Builder.defaultV1Builder,
    [BATCH_PARAMS.RENTAL_LAST_CAMPAIGN_LAST_LISTED_PERIOD]: Builder.defaultV1Builder,
    [BATCH_PARAMS.AIR_CONDITIONED]: Builder.defaultV1Builder,
    [BATCH_PARAMS.BUILT_IN_WARDROBES]: Builder.defaultV1Builder,
    [BATCH_PARAMS.DUCTED_HEATING]: Builder.defaultV1Builder,
    [BATCH_PARAMS.DUCTED_VACUUM]: Builder.defaultV1Builder,
    [BATCH_PARAMS.FIREPLACE]: Builder.defaultV1Builder,
    [BATCH_PARAMS.OPEN_PLAN]: Builder.defaultV1Builder,
    [BATCH_PARAMS.POLISHED_FLOORS]: Builder.defaultV1Builder,
    [BATCH_PARAMS.FENCED]: Builder.defaultV1Builder,
    [BATCH_PARAMS.RENOVATED]: Builder.defaultV1Builder,
    [BATCH_PARAMS.GRANNY_FLAT_SELF_CONTAINED]: Builder.defaultV1Builder,
    [BATCH_PARAMS.BOAT_RAMP_MOORING_SPACE]: Builder.defaultV1Builder,
    [BATCH_PARAMS.SWIMMING_POOL]: Builder.defaultV1Builder,
    [BATCH_PARAMS.TREES_ON_LAND]: Builder.defaultV1Builder,
    [BATCH_PARAMS.CANAL_FRONTAGE]: Builder.defaultV1Builder,
    [BATCH_PARAMS.COAST_FRONTAGE]: Builder.defaultV1Builder,
    [BATCH_PARAMS.GOLF_FRONTAGE]: Builder.defaultV1Builder,
    [BATCH_PARAMS.HIGH_EXPOSURE]: Builder.defaultV1Builder,
    [BATCH_PARAMS.LAKE_FRONTAGE]: Builder.defaultV1Builder,
    [BATCH_PARAMS.RIVER_FRONTAGE]: Builder.defaultV1Builder,
    [BATCH_PARAMS.SCENIC_VIEW]: Builder.defaultV1Builder,
    [BATCH_PARAMS.WATER_FRONTAGE]: Builder.defaultV1Builder,
    [BATCH_PARAMS.ACCESS_AVAILABLE_24_HOURS_PARAM]: Builder.defaultV1Builder,
    [BATCH_PARAMS.ACCESS_SECURITY_INSTALLED_PARAM]: Builder.defaultV1Builder,
    [BATCH_PARAMS.CLOSE_TO_PUBLIC_TRANSPORT_PARAM]: Builder.defaultV1Builder,
    [BATCH_PARAMS.LIFT_INSTALLED_PARAM]: Builder.defaultV1Builder,
    [BATCH_PARAMS.ON_MAIN_ROAD_PARAM]: Builder.defaultV1Builder,
    [BATCH_PARAMS.SEWAGE_PARAM]: Builder.defaultV1Builder,
    [BATCH_PARAMS.TOWN_SEWERAGE_AVAILABLE_PARAM]: Builder.defaultV1Builder,
    [BATCH_PARAMS.TOWN_WATER_AVAILABLE_PARAM]: Builder.defaultV1Builder,
    [BATCH_PARAMS.WATER_PARAM]: Builder.defaultV1Builder,
    [BATCH_PARAMS.ALL_WEATHER_ACCESS_TO_LAND_PARAM]: Builder.defaultV1Builder,
    [BATCH_PARAMS.ALL_WEATHER_ROADS_TO_FARM_PARAM]: Builder.defaultV1Builder,
    [BATCH_PARAMS.SEALED_ROADS_TO_PROPERTY]: Builder.defaultV1Builder,
    [BATCH_PARAMS.FREE_STANDING_BUILDING]: Builder.defaultV1Builder,
    [BATCH_PARAMS.PERMANENT_WATER]: Builder.defaultV1Builder,
    [BATCH_PARAMS.IS_ACTIVE_FOR_SALE_CAMPAIGN_PARAM]: Builder.defaultV1Builder,
    [BATCH_PARAMS.IS_ACTIVE_FOR_RENT_CAMPAIGN_PARAM]: Builder.defaultV1Builder,
    [BATCH_PARAMS.SALES_LAST_SALE_IS_ARMS_LENGTH_PARAM]: Builder.defaultV1Builder,
    [BATCH_PARAMS.SALES_LAST_SALE_CONTRACT_DATE_PARAM]: Builder.dateRangeToV1Builder,
    [BATCH_PARAMS.SALES_LAST_CAMPAIGN_END_DATE_PARAM]: Builder.dateRangeToV1Builder,
    [BATCH_PARAMS.SALES_LAST_CAMPAIGN_START_DATE_PARAM]: Builder.dateRangeToV1Builder,
    [BATCH_PARAMS.RENTAL_LAST_CAMPAIGN_END_DATE_PARAM]: Builder.dateRangeToV1Builder,
    [BATCH_PARAMS.RENTAL_LAST_CAMPAIGN_START_DATE_PARAM]: Builder.dateRangeToV1Builder,
    [BATCH_PARAMS.SALES_LAST_SALE_SETTLEMENT_DATE_PARAM]: Builder.dateRangeToV1Builder,
    [BATCH_PARAMS.VALUATION_DATE_PARAM]: Builder.dateRangeToV1Builder,
    [BATCH_PARAMS.UNIMPROVED_CAPITAL_VALUE_DATE_PARAM]: Builder.dateRangeToV1Builder,
    // add the keys in ARRAY_TYPE_FIELDS constant
    [BATCH_PARAMS.SUB_TYPE_PARAM]: Builder.arrayToV1Builder,
    [BATCH_PARAMS.LAND_USE_PARAM]: Builder.arrayToV1Builder,
    [BATCH_PARAMS.DEVELOPMENT_ZONE_DESCRIPTION_PARAM]: Builder.arrayToV1Builder,
    [BATCH_PARAMS.SALES_LAST_CAMPAIGN_LISTED_TYPE_PARAM]: Builder.arrayToV1Builder,
    [BATCH_PARAMS.LATEST_CONSUMER_SALE_CONFIDENCE_PARAM]: Builder.arrayToV1Builder,
    // custom builder
    [BATCH_PARAMS.LATEST_PROPERTY_CAMPAIGN_PARAM]: Builder.keywordsBuilder,
};
const isRentalOccupancy = (statusType, request, isAndNotOperation) => {
    const isStatusAll = statusType === ALL;
    const isRentalDate = request?.field === BATCH_PARAMS.RENTAL_LAST_CAMPAIGN_END_DATE_PARAM;
    const isSalesDate = request?.field === BATCH_PARAMS.SALES_LAST_SALE_CONTRACT_DATE_PARAM;

    const result = isStatusAll && (isRentalDate || (isSalesDate && isAndNotOperation));

    return result;
};
const getBuilderFnByFieldName = (request) =>
    (accum, item, statusType, isAndNotOperation) => {
        if (isRentalOccupancy(statusType, request, isAndNotOperation)) {
            accum.rentalOccupancy = true;
        } else if (v1Builders[request?.field]) {
            accum[request?.field] = v1Builders[request?.field](item, accum, statusType);
        } else if (v1Builders[request?.parentPath]) {
            const builder = v1Builders[request?.parentPath](item);
            accum[builder?.key] = builder?.value;
        }
};

const formatFilterForV1 = (filters, formattedFilters = {}, statusType, isAndNotOperation = false) => {
    return filters.reduce((accum, item) => {
        const isAndNot = item.operation === BATCH_PARAMS.AND_NOT_OPERATOR;
        if (item?.filters) {
            return Object.keys(item).reduce((itemAccum, key) => {
                if (key === 'filters') {
                    return formatFilterForV1(item[key], accum, statusType, isAndNot);
                }
                return itemAccum;
            }, {});
        }

        // if the field is not a filter, it will proceed with v1 conversion
        // the converted value will be added to the accumulator
        const convertToV1 = getBuilderFnByFieldName(item);
        if (convertToV1) {
            convertToV1(accum, item, statusType, isAndNotOperation);
        }

        // if any conditions above are not met,
        // the current field will be skipped and won't be added in the accumulator
        return accum;
    }, {...formattedFilters });
};

export const formatSavedSearchesAsV1 = (savedSearch) => {
    const { customFields, filters, selectedTab } = savedSearch;
    const [ firstRequest ] = filters.requests;
    const sort = firstRequest?.resultsFormat?.sort || '+address';
    const filterSet = firstRequest?.filters || [];
    const statusType = tabs[selectedTab];

    let formattedFilterSet = {};
    filterSet.forEach((set) => {
        Object.keys(set).forEach(key => {
            if (key === 'filters') {
                formattedFilterSet = formatFilterForV1(set[key], formattedFilterSet, statusType);
            }
        });
    });

    Object.keys(formattedFilterSet).forEach(key => {
        if (ARRAY_TYPE_FIELDS.includes(key)) {
            formattedFilterSet[key] = SearchFilterHelper.constructRapidMultiSelectParams(formattedFilterSet[key]);
        }
    });

    if (statusType !== ALL) {
        formattedFilterSet[statusType] = true;
    }

    formattedFilterSet.sort = sort;
    formattedFilterSet.isCustomDate = Builder.getIsCustomDateFromCustomFields(customFields, selectedTab);

    return { ...savedSearch, filters: formattedFilterSet};
};