import React from 'react';
import PropTypes from 'prop-types';
import Backdrop from '@mui/material/Backdrop';
import BlockUiLoader from './BlockUiLoader';

const defaultStyle = {
    display: 'flex',
    zIndex: 10,
    width: '100%',
    height: '100%',
    position: 'absolute',
    backgroundColor: 'transparent',
};
const BlockUi = React.memo(({
    blocking = false,
    children,
    renderChildren = true,
    containerProps: {
        id = 'backdrop-cont-id',
        className = 'backdrop-cont',
        testId = 'backdrop-cont-testid',
        position = 'inherit',
        containerCss = {},
    } = {},
    backdropProps: {
        loaderSize = '55px',
        style = {},
    } = {},
    message = null,
    loader,
}) => {
    const showDisplayOnLoading = renderChildren || !blocking;
    const mergedStyle = { ...defaultStyle, ...style };

    return (
        <div
            id={id}
            data-testid={testId}
            className={className}
            style={{ position: position, ...containerCss }}
        >
            <Backdrop
                sx={mergedStyle}
                open={blocking}
            >
                { loader || <BlockUiLoader display={!blocking && 'none'} size={loaderSize} /> }
                <div className="backdrop-message">
                    { message }
                </div>
            </Backdrop>
            {showDisplayOnLoading && children}
        </div>
    );
});

BlockUi.propTypes = {
    blocking: PropTypes.bool,
    renderChildren: PropTypes.bool,
    containerProps: PropTypes.shape({
        id: PropTypes.string,
        className: PropTypes.string,
        testId: PropTypes.string,
        position: PropTypes.string,
        containerCss: PropTypes.object,
    }),
    backdropProps: PropTypes.shape({
        loaderSize: PropTypes.string,
        style: PropTypes.object,
    }),
};
export default BlockUi;
