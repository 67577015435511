import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import { withRouter } from 'react-router';
import { invalidateCruxAppError } from '../actions/errorHandler';
import { getAssetDomain } from '../constants/assets';
import * as Route from '../constants/routes';
import { trackEvent } from '../actions/segment';
import Segment from '../helpers/Segment';
import ErrorMsg from '../constants/errorMsg';

export class ErrorHandler extends Component {
    static propTypes = {
        error: PropTypes.object,
        dispatch: PropTypes.func,
    };

    componentWillUnmount() {
        const { dispatch } = this.props;
        dispatch(invalidateCruxAppError());
    }

    redirectToDashboard = () => {
        const { history, dispatch } = this.props;
        // Dispatch mix panel event RETURN_HOME
        const route = Route.getRoute(this.props.match.path);
        dispatch(invalidateCruxAppError());
        dispatch(trackEvent(Segment.returnHome({ page: route.pageContext })));
        // redirect to homepage
        history.push(Route.routeCodes.HOME.path);
    };

    reloadPage = () => {
        const { dispatch } = this.props;
        // Dispatch mix panel event RELOAD_PAGE
        const route = Route.getRoute(this.props.match.path);
        dispatch(trackEvent(Segment.reloadPage({ page: route.pageContext })));
        window.location.reload();
    }

    render() {
        const { error } = this.props;
        const route = Route.getRoute(this.props.match && this.props.match.path);
        let homePage = false;
        if (route === Route.routeCodes.HOME || route === Route.routeCodes.DASHBOARD) {
            homePage = true;
        }
        let errorMessage;
        if (error) {
            errorMessage = error.message;
        } else {
            errorMessage = ErrorMsg.SERVER_SLOW_RESPONSE;
        }

        return (
            <div className="error-handler container">
                <div className="row">
                    <div className="col-md-6 col-md-offset-3 text-center">
                        <img className="crux-error-icon" src={getAssetDomain('house-error.png')} alt="CRUX error" />
                        <h4>Sorry, something went wrong</h4>
                        <p className="error-message">
                            { errorMessage }
                        </p>
                        <Button
                            onClick={this.reloadPage}
                            className="reload-page-button"
                            variant="contained"
                            color="primary"
                        >
                            Reload Page
                        </Button>
                        { !homePage &&
                        <Button
                            onClick={this.redirectToDashboard}
                            className="homepage-button"
                            variant="contained"
                            color="primary"
                        >
                            Return Home
                        </Button> }
                    </div>
                </div>
            </div>
        );
    }
}
const ConnectedErrorHandler = withRouter(connect(state => ({
    error: state.errorHandler.get('error'),
}))(ErrorHandler));
ConnectedErrorHandler.displayName = 'ErrorHandler';

export default ConnectedErrorHandler;
