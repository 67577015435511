import React from 'react';
import Commons from 'js/helpers/Commons';
import environments from 'js/constants/environments';

export const getAssetDomain = (fileName = '') => {
    // change if necessary
    const assetDomain = 'https://d3ihm20vnhytj4.cloudfront.net/crux';
    try {
        const cruxLocalStorage = window.localStorage;
        const cruxStore = JSON.parse(cruxLocalStorage.getItem('persist:crux'));
        const storedConfig = JSON.parse(cruxStore.config);
        const environment = Commons.get(storedConfig, 'appenv.env');
        switch (environment) {
            case environments.UAT:
                return `${assetDomain}/uat/crux_assets/${fileName}`;
            case environments.PRODUCTION:
                return `${assetDomain}/prod/crux_assets/${fileName}`;
            default:
                return `${assetDomain}/dev/crux_assets/${fileName}`;
        }
    } catch (e) {
        return `${assetDomain}/prod/crux_assets/${fileName}`;
    }
};

export const LOGOS = {
    RP_HEADER: getAssetDomain('logos/rp-header.png'),
    PG_HEADER: getAssetDomain('logos/pg-header.png'),
    ORANGE_CL_LOGO: getAssetDomain('logos/orange-cl-logo.png'),
    FAVICON: getAssetDomain('logos/favicon.png'),
}

export const FILTERS = {
    LAND_USE: 'filters/land_use.json',
    DEV_ZONE: 'filters/dev_zone.json',
    PROPERTY_SUB_TYPE: 'filters/property_sub_type.json',
}

export const FILTERS_NZ = {
    LAND_USE: 'filters/land_use_nz.json',
    DEV_ZONE: 'filters/dev_zone_nz.json',
    PROPERTY_SUB_TYPE: 'filters/property_sub_type_nz.json',
}

export const COMPLIANCE_NOTES_AU = 'Marketing_Direct_Compliance_AU.txt';
export const COMPLIANCE_NOTES_NZ = 'Marketing_Direct_Compliance_NZ.txt';

const ATTRIBUTES = {
    BEDS: <img src={getAssetDomain('bed@2x.png')} className="half-opacity" alt="Bedrooms" width="27px" />,
    BATHS: <img src={getAssetDomain('bathroom@2x.png')} className="half-opacity" alt="Bathrooms" width="27px" />,
    CARS: <img src={getAssetDomain('car@2x.png')} className="half-opacity" alt="Car Spaces" width="30px" />,
    LANDSIZE: <img src={getAssetDomain('land@2x.png')} className="half-opacity" alt="Land Size" width="26px" />,
};

const ATTRIBUTES_MIN = {
    BEDS: <img src={getAssetDomain('bed@2x.png')} className="half-opacity" alt="Bedrooms" width="22px" />,
    BATHS: <img src={getAssetDomain('bathroom@2x.png')} className="half-opacity" alt="Bathrooms" width="22px" />,
    CARS: <img src={getAssetDomain('car@2x.png')} className="half-opacity" alt="Car Spaces" width="25px" />,
    LANDSIZE: <img src={getAssetDomain('land@2x.png')} className="half-opacity" alt="Land Size" width="21px" />,
};

export default {
    ATTRIBUTES,
    ATTRIBUTES_MIN,
};
