import { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { reduxForm, reset, formValueSelector } from 'redux-form';
import PropTypes from 'prop-types';
import Reports from 'js/constants/reports';
import { getSuburbProfileReport } from 'js/actions/pdfReports';
import ReportForm from 'js/components/reports/ReportForm';
import ReportFormFields from 'js/components/reports/ReportFormFields';
import ReportHelper from 'js/helpers/report/ReportHelper';

const selector = formValueSelector('suburbReportForm');
export class SuburbReport extends Component {
    static propTypes = {
        suburbAddress: PropTypes.string,
        dispatch: PropTypes.func,
        toggleReportModal: PropTypes.func,
        clapiUsrDetail: PropTypes.object,
        entryPoint: PropTypes.string,
        propertyId: PropTypes.number,
    };

    static defaultProps = {
        entryPoint: null,
    };

    constructor(props) {
        super(props);
        this.state = {
            id: null,
            filename: null,
            includeEmail: true,
            includePhone: true,
        };

        const { email, phoneNumber } = ReportHelper.getUserContactDetail(props.clapiUsrDetail);
        this.email = email;
        this.phoneNumber = phoneNumber;
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.localityId !== prevState.id) {
            const dateNow = moment().format('dd-mm-yyyy');
            return {
                id: nextProps.localityId,
                filename: `Suburb_Report_${nextProps.suburbAddress && nextProps.suburbAddress.replace(/\s/g, '_')}_${dateNow}`,
            };
        }
        return null;
    }

    handler = () => {
        const {
            dispatch,
            route,
            preparedFor,
            preparedBy,
            propertyId,
            entryPoint,
            suburbAddress,
        } = this.props;

        const {
            id, includeEmail, includePhone,
        } = this.state;

        dispatch(getSuburbProfileReport(
            propertyId,
            id,
            route,
            Reports.SUBURB_REPORT,
            entryPoint,
            suburbAddress,
            {
                customerInput: {
                    customerFirstName: preparedFor,
                },
                requesterInput: {
                    requesterFirstName: preparedBy,
                    requesterEmail: includeEmail ? this.email : null,
                    requesterPhone: includePhone ? this.phoneNumber : null,
                },
            },
        ));
    };

    resetForm() {
        this.props.dispatch(reset('suburbReportForm'));
    }

    handleKeyPress = (e) => {
        if (e.key === 'Enter') e.preventDefault();
    };

    handleIOSToggle = (e, changedState) => {
        this.setState({ [changedState]: e.target.checked });
    };

    renderFields = label => (
        <ReportFormFields
            label={label}
            handleKeyPress={this.handleKeyPress}
            includeEmail={this.state.includeEmail}
            includePhone={this.state.includePhone}
            handleIOSToggle={this.handleIOSToggle}
            email={this.email}
            phoneNumber={this.phoneNumber}
        />
    );

    handleResetForm = () => {
        this.props.toggleReportModal();
        this.resetForm();
    };

    render() {
        const { entryPoint, propertyId } = this.props;
        const fields = this.renderFields('Edit Report Cover Page Details');
        return (
            <div>
                <ReportForm
                    propertyId={propertyId}
                    icon="suburb-report-white.png"
                    title="Suburb Report"
                    fields={fields}
                    toggleReportModal={this.handleResetForm}
                    triggerHandler={this.handler}
                    reportType={Reports.SUBURB_REPORT}
                    reportName={this.props.suburbAddress}
                    filename={this.state.filename}
                    entryPoint={entryPoint}
                />
            </div>
        );
    }
}

export default connect(state => ({
    preparedFor: selector(state, 'preparedFor'),
    preparedBy: selector(state, 'preparedBy'),
    clapiUsrDetail: state.clapi.get('usrDetail'),
}))(reduxForm({
    form: 'suburbReportForm',
    initialValues: {
        preparedFor: '',
        preparedBy: '',
    },
    destroyOnUnmount: true,
})(SuburbReport));
