import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ErrorHandler from '../../views/ErrorHandler';
import { cruxAppError, invalidateCruxAppError } from '../../actions/errorHandler';
import Bugsnag from '../../bugsnag';
import { CruxPageLayout } from '../layout/CruxPageLayout';
import Commons from '../../helpers/Commons';
import ErrorMsg from '../../constants/errorMsg';
import BareMenu from '../../components/errorHandler/BareMenu';

/**
 * The purpose of this H.O.C. is to prevent propagation of error at Page level to parent component/s
 * @param WrappedComponent
 * @returns ErrorHandler when error is encounter otherwise return WrappedComponent
 */
export function withPageErrorHandler(WrappedComponent) {
    return (class extends Component {
        static displayName = 'CruxPageErrorBoundary';
        static propTypes = {
            dispatch: PropTypes.func.isRequired,
        };
        constructor(props) {
            super(props);
            this.state = {
                hasError: false,
                match: null,
            };
        }

        static getDerivedStateFromProps(nextProps, prevState) {
            const nextMatch = Commons.get(nextProps, 'header.props.match');
            if (prevState.match !== nextMatch) {
                if (prevState.hasError) {
                    return {
                        hasError: false,
                        match: nextMatch,
                    };
                }
                return {
                    match: nextMatch,
                };
            }

            return null;
        }

        static getDerivedStateFromError() {
            return { hasError: true };
        }

        componentDidUpdate(prevProps, prevState) {
            const { match: prevMatch, hasError } = prevState;
            const { match } = this.state;
            if (hasError && prevMatch !== match) {
                this.props.dispatch(invalidateCruxAppError());
            }
        }

        componentDidCatch(error) {
            Bugsnag.notify(error);
            this.props.dispatch(cruxAppError(ErrorMsg.UNEXPECTED_ERROR));
        }

        render() {
            const {
                dispatch, history, error, footer,
            } = this.props;
            if (this.state.hasError) {
                return (
                    <CruxPageLayout
                        header={<BareMenu
                            match={this.state.match}
                            dispatch={dispatch}
                            history={history}
                        />}
                        footer={error && footer}
                    >
                        <ErrorHandler />
                    </CruxPageLayout>
                );
            }
            return <WrappedComponent {...this.props} />;
        }
    });
}

export default function (WrappedComponent) {
    return connect(state => ({
        error: state.errorHandler.get('error'),
    }))(withPageErrorHandler(WrappedComponent));
}
