// TODO: Rapid Search - remove
export default {
    ALL: { type: 'All', pTypes: 'All' },
    HOUSE: { type: 'House', pTypes: 'House' },
    UNIT: { type: 'Unit', pTypes: 'Unit,Flats' },
    COMMERCIAL: { type: 'Commercial', pTypes: 'Commercial,Business' },
    LAND: { type: 'Land', pTypes: 'Land' },
    FARM: { type: 'Farm', pTypes: 'Farm' },
    OTHER: { type: 'Other', pTypes: 'Other,Storage_Unit,Community' },
};

export const allPropertyTypes = [
    'Business',
    'Community',
    'Commercial',
    'Farm',
    'Flats',
    'House',
    'Land',
    'Unit',
];
