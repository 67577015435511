import { createContext, useState, useContext } from 'react';

export const FullPageModalContext = createContext({});
export const useFullPageModalContext = () => useContext(FullPageModalContext);

export default (props) => {
    const [open, setOpen] = useState(false);
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

    return (
        <FullPageModalContext.Provider
            value={{
                open,
                setOpen,
                confirmDialogOpen,
                setConfirmDialogOpen,
            }}
        >
            {props.children}
        </FullPageModalContext.Provider>
    );
}
