import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import SimpleBar from 'simplebar-react';

const ScrollableContainer = forwardRef(({
    children,
    height,
    width,
    autoHide = false,
    staticContext,
    ...props
}, ref) => {
    const styles = {
        ...(height && { height }),
        ...(width && { width }),
    };

    return (
        <SimpleBar
            ref={ref}
            className="simplebar-wrap__custom-scroll"
            style={styles}
            autoHide={autoHide}
            {...props}
        >
            {children}
        </SimpleBar>
    );
});

ScrollableContainer.propTypes = {
    height: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
    ]),
    width: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
    ]),
    autoHide: PropTypes.bool,
    children: PropTypes.element,
    staticContext: PropTypes.any,
};

export default ScrollableContainer;
