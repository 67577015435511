import { Map as IMap } from 'immutable';
import * as Action from '../actions/territories';
export const initialState = () => IMap({
    territories: {
        list: [],
        fetching: null,
        error: false,
        success: false,
        selected: null,
    },
});

export default function reducer(state = initialState(), action = {}) {
    switch (action.type) {
        case Action.CLEAR_TERRITORIES: {
            return initialState();
        }
        case Action.GET_SAVED_TERRITORIES: {
            return state.mergeDeep(IMap({
                territories: {
                    fetching: true,
                },
            }));
        }
        case Action.GET_SAVED_TERRITORIES_SUCCESS: {
            const { territories, ...rest } = action.payload;
            return state.merge(IMap({
                territories: {
                    ...state.get('territories'),
                    ...rest,
                    list: territories,
                    fetching: false,
                    success: true,
                    error: false,
                },
            }));
        }
        case Action.GET_SAVED_TERRITORIES_FAIL: {
            return state.mergeDeep(IMap({
                territories: {
                    fetching: false,
                    error: true,
                },
            }));
        }
        case Action.SET_SELECTED_TERRITORY: {
            return state.mergeDeep(IMap({
                territories: {
                    selected: action?.payload?.id,
                },
            }))
        }
        default:
            return state;
    }
}
