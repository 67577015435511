import { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import MoreVert from '@mui/icons-material/MoreVert';
import Colors from '../../../constants/colors';
import DropdownModal from '../../common/modals/DropdownModal';

const MoreActions = (props) => {
    const [isDropdownVisible, setDropdownVisible] = useState(false);
    return (
        <div className="crux-search-more">
            <IconButton
                sx={{
                    backgroundColor: Colors.WHITE,
                    color: Colors.PRIMARY.MAIN,
                    borderRadius: '4px',
                    border: `1px solid ${Colors.PRIMARY.MAIN}`,
                    marginRight: '6px',
                    '& .MuiSvgIcon-root': {
                        fontSize: 22.28,
                    },
                }}
                disableRipple
                aria-label="More Actions"
                component="span"
                onClick={() => setDropdownVisible(!isDropdownVisible)}
                data-testid="more-actions"
                className={`more-actions-btn${(props.isChecked ? ' active' : '')}`}
            >
                <MoreVert />
            </IconButton>
            <DropdownModal
                onModalClose={() => setDropdownVisible(false)}
                isVisible={isDropdownVisible}
            >
                {props.children}
            </DropdownModal>
        </div>
    );
};

export default MoreActions;
