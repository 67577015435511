import React from 'react';
import { components } from 'react-select';
import DropdownArrowIcon from '../searchResults/rapidSearchFiltersV2/cruxFilterDropdown/components/DropdownArrowIcon';

// Custom dropdown arrow for react-select
const DropdownIndicator = props => (
    components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
            <DropdownArrowIcon sx={{color: '#38424a', marginRight: '-6px', marginLeft: '-6px' }} isOpen={props.selectProps.menuIsOpen} />
        </components.DropdownIndicator>
    )
);

export default DropdownIndicator;
