const degrees2metersLon = (lon) => {
    if (!lon) {
        return 0;
    }

    return lon * 20037508.34 / 180;
};

const meters2degreesX = (x) => {
    if (!x) {
        return 0;
    }

    return x * 180 / 20037508.34;
};

const degrees2metersLat = (lat) => {
    if (!lat) {
        return 0;
    }

    const y = Math.log(Math.tan((90 + lat) * Math.PI / 360)) / (Math.PI / 180);
    return y * 20037508.34 / 180;
};

const meters2degreesY = (y) => {
    if (!y) {
        return 0;
    }

    return Math.atan(Math.exp(y * Math.PI / 20037508.34)) * 360 / Math.PI - 90;
};

export default {
    degrees2metersLon,
    meters2degreesX,
    degrees2metersLat,
    meters2degreesY
};
