import React from 'react';
import PropTypes from 'prop-types';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
const InfoIcon = props => (
    <span data-testid={`tool-tip-info-icon`} className="icon-container" style={{...props.style}}>
        <InfoOutlinedIcon sx={{fontSize: `${props.size ? props.size : '16' }px`, verticalAlign: 'text-bottom'}}/>
    </span>
);

InfoIcon.propTypes = {
    style: PropTypes.object,
    size: PropTypes.number,
};

export default InfoIcon;
