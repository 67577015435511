export const SAVE_HERO_IMAGE = 'SAVE_HERO_IMAGE';
export const SAVE_LAND_USE_OPTIONS = 'SAVE_LAND_USE_OPTIONS';
export const SAVE_DEV_ZONE_OPTIONS = 'SAVE_DEV_ZONE_OPTIONS';
export const SAVE_PROPERTY_SUB_TYPE_OPTIONS = 'SAVE_PROPERTY_SUB_TYPE_OPTIONS';

export function saveHeroImage(heroImage) {
    return {
        type: SAVE_HERO_IMAGE,
        payload: heroImage,
    };
}

export function saveLandUseOptions(landUse) {
    return {
        type: SAVE_LAND_USE_OPTIONS,
        payload: landUse,
    };
}

export function saveDevZoneOptions(devZone) {
    return {
        type: SAVE_DEV_ZONE_OPTIONS,
        payload: devZone,
    };
}

export function savePropertySubTypeOptions(propertySubTypes) {
    return {
        type: SAVE_PROPERTY_SUB_TYPE_OPTIONS,
        payload: propertySubTypes,
    };
}
