import React from 'react';
import { List } from 'immutable';
import Constant from '../constants/constant';

export class DataRule {
    /**
     * @function getConfidenceLevel returns the confidence level based on the fsd score
     * @param {*} fsd
     */
    getConfidenceLevel(fsd, confidenceBasis) {
        let confidenceLevel;
        let confidence = Constant.FSD.Rental;

        if (confidenceBasis) {
            confidence = confidenceBasis;
        }
        if (fsd >= confidence.high.min && fsd <= confidence.high.max) {
            confidenceLevel = {
                color: '#7ED321',
                label: 'High Confidence',
            };
        } else if (fsd >= confidence.medium.min && fsd <= confidence.medium.max) {
            confidenceLevel = {
                color: '#F3B200',
                label: 'Medium Confidence',
            };
        } else { /** (fsd > 22 && fsd <= 29) * */
            confidenceLevel = {
                color: '#FF1310',
                label: 'Low Confidence',
            };
        }
        return confidenceLevel;
    }

    /**
     * @function formatAvm returns the formatted value of avm, eg 1234567 -> 1.24M
     * @param {*} avm = { lowEstimate, estimate, highEstimate }
     */
    formatAvm(avm) {
        const { lowEstimate, estimate, highEstimate } = avm;
        const formattedAvm = {
            lowEstimate: this.formatPrice(lowEstimate),
            estimate: this.formatPrice(estimate),
            highEstimate: this.formatPrice(highEstimate),
        };
        return formattedAvm;
    }

    /**
     * @function formatPrice returns the formatted value of price, eg 1234567 -> 1.24M
     * if decimalPlaceForThousands is set to 1, `100,450` will be evaluated as `$100.5k`.
     * @param {*} price
     * @param {*} currency
     * @param {*} decimalPlaceForThousands
     */
    formatPrice(price, currency = '$', decimalPlaceForThousands = 0) {
        let formattedPrice = `${currency}${price}`;
        if (price > 999 && price <= 999999) {
            formattedPrice = `${currency}${(price / 1000).toFixed(decimalPlaceForThousands)}k`;
        } else if (price >= 1000000) {
            formattedPrice = `${currency}${(price / 1000000).toFixed(2)}M`;
        }
        return formattedPrice;
    }

    /**
     * @function formatPrice returns the formatted value of price, eg 1234567 -> 1.24M
     * @param {*} price
     * @param {*} currency
     * @param {*} decimalPlaceForThousands
     */
    oneDecimalPlaceForThousands(p) {
        return `$${(p / 1000).toFixed(1)}k`;
    }

    /**
     * @function formatPriceNoRounding returns the formatted value of price without rounding the value, eg 1234567 -> 1.23M
     * @param {*} price
     */
    formatPriceNoRounding(price) {
        let formattedPrice = `$${price}`;
        if (price > 999 && price <= 999999) {
            formattedPrice = `$${Math.trunc(price / 1000)}k`;
        } else if (price >= 1000000) {
            const million = `${(price / 1000000).toFixed(3)}`;
            formattedPrice = `$${million.substring(0, (million.length - 1))}M`;
        }
        return formattedPrice;
    }
    /**
     * @function calculateTotalTenure returns the total tenure from
     * settled/contractedDate date up to today
     * @param {*} settledDate
     */
    calculateTotalTenure(settledDate) {
        const listingDate = new Date(settledDate);
        const today = new Date();
        const todayYear = today.getFullYear();
        const listingYear = listingDate.getFullYear();
        const todayMonth = today.getMonth();
        const listingMonth = listingDate.getMonth();
        const todayDay = today.getDate();
        const listingDay = listingDate.getDate();
        let years = todayYear - listingYear;
        let months = todayMonth - listingMonth;
        let days = todayDay - listingDay;
        // finalise result for year.
        // This also check if last sold already took a year.
        // (m is month) m < 0
        // example : m = {current month is January} -
        // {listing date month is April} this will result negative values.
        // then we will deduct 1 on our year variable.
        if (months < 0 || (months === 0 && todayMonth <= listingMonth && todayDay < listingDay)) {
            years -= 1;
        }
        // if it fall under the same month, but day count doesnt match,
        // It is automatically 12months - 1 but - 1 will be handled on finalise result for days.
        if (months < 0) {
            months += 12;
        }
        // finalise result for days.
        if (days < 0) {
            months -= 1;
            if (months < 0) {
                months = 11;
            }
            // get number of days from previous month.
            const md = new Date(listingDate.getFullYear(), today.getMonth(), 0).getDate();
            days = (md - listingDate.getDate()) + today.getDate();
        }
        if (days > 0 && months === 0 && years === 0) {
            return (
                <span>
                    {this.renderDay(days)}&nbsp;ago
                </span>
            );
        } else if (months > 0 && years === 0) {
            return (
                <span>
                    {this.renderMonth(months)}
                    {days > 0 &&
                        <React.Fragment>
                            <span>&nbsp;and&nbsp;</span>
                            {this.renderDay(days)}
                        </React.Fragment>
                    }
                    <span>&nbsp;ago</span>
                </span>
            );
        } else if (years > 0) {
            return (
                <span>
                    {this.renderYear(years)}
                    {months > 0 &&
                        <React.Fragment>
                            {days > 0 ?
                                <span>,&nbsp;</span> :
                                <span>&nbsp;and&nbsp;</span>
                            }
                            {this.renderMonth(months)}
                        </React.Fragment>
                    }
                    {days > 0 &&
                        <React.Fragment>
                            <span>&nbsp;and&nbsp;</span>
                            {this.renderDay(days)}
                        </React.Fragment>
                    }
                    <span>&nbsp;ago</span>
                </span>
            );
        }
        return 'Not available';
    }
    renderMonth(months) {
        return (
            <span className="month">
                {months} month{months === 1 ? '' : 's'}
            </span>
        );
    }
    renderYear(years) {
        return (
            <span className="year">
                {years} year{years === 1 ? '' : 's'}
            </span>
        );
    }
    renderDay(days) {
        return (
            <span className="day">
                {days} day{days === 1 ? '' : 's'}
            </span>
        );
    }
    getAvmDetailsFromList(avmDetailList) {
        const immutableAvmDetailsList = List(avmDetailList);
        return immutableAvmDetailsList.filter(avmDetail => avmDetail.source === Constant.Intellival.Source);
    }
}
