import { useEffect, useState,  } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { routeCodes } from 'js/constants/routes';
import { getAssetDomain } from 'js/constants/assets';
import TOOLTIP from 'js/constants/tooltipText';
import { trackEvent } from 'js/actions/segment';
import Segment from 'js/helpers/Segment';
import StyledMenuButton from 'js/components/common/styledMUI/StyledMenuButton';
import MUITooltip from 'js/components/common/MUITooltip';

export const CustomPrintButton = ({
    addressComplete, propertyId, panelDetails,
    propertyCommons, dispatch, panelInitialized,
}) => {
    const [tooltip, setTooltip] = useState(TOOLTIP.TEXT.MENU.UNAVAILABLE);
    const handleOnClick = (e) => {
        if (tooltip) e.preventDefault();
        localStorage.setItem(`print-pdp-${propertyId}`, JSON.stringify({ propertyCommons, ...panelDetails }));
        dispatch(trackEvent(Segment.viewPrintPage({ propertyId })));
    };
    const toUrl = routeCodes.PROPERTY_PRINT.path(addressComplete, propertyId);
    useEffect(() => {
        const panelsInitialized = Object
            .values(panelInitialized)
            .every(isTrue => !!isTrue);
        if (!propertyCommons) {
            setTooltip(TOOLTIP.TEXT.MENU.UNAVAILABLE);
        } else if (propertyCommons && !panelsInitialized) {
            setTooltip(TOOLTIP.TEXT.MENU.INITIALIZE);
        } else {
            setTooltip('');
        }
    }, [propertyCommons, panelInitialized]);

    return (
        <Link
            onClick={(e) => handleOnClick(e)}
            to={toUrl}
            target="_blank"
        >
            <MUITooltip
                title={tooltip}
                arrow
                placement="bottom"
                longTooltip
            >
                <div>
                    <StyledMenuButton
                        variant="contained"
                        color="secondary"
                        disabled={!!tooltip}
                        startIcon={<img
                            src={getAssetDomain('1-page-pdf-white.png')}
                            width="14px"
                            alt="custom-print-icon"
                        />}
                    >
                        <span>Custom Print</span>
                    </StyledMenuButton>
                </div>
            </MUITooltip>
        </Link>
    );
};

CustomPrintButton.propTypes = {
    addressComplete: PropTypes.string.isRequired,
    propertyId: PropTypes.any.isRequired,
    dispatch: PropTypes.func.isRequired,
    panelDetails: PropTypes.object,
    propertyCommons: PropTypes.object,
    panelInitialized: PropTypes.object,
};

export default connect(state => ({
    panelDetails: {
        copyRights: state.printPdp.get('copyRights'),
        imageGallery: state.printPdp.get('imageGallery'),
        map: state.printPdp.get('map'),
        propertySummary: state.printPdp.get('propertySummary'),
        propertyNotes: state.printPdp.get('propertyNotes'),
        householdInformation: state.printPdp.get('householdInformation'),
        propertyTimeline: state.printPdp.get('propertyTimeline'),
        marketTrends: state.printPdp.get('marketTrends'),
        nearbySchools: state.printPdp.get('nearbySchools'),
        similarProperty: state.printPdp.get('similarProperty'),
        additionalInformation: state.printPdp.get('additionalInformation'),
        rentalEstimate: state.printPdp.get('rentalEstimate'),
        valuationEstimate: state.printPdp.get('valuationEstimate'),
        generalDisclaimer: state.printPdp.get('generalDisclaimer'),
        stateDisclaimer: state.printPdp.get('stateDisclaimer'),
        contentDisclaimer: state.printPdp.get('contentDisclaimer'),
        valuationDisclaimer: state.printPdp.get('valuationDisclaimer'),
        propertyHistoryRecentSale: state.printPdp.get('propertyHistoryRecentSale'),
        propertyHistoryForSale: state.printPdp.get('propertyHistoryForSale'),
        propertyHistoryForRent: state.printPdp.get('propertyHistoryForRent'),
        propertyHistoryDevApp: state.printPdp.get('propertyHistoryDevApp'),
        propertyHistoryBuildingConsent: state.printPdp.get('propertyHistoryBuildingConsent'),
        ratingValuations: state.printPdp.get('ratingValuations'),
        titleInformation: state.printPdp.get('titleInformation'),
        propertyTimelineData: state.printPdp.get('propertyTimelineData'),
        recentAdvertisementForRent: state.printPdp.get('recentAdvertisementForRent'),
        recentAdvertisementForSale: state.printPdp.get('recentAdvertisementForSale'),
    },
    panelInitialized: state.printPdp.get('panelInitialized'),
    propertyCommons: state.crux.get('property').get('commons'),
}))(CustomPrintButton);
