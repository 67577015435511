import { Map as IMap } from 'immutable';
import { CRUX_APP_ERROR, INVALIDATE_CRUX_APP_ERROR, ADD_CRUX_CMPT_ERROR, RM_CRUX_CMPT_ERROR } from '../actions/errorHandler';

export const initialState = IMap({
    error: null,
    componentErrors: IMap({}),
});

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case CRUX_APP_ERROR:
            return state.merge(IMap({
                error: action.error,
            }));
        case INVALIDATE_CRUX_APP_ERROR:
            return state.merge(IMap({
                error: null,
            }));
        case ADD_CRUX_CMPT_ERROR:
            return state.merge(IMap({
                componentErrors: state.get('componentErrors').set(action.key, action.error),
            }));
        case RM_CRUX_CMPT_ERROR:
            return state.merge(IMap({
                componentErrors: state.get('componentErrors').delete(action.key),
            }));
        default:
            return state;
    }
}
