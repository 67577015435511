import { Map as IMap } from 'immutable';
import * as TYPE from 'js/actions/pdfReports';
import * as ClapiActionType from 'js/actions/clapi';

export const initialState = IMap({
    reports: {},
});

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case TYPE.PDF_REPORT_SUCCESS:
            return state.merge(IMap({
                reports: {
                    key: action.payload.key,
                    [action.payload.key]: action.payload.url,
                    success: true,
                },
            }));
        case TYPE.PDF_REPORT_FAIL:
            return state.merge(IMap({
                reports: {
                    key: action.payload,
                    success: false,
                },
            }));
        case TYPE.CLEAR_REPORT:
        case ClapiActionType.CLAPI_CLEAR_PROPERTY:
        case ClapiActionType.GET_PARTIAL_PROPERTY_DETAILS:
            return state.merge(IMap(initialState));
        default:
            return state;
    }
}
