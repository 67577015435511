import React, { useEffect } from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { blue } from '@mui/material/colors';
import CircularProgress from '@mui/material/CircularProgress';

import { searchSavedList } from '../../../../actions/watchList';

let TIME_OUT = null;

const SearchSavedList = ({
    searchValue, setSearchValue,
    setReached,
}) => {
    const dispatch = useDispatch();
    const savedList = useSelector(state => state?.watchList.get('savedList'));

    const handleChange = (e) => {
        if (TIME_OUT) {
            clearTimeout(TIME_OUT)
        }
        TIME_OUT = setTimeout(() => {
            setReached(false);
            dispatch(searchSavedList({ params: { search: e.target.value } }));
        }, 500);
        setSearchValue(e.target.value)
    };

    const handleDelete = () => {
        setSearchValue('');
        dispatch(searchSavedList());
    };

    // Effect to clear search when unmounting
    useEffect(() => {
        return () => {
            dispatch(searchSavedList());
        };
    }, []);

    return (
        <TextField
            id="search saved search"
            label="Search here"
            variant="outlined"
            size="small"
            fullWidth
            value={searchValue}
            onChange={handleChange}
            autoFocus
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <CircularProgress
                            size={18}
                            sx={{
                                color: blue[500],
                                visibility: savedList.searching ? 'visible' : 'hidden',
                            }}
                        />
                        <IconButton
                            aria-label="delete search"
                            onClick={handleDelete}
                            onMouseDown={handleDelete}
                            edge="end"
                            disabled={!searchValue}
                        >
                            <CloseIcon />
                        </IconButton>
                    </InputAdornment>
                ),
            }}
            sx={{
                marginBottom: 1,
                '& .MuiFormLabel-root': {
                    fontSize: 14,
                    marginTop: '-1px',
                },
                '& .MuiInputBase-input': {
                    fontSize: 13,
                },
            }}
        />
    );
}

SearchSavedList.propTypes = {
    searchActive: PropTypes.bool,
    setSearchValue: PropTypes.func,
    searchValue: PropTypes.string,
};

export default SearchSavedList;
