import Typography from '@mui/material/Typography';
import React from 'react';
import { styled } from '@mui/material';
import Colors from '../../../constants/colors';
import PropTypes from 'prop-types';

const StyledContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '8px',
    flex: '1 0 0',
    alignSelf: 'stretch',
    width: '479px',
    height: '234px',
    color: Colors.WHITE,
});

const EmptyFavoritesPage = (props) => {
    const { Icon, title, subTitle, actionButton } = props

    return (
        <StyledContainer>
            {Icon}
            <Typography variant="subtitle1" className="subtitle">
                {title}
            </Typography>
            <Typography variant="body2" className="subtitle">{subTitle}</Typography>
            {
                actionButton &&
                <div style={{ marginTop: '10px' }}>
                    { actionButton }
                </div>
            }
        </StyledContainer>
    );
};

EmptyFavoritesPage.propTypes = {
    title: PropTypes.string,
    subTitle: PropTypes.string,
    Icon: PropTypes.elementType,
    actionButton: PropTypes.node,
}

export default EmptyFavoritesPage;
