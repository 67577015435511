import { Map as IMap } from 'immutable';
import { SEGMENT_INIT, SEGMENT_INIT_FAIL, SEGMENT_INIT_SUCCESS, EVENT_TRACKER_SUGGESTIONS } from '../actions/segment';

export const initialState = IMap({
    isInitialized: false,
    searchDetails: null,
});

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case SEGMENT_INIT:
        case SEGMENT_INIT_SUCCESS:
            return state.merge(IMap({
                isInitialized: true,
            }));
        case SEGMENT_INIT_FAIL:
            return state.merge(IMap({
                isInitialized: false,
            }));
        case EVENT_TRACKER_SUGGESTIONS:
            return state.merge(IMap({
                searchDetails: action.payload,
            }));
        default:
            return state;
    }
}
