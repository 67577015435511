import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Bugsnag from '../../bugsnag';

/**
 * The purpose of this component is to prevent propagation of error at the component level
 * @returns nothing if runtime error exist inside the component
 */
export default class ErrorBoundary extends Component {
    static propTypes = {
        DefaultComponent: PropTypes.func,
    };

    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
        };
    }

    static getDerivedStateFromError() {
        // setting hasError state when component encounters an error
        return { hasError: true };
    }

    componentDidCatch(error) {
        Bugsnag.notify(error);
    }

    render() {
        const { DefaultComponent, children } = this.props;
        if (this.state.hasError) {
            if (DefaultComponent) {
                return <DefaultComponent />;
            } return null;
        }
        return children;
    }
}
