import { Map as IMap } from 'immutable';
import { REHYDRATE } from 'redux-persist';
import {
    LOGOUT_SUCCESS,
    GET_USER_DETAILS_SUCCESS,
} from '../actions/claud';

// set initial state values
export const initialState = IMap({
    session: null,
    user: null,
});

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case LOGOUT_SUCCESS:
            return state.merge(IMap({
                session: null,
            }));
        case GET_USER_DETAILS_SUCCESS:
            return state.merge(IMap({
                user: action.payload,
            }));
        case REHYDRATE:
            if (action.payload) {
                return state.merge(action.payload.claud);
            }
            return state.merge(initialState);
        default:
            return state;
    }
}
