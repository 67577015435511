import { useEffect, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material';
import Button from '@mui/material/Button';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MapIcons from '../../../../constants/mapIcon';
import GoogleMapTypes from './maptypes/GoogleMapTypes';
import MapTypeConstant from '../../../../constants/mapTypeConstant';
import Colors from '../../../../constants/colors';
import EsriMapTypes from './maptypes/EsriMapTypes';
import CorelogicMapTypes from './maptypes/CorelogicMapTypes';
import CoreLogicLogo from '../../../../../assets/img/CL_Logo_Horizontal.png';
import { isNZ } from '../../../../constants/crux';
import { useMapTypeContext } from '../../../context/MapTypeContext';
import { LAYER, LAYER_NZ } from '../../../../constants/map';
import NearmapMapTypes from './maptypes/NearmapMapTypes';
import FeatureFlags from '../../../../constants/featureFlags';
import Featured from '../../../common/Featured';
import EntitlementsRole from '../../../../constants/entitlementsRole';

const countryLayer = isNZ ? LAYER_NZ : LAYER;
const {
    MAP_TYPES, ROADMAP, GOOGLE_MAP_TYPES,
} = MapTypeConstant;
const MapTypeLabel = styled(Button)({
    backgroundColor: Colors.WHITE,
    height: '32px',
    width: '80px',
    padding: '7px 8px',
    color: Colors.BLACK_60_OPAQUE,
    marginTop: '10px',
    fontSize: '13px',
    lineHeight: '18px',
    borderRadius: '4px 0px 0px 4px',
    pointerEvents: 'none',
});
const MapTypeButton = styled(Button)({
    backgroundColor: Colors.WHITE,
    height: '32px',
    width: '90px',
    padding: '7px 8px',
    color: Colors.PRIMARY.MAIN,
    marginTop: '10px',
    fontSize: '13px',
    lineHeight: '18px',
    borderRadius: '0px 4px 4px 0px',
    '&:hover': {
        backgroundColor: '#ebebeb',
    },
});
const StyledPaper = styled(Paper)({
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '13px',
    lineHeight: '22px',
    color: Colors.BLACK_60_OPAQUE,
    alignItems: 'center',
    textAlign: 'center',
    letterSpacing: '0.46px',
});

export const MapDropdown = (props) => {
    const {
        id,
        setMapTypeId,
        onChangeMapLayer,
        triggerEvent,
    } = props;
    const [anchorEl, setAnchorEl] = useState(null);
    const [useGoogle, setUseGoogle] = useState(true);
    const [showOption, setShowOption] = useState(false);
    const { contextMapTypeId, setContextMapTypeId } = useMapTypeContext();
    const [mapTypeIdState, setMapTypeIdState] = useState(contextMapTypeId);
    const [buttonLabel, setButtonLabel] = useState(MAP_TYPES[ROADMAP].label);

    const changeMapLayer = (baseMapLayer) => {
        onChangeMapLayer(baseMapLayer);
    };

    const selectMapLayer = layerId => countryLayer.baseMap.find(layer => layer.id === layerId);

    const switchMapType = (mapTypeId) => {
        if (mapTypeId === '') {
            setMapTypeId(ROADMAP);
            setButtonLabel(MAP_TYPES[ROADMAP].label);
        } else if (GOOGLE_MAP_TYPES.includes(mapTypeId)) {
            setMapTypeId(mapTypeId);
            setUseGoogle(true);
            setButtonLabel(MAP_TYPES[mapTypeId].label);
            changeMapLayer(undefined);
        } else {
            setMapTypeId(ROADMAP);
            setUseGoogle(false);
            setButtonLabel(MAP_TYPES[mapTypeId].label);
            const baseMapLayer = selectMapLayer(mapTypeId);
            if (baseMapLayer) {
                changeMapLayer(baseMapLayer);
            }
        }
    };

    const triggerMapViewChangeEvent = (mapTypeId) => {
        triggerEvent(mapTypeId);
    };

    const handleClose = (event) => {
        if (anchorEl.contains(event.target)) {
            return;
        }
        setShowOption(false);
    };

    const handleButtonClick = (event) => {
        if (!anchorEl) {
            setAnchorEl(event.currentTarget);
        }
        setShowOption(!showOption);
    };

    useEffect(() => {
        setContextMapTypeId(mapTypeIdState);
        switchMapType(mapTypeIdState);
    }, [mapTypeIdState]);

    useEffect(() => {
        setMapTypeIdState(contextMapTypeId);
    }, [contextMapTypeId]);

    const buttonMapTypeIconClass = isNZ ? 'corelogic-icon-img' : 'esri-icon-img';

    return (
        <Fragment>
            <MapTypeLabel
                id={`${id}-label`}
                variant="contained"
            >
                Map View
            </MapTypeLabel>
            <MapTypeButton
                id={id}
                ref={(node) => {
                    setAnchorEl(node);
                }}
                aria-owns={showOption ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                variant="contained"
                data-testid="crux-map-types-dropdown-trigger"
                onClick={handleButtonClick}
            >
                {
                    useGoogle ?
                        <div className="button-map-type-icon">
                            {buttonLabel}<MapIcons.GoogleIcon />
                        </div>
                        :
                        <div className="button-map-type-icon">
                            {buttonLabel}<img
                                className={buttonMapTypeIconClass}
                                alt={MapTypeConstant.MAP_TYPES[mapTypeIdState].mixpanel}
                                src={MapTypeConstant.MAP_TYPES[mapTypeIdState].icon}
                            />
                        </div>
                }
            </MapTypeButton>
            <Popper data-testid="crux-map-types" key="crux-map-types" open={showOption} id="crux-map-dropdown-popper" anchorEl={anchorEl} transition disablePortal>
                {
                    ({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            id="map-dropdown-menu-list-grow"
                            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                        >
                            <StyledPaper>
                                <div className="dropdown-map-type-icon"><MapIcons.GoogleLogo /></div>
                                <GoogleMapTypes
                                    setMapTypeIdState={setMapTypeIdState}
                                    handleClose={handleClose}
                                    mapTypeIdState={mapTypeIdState}
                                    triggerMapViewChangeEvent={triggerMapViewChangeEvent}
                                />
                                <hr />
                                <div className="dropdown-map-type-icon">
                                    { isNZ ?
                                        <img className="corelogic-map-types-logo" src={CoreLogicLogo} />
                                        :
                                        <Fragment>
                                            <MapIcons.EsriIcon /><MapIcons.EsriLabel />
                                        </Fragment>
                                    }
                                </div>
                                {
                                    isNZ ?
                                        <CorelogicMapTypes
                                            handleClose={handleClose}
                                            setMapTypeIdState={setMapTypeIdState}
                                            mapTypeIdState={mapTypeIdState}
                                            triggerMapViewChangeEvent={triggerMapViewChangeEvent}
                                        />
                                        :
                                        <EsriMapTypes
                                            handleClose={handleClose}
                                            setMapTypeIdState={setMapTypeIdState}
                                            mapTypeIdState={mapTypeIdState}
                                            triggerMapViewChangeEvent={triggerMapViewChangeEvent}
                                        />
                                }
                                <Featured
                                    role={EntitlementsRole.MAP_ADVANCED}
                                    flag={FeatureFlags.NEARMAP}
                                    displayWhenUnEntitled
                                >
                                    <NearmapMapTypes
                                        key="crux-nearmap-map-type"
                                        onClose={handleClose}
                                        onMapTypeChange={setMapTypeIdState}
                                        mapTypeIdState={mapTypeIdState}
                                        shouldClearCoverage={props.isMapPanel}
                                        bounds={props.bounds}
                                        triggerMapViewChangeEvent={triggerMapViewChangeEvent}
                                    />
                                </Featured>
                            </StyledPaper>
                        </Grow>
                    )
                }
            </Popper>
        </Fragment>
    );
};

MapDropdown.propTypes = {
    id: PropTypes.string.isRequired,
    setMapTypeId: PropTypes.func.isRequired,
    onChangeMapLayer: PropTypes.func.isRequired,
    triggerEvent: PropTypes.func.isRequired,
    bounds: PropTypes.object,
};

export default MapDropdown;
