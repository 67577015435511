import DOMPurify from 'dompurify';
import API from './index';
import methods from '../constants/methods';
import CLAPI from './clapi';

const getTitlesUrl = (propertyId, selectedClAppAccountUserGuid) => {
    const newWindowInstance = window.open('', '_blank');
    let path = '/api/titles';

    if (propertyId) {
        path += `?propertyId=${propertyId}`;
    }
    path += propertyId ? '&' : '?';
    if (selectedClAppAccountUserGuid) {
        path += `clAppAccountUserGuid=${selectedClAppAccountUserGuid}`;
    } else {
        path += `clAppAccountUserGuid=${CLAPI.getSelectedClAppAccountUserGuid()}`;
    }

    const requestOptions = {
        path,
        isProxy: true,
        timeout: API.getTimeouts().large,
    };
    const headerOptions = {
        method: methods.GET.method,
    };
    return API.initiateSessionApiCall(requestOptions, headerOptions)
        .then((response) => {
            const { url } = response;
            if (url && url !== '') {
                newWindowInstance.location.href = DOMPurify.sanitize(url);
                return url;
            }
            newWindowInstance.document.write('Unable to launch titles page, please try again later...');
        }).catch((error) => {
            newWindowInstance.document.write(error);
        });
};

export default {
    getTitlesUrl,
};
