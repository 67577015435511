import React from 'react';
import PropTypes from 'prop-types';

/**
 * This will update the marker location
 * @param message - prioritized over prop panel for custom error message.
 * @param panel - concatinated after the generic message.
 */
const PanelError = ({ message, panel }) => (
    <div className="error-fetching" data-testid="panel-error">
        { message ?
            <span dangerouslySetInnerHTML={{ __html: message }} /> :
            <span>
                Sorry, there was an error fetching {panel} data.
                <br />Please refresh the page to try again.
            </span>
        }
    </div>
);

export default PanelError;

PanelError.propTypes = {
    message: PropTypes.string,
    panel: PropTypes.string,
};
PanelError.displayName = 'PanelError';
