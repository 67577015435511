export const GET_PROPERTY_BY_ID = 'GET_PROPERTY_BY_ID';
export const GET_PROPERTY_BY_ID_FORKED = 'GET_PROPERTY_BY_ID_FORKED';
export const GET_PROPERTY_BY_ID_FAIL = 'GET_PROPERTY_BY_ID_FAIL';
export const GET_PROPERTY_BY_ID_SUCCESS = 'GET_PROPERTY_BY_ID_SUCCESS';

export const GET_PROPERTY_BY_ADDRESS = 'GET_PROPERTY_BY_ADDRESS';
export const GET_PROPERTY_BY_ADDRESS_FAIL = 'GET_PROPERTY_BY_ADDRESS_FAIL';
export const GET_PROPERTY_BY_ADDRESS_SUCCESS = 'GET_PROPERTY_BY_ADDRESS_SUCCESS';

export const CLEAR_TARGET_PROPERTY = 'CLEAR_TARGET_PROPERTY';

export function getPropertyByID(propertyId) {
    return {
        type: GET_PROPERTY_BY_ID,
        payload: {
            propertyId,
        },
    };
}

export function getPropertyByAddress(propertyId, addressComplete) {
    return {
        type: GET_PROPERTY_BY_ADDRESS,
        payload: {
            propertyId,
            addressComplete,
        },
    };
}

export function clearTargetProperty() {
    return {
        type: CLEAR_TARGET_PROPERTY,
    };
}
