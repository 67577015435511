import RapidOwnerSearchParamBuilder from './RapidOwnerSearchParamBuilder';

export default (search) => {
    const state = {};
    const queryParams = RapidOwnerSearchParamBuilder(search);

    Object.keys(queryParams).forEach((key) => {
        if (key === 'isCurrentOwner') {
            state[key] = queryParams[key] === 'true';
        } else {
            state[key] = decodeURIComponent(queryParams[key]);
        }
    });

    if (queryParams.isCurrentOwner === undefined) {
        state.isCurrentOwner = false;
    }
    return state;
};
