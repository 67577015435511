import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ValuationReport from '../reports/ValuationReport';
import { entryPoints } from '../../constants/entryPoint';
import Reports from '../../constants/reports';
import ValuationEstimate from '../property/valuation/ValuationEstimate';

/**
 * Parent component of <b>ValuationReport</b> and <b>ValuationEstimate</b>.<br/>
 * Validation for <b>AVM Details</b> is mostly handled in this functional component.
 * @param props
 * @returns {ReactElement}
 * @constructor
 */
export const Valuation = (props) => {
    const {
        display,
        avmDetails: {
            isFetchingAvm,
        } = {},
        avmDetails,
        // Valuation Report props
        propertyAddress,
        propertyId,
        trigger,
        pageContext,
        entryPoint,
        dispatch,
        // Valuation Estimate props
        reportSection,
        tooltipDisclaimer,
        clapiUsrDetail,
        componentError,
        displayAvmDetails,
    } = props;

    const valuationId = Reports.REPORT_ID.valuationReport;

    const valuationToDisplay = () => {
        if (display === Reports.DISPLAY_TYPE.REPORT) {
            return displayAvmDetails ?
                <ValuationReport
                    propertyAddress={propertyAddress}
                    propertyId={propertyId}
                    trigger={trigger}
                    pageContext={pageContext}
                    entryPoint={entryPoint || entryPoints.REPORTS_MENU}
                    valuationId={valuationId}
                    dispatch={dispatch}
                />
                :
                <Fragment />;
        }

        if (display === Reports.DISPLAY_TYPE.PANEL) {
            // When no avm details available,
            // user always has the ability to create custom valuation estimate,
            // even when there are errors.
            return (<ValuationEstimate
                avmDetails={avmDetails || {}}
                pageContext={pageContext}
                isFetchingAvm={isFetchingAvm}
                tooltipDisclaimer={tooltipDisclaimer}
                clapiUsrDetail={clapiUsrDetail}
                componentError={componentError}
                reportSection={reportSection}
            />);
        }

        return <Fragment />;
    };

    return (
        <Fragment>
            {valuationToDisplay()}
        </Fragment>
    );
};

Valuation.propTypes = {
    display: PropTypes.oneOf(Object.keys(Reports.DISPLAY_TYPE)).isRequired,
    avmDetails: PropTypes.object,
    dispatch: PropTypes.func.isRequired,
    // Valuation Report props
    propertyAddress: PropTypes.string,
    propertyId: PropTypes.number,
    trigger: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.object,
    ]),
    displayAvmDetails: PropTypes.bool,
    pageContext: PropTypes.string,
    entryPoint: PropTypes.string,
    // Valuation Estimate props
    reportSection: PropTypes.any, // <ValuationReportSection />
    tooltipDisclaimer: PropTypes.string,
    clapiUsrDetail: PropTypes.object,
    componentError: PropTypes.any,
};

export default connect()(Valuation);
