import API from './index';
import methods from '../constants/methods';
import cruxProxy from './cruxProxy';
import CLAPI from './clapi';
import { UriBuilder } from 'js/helpers/UriBuilder';

const headerOptions = {
    method: methods.GET.method,
    ...cruxProxy.getXsrfHeader(),
};

const getPlezzelOrderForm = (propertyId) => {
    const uriBuilder = new UriBuilder('/api/plezzelOrderForm');
    uriBuilder.addParam('clAppAccountUserGuid', CLAPI.getSelectedClAppAccountUserGuid());

    if (propertyId) {
        uriBuilder.addParam('propertyId', propertyId);
    }

    const requestOptions = {
        path: uriBuilder.build(),
        protected: true,
    };
    return API.initiateSessionApiCall(requestOptions, headerOptions);
};

export default {
    getPlezzelOrderForm,
};
