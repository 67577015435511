import React, { useContext, useEffect, useState } from 'react';
import { Button, Grid } from '@mui/material';
import ShareLocation from '@mui/icons-material/ShareLocation';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import ScrollableContainer from 'js/components/common/ScrollableContainer';
import SavedTerritoryCardHome from './SavedTerritoryCardHome';
import EmptyFavoritesPage from '../EmptyFavoritesPage';
import { CONTENT_HEIGHT, StyledButton } from '../UserFavoritesHome';
import { getSavedTerritories } from '../../../../actions/territories';
import { routeCodes } from '../../../../constants/routes';
import { SAVED_TERRITORIES } from '../../../../constants/favourites/favourites';
import Colors from '../../../../constants/colors';
import { FullPageModalContext } from '../../../context/FullPageModalContext';
import Entitlements from '../../../../helpers/Entitlements';
import UnsubscribedPage from '../UnsubscribedPage';
import Commons from '../../../../helpers/Commons';
import MaxTerritoryTooltipBody from '../../../common/MaxTerritoryTooltipBody';
import MUITooltip from '../../../common/MUITooltip';
import { trackEvent } from '../../../../actions/segment';
import { createMixpanelProperties } from '../../../../helpers/Segment';
import SEGMENT from '../../../../constants/segment';

const SAVED_TERRITORIES_DISPLAY_LIMIT = 25;

const SavedTerritoryPanelHome = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const savedTerritory = useSelector(state => state.territories?.get('territories'));
    const usrDetail = useSelector(state => state.clapi.get('usrDetail'));

    const { setOpen } = useContext(FullPageModalContext);

    const [loading, setLoading] = useState(true);
    const [withData, setWithData] = useState(true);
    const hasSavedTerritoriesRole = Entitlements.hasSavedTerritoriesRole(usrDetail);

    useEffect(() => {
        if (usrDetail?.accountDetail?.clAccountGuid && hasSavedTerritoriesRole) {
            dispatch(getSavedTerritories());
        }
    }, [usrDetail?.accountDetail?.clAccountGuid]);

    useEffect(() => {
        if (savedTerritory?.fetching === false) {
            setWithData(savedTerritory.totalCount > 0 || savedTerritory.list.length > 0);
            setLoading(false);
        }
    }, [savedTerritory]);

    const navigateToFavourites = () => {
        history.push({
            pathname: routeCodes.USER_FAVORITES.path,
            state: {
                activeTab: SAVED_TERRITORIES,
            },
        });
        window.scrollTo(0, 0);
    };

    const sendMixpanelEvent = (territoryName) => {
        dispatch(trackEvent(createMixpanelProperties(SEGMENT.EVENT_NAMES.OPEN_SAVED_TERRITORY, {
            page: 'Homepage',
            pageTab: 'Saved Territory',
            name: territoryName,
        })));
    };

    const CreateButton = () => (
        Commons.isTerritoriesMaxed(savedTerritory) ?
            <MUITooltip arrow title={<><MaxTerritoryTooltipBody /></>}>
                <span>
                    <Button
                        disabled
                        onClick={() => setOpen(true)}
                        variant="contained"
                        sx={{
                            marginTop: '7px',
                            height: '30px',
                        }}
                    >
                        Create
                    </Button>
                </span>
            </MUITooltip> :
            <Button
                sx={{
                    marginTop: '7px',
                    height: '30px',
                }}
                onClick={() => setOpen(true)}
                variant="contained"
            >
                Create
            </Button>
        );

    if (!hasSavedTerritoriesRole) {
        return (
            <UnsubscribedPage
                type="Saved Territories"
                message="To create a Saved Territory and stay on top of your patch"
                Icon={<ShareLocation sx={{
                        width: '56px',
                        height: '56px',
                        flexShrink: '0',
                        color: Colors.PRIMARY.MAIN,
                    }}
                />}
            />
        );
    }

    return (
        <>
            {
                withData ?
                    <div>
                        <ScrollableContainer height={CONTENT_HEIGHT}>
                            <Grid container rowSpacing={1} columnSpacing={2}>
                                {
                                    loading ?
                                        <Grid item xs={6}>
                                            <SavedTerritoryCardHome />
                                        </Grid> :
                                        <>
                                            {
                                                savedTerritory
                                                    ?.list
                                                    ?.slice(0, SAVED_TERRITORIES_DISPLAY_LIMIT)
                                                    ?.map(({territoryName, id, numberOfProperties}) =>
                                                        (<Grid item xs={6} key={id}>
                                                            <Link
                                                                data-testid="search-card-link"
                                                                target="_blank"
                                                                to={routeCodes.SAVED_TERRITORY.path(id)}
                                                                onClick={() => sendMixpanelEvent(territoryName)}
                                                            >
                                                                <SavedTerritoryCardHome
                                                                    description={territoryName}
                                                                    propertiesCount={numberOfProperties}
                                                                />
                                                            </Link>
                                                        </Grid>))
                                            }
                                        </>
                                }
                            </Grid>
                        </ScrollableContainer>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                            }}
                        >
                            <CreateButton />
                            <StyledButton onClick={() => navigateToFavourites()}>
                                View All
                            </StyledButton>
                        </div>
                    </div> :
                    <div>
                        <EmptyFavoritesPage
                            title={savedTerritory?.error ? 'Sorry, there was an error fetching your data.' : 'You\'re out of the loop!'}
                            subTitle={savedTerritory?.error
                                ? 'Please refresh the page to try again.'
                                : 'Create a Saved Territory to stay on top of your patch.'
                            }
                            actionButton={!savedTerritory?.error && <CreateButton />}
                            Icon={<ShareLocation sx={{
                                width: '56px',
                                height: '56px',
                                flexShrink: '0',
                                color: Colors.PRIMARY.MAIN,
                            }}
                            />}
                        />
                    </div>
            }
        </>
    );
};

export default SavedTerritoryPanelHome;
