import { Map as IMap } from 'immutable';
import * as Action from '../actions/reiForms';
import { ACCOUNT_SWITCH } from '../actions/linkedAccounts';

export const initialState = () => IMap({
    reiForms: {
        tokens: [],
        fetching: null,
        error: false,
        success: false,
    },
    integration: {
        connectStatus: null,
        disconnectStatus: null,
    },
    formTemplates: {},
    userTemplates: {},
});

export default function reducer(state = initialState(), action = {}) {
    switch (action.type) {
        case Action.GET_ALL_REI_TOKENS: {
            return state.mergeDeep(IMap({
                reiForms: {
                    fetching: true,
                },
            }));
        }
        case Action.GET_ALL_REI_TOKENS_SUCCESS: {
            return state.merge(IMap({
                reiForms: {
                    tokens: [...action.payload],
                    fetching: false,
                    success: true,
                    error: false,
                },
            }));
        }
        case Action.GET_ALL_REI_TOKENS_FAIL: {
            return state.mergeDeep(IMap({
                reiForms: {
                    fetching: false,
                    error: true,
                },
            }));
        }
        case Action.SET_INTEGRATION_STATUS: {
            return state.mergeDeep(IMap({
                integration: {
                    ...state.get('integration'),
                    ...action.payload,
                },
            }));
        }
        case Action.CONNECT_REI_TOKEN_SUCCESS: {
            let tokens = state.get('reiForms')?.tokens || [];
            const existingStateToken = tokens.find(token => token.state === action.payload.state);
            if (existingStateToken) {
                tokens = tokens?.map(token => (
                    token.state === existingStateToken.state
                        ? { ...token, token: action.payload.token }
                        : token
                ));
            } else {
                tokens = [ ...tokens, { ...action.payload } ];
            }
            return state.merge(IMap({
                reiForms: {
                    tokens: [...tokens],
                    fetching: null,
                    error: false,
                    success: false,
                },
            }));
        }
        case Action.DISCONNECT_REI_TOKEN_SUCCESS: {
            let tokens = state.get('reiForms')?.tokens || [];
            tokens = tokens?.filter(token => token.state !== action.payload.state);
            return state.merge(IMap({
                reiForms: {
                    tokens: [...tokens],
                    fetching: null,
                    error: false,
                    success: false,
                },
            }));
        }
        case Action.GET_FORM_TEMPLATES_SUCCESS: {
            return state.mergeDeep(IMap({
                formTemplates: {
                    ...JSON.parse(JSON.stringify(state.get('formTemplates'))),
                    ...action?.payload,
                },
            }));
        }
        case Action.GET_FORM_TEMPLATES_FAIL: {
            return state.mergeDeep(IMap({
                formTemplates: {
                    ...JSON.parse(JSON.stringify(state.get('formTemplates'))),
                    [action?.payload]: {
                        statusCode: 400
                    },
                },
            }));
        }
        case Action.GET_USER_TEMPLATES_SUCCESS: {
            return state.mergeDeep(IMap({
                userTemplates: {
                    ...JSON.parse(JSON.stringify(state.get('userTemplates'))),
                    ...action?.payload,
                },
            }));
        }
        case Action.GET_USER_TEMPLATES_FAIL: {
            return state.mergeDeep(IMap({
                userTemplates: {
                    ...JSON.parse(JSON.stringify(state.get('userTemplates'))),
                    [action?.payload]: {
                        statusCode: 400
                    },
                },
            }));
        }
        case ACCOUNT_SWITCH: {
            return initialState();
        }
        default:
            return state;
    }
}