export const SAVE_PANEL_DETAILS = 'SAVE_PANEL_DETAILS';
export const CONVERT_HTML_TO_PDF_PDP = 'CONVERT_HTML_TO_PDF_PDP';
export const CONVERT_HTML_TO_PDF_PDP_SUCCESS = 'CONVERT_HTML_TO_PDF_PDP_SUCCESS';
export const CONVERT_HTML_TO_PDF_PDP_FAIL = 'CONVERT_HTML_TO_PDF_PDP_FAIL';
export const RESET_PANEL_DETAILS = 'RESET_PANEL_DETAILS';
export const PANEL_INITIALIZED = 'PANEL_INITIALIZED';
export const RESET_INITIALIZATION = 'RESET_INITIALIZATION';
export const SET_SELECTED_SIMILAR_PROPERTY_THUMBNAIL = 'SET_SELECTED_SIMILAR_PROPERTY_THUMBNAIL';

export const savePanelDetails = payload => ({
    type: SAVE_PANEL_DETAILS,
    payload,
});


export const convertHtmlToPdf = payload => ({
    type: CONVERT_HTML_TO_PDF_PDP,
    payload,
});

export const resetPanelDetails = () => ({
    type: RESET_PANEL_DETAILS,
});

export const initializePrint = payload => ({
    type: PANEL_INITIALIZED,
    payload,
});

export const resetInitialization = () => ({
    type: RESET_INITIALIZATION,
});

export const setSelectedSimilarPropertyThumbnail =
    (selectedTabKey, thumbnailIndex, thumbnailPhotoIndex) => ({
        type: SET_SELECTED_SIMILAR_PROPERTY_THUMBNAIL,
        payload: { selectedTabKey, thumbnailIndex, thumbnailPhotoIndex },
    });

