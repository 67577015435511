export const NEARMAP_COVERAGE = 'NEARMAP_COVERAGE';
export const NEARMAP_COVERAGE_FAIL = 'NEARMAP_COVERAGE_FAIL';
export const NEARMAP_COVERAGE_SUCCESS = 'NEARMAP_COVERAGE_SUCCESS';
export const SET_SELECTED_NEARMAP_COVERAGE = 'SET_SELECTED_NEARMAP_COVERAGE';
export const CLEAR_NEARMAP_COVERAGE = 'CLEAR_NEARMAP_COVERAGE';

export function getNearmapCoverage(bounds) {
    return {
        type: NEARMAP_COVERAGE,
        payload: {
            bounds,
        },
    };
}

export function setSelectedNearmapCoverage(survey) {
    return {
        type: SET_SELECTED_NEARMAP_COVERAGE,
        payload: {
            selected: survey,
        },
    };
}

export function clearNearmapCoverage() {
    return {
        type: CLEAR_NEARMAP_COVERAGE,
    };
}
