import RapidVolumeFolioSearchParamBuilder from './RapidVolumeFolioSearchParamBuilder';

export default (search) => {
    const state = {};
    const queryParams = RapidVolumeFolioSearchParamBuilder(search);

    Object.keys(queryParams).forEach((key) => {
        state[key] = decodeURIComponent(queryParams[key]);
    });
    return state;
};
