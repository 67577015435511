import React from 'react';
import PropTypes from 'prop-types';
import DropdownModal from '../../../common/modals/DropdownModal';
import SingleSelectCheckBox from '../../../searchResults/rapidSearchFiltersV2/SingleSelectCheckBox';

const DropdownCheckboxOptions = (props) => {
    const {
        customClass,
        customClassName,
        onModalClose,
        onSelectionChange,
        options,
        selectOption,
        showModal,
        tooltipText,
        showTooltip,
    } = props;

    return (
        <React.Fragment>
            {
                showModal &&
                <DropdownModal
                    customClass={customClass}
                    isVisible
                    onModalClose={onModalClose}
                >
                    <SingleSelectCheckBox
                        customClassName={customClassName}
                        options={options}
                        hideCheckBox
                        selectOption={selectOption}
                        onSelectionChange={onSelectionChange}
                    />
                    {
                        showTooltip &&
                        <div className="tooltipText">
                            <hr />
                            {tooltipText}
                        </div>
                    }
                </DropdownModal>
            }
        </React.Fragment>
    );
};

DropdownCheckboxOptions.propTypes = {
    showModal: PropTypes.bool,
    customClass: PropTypes.string,
    onModalClose: PropTypes.func,
    customClassName: PropTypes.string,
    options: PropTypes.object,
    selectOption: PropTypes.string,
    onSelectionChange: PropTypes.func,
    tooltipText: PropTypes.string,
    showTooltip: PropTypes.bool,
};

export default DropdownCheckboxOptions;
