export default (rawParams) => {
    const _params = new URLSearchParams(rawParams);
    const params = {
        valuationReference: _params.get('valuationReference') === null ? '' : _params.get('valuationReference'),
        addressSuburb: _params.get('addressSuburb') === null ? '' : _params.get('addressSuburb'),
    };
    Object.keys(params).forEach((key) => {
        if (!params[key]) delete params[key];
    });
    return params;
};
