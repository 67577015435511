export default class LiveIntellivalAvmEstimateRequestBody {
    constructor() {
        this.bedrooms = null;
        this.bathrooms = null;
        this.carSpaces = null;
        this.craftsmanshipQuality = null;
        this.floorAreaM2 = null;
        this.landAreaM2 = null;
        this.propertyType = null;
        this.saleDate = null;
        this.salePrice = null;
        this.valuationDate = null;
        this.yearBuilt = null;
    }
}
