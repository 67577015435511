import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import DOMPurify from 'dompurify';
import useDisclaimer from '../../../hooks/useDisclaimer';
import GetDisclaimer from '../../../selectors/getDisclaimer';
import {
    GET_CONTENT_DISCLAIMER_FAIL,
    getContentDisclaimer,
} from '../../../actions/clapi';
import Commons from '../../../helpers/Commons';

export const ContentDisclaimer = (props) => {
    const { contentDisclaimer, dispatch } = props;
    const action = useMemo(() =>
        !Commons.get(contentDisclaimer, 'context.disclaimer') &&
        getContentDisclaimer('disclaimers', 'cc_au'
    ), []);
    const disclaimer = useDisclaimer(contentDisclaimer,
        action,
        dispatch,
    );

    const disclaimerText = Commons.get(disclaimer, 'context.disclaimer');
    return (
        disclaimer.context &&
        <p className="content-disclaimer" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(disclaimerText) }} />
    )
};

const ConnectedContentDisclaimer = connect((state) => ({
    contentDisclaimer: GetDisclaimer(state,
        GET_CONTENT_DISCLAIMER_FAIL,
        'contentDisclaimer',
        'Unable to fetch Content disclaimer ({status})'
    ),
}))(ContentDisclaimer);
ConnectedContentDisclaimer.displayName = 'ContentDisclaimer';

export default ConnectedContentDisclaimer;
