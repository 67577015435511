import { Fragment, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import Close from '@mui/icons-material/Close';
import Colors from '../../constants/colors';
import { styled } from '@mui/material';

const DEFAULT_INPUT_WIDTH = 50;
const MULTIPLIER = 10;
const ENTER_KEY = 'Enter';

const StyledTextField = styled(TextField, {
    shouldForwardProp: (prop) => prop !== 'maxWidth' && prop !== 'inputValue'
})(({ theme, maxWidth, inputValue, }) => {
    const currentWidth = inputValue.length * MULTIPLIER;
    let inputWidth = DEFAULT_INPUT_WIDTH;
    if (currentWidth >= maxWidth) {
        inputWidth = maxWidth
    } else if (currentWidth > DEFAULT_INPUT_WIDTH) {
        inputWidth = currentWidth
    }
    return {
        width: 'unset',
        marginBottom: 0,
        marginTop: '10px',
        '& .MuiInput-input': {
            fontSize: 13,
            zIndex: 1,
            width: `${inputWidth}px`,
        },
    }
});

const MultiValueTextField = (props) => {
    const {
        label,
        onUpdateChips,
        validator,
        onError,
        list = [],
        delimiter = ',',
        maxLength = 60,
        maxWidth = 552,
        chipLimit = 20,
    } = props;
    const [chips, setChips] = useState(list);
    const [errorMessage, setErrorMessage] = useState('');
    const [inputValue, setInputValue] = useState('');
    const inputRef = useRef();

    const updateChips = (newChips) => {
        setChips(newChips);
        onUpdateChips(newChips);
    };

    const handleDelete = (index) => {
        const newChips = [...chips];
        newChips.splice(index, 1);
        updateChips(newChips);
    };

    const handleKeyDown = (event) => {
        const { target: { value }, key } = event;
        const newChips = [...chips];

        if (key === delimiter || key === ENTER_KEY) {
            const validationMessage = validator(value, chips, chipLimit);
            if (!validationMessage) {
                newChips.push(value);
                updateChips(newChips);

                setInputValue('');
                setErrorMessage('');
                onError(false);
            } else {
                setErrorMessage(validationMessage);
                onError(true);
            }
        }

        if (chips.length && value === '' && key === 'Backspace') {
            newChips.pop();
            updateChips(newChips);
            setErrorMessage('');
            onError(false);
        }
    };

    const handleChange = (e) => {
        const { value } = e.target;
        if (value === delimiter) return;
        if (value.length > maxLength) return;
        if (value) {
            onError(true);
        } else {
            onError(false);
            setErrorMessage('');
        }
        setInputValue(value);
    };

    const handleContainerClick = () => inputRef.current.focus();

    return (
        <Fragment>
            <Box
                sx={{
                    borderBottom: `2px solid ${Colors.MIDNIGHT_BLUE}`,
                    maxHeight: 300,
                    overflowY: 'auto',
                }}
                 onClick={handleContainerClick}
            >
                <Typography
                    variant="caption"
                    display="block"
                    sx={{
                        color: Colors.MIDNIGHT_BLUE,
                        lineHeight: '12px',
                        letterSpacing: 0.15,
                        opacity: 1,
                    }}
                >
                    {label}
                </Typography>
                {
                    chips.map((value, index) => (
                        <Chip
                            key={`${value}-${index}`}
                            label={value}
                            variant="outlined"
                            data-testid={`chip-${index}`}
                            sx={{
                                backgroundColor: Colors.DIRTY_WHITE,
                                margin: '8px 8px 9px 0px',
                            }}
                            deleteIcon={
                                <Close
                                    sx={{ width: 20 }}
                                    data-testid={`delete-icon-${index}`}
                                />
                            }
                            onDelete={() => handleDelete(index)}
                        />
                    ))
                }
                <StyledTextField
                    maxWidth={maxWidth}
                    inputValue={inputValue}
                    inputRef={inputRef}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                        disableUnderline: true,
                    }}
                    value={inputValue}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                    autoFocus
                    variant="standard"
                />
            </Box>
            <Typography
                variant="caption"
                display="block"
                sx={{
                    color: Colors.ERROR_FIELD_BORDER,
                    opacity: 1,
                }}
            >
                {errorMessage}
            </Typography>
        </Fragment>
    );
};

MultiValueTextField.propTypes = {
    label: PropTypes.string.isRequired,
    onUpdateChips: PropTypes.func.isRequired,
    validator: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
    delimiter: PropTypes.string,
    maxLength: PropTypes.number,
    maxWidth: PropTypes.number,
    chipLimit: PropTypes.number,
    list: PropTypes.array,
};

export default MultiValueTextField;
