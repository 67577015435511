import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import useDisclaimer from '../../../hooks/useDisclaimer';
import GetDisclaimer from '../../../selectors/getDisclaimer';
import {
    GET_GENERAL_DISCLAIMER_FAIL,
    getGeneralDisclaimer
} from '../../../actions/clapi';
import { FOOTER } from '../../../helpers/Localization';
import Commons from '../../../helpers/Commons';

export const GeneralDisclaimer = (props) => {
    const { generalDisclaimer, dispatch } = props;
    const action = useMemo(() =>
        !Commons.get(generalDisclaimer, 'context.disclaimer') &&
        (getGeneralDisclaimer('disclaimers', FOOTER.disclaimers.general)
    ), []);
    const disclaimer = useDisclaimer(generalDisclaimer,
        action,
        dispatch
    );
    const disclaimerText = Commons.get(disclaimer, 'context.disclaimer');
    const cleanDisclaimer = useMemo(() => (
        Commons.purifyDomElement(disclaimerText)
    ), [disclaimer]);
    return (
        !!disclaimerText &&
        <p className="property-disclaimer" dangerouslySetInnerHTML={{ __html: `${cleanDisclaimer}` }} />
    )
};

const ConnectedGeneralDisclaimer = connect((state) => ({
    generalDisclaimer: GetDisclaimer(state,
        GET_GENERAL_DISCLAIMER_FAIL,
        'generalDisclaimer',
        'Unable to fetch General disclaimer ({status})'
    ),
}))(GeneralDisclaimer);
ConnectedGeneralDisclaimer.displayName = 'GeneralDisclaimer';

export default ConnectedGeneralDisclaimer;
