import API from './index';
import methods from '../constants/methods';
import { UriBuilder } from '../helpers/UriBuilder';
import cruxProxy from './cruxProxy';
import { MULTIPLE_RECENT_SEARCH_DELIMITER } from '../constants/slas';

const ADDRESS = 'address';
const PARCEL = 'parcel';
const GET_TITLE = 'get-title';
const SAVE_TITLE = 'save-title';

const getRecentAddressSearches = (type = ADDRESS, allowMultiple = false, limit = 40) => {
    const requestOptions = {
        path: `/api/recent-search/${type}?allowMultiple=${allowMultiple}&limit=${limit}`,
        isProxy: true,
    };
    return API.initiateSessionApiCall(requestOptions, {});
};

const getRecentParcelSearches = () => getRecentAddressSearches(PARCEL);

const getRecentTitleSearches = () => getRecentAddressSearches(GET_TITLE);

const removeRecentSearch = (searchType) => {
    const requestOptions = {
        path: `/api/recent-search/${searchType}`,
        isProxy: true,
    };
    const headerOptions = {
        method: methods.DELETE.method,
        headers: {
            'Content-Type': 'application/json',
            ...cruxProxy.getXsrfHeader(),
        },
        body: {
            searches: [],
        },
    };
    return API.initiateSessionApiCall(requestOptions, headerOptions);
};

const saveRecentAddressSearches = (searchString = '', suggestionType, type = ADDRESS) => {
    const multiple = searchString.split(MULTIPLE_RECENT_SEARCH_DELIMITER).length > 1;
    const saveAddressBody = {
        searchString,
        suggestionType,
        multiple,
    };
    const requestOptions = {
        path: `/api/recent-search/${type}`,
        isProxy: true,
    };
    const headerOptions = {
        method: methods.POST.method,
        headers: {
            'Content-Type': 'application/json',
            ...cruxProxy.getXsrfHeader(),
        },
        body: saveAddressBody,
    };
    return API.initiateSessionApiCall(requestOptions, headerOptions);
};

const saveRecentParcelSearches = (searchString, suggestionType) =>
    saveRecentAddressSearches(searchString, suggestionType, PARCEL);

const saveRecentTitleSearches = (searchString, suggestionType) =>
    saveRecentAddressSearches(searchString, suggestionType, SAVE_TITLE);

const updatePropertyNote = (propertyId, note) => {
    const requestOptions = {
        path: '/api/property/notes',
        isProxy: true,
    };

    const headerOptions = {
        method: methods.PUT.method,
        headers: {
            'Content-Type': 'application/json',
            ...cruxProxy.getXsrfHeader(),
        },
        body: {
            propertyId,
            note,
        },
    };

    return API.initiateSessionApiCall(requestOptions, headerOptions);
};

const getPropertyNotes = (propertyId) => {
    const endpoint = new UriBuilder('/api/property/notes');
    propertyId && endpoint.addParam('propertyId', propertyId.toString());

    const requestOptions = {
        path: endpoint.build(),
        isProxy: true,
    };

    const headerOptions = {
        method: methods.GET.method,
    };

    return API.initiateSessionApiCall(requestOptions, headerOptions);
};

const getPropertyInvestmentReport = (
    clAppAccountUserGuid,
    propertyId,
    extraInfo,
) => {
    const endpoint = new UriBuilder('/api/reports/investments');
    endpoint.addSlug(clAppAccountUserGuid);


    const requestOptions = {
        path: endpoint.build(),
        timeout: API.getTimeouts().large,
        isProxy: true,
    };

    const otherRequestBody = Object.keys(extraInfo).reduce((a, c) => {
        if (!!extraInfo[c]) { a = { ...a, [c]: extraInfo[c] } }
        return a;
    }, {});

    const headerOptions = {
        method: methods.POST.method,
        headers: {
            'Content-Type': 'application/json',
            ...cruxProxy.getXsrfHeader(),
        },
        body: {
            propertyId,
            ...otherRequestBody,
        },
    };

    return API.initiateSessionApiCall(requestOptions, headerOptions);
}
export default {
    getRecentAddressSearches,
    saveRecentAddressSearches,
    updatePropertyNote,
    getPropertyNotes,
    getRecentParcelSearches,
    getRecentTitleSearches,
    saveRecentParcelSearches,
    saveRecentTitleSearches,
    removeRecentSearch,
    getPropertyInvestmentReport,
};
