/* eslint-disable no-undef */
import { Component, createRef } from 'react';
import { MapContext } from '@react-google-maps/api';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import NearmapCustomMapType from './NearmapCustomMapType';
import { isNZ } from '../../../constants/crux';
import MapTypeConstant from '../../../constants/mapTypeConstant';
import { DEFAULT_MAP } from '../../../helpers/Localization';
import { LAYER_NZ } from '../../../constants/map';
import BoundingBoxLayer from './layer/BoundingBoxLayer';
import Commons from '../../../helpers/Commons';

class NearmapTileLayer extends Component {
    static contextType = MapContext;

    static propTypes = {
        mapTypeId: PropTypes.string,
        surveyId: PropTypes.string,
        bounds: PropTypes.object,
        zoom: PropTypes.number,
    };

    constructor(props) {
        super(props);
        this.surveyRef = createRef(null);
    }

    componentDidUpdate() {
        const { surveyId } = this.props;
        if (this.ifUseCoverage()) {
            if (surveyId && surveyId !== this.surveyRef.current) {
                this.clearMapLayer();
                this.insertLayer(this.props.surveyId);
                this.surveyRef.current = surveyId;
            }
        } else {
            this.clearMapLayer();
            this.insertLayer();
        }
    }

    componentWillUnmount() {
        this.clearMapLayer();
    }

    // If the map is not wrapped by MapTypeContext, which means
    // the map is nearby school or similar properties,
    // do not use nearmap coverage, use full nearmap instead
    ifUseCoverage() {
        const { mapTypeId } = this.props;
        const { NEARMAP } = MapTypeConstant;
        return mapTypeId === NEARMAP;
    }

    insertLayer(surveyId) {
        let mapTileLayer;
        if (surveyId) {
            mapTileLayer = new NearmapCustomMapType(new google.maps.Size(256, 256), surveyId);
        } else {
            mapTileLayer = new NearmapCustomMapType(new google.maps.Size(256, 256));
        }
        this.context.overlayMapTypes.insertAt(0, mapTileLayer);
    }

    clearMapLayer() {
        if (Commons.get(this.context, 'overlayMapTypes.clear')) {
            this.context.overlayMapTypes.clear();
        }
    }

    render() {
        const { coverage, bounds, zoom } = this.props;
        // for NZ, if nearmap coverage is not available, display corelogic road map instead
        if (!(coverage && coverage.length > 0) && isNZ) {
            const baseMapLayer = LAYER_NZ.baseMap.find(layer => layer.id === DEFAULT_MAP);
            return (<BoundingBoxLayer
                request={baseMapLayer.request}
                bounds={bounds}
                layerName={baseMapLayer.id}
                layerCategory={baseMapLayer.category}
                zoom={zoom}
            />);
        }
        return null;
    }
}

export default connect((state) => {
    const nearmap = state.map.get('nearmap');
    return {
        coverage: nearmap.coverage ? nearmap.coverage.surveys : null,
        surveyId: nearmap.selectedCoverage ? nearmap.selectedCoverage.id : null,
    };
})(NearmapTileLayer)
