import React, { useMemo, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import GetDisclaimer from '../../../selectors/getDisclaimer';
import {
    GET_STATE_DISCLAIMER_FAIL,
    getStateDisclaimer,
} from '../../../actions/clapi';
import Commons from '../../../helpers/Commons';
import { STATE } from '../../../constants/states';

export const StateDisclaimer = (props) => {
    const {
        stateDisclaimer,
        dispatch,
        property,
        propertyDetailsLocation,
        searchResults,
        state, // singele state (PDP/Account management)
        states: statesProp, // multiple states (searchResults/report)
    } = props;
    const [states, setStates] = useState([]);
    const propertyState = Commons.get(property, 'location.state');
    const propertySummaryList = Commons.get(searchResults, '_embedded.propertySummaryList');
    const rapidPropertySummaryList = Commons.get(searchResults, 'data');
    const stringRapidPropertySummaryList = JSON.stringify(rapidPropertySummaryList);
    const stringPropertyDetailsLocation = JSON.stringify(propertyDetailsLocation);
    const stringPropertySummaryList = JSON.stringify(propertySummaryList);
    useEffect(() => {
        const _stateProp = state || propertyState;
        if (_stateProp) {
            setStates(Commons.getStateDisclaimerKeys(null, _stateProp));
        } else if (propertyDetailsLocation && (propertyDetailsLocation.state !== STATE.NT)) {
            setStates(Commons.getStateDisclaimerKeys(propertySummaryList, propertyDetailsLocation.state));
        } else if (statesProp) {
            const _states = Commons.removeFalsyFromArray(Array.from(statesProp));
            setStates(_states.map((_state) => `state${_state}_au`));
        } else if (rapidPropertySummaryList) {
            setStates(Commons.getStateDisclaimerKeys(rapidPropertySummaryList));
        }
    }, [propertyState, stringPropertyDetailsLocation, stringPropertySummaryList,
        state, statesProp, stringRapidPropertySummaryList]);
    useEffect(() => {
        if (states.length) {
            dispatch(getStateDisclaimer(
                'disclaimers',
                states.filter(state => state !== 'stateNT_au'),
            ));
        }
    }, [JSON.stringify(states)]);
    let disclaimerText = Commons.get(stateDisclaimer, 'context.disclaimer');
    const cleanDisclaimer = useMemo(() => {
        disclaimerText = disclaimerText ? disclaimerText.replace(/\u00a0/g, ' ') : disclaimerText;
        return Commons.purifyDomElement(disclaimerText);
    }, [Commons.get(stateDisclaimer, 'context.disclaimer')]);

    return (
        !!disclaimerText &&
        <p
            className="state-disclaimer"
            dangerouslySetInnerHTML={{ __html: `${cleanDisclaimer}` }}
        />
    )
};

StateDisclaimer.propTypes = {
    state: PropTypes.string,
};

const ConnectedStateDisclaimer = connect((state) => ({
    stateDisclaimer: GetDisclaimer(state,
        GET_STATE_DISCLAIMER_FAIL,
        'stateDisclaimer',
        'Unable to fetch State disclaimer ({status})'
    ),
    property: state.crux.get('property').get('commons'),
    propertyDetailsLocation: state.clapi.get('propertyDetailsLocation'),
    searchResults: state.searchResults.get('searchResults'),
}))(StateDisclaimer);
ConnectedStateDisclaimer.displayName = 'StateDisclaimer';

export default ConnectedStateDisclaimer;
