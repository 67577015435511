import { call, put, select, takeLatest, takeEvery } from 'redux-saga/effects';
import {
    GET_BRANDING_SUCCESS,
    GET_BRANDING,
    GET_BRANDING_FAIL,
    SAVE_BRANDING_SUCCESS,
    SAVE_BRANDING,
    SAVE_BRANDING_FAIL,
    EDIT_BRANDING_SUCCESS,
    EDIT_BRANDING,
    EDIT_BRANDING_FAIL,
    DELETE_BRANDING,
    DELETE_BRANDING_SUCCESS,
    DELETE_BRANDING_FAIL,
    ACTIVATE_THEME,
    ACTIVATE_THEME_SUCCESS,
    activateThemeFail,
    REMOVE_THEME_LOGO,
    REMOVE_THEME_LOGO_SUCCESS,
    REMOVE_THEME_LOGO_FAIL,
} from '../actions/manageBranding';
import UserPrefApi from '../api/userPreferences';
import { trackEvent } from '../actions/segment';
import SegmentHelper from '../helpers/Segment';
import Segment from '../constants/segment';

const SUCCESS_ADD = 'has been successfully created for this organisation';
const SUCCESS_EDIT = 'has been successfully updated for this organisation';
const EDIT_THEME_WITH_LOGO_FAIL = ` ${SUCCESS_EDIT} but the logo cannot be saved.`;
const ADD_THEME_WITH_LOGO_FAIL = ` ${SUCCESS_ADD} but the logo cannot be saved.`;
const SAVE_LOGO_ALONE = 'Logo cannot be saved. Please try again.';
const NETWORK_ERROR = 'A network error occurred, and your changes were not saved. Please try again.';

function getBranding() {
    return function* () {
        try {
            const state = yield select();
            const themes = yield call(
                UserPrefApi.getBranding,
                state.config.get('applicationDomain').userPreferences,
            );
            yield put({
                type: GET_BRANDING_SUCCESS,
                payload: themes,
            });
        } catch (e) {
            yield put({
                type: GET_BRANDING_FAIL,
                payload: {
                    isError: true,
                    message: e.message,
                },
            });
        }
    };
}

function addNewBranding() {
    return function* (action) {
        try {
            const state = yield select();
            const {
                themePoid, logo, ...rest
            } = action.payload;
            const userPrefDomain = state.config.get('applicationDomain').userPreferences;
            const addResponse = yield call(
                UserPrefApi.addNewBranding,
                userPrefDomain,
                rest,
            );

            if (logo) {
                try {
                    const data = new FormData();
                    data.append('image', logo);
                    yield call(
                        UserPrefApi.uploadThemeLogo,
                        userPrefDomain,
                        data,
                        addResponse.themePoid,
                    );

                    yield put(trackEvent(SegmentHelper
                        .uploadLogo(
                            action.payload.themeName,
                            Segment.EVENT_PROPERTIES.SUCCESS,
                        )));
                } catch (e) {
                    if (rest.headerTextColour) {
                        yield put({
                            type: GET_BRANDING,
                        });
                    }
                    yield put({
                        type: SAVE_BRANDING_SUCCESS,
                        payload: {
                            isError: false,
                            message: `${action.payload.themeName}${ADD_THEME_WITH_LOGO_FAIL}`,
                        },
                    });
                    yield put(trackEvent(SegmentHelper
                        .uploadLogo(
                            action.payload.themeName,
                            Segment.EVENT_PROPERTIES.FAILURE,
                        )));
                    yield put(trackEvent(SegmentHelper
                        .addNewTheme(
                            action.payload.themeName,
                            Segment.EVENT_PROPERTIES.SUCCESS,
                        )));
                    return;
                }
            }

            yield put({
                type: GET_BRANDING,
            });

            yield put({
                type: SAVE_BRANDING_SUCCESS,
                payload: {
                    isError: false,
                    message: `${action.payload.themeName} ${SUCCESS_ADD}.`,
                },
            });
            yield put(trackEvent(SegmentHelper
                .addNewTheme(
                    action.payload.themeName,
                    Segment.EVENT_PROPERTIES.SUCCESS,
                )));
        } catch (e) {
            yield put({
                type: SAVE_BRANDING_FAIL,
                payload: {
                    isError: true,
                    message: NETWORK_ERROR,
                },
            });
            yield put(trackEvent(SegmentHelper
                .addNewTheme(
                    action.payload.themeName,
                    Segment.EVENT_PROPERTIES.FAILURE,
                )));
        }
    };
}

function editBranding() {
    return function* (action) {
        const {
            customerThemePoid, themePoid, logo, ...rest
        } = action.payload;
        try {
            const state = yield select();
            const userPrefDomain = state.config.get('applicationDomain').userPreferences;

            if (rest.headerTextColour) {
                yield call(
                    UserPrefApi.editBranding,
                    userPrefDomain,
                    rest,
                    customerThemePoid,
                );
            }

            if (logo) {
                try {
                    const data = new FormData();
                    data.append('image', logo);
                    yield call(
                        UserPrefApi.uploadThemeLogo,
                        userPrefDomain,
                        data,
                        themePoid,
                    );

                    yield put(trackEvent(SegmentHelper
                        .uploadLogo(
                            action.payload.themeName,
                            Segment.EVENT_PROPERTIES.SUCCESS,
                        )));
                } catch (e) {
                    if (rest.headerTextColour) {
                        yield put({
                            type: GET_BRANDING,
                        });
                    }
                    const themesCopy = yield select((_state) => _state.manageBranding.get('themes'));
                    const themesClone = JSON.parse(JSON.stringify(themesCopy));
                    const themeToBeEditedIndex = themesClone
                        .findIndex(theme => theme.themePoid === themePoid);
                    themesClone[themeToBeEditedIndex].hasError = true;
                    yield put({
                        type: rest.headerTextColour ? EDIT_BRANDING_SUCCESS : EDIT_BRANDING_FAIL,
                        payload: {
                            isError: !rest.headerTextColour,
                            message: rest.headerTextColour
                                ? `${action.payload.themeName}${EDIT_THEME_WITH_LOGO_FAIL}`
                                : SAVE_LOGO_ALONE,
                            themes: themesClone,
                        },
                    });
                    yield put(trackEvent(SegmentHelper
                        .uploadLogo(
                            action.payload.themeName,
                            Segment.EVENT_PROPERTIES.FAILURE,
                        )));
                    yield put(trackEvent(SegmentHelper
                        .editTheme(
                            action.payload.themeName,
                            rest.headerTextColour
                                ? Segment.EVENT_PROPERTIES.SUCCESS :
                                Segment.EVENT_PROPERTIES.FAILURE,
                        )));
                    return;
                }
            }

            yield put({
                type: GET_BRANDING,
            });

            yield put({
                type: EDIT_BRANDING_SUCCESS,
                payload: {
                    isError: false,
                    message: `${action.payload.themeName} ${SUCCESS_EDIT}.`,
                },
            });
            yield put(trackEvent(SegmentHelper
                .editTheme(
                    action.payload.themeName,
                    Segment.EVENT_PROPERTIES.SUCCESS,
                )));
        } catch (e) {
            yield put({
                type: EDIT_BRANDING_FAIL,
                payload: {
                    isError: true,
                    message: NETWORK_ERROR,
                },
            });
            yield put(trackEvent(SegmentHelper
                .editTheme(
                    action.payload.themeName,
                    Segment.EVENT_PROPERTIES.FAILURE,
                )));
        }
    };
}

function deleteBranding() {
    return function* (action) {
        const { customerThemePoid, themeName } = action.payload;
        try {
            const state = yield select();
            yield call(
                UserPrefApi.deleteBranding,
                state.config.get('applicationDomain').userPreferences,
                customerThemePoid,
            );
            yield put(trackEvent(SegmentHelper
                .deleteTheme(themeName, Segment.EVENT_PROPERTIES.SUCCESS)));
            yield put({
                type: GET_BRANDING,
            });
            yield put({
                type: DELETE_BRANDING_SUCCESS,
                payload: {
                    isError: false,
                    message: `${themeName} has been removed from this organisation`,
                },
            });
        } catch (e) {
            yield put({
                type: DELETE_BRANDING_FAIL,
                payload: {
                    isError: true,
                    message: NETWORK_ERROR,
                },
            });
            yield put(trackEvent(SegmentHelper
                .deleteTheme(themeName, Segment.EVENT_PROPERTIES.FAILURE)));
        }
    };
}

function activateThemeFn() {
    return function* (action) {
        const { customerThemePoid, themeName, index } = action.payload;
        try {
            const { config } = yield select();
            yield call(
                UserPrefApi.activateTheme,
                config.get('applicationDomain').userPreferences,
                customerThemePoid,
            );
            yield put({
                type: ACTIVATE_THEME_SUCCESS,
                payload: {
                    index,
                    isError: false,
                    message: `${themeName} has been applied as the Branding for this organisation`,
                },
            });
            yield put(trackEvent(SegmentHelper
                .activateTheme(
                    themeName,
                    Segment.EVENT_PROPERTIES.SUCCESS,
                )));
        } catch (e) {
            yield put(activateThemeFail({
                isError: true,
                message: NETWORK_ERROR,
            }));
            yield put(trackEvent(SegmentHelper
                .activateTheme(
                    action.payload.themeName,
                    Segment.EVENT_PROPERTIES.FAILURE,
                )));
        }
    };
}

function removeThemeLogo() {
    return function* (action) {
        const { customerThemePoid, themeName } = action.payload;
        try {
            const state = yield select();
            yield call(
                UserPrefApi.removeThemeLogo,
                state.config.get('applicationDomain').userPreferences,
                customerThemePoid,
            );
            yield put({
                type: GET_BRANDING,
            });
            yield put({
                type: REMOVE_THEME_LOGO_SUCCESS,
                payload: {
                    isError: false,
                    message: `Uploaded logo for ${themeName} has been removed`,
                },
            });
        } catch (e) {
            yield put({
                type: REMOVE_THEME_LOGO_FAIL,
                payload: {
                    isError: true,
                    message: NETWORK_ERROR,
                },
            });
        }
    };
}

export const getBrandingGenerator = getBranding();
export const addNewBrandingGenerator = addNewBranding();
export const editBrandingGenerator = editBranding();
export const deleteBrandingGenerator = deleteBranding();
export const activateThemeGenerator = activateThemeFn();
export const removeThemeLogoGenerator = removeThemeLogo();

function* getBrandingWatcher() {
    yield takeLatest(GET_BRANDING, getBrandingGenerator);
}
function* addNewBrandingWatcher() {
    yield takeEvery(SAVE_BRANDING, addNewBrandingGenerator);
}
function* editBrandingWatcher() {
    yield takeLatest(EDIT_BRANDING, editBrandingGenerator);
}
function* deleteBrandingWatcher() {
    yield takeLatest(DELETE_BRANDING, deleteBrandingGenerator);
}
function* activateThemeWatcher() {
    yield takeEvery(ACTIVATE_THEME, activateThemeGenerator);
}
function* removeThemeLogoWatcher() {
    yield takeEvery(REMOVE_THEME_LOGO, removeThemeLogoGenerator);
}

export default [
    getBrandingWatcher(),
    addNewBrandingWatcher(),
    editBrandingWatcher(),
    deleteBrandingWatcher(),
    activateThemeWatcher(),
    removeThemeLogoWatcher(),
];
