import { memo } from 'react';
import PropTypes from 'prop-types';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import MenuList from '@mui/material/MenuList';
import MapTypeConstant from '../../../../../constants/mapTypeConstant';
import MapType from './MapType';

const {
    CORELOGIC_SATELLITE, CORELOGIC_HYBRID, CORELOGIC_ROAD, MAP_TYPES,
} = MapTypeConstant;

const CorelogicMapTypes = memo(({
    handleClose,
    setMapTypeIdState,
    mapTypeIdState,
    triggerMapViewChangeEvent,
}) => {
    const roadMap = mapTypeIdState === CORELOGIC_ROAD;
    const hybrid = mapTypeIdState === CORELOGIC_HYBRID;
    const satellite = mapTypeIdState === CORELOGIC_SATELLITE;

    const switchCorelogicMapTypes = (checked, corelogicMapType) => {
        if (checked) {
            setMapTypeIdState(corelogicMapType);
        } else {
            setMapTypeIdState(CORELOGIC_ROAD);
        }
    };

    const onChangeSatellite = (event) => {
        const isChecked = !satellite;
        switchCorelogicMapTypes(isChecked, CORELOGIC_SATELLITE);
        handleClose(event);
        triggerMapViewChangeEvent(CORELOGIC_SATELLITE);
    };

    const onChangeHybrid = (event) => {
        const isChecked = !hybrid;
        switchCorelogicMapTypes(isChecked, CORELOGIC_HYBRID);
        handleClose(event);
        triggerMapViewChangeEvent(CORELOGIC_HYBRID);
    };

    const onChangeRoadMap = (event) => {
        const isChecked = !roadMap;
        setMapTypeIdState(isChecked ? CORELOGIC_ROAD : CORELOGIC_SATELLITE);
        handleClose(event);
        triggerMapViewChangeEvent(isChecked ? CORELOGIC_ROAD : CORELOGIC_SATELLITE)
    };

    return (
        <ClickAwayListener onClickAway={handleClose}>
            <MenuList>
                <MapType onClick={onChangeSatellite} checked={satellite}>
                    { MAP_TYPES[CORELOGIC_SATELLITE].label }
                </MapType>
                <MapType onClick={onChangeHybrid} checked={hybrid}>
                    { MAP_TYPES[CORELOGIC_HYBRID].label }
                </MapType>
                <MapType onClick={onChangeRoadMap} checked={roadMap}>
                    { MAP_TYPES[CORELOGIC_ROAD].label }
                </MapType>
            </MenuList>
        </ClickAwayListener>
    );
});
CorelogicMapTypes.propTypes = {
    handleClose: PropTypes.func.isRequired,
    setMapTypeIdState: PropTypes.func.isRequired,
    mapTypeIdState: PropTypes.string.isRequired,
    triggerMapViewChangeEvent: PropTypes.func,
};
export default CorelogicMapTypes;
