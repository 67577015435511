import { isNZ } from '../../constants/crux';

export default (rawParams) => {
    const _params = new URLSearchParams(rawParams);
    const params = {
        volumeNumber: _params.get('volumeNumber') === 'null' ? '' :
            _params.get('volumeNumber'),
        folioNumber: _params.get('folioNumber') === 'null' ?
            '' :
            _params.get('folioNumber'),
        addressState: isNZ ? null : _params.get('addressState'),
        addressSuburb: _params.get('addressSuburb') === 'null' ?
            '' :
            _params.get('addressSuburb'),
    };
    Object.keys(params).forEach((key) => {
        if (!params[key]) delete params[key];
    });
    return params;
};
