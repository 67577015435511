import { Children, cloneElement } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Entitlements from 'js/helpers/Entitlements';

const FeaturedV2 = ({
                      role,
                      children,
                      displayWhenUnEntitled = true,
                      ...rest
                  }) => {
    const userDetail = useSelector(state => state?.clapi.get('usrDetail'));

    const [Child] = Children.toArray(children);

    let isEntitled = false;
    if (Entitlements.hasRole(userDetail, role)) {
        isEntitled = true;
    }

    return displayWhenUnEntitled || isEntitled ? cloneElement(Child, {
        entitled: isEntitled,
        ...rest,
    }) : null;
}

FeaturedV2.propTypes = {
    role: PropTypes.string,
    displayWhenUnEntitled: PropTypes.bool,
};

export default FeaturedV2;
