export const ADD_SAVED_LIST = 'ADD_SAVED_LIST';
export const GET_SAVED_LIST = 'GET_SAVED_LIST';
export const GET_SAVED_LIST_SUCCESS = 'GET_SAVED_LIST_SUCCESS';
export const GET_MORE_SAVED_LIST_SUCCESS = 'GET_MORE_SAVED_LIST_SUCCESS';
export const GET_MORE_SAVED_LIST_FAIL = 'GET_MORE_SAVED_LIST_FAIL';
export const GET_MORE_SAVED_LIST = 'GET_MORE_SAVED_LIST';
export const GET_SAVED_LIST_FAIL = 'GET_SAVED_LIST_FAIL';
export const GET_WATCHLIST_COUNT = 'GET_WATCHLIST_COUNT';
export const GET_WATCHLIST_COUNT_SUCCESS = 'GET_WATCHLIST_COUNT_SUCCESS';
export const GET_WATCHLIST_COUNT_FAIL = 'GET_WATCHLIST_COUNT_FAIL';
export const UPDATE_SAVED_LIST = 'UPDATE_SAVED_LIST';
export const GET_SAVED_LIST_INFO_MULTIPLE = 'GET_SAVED_LIST_INFO_MULTIPLE';
export const GET_SAVED_LIST_INFO_MULTIPLE_SUCCESS = 'GET_SAVED_LIST_INFO_MULTIPLE_SUCCESS';
export const GET_SAVED_LIST_INFO_MULTIPLE_FAIL = 'GET_SAVED_LIST_INFO_MULTIPLE_FAIL';
export const ADD_SAVED_LIST_INFO = 'ADD_SAVED_LIST_INFO';
export const ADD_WATCH_LIST_INFO = 'ADD_WATCH_LIST_INFO';
export const REMOVE_SAVED_LIST_INFO = 'REMOVE_SAVED_LIST_INFO';
export const REMOVE_WATCH_LIST_INFO = 'REMOVE_WATCH_LIST_INFO';
export const UPDATE_WATCH_LIST = 'UPDATE_WATCH_LIST';
export const SEARCH_SAVED_LIST = 'SEARCH_SAVED_LIST';
export const SEARCH_SAVED_LIST_SUCCESS = 'SEARCH_SAVED_LIST_SUCCESS';
export const SEARCH_SAVED_LIST_FAIL = 'SEARCH_SAVED_LIST_FAIL';
export const GET_PROPERTIES_ON_SAVED_OR_WATCH_LIST = 'GET_PROPERTIES_ON_SAVED_OR_WATCH_LIST';
export const GET_PROPERTIES_ON_SAVED_OR_WATCH_LIST_SUCCESS = 'GET_PROPERTIES_ON_SAVED_OR__WATCH_LIST_SUCCESS';
export const GET_MORE_PROPERTIES_ON_SAVED_OR_WATCH_LIST_SUCCESS = 'GET_MORE_PROPERTIES_ON_SAVED_OR_WATCH_LIST_SUCCESS';
export const GET_PROPERTIES_ON_SAVED_OR_WATCH_LIST_FAIL = 'GET_PROPERTIES_ON_SAVED_OR__WATCH_LIST_FAIL';
export const GET_RAPID_PROPERTIES_ON_SAVED_OR_WATCH_LIST = 'GET_RAPID_PROPERTIES_ON_SAVED_OR_WATCH_LIST';
export const GET_RAPID_PROPERTIES_ON_SAVED_OR_WATCH_LIST_SUCCESS = 'GET_RAPID_PROPERTIES_ON_SAVED_OR_WATCH_LIST_SUCCESS';
export const GET_RAPID_PROPERTIES_ON_SAVED_OR_WATCH_LIST_FAIL = 'GET_RAPID_PROPERTIES_ON_SAVED_OR_WATCH_LIST_FAIL';
export const GET_MORE_RAPID_PROPERTIES_ON_SAVED_OR_WATCH_LIST = 'GET_MORE_RAPID_PROPERTIES_ON_SAVED_OR_WATCH_LIST';
export const GET_MORE_RAPID_PROPERTIES_ON_SAVED_OR_WATCH_LIST_SUCCESS = 'GET_MORE_RAPID_PROPERTIES_ON_SAVED_OR_WATCH_LIST_SUCCESS';
export const GET_MORE_RAPID_PROPERTIES_ON_SAVED_OR_WATCH_LIST_FAIL = 'GET_MORE_RAPID_PROPERTIES_ON_SAVED_OR_WATCH_LIST_FAIL';

export const addSavedList = (payload) => ({
    type: ADD_SAVED_LIST,
    payload,
});

export const updateSavedList = (payload) => ({
    type: UPDATE_SAVED_LIST,
    payload,
});

export const addSavedListInfo = (payload) => ({
    type: ADD_SAVED_LIST_INFO,
    payload,
});

export const addWatchListInfo = (payload) => ({
    type: ADD_WATCH_LIST_INFO,
    payload,
});

export const removeSavedListInfo = (payload) => ({
    type: REMOVE_SAVED_LIST_INFO,
    payload,
});

export const removeWatchListInfo = (payload) => ({
    type: REMOVE_WATCH_LIST_INFO,
    payload,
});

export const updateWatchList = (payload) => ({
    type: UPDATE_WATCH_LIST,
    payload,
});

export const getSavedList = (payload) => ({
    type: GET_SAVED_LIST,
    payload,
});

export const getMoreSavedList = (payload) => ({
    type: GET_MORE_SAVED_LIST,
    payload,
});

export const searchSavedList = (payload) => ({
    type: SEARCH_SAVED_LIST,
    payload,
});


export const getWatchList = () => ({
    type: GET_WATCHLIST_COUNT,
});


export const getPropertiesOnSavedOrWatchList = (payload) => ({
    type: GET_PROPERTIES_ON_SAVED_OR_WATCH_LIST,
    payload,
});

export const getRapidSavedList = (payload) => ({
    type: GET_RAPID_PROPERTIES_ON_SAVED_OR_WATCH_LIST,
    payload,
});

export const getMoreRapidSavedList = (payload) => ({
    type: GET_MORE_RAPID_PROPERTIES_ON_SAVED_OR_WATCH_LIST,
    payload,
});

export const getSavedListInfoMultiple = (payload) => ({
    type: GET_SAVED_LIST_INFO_MULTIPLE,
    payload,
});
