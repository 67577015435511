import React from 'react';
import PropTypes from 'prop-types';

const RPDSidebarToggle = ({ rightAlign, setIsActivePane }) => (
    <div className={`rpd-sidebar-menu${rightAlign ? ' right-align' : ''}`}>
        <button
            className="rpd-burger-menu"
            onClick={() => {
                setIsActivePane(prevState => !prevState);
            }}
        >
            <span /><span /><span />
        </button>
    </div>
);

RPDSidebarToggle.propTypes = {
    rightAlign: PropTypes.bool,
    setIsActivePane: PropTypes.func,
};

export default RPDSidebarToggle;
