import PropTypes from 'prop-types';
import { FormControl, FormControlLabel, Radio, RadioGroup, Stack } from '@mui/material';
import {
    STATUS_TYPE_OPTIONS_ADDRESS
} from 'js/constants/rapidSearch';
import MUITooltip from 'js/components/common/MUITooltip';
import SearchFilterHelper from 'js/helpers/search/SearchFilterHelper';
import { UNSUBSCRIBED, ENTITLED_EXCLUDE_VIC } from 'js/constants/tooltipText';
import { STATE } from '../../../../constants/states';
import OTM from '../../../../constants/otm';
import ExcludeVICFeatureFlag from '../../../../model/launchDarkly/ExcludeVICFeatureFlag';
import Tooltip from '../../../common/Tooltip';

const StatusTypesRadio = ({
    statusType,
    onChange,
    clapiUsrDetail,
    featureFlag,
    isTenureDisabled = false,
    selectedAddress,
}) => {
    return (
        <FormControl sx={{ marginBottom: '19px' }}>
            <RadioGroup
                row
                name="row-radio-buttons-group"
                value={statusType}
                onChange={(e) => onChange(e.target.value)}
            >
                {
                    STATUS_TYPE_OPTIONS_ADDRESS.map((status) => {
                        let isEnabled = false;
                        let tooltip = status.subLabel;
                        const isEntitled = SearchFilterHelper
                            .isEntitledToSearchType(
                                status.entitlementChecker,
                                clapiUsrDetail,
                            );

                        const _featureFlag = new ExcludeVICFeatureFlag(featureFlag[status.ldFlagKey]);
                        const displayed = _featureFlag.isTrue()
                            || _featureFlag.isEntitlements()
                            || _featureFlag.isExcludeVICEntitlements()
                            || !_featureFlag.hasValue();
                        //TODO: To be refactored
                        if (_featureFlag.isTrue()) {
                            isEnabled = true;
                        } else if (!_featureFlag.hasValue() || _featureFlag.isEntitlements()) {
                            isEnabled = isEntitled;
                            tooltip = isEnabled ? tooltip : UNSUBSCRIBED;
                        } else if (!_featureFlag.hasValue() || _featureFlag.isExcludeVICEntitlements()) {
                            const hasVIC = selectedAddress?.some((address) => address.split(' ').includes(STATE.VIC.toUpperCase()));
                            isEnabled = isEntitled && !hasVIC;
                            if (isEntitled) {
                                if (hasVIC) {
                                    tooltip = ENTITLED_EXCLUDE_VIC;
                                    if (statusType === OTM.TENURE) {
                                        onChange(OTM.ALL);
                                    }
                                }
                            } else {
                                tooltip = UNSUBSCRIBED;
                            }
                        }

                        return (
                            displayed &&
                            <>
                                <MUITooltip
                                    key={status.value}
                                    title={tooltip}
                                    placement="top"
                                    arrow
                                    longTooltip
                                >
                                    <div>
                                        <FormControlLabel
                                            value={status.value}
                                            sx={{ marginBottom: '0' }}
                                            control={
                                                <Radio
                                                    sx={{
                                                        color: 'white',
                                                        '& + .MuiFormControlLabel-label': {
                                                            color: 'white',
                                                            fontSize: '13px',
                                                            letterSpacing: '0.5px',
                                                            lineHeight: '24px',
                                                        },
                                                        '&.Mui-disabled': {
                                                            color: 'white',
                                                            opacity: '0.5',
                                                            '& + .Mui-disabled': {
                                                                color: 'white',
                                                                opacity: '0.5',
                                                            },
                                                        },
                                                    }}
                                                    disabled={!isEnabled || (isTenureDisabled && status.value === 'tenure')}
                                                />
                                            }
                                            label={status.label}/>
                                    </div>
                                </MUITooltip>
                                {
                                    isEnabled && status?.tooltipText &&
                                    <Stack
                                        direction="column"
                                        sx={{
                                            justifyContent: 'center',
                                            '& > span': {
                                                height: '18px',
                                            }}}
                                    >
                                        <Tooltip
                                            tooltipTxt={status.tooltipText}
                                            tooltipId="tenure"
                                            tooltipClass="white"
                                            iconType="infoIcon"
                                            tooltipPosition="right"
                                            style={{
                                                marginTop: '-12px',
                                                fontSize: '7px',
                                            }}
                                        />
                                    </Stack>
                                }
                            </>
                        );
                    })
                }
            </RadioGroup>
        </FormControl>
    );
};

StatusTypesRadio.propTypes = {
    statusType: PropTypes.string,
    onChange: PropTypes.func,
    clapiUsrDetail: PropTypes.object,
    featureFlag: PropTypes.object,
};

export default StatusTypesRadio;
