import { Component } from 'react';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import PDFViewer from './PDFViewer';
import { getAssetDomain } from '../../constants/assets';

export const GenerateReportButton = props => (
    <Button
        name="generate-report-pdf"
        variant="contained"
        color="primary"
        className="button-primary"
        fullWidth
        {...props}
    >
        Generate Report
    </Button>
);

export default class ReportForm extends Component {
    static propTypes = {
        icon: PropTypes.string,
        title: PropTypes.string,
        fields: PropTypes.object,
        toggleReportModal: PropTypes.func,
        triggerHandler: PropTypes.func,
        reportType: PropTypes.string,
        reportName: PropTypes.string,
        filename: PropTypes.string,
        preparedByChecked: PropTypes.bool,
        manualOpenModal: PropTypes.bool,
        entryPoint: PropTypes.string,
        propertyId: PropTypes.number,
        renderPdfViewer: PropTypes.bool,
    };

    static defaultProps = {
        entryPoint: null,
    };

    render() {
        const {
            icon,
            title,
            fields,
            toggleReportModal,
            triggerHandler,
            reportType,
            reportName,
            filename,
            preparedByChecked,
            manualOpenModal,
            entryPoint,
            propertyId,
            renderPdfViewer = true,
            children,
            disabled,
        } = this.props;
        return (
            <form>
                <div className="header">
                    <span>
                        <img
                            src={getAssetDomain(icon)}
                            className="img-icon"
                            width="20px"
                            alt="print-icon"
                        />
                        {title}
                    </span>
                </div>
                <div className="content">
                    {fields}
                    {
                        renderPdfViewer &&
                            <PDFViewer
                                trigger={<GenerateReportButton disabled={disabled} />}
                                title={reportName}
                                triggerHandler={triggerHandler}
                                reportType={reportType}
                                filename={filename}
                                toggleReportModal={toggleReportModal}
                                clearBg
                                preparedByChecked={preparedByChecked}
                                manualOpenModal={manualOpenModal || null}
                                entryPoint={entryPoint}
                                propertyId={propertyId}
                            />
                    }
                    { children }
                </div>
            </form>
        );
    }
}
