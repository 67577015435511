export const GET_ALL_REI_TOKENS = 'GET_ALL_REI_TOKENS';
export const GET_ALL_REI_TOKENS_SUCCESS = 'GET_ALL_REI_TOKENS_SUCCESS';
export const GET_ALL_REI_TOKENS_FAIL = 'GET_ALL_REI_TOKENS_FAIL';
export const CONNECT_REI_TOKEN = 'CONNECT_REI_TOKEN';
export const CONNECT_REI_TOKEN_SUCCESS = 'CONNECT_REI_TOKEN_SUCCESS';
export const CONNECT_REI_TOKEN_FAIL = 'CONNECT_REI_TOKEN_FAIL';
export const DISCONNECT_REI_TOKEN = 'DISCONNECT_REI_TOKEN';
export const DISCONNECT_REI_TOKEN_SUCCESS = 'DISCONNECT_REI_TOKEN_SUCCESS';
export const DISCONNECT_REI_TOKEN_FAIL = 'DISCONNECT_REI_TOKEN_FAIL';
export const SET_INTEGRATION_STATUS = 'SET_INTEGRATION_STATUS';

export const GET_FORM_TEMPLATES = 'GET_FORM_TEMPLATES';
export const GET_FORM_TEMPLATES_SUCCESS = 'GET_FORM_TEMPLATES_SUCCESS';
export const GET_FORM_TEMPLATES_FAIL = 'GET_FORM_TEMPLATES_FAIL';

export const GET_USER_TEMPLATES = 'GET_USER_TEMPLATES';
export const GET_USER_TEMPLATES_SUCCESS = 'GET_USER_TEMPLATES_SUCCESS';
export const GET_USER_TEMPLATES_FAIL = 'GET_USER_TEMPLATES_FAIL';

export const getAllREITokens = (payload) => ({
    type: GET_ALL_REI_TOKENS,
    payload,
});

export const connectByStateAndToken = (state, token) => ({
    type: CONNECT_REI_TOKEN,
    payload: { state, token },
});

export const connectionSuccess = (payload) => ({
    type: CONNECT_REI_TOKEN_SUCCESS,
    payload,
});
export const connectionFailed = () => ({
    type: CONNECT_REI_TOKEN_FAIL
});

export const disconnectToken = (payload) => ({
    type: DISCONNECT_REI_TOKEN,
    payload,
});

export const disconnectSuccess = (payload) => ({
    type: DISCONNECT_REI_TOKEN_SUCCESS,
    payload,
});
export const disconnectFailed = () => ({
    type: DISCONNECT_REI_TOKEN_FAIL
});

export const setIntegrationStatus = (payload = null) => ({
    type: SET_INTEGRATION_STATUS, payload,
});

export const getFormTemplates = (payload) => ({
    type: GET_FORM_TEMPLATES,
    payload,
});

export const getUserTemplates = (payload) => ({
    type: GET_USER_TEMPLATES,
    payload,
});