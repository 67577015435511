export const GET_MULTI_SELECT_REPORT_SECTION = 'GET_MULTI_SELECT_REPORT_SECTION';
export const SET_MULTI_SELECT_REPORT_SECTION = 'SET_MULTI_SELECT_REPORT_SECTION';
export const SET_MULTI_SELECT_REPORT_SECTION_SUCCESS = 'SET_MULTI_SELECT_REPORT_SECTION_SUCCESS';
export const SSR_CHECKBOX_STATUS = 'SSR_CHECKBOX_STATUS';
export const SSR_PROPERTY_TYPE = 'SSR_PROPERTY_TYPE';
export const SSR_PROPERTY_TYPE_SUCCESS = 'SSR_PROPERTY_TYPE_SUCCESS';

export function setReportSection(type){
    return function(payload) {
        return {
            type,
            payload,
        }
    }
}

export function getReportSections(type){
    return function(payload) {
        return {
            type,
            payload,
        }
    }
}

export function setSSRCheckboxStatus(payload) {
    return {
        type: SSR_CHECKBOX_STATUS,
        payload,
    }
}

export function setSSRPropertyType(payload) {
    return {
        type: SSR_PROPERTY_TYPE,
        payload,
    }
}
