import attributes from '../constants/attributes';
import { isNZ } from '../constants/crux';

const buildPropertyUpdateRequestBody = (props) => {
    const {
        initialValues, formValues, property, usrDetail,
        additionalFields = null,
    } = props;
    let changedFields = {};

    if (formValues.propertyType &&
        formValues.propertyType.value !== initialValues.propertyType.value) {
        changedFields = {
            propertyType: formValues.propertyType.value,
        };
    }

    attributes.forEach((attribute) => {
        if (formValues[attribute.value] &&
            initialValues[attribute.value] !== formValues[attribute.value]) {
            changedFields = {
                ...changedFields,
                [attribute.value]: parseInt(formValues[attribute.value], 10),
            };
        }
    });

    return {
        propertyId: property.id,
        attributes: changedFields,
        author: {
            name: usrDetail ? `${usrDetail.firstName} ${usrDetail.lastName}`.trim() : '',
            email: usrDetail ? usrDetail.emailAddress : '',
            ...isNZ ? { userName: usrDetail ? usrDetail.userName : '' } : {}
        },
        additionalDetails: {
            message: formValues.additionalDetails,
            content: additionalFields,
        },
        image: formValues.image && formValues.image.base64,
    };
};

export default {
    buildPropertyUpdateRequestBody,
};
