import { Map as IMap } from 'immutable';
import {
    NEARMAP_COVERAGE,
    NEARMAP_COVERAGE_SUCCESS,
    NEARMAP_COVERAGE_FAIL,
    SET_SELECTED_NEARMAP_COVERAGE,
    CLEAR_NEARMAP_COVERAGE,
} from '../actions/map';
import {
    CLAPI_CLEAR_PROPERTY,
    GET_PARTIAL_PROPERTY_DETAILS
} from '../actions/clapi';

export const initialState = IMap({
    nearmap: {
        coverage: null,
        selectedCoverage: null,
        coverageFetching: false,
    },
});

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case NEARMAP_COVERAGE:
            return state.merge(IMap({
                nearmap: {
                    ...state.get('nearmap'),
                    coverageFetching: true,
                },
            }));
        case NEARMAP_COVERAGE_FAIL: case CLEAR_NEARMAP_COVERAGE:
            return state.merge(IMap({
                nearmap: {
                    ...state.get('nearmap'),
                    coverage: null,
                    coverageFetching: false,
                },
            }));
        case NEARMAP_COVERAGE_SUCCESS:
            return state.merge(IMap({
                nearmap: {
                    ...state.get('nearmap'),
                    coverage: action.payload.coverage,
                    coverageFetching: false,
                },
            }));
        case SET_SELECTED_NEARMAP_COVERAGE:
            return state.merge(IMap({
                nearmap: {
                    ...state.get('nearmap'),
                    selectedCoverage: action.payload.selected,
                },
            }));
        case CLAPI_CLEAR_PROPERTY:
        case GET_PARTIAL_PROPERTY_DETAILS:
            return IMap({
                nearmap: {
                    coverage: null,
                    selectedCoverage: null,
                    coverageFetching: false,
                },
            });
        default: return state;
    }
}
