import { UriBuilder } from '../helpers/UriBuilder';
import methods from '../constants/methods';
import API from './index';
import cruxProxy from './cruxProxy';

const HeaderOptions = {
    method: methods.GET.method,
    ...cruxProxy.getXsrfHeader(),
};

const getREIStateToken = (state, clAppAcctUserGuid) => {
    const path = new UriBuilder(`/api/rei/connected-token?clAppAccountUserGuid=${clAppAcctUserGuid}&state=${state}`);


    const requestOptions = {
        path: path.build(),
        protected: true,
        timeout: API.getTimeouts().large,
    };
    return API.initiateSessionApiCall(requestOptions, HeaderOptions);
};

const validateToken = (state, clAppAcctUserGuid) => {
    const path = new UriBuilder(`/api/rei/validate-token?clAppAccountUserGuid=${clAppAcctUserGuid}&state=${state}`);

    const requestOptions = {
        path: path.build(),
        protected: true,
        timeout: API.getTimeouts().large,
    };
    return API.initiateSessionApiCall(requestOptions, HeaderOptions);
};

const getTemplates = (state, clAppAcctUserGuid) => {
    const path = new UriBuilder(`/api/rei/templates?clAppAccountUserGuid=${clAppAcctUserGuid}&state=${state}`);

    const requestOptions = {
        path: path.build(),
        protected: true,
        timeout: API.getTimeouts().large,
    };
    return API.initiateSessionApiCall(requestOptions, HeaderOptions);
};

const getUserTemplates = (state, clAppAcctUserGuid) => {
    const path = new UriBuilder(`/api/rei/user-templates?clAppAccountUserGuid=${clAppAcctUserGuid}&state=${state}`);

    const requestOptions = {
        path: path.build(),
        protected: true,
        timeout: API.getTimeouts().large,
    };
    return API.initiateSessionApiCall(requestOptions, HeaderOptions);
};

const submitForm = ({
    state,
    clAppAcctUserGuid,
    formName,
    propertyId,
    templateId,
    isUserTemplate,
}) => {
    const path = new UriBuilder(`/api/rei/submit-form`);
    path.addParam('clAppAccountUserGuid', clAppAcctUserGuid);
    path.addParam('state', state);
    path.addParam('formName', formName);
    path.addParam('propertyId', propertyId);
    path.addParam('templateId', templateId);
    path.addParam('isUserTemplate', isUserTemplate);

    const requestOptions = {
        path: path.build(),
        protected: true,
        timeout: API.getTimeouts().large,
    };
    return API.initiateSessionApiCall(requestOptions, HeaderOptions);
};

export default {
    getREIStateToken,
    validateToken,
    getTemplates,
    getUserTemplates,
    submitForm,
};
