import React from 'react';

export default (props) => {
    if (props.onError) {
        const classname = props.className ? ` ${props.className}` : '';
        return (
            <div data-testid="search-validation" className={`field-error${classname}`}>
                <span>
                    { props.message }
                </span>
            </div>
        );
    }
    return null;
};
