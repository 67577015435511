import React from 'react';

export default ({ parts, className }) => parts.map((part, index) => (part.highlight ? (
    <span key={String(index)} className={className}>
        {part.text}
    </span>
) : (
    <span key={String(index)}>
        {part.text}
    </span>
)));
