import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import BlockUi from '../blockUi/BlockUi';
import ReportIcon from './ReportIcon';
import ReportModal from './ReportModal';
import SuburbReport from './SuburbReport';
import DigitalPropertyReport from './dppr/DigitalPropertyReport';
import OnePagePDF from './OnePagePDF';
import Commons from '../../helpers/Commons';
import { goToSignatureReport, removeSignatureError } from '../../actions/signature';
import PropertyReport from './PropertyReport';
import RentalEstimateReport from './RentalEstimateReport';
import { routeCodes } from '../../constants/routes';
import WithValuationAndRentalReportsHandler from '../higherOrderComponents/property/reports/WithValuationAndRentalReportsHandler';
import { entryPoints } from '../../constants/entryPoint';
import Valuation from '../common/Valuation';
import CruxSnackBar from '../common/CruxSnackBar';
import { isAU, isNZ } from '../../constants/crux';
import TitleDetailsReportModal from './TitleDetailsReportModal';
import LaunchDarklyHelper from '../../helpers/LaunchDarklyHelper';
import { trackEvent } from '../../actions/segment';
import Segment from '../../helpers/Segment';
import { NO_SUBURB_STATISTICS_REPORT } from '../../constants/tooltipText';
import SEGMENT from 'js/constants/segment';
import EntitlementsRole from 'js/constants/entitlementsRole';
import PropertyInvestmentReport from 'js/components/reports/PropertyInvestmentReport';
import Entitlements from '../../helpers/Entitlements';
import Reports from '../../constants/reports';
import Flags from 'js/constants/featureFlags';

const { CMA, RENTAL, SOI } = Reports.SIGNATURE_REPORT_TYPES;
const {
    PROPERTY_CMA,
    PROFILER_PROPERTY_ONE_PAGE,
    VALUATION,
    PROPERTY_PROFILER,
    PROFILER_SUBURB,
    RENTAL_AVM_FULL,
    CMA_GENERIC_REPORT_ENGINE_FULL,
    RENTAL_CMA,
    TITLES_REPORT,
    DIGITAL_PROPERTY_PROFILE,
    SUBURB_STATISTICS,
} = EntitlementsRole;

class ReportCollection extends Component {
    static propTypes = {
        route: PropTypes.object.isRequired,
        property: PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
            isActiveProperty: PropTypes.bool,
            address: PropTypes.shape({
                councilAreaId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
                singleLine: PropTypes.string,
                street: PropTypes.shape({
                    locality: PropTypes.shape({
                        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
                        name: PropTypes.string,
                        postcode: PropTypes.shape({
                            state: PropTypes.string,
                        }),
                        singleLine: PropTypes.string,
                    })
                })
            }),
            attributes: PropTypes.shape({
                bathrooms: PropTypes.number,
                bedrooms: PropTypes.number,
                carSpaces: PropTypes.number,
                floorArea: PropTypes.number,
                landArea: PropTypes.number,
                propertyType: PropTypes.string,
            }),
            coordinate: PropTypes.shape({
                latitude: PropTypes.number,
                longitude: PropTypes.number,
            }),
        }),
        match: PropTypes.object,
        featureFlag: PropTypes.object,
        dispatch: PropTypes.func,
        displaySOIReport: PropTypes.bool,
        displayRentalEstimate: PropTypes.bool,
        displayAvmDetails: PropTypes.bool,
        error: PropTypes.object,
        blocking: PropTypes.bool,
        entryPoint: PropTypes.string,
        reportRoles: PropTypes.array,
        isFetching: PropTypes.bool,
        signatureErrorMessage: PropTypes.string,
        signatureSubType: PropTypes.string,
        applicationDomain: PropTypes.object,
    };

    static defaultProps = {
        blocking: false,
        entryPoint: null,
    };

    constructor(props) {
        super(props);
        this.state = {
            activeReport: 5,
            openReportModal: false,
        };
    }

    static getDerivedStateFromProps(nextProps) {
        const isSuburb = !!nextProps.property?.address?.street?.locality?.singleLine
            && !!nextProps.property?.address?.street?.locality?.id;

        return {
            isSuburb,
            proposalsReport: LaunchDarklyHelper
                .getProposalsPointReportState(nextProps.featureFlag, nextProps.userDetail),
            propertyInvestmentReport: LaunchDarklyHelper
                .featureFlagCheckerWithEntitlements(
                    nextProps.featureFlag,
                    Flags.PROPERTY_INVESTMENT_REPORT,
                    Entitlements.hasPropertyInvestmentRole(nextProps.userDetail),
                ),
        }
    }

    componentDidUpdate(prevProps) {
        const { route, error, match } = this.props;
        if ((error && route !== routeCodes.APPLICATION_ERROR) ||
            (prevProps.match && prevProps.match.url !== match.url)
        ) {
            this.closeReportModal(false);
        }
    }

    closeReportModal = () => this.setState(({ openReportModal }) => {
        if (!openReportModal) return;
        return { openReportModal: false };
    });

    toggleReportIcons = () => {
        const { blocking } = this.props;
        if (!blocking && this.props.toggleReportModal) {
            this.props.toggleReportModal();
        }
    };

    // Toggles report modal
    // accepts number 1-3
    // 1 - property report
    // 2 - suburb report
    // 3 - 1 page pdf
    toggleReportModal = (activeReport = null) => {
        this.setState(state => ({
            openReportModal: !state.openReportModal,
            activeReport: activeReport && parseInt(activeReport, 10),
        }));
    };

    openSignatureInNewTab = (reportType) => {
        const { property, dispatch, entryPoint, route } = this.props;
        dispatch(goToSignatureReport(property.id, reportType, null, entryPoint, route.pageContext));
    };

    openSuburbStatsInNewTab = () => {
        const { property, route, dispatch, entryPoint } = this.props;
        const localityId = Commons.get(property, 'address.street.locality.id');
        const suburbAddress = Commons.get(property, 'address.street.locality.singleLine');
        const councilAreaId = Commons.get(property, 'address.councilAreaId');
        dispatch(trackEvent(Segment.suburbStatisticReport({
            pageContext: route.pageContext,
            objectContext: suburbAddress,
            entryPoint: entryPoint || entryPoints.REPORTS_MENU,
        })));
        window.open(routeCodes.SUBURB_STATS_REPORT.path(suburbAddress, localityId, councilAreaId));
    };

    openProposalReport = () => {
        const { applicationDomain, property, dispatch, route, entryPoint } = this.props;
        const link = {
            page: 'createProposal',
            corelogicPropertyId: property?.id,
        };
        const proposalUrl = `${applicationDomain?.rpProposals}?link=${JSON.stringify(link)}`;
        window.open(proposalUrl, '_blank');
        dispatch(trackEvent(Segment.generateReport({
            pageContext: route.pageContext,
            entryPoint: entryPoint || entryPoints.REPORTS_MENU,
            propertyId: property?.id,
            reportType: SEGMENT.EVENT_PROPERTIES.PROPOSAL,
            reportFormat: SEGMENT.EVENT_PROPERTIES.HTML,
        })));
    };

    getSuburbStatisticsTooltipText = () => !this.state.isSuburb && NO_SUBURB_STATISTICS_REPORT;

    render() {
        const {
            children,
            route,
            property,
            displayAvmDetails,
            displaySOIReport,
            displayRentalEstimate,
            blocking,
            dispatch,
            entryPoint,
            reportRoles,
            isFetching,
            signatureSubType,
            signatureErrorMessage,
            showReport,
        } = this.props;
        const {
            openReportModal,
            activeReport,
            isSuburb,
            proposalsReport,
            propertyInvestmentReport,
        } = this.state;
        const propertyAddress = Commons.get(property, 'address.singleLine');
        const isSuburbStatisticsEnabled = isSuburb && reportRoles.includes(SUBURB_STATISTICS);
        return (
            <>
                <div className="dropdown reports-dropdown">
                    { children }
                    {
                        showReport &&
                        <div className="dropdown-menu">
                            <div className="snack-bar-cont" style={{ position: 'relative', top: '-23px', left: '323px' }}>
                                {!!signatureErrorMessage &&
                                    <CruxSnackBar
                                        open={[CMA, RENTAL, SOI].includes(signatureSubType)}
                                        onClose={() => dispatch(removeSignatureError())}
                                        severity="error"
                                        message={signatureErrorMessage}
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        sx={{
                                            position: 'absolute',
                                        }}
                                    />
                                }
                            </div>
                            <BlockUi
                                blocking={blocking}
                                renderChildren={false}
                                containerProps={{
                                    position: 'relative',
                                    containerCss: { minHeight: 101 }
                                }}
                            >
                                <ClickAwayListener
                                    onClickAway={this.toggleReportIcons}
                                >
                                    <div className="menu-container">
                                        <h5>Digital Reports</h5>
                                        <div className="menus">
                                            <ReportIcon
                                                onClick={() => this.toggleReportModal(4)}
                                                asset="property-report@2x.png"
                                                reportClass="digital-profile-report"
                                                upperTitle="Digital Property"
                                                lowerTitle="Report"
                                                disabled={!reportRoles?.includes(DIGITAL_PROPERTY_PROFILE)}
                                                tooltipId="digital-profile-report-tooltip"
                                            />
                                            {
                                                proposalsReport.visible &&
                                                <ReportIcon
                                                    onClick={() => this.openProposalReport()}
                                                    asset="proposals.svg"
                                                    reportClass="rp-proposals"
                                                    upperTitle="Proposal"
                                                    lowerTitle=""
                                                    disabled={proposalsReport.disabled || proposalsReport.entitled === false}
                                                />
                                            }
                                        </div>
                                        <h5>PDF Reports</h5>
                                        <div className="menus">
                                            <ReportIcon
                                                onClick={() => this.openSignatureInNewTab(CMA)}
                                                asset="cma@2x.png"
                                                reportClass="cma-report"
                                                upperTitle="CMA"
                                                lowerTitle="Report"
                                                isLoading={signatureSubType === CMA && isFetching}
                                                disabled={!reportRoles.includes(PROPERTY_CMA)}
                                            />
                                            <ReportIcon
                                                onClick={() => this.toggleReportModal(3)}
                                                asset="1-page-pdf@2x.png"
                                                reportClass="one-page-report"
                                                upperTitle="1 Page"
                                                lowerTitle="PDF"
                                                disabled={!reportRoles.includes(PROFILER_PROPERTY_ONE_PAGE)}
                                            />
                                            {
                                                isAU && displayAvmDetails &&
                                                <Valuation
                                                    display={Reports.DISPLAY_TYPE.REPORT}
                                                    trigger={(
                                                        <ReportIcon
                                                            asset="valuation-report@2x.png"
                                                            reportClass="valuation-report"
                                                            upperTitle="Valuation"
                                                            lowerTitle="Estimate"
                                                            disabled={!reportRoles.includes(VALUATION)}
                                                        />
                                                    )}
                                                    displayAvmDetails={displayAvmDetails}
                                                    propertyAddress={propertyAddress}
                                                    propertyId={property.id}
                                                    pageContext={route.pageContext}
                                                    entryPoint={entryPoint || entryPoints.REPORTS_MENU}
                                                />
                                            }
                                            <ReportIcon
                                                onClick={() => this.toggleReportModal(1)}
                                                asset="property-report@2x.png"
                                                reportClass="property-report"
                                                upperTitle="Property"
                                                lowerTitle="Report"
                                                disabled={!reportRoles.includes(PROPERTY_PROFILER)}
                                            />
                                            {
                                                isAU && isSuburb &&
                                                <ReportIcon
                                                    onClick={() => this.toggleReportModal(2)}
                                                    asset="suburb-report@2x.png"
                                                    reportClass="suburb-report"
                                                    upperTitle="Suburb"
                                                    lowerTitle="Report"
                                                    disabled={!reportRoles.includes(PROFILER_SUBURB)}
                                                />
                                            }
                                            {
                                                displayRentalEstimate &&
                                                <RentalEstimateReport
                                                    trigger={
                                                        <ReportIcon
                                                            asset="rental-valuation-report@2x.png"
                                                            reportClass="rental-report"
                                                            upperTitle="Rental"
                                                            lowerTitle="Estimate"
                                                            disabled={!reportRoles.includes(RENTAL_AVM_FULL)}
                                                        />
                                                    }
                                                    propertyAddress={propertyAddress}
                                                    propertyId={property.id}
                                                    route={route}
                                                    entryPoint={entryPoint || entryPoints.REPORTS_MENU}
                                                />
                                            }
                                            {
                                                displaySOIReport &&
                                                <ReportIcon
                                                    onClick={() => this.openSignatureInNewTab(SOI)}
                                                    asset="statement-of-information-vic@2x.png"
                                                    reportClass="soi-report"
                                                    upperTitle="Statement of"
                                                    lowerTitle="Information"
                                                    disabled={!reportRoles.includes(CMA_GENERIC_REPORT_ENGINE_FULL)}
                                                    isLoading={signatureSubType === SOI && isFetching}
                                                />
                                            }
                                            <ReportIcon
                                                onClick={() => this.openSignatureInNewTab(RENTAL)}
                                                asset="rental-cma@2x.png"
                                                reportClass="rcma-report"
                                                upperTitle="Rental CMA"
                                                lowerTitle="Report"
                                                isLoading={signatureSubType === RENTAL && isFetching}
                                                disabled={!reportRoles.includes(RENTAL_CMA)}
                                            />
                                            {
                                                isNZ &&
                                                <ReportIcon
                                                    onClick={() => this.toggleReportModal(5)}
                                                    asset="title@2x.png"
                                                    reportClass="titles-report"
                                                    upperTitle="Title"
                                                    lowerTitle="Report"
                                                    disabled={!reportRoles.includes(TITLES_REPORT)}
                                                />
                                            }
                                            <ReportIcon
                                                onClick={() => this.openSuburbStatsInNewTab()}
                                                asset="suburb-statistics-report-orange@2x.png"
                                                reportClass="suburb-stats-report"
                                                upperTitle="Suburb Statistics"
                                                lowerTitle="Report"
                                                disabled={!isSuburbStatisticsEnabled}
                                                tooltipTxt={this.getSuburbStatisticsTooltipText()}
                                            />
                                            {
                                                !propertyInvestmentReport?.isHidden && isAU && (
                                                    <ReportIcon
                                                        onClick={() => this.toggleReportModal(6)}
                                                        asset="PIR-Black.svg"
                                                        reportClass="property-investment-report"
                                                        upperTitle="Property Investment"
                                                        lowerTitle="Report"
                                                        disabled={!propertyInvestmentReport?.isEnabled}
                                                        tooltipTxt={propertyInvestmentReport?.tooltip}
                                                    />
                                                )
                                            }
                                        </div>
                                    </div>
                                </ClickAwayListener>
                            </BlockUi>
                        </div>
                    }
                </div>
                <ReportModal
                    open={openReportModal}
                    onClose={this.toggleReportModal}
                >
                    {
                        activeReport === 1 &&
                        <PropertyReport
                            propertyAddress={propertyAddress}
                            propertyId={property.id}
                            route={route}
                            entryPoint={entryPoint}
                            toggleReportModal={this.toggleReportModal}
                        />
                    }
                    {
                        activeReport === 2 &&
                        <SuburbReport
                            suburbAddress={
                                Commons.get(property, 'address.street.locality.singleLine')
                            }
                            localityId={
                                Commons.get(property, 'address.street.locality.id')
                            }
                            propertyId={property.id}
                            route={route}
                            entryPoint={entryPoint}
                            toggleReportModal={this.toggleReportModal}
                        />
                    }
                    {
                        activeReport === 3 &&
                        <OnePagePDF
                            property={property}
                            pageContext={route.pageContext}
                            title="1 Page PDF Report"
                            entryPoint={entryPoint}
                            toggleReportModal={this.toggleReportModal}
                        />
                    }
                    {
                        activeReport === 4 &&
                        <DigitalPropertyReport
                            suburbAddress={
                                Commons.get(property, 'address.street.locality.singleLine')
                            }
                            localityId={
                                Commons.get(property, 'address.street.locality.id')
                            }
                            propertyAddress={propertyAddress}
                            propertyId={property.id}
                            route={route}
                            toggleReportModal={this.toggleReportModal}
                        />
                    }
                    {
                        activeReport === 5 &&
                        <TitleDetailsReportModal
                            propertyAddress={propertyAddress}
                            propertyId={property.id}
                            toggleReportModal={this.toggleReportModal}
                            route={route}
                        />
                    }
                    {
                        activeReport === 6 && isAU &&
                        <PropertyInvestmentReport
                            propertyAddress={propertyAddress}
                            propertyState={property?.address?.street?.locality?.postcode?.state}
                            propertyId={property.id}
                            toggleReportModal={this.toggleReportModal}
                            entryPoint={entryPoint || entryPoints.REPORTS_MENU}
                            pageContext={route.pageContext}
                        />
                    }
                </ReportModal>
            </>
        );
    }
}

export const ConnectedReportCollection = connect(state => ({
    applicationDomain: state.config.get('applicationDomain'),
    featureFlag: state.launchDarkly.get('featureFlag'),
    userDetail: state?.clapi.get('usrDetail'),
}))(ReportCollection);
ConnectedReportCollection.displayName = 'ReportCollection';

export default connect(state => ({
    isFetching: state.signature.get('isFetching'),
    signatureErrorMessage: state.signature.get('errorMessage'),
    signatureSubType: state.signature.get('subType'),
    featureFlag: state.launchDarkly.get('featureFlag'),
    applicationDomain: state.config.get('applicationDomain'),
    userDetail: state?.clapi.get('usrDetail'),
}))(WithValuationAndRentalReportsHandler(ReportCollection))
