import React from 'react';
import PropTypes from 'prop-types';

const RPDSidebarHeader = ({
    openingText = 'Select one of our products below',
}) => (
    <div id="rpd-sidebar-header">
        <p className="rpd-opening-text">{openingText}</p>
    </div>
);

RPDSidebarHeader.propTypes = {
    openingText: PropTypes.string,
};

export default RPDSidebarHeader;
