import React from 'react';
import ReactDOM from 'react-dom';
import 'js/polyfill/polyfill';
import './index.scss';
import './i18n';
import { ThemeProvider } from '@mui/material/styles';
import { LicenseInfo } from '@mui/x-license';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import Redux from './js/store';
import App from './js/views/App';
import BugsnagClient from './js/bugsnag';
import reportWebVitals from './reportWebVitals';
import Themes from 'js/helpers/themes';
import BlockUi from './js/components/blockUi/BlockUi';

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_X_PRO_KEY);

const ErrorBoundary = BugsnagClient.getPlugin('react').createErrorBoundary(React);

ReactDOM.render(
    <React.StrictMode>
        <Provider store={Redux.store}>
            <PersistGate
                loading={<BlockUi parent="index" tag="div" className="page" blocking />}
                persistor={Redux.localStoragePersistor}
            >
                <ThemeProvider theme={Themes.mainAppTheme}>
                    <ErrorBoundary>
                        <App />
                    </ErrorBoundary>
                </ThemeProvider>
            </PersistGate>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
