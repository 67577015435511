import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import React, { useEffect, useState } from 'react';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import { styled, Chip, TextField, Paper } from '@mui/material';
import STATES, { STATE } from 'js/constants/states';
import { isAU, isNZ } from 'js/constants/crux';
import Commons from 'js/helpers/Commons';
import { clearSuggestion, getSuggestions, setSearchStatusType } from 'js/actions/clapi';
import { resetHandler } from 'js/actions/msgApi';
import StyledTooltip from 'js/components/common/styledMUI/StyledTooltip';
import { routeCodes } from 'js/constants/routes';
import {
    SUGGESTION_TYPE_NO_LGA_SEARCH,
    SLAS_PLACEHOLDER,
} from 'js/helpers/Localization';
import Entitlements from 'js/helpers/Entitlements';
import Colors from 'js/constants/colors';
import SuggestionTypes, { suggestionLabel } from 'js/constants/suggestionTypes';
import { getAssetDomain } from 'js/constants/assets';
import { NO_RECENT_SEARCH, MULTIPLE_RECENT_SEARCH_DELIMITER } from 'js/constants/slas';
import StyledSearchButton from 'js/components/common/styledMUI/StyledSearchButton';
import LDHelper from 'js/helpers/LaunchDarklyHelper';
import IncludeHistoricCheckbox from 'js/components/global/searchBar/SLAS/IncludeHistoricCheckbox';
import HistoricalFlag from 'js/components/global/searchBar/SLAS/HistoricalFlag';
import { createEventSuggestion } from 'js/actions/segment';
import { invalidateCruxAppError } from 'js/actions/errorHandler';
import useAddressSuggestionType from 'js/hooks/multiLocality/useAddressSuggestionType';
import useLocationSearch from 'js/hooks/multiLocality/useLocationSearch';
import CruxDialogBox from 'js/components/common/modals/CruxDialogBox';
import CruxSnackBar from 'js/components/common/CruxSnackBar';
import StatusTypesRadio from 'js/components/global/searchBar/multiLocalitySearch/StatusTypesRadio';
import RapidSearch, {
    RESULT_VIEW,
    STATUS_TYPE_OPTIONS,
    STATUS_TYPE_OPTIONS_ADDRESS,
    SUGGESTION_TYPE_TENURE,
} from 'js/constants/rapidSearch';
import OTM from 'js/constants/otm';
import Clapi from 'js/api/clapi';
import { cruxLoader, cruxUnloader } from 'js/actions/crux';
import SearchValidation from 'js/components/global/searchBar/SearchValidation';
import tooltipText from 'js/constants/tooltipText';
import SearchResultsFunctions from 'js/helpers/search/SearchResultsFunctions';
import ExcludeVICFeatureFlag from 'js/model/launchDarkly/ExcludeVICFeatureFlag';
import {
    SearchBarContainer,
    SuggestHeaderContainer,
    StyledAutoComplete,
    StyledOption,
    StyledNoRecentSearchOption,
    SuggestionIcon,
    SuggestionHeader,
    SuggestionHeaderText,
    StyledButton,
    EndAdornmentContainer,
    StyledOptionText,
} from 'js/components/global/searchBar/styledComponents/searchbarStyles';
import useActiveView from '../../../../hooks/useActiveView';

const getActiveView = (goToPdp, optionSelected, activeView) => {
    if (!goToPdp) {
        if (optionSelected?.length > 1 && activeView === RESULT_VIEW.MAP) {
            return RESULT_VIEW.CARD;
        }
        return activeView;
    }
};

const {
    ADDRESS, LOCALITY, TERRITORIAL_AUTHORITY, COUNCIL_AREA, SUBURB,
} = SuggestionTypes;

const PROPERTY = 'Property';
const searchContext = { ...suggestionLabel, address: PROPERTY };

const MultiLocalitySearchBar = styled('div')({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
});

let timeoutRequest;

export const MultiLocalitySearch = ({
    usrDetail,
    suggestions,
    recentSearches,
    includeHistoric,
    location,
    history,
    dispatch,
    isHeader,
    entryPoint,
    route,
    handler,
    featureFlag,
 }) => {
    const [options, setOptions] = useState([]);
    const [optionSelected, setOptionSelected] = useState([]);
    const [searchButtonEnabled, setSearchButtonEnabled] = useState(false);
    const [isSearchBarActive, setIsSearchBarActive] = useState(false);
    const [statusType, setStatusType] = useState(STATUS_TYPE_OPTIONS[0].value);
    const [isTenureDisabled, setIsTenureDisabled] = useState(false);
    const [hasAverageHoldTrend, setHasAverageHoldTrend] = useState(true);
    const isTenureSearch = statusType === OTM.TENURE;

    const {
        isHistoric,
        setIsHistoric,
        suggestionTypeSelected,
        setSuggestionTypeSelected,
        hasRecentSearch,
        setHasRecentSearch,
        isOpen,
        setIsOpen,
        handleDeleteDialogOpen,
        handleDeleteDialogClose,
        handleRemoveRecentSearches,
        openDeleteDialog,
        getOptionLabel,
        keyword,
        setKeyword,
    } = useLocationSearch({ location, route, dispatch });
    const { activeView } = useActiveView();

    const getEntryPoint = () => {
        const isSelectedOptionRecent = optionSelected.every(item => !!item.isRecentSearch);
        return isSelectedOptionRecent ? 'Recent' : 'Typed';
    };

    const trackSearchEvent = (suggestionType, goToPdp = false) => {
        const suggestionTypeToMap = suggestionType || suggestionTypeSelected;
        const contextToSend = searchContext[suggestionTypeToMap];
        const activeViewToSend = getActiveView(goToPdp, optionSelected, activeView);
        dispatch(createEventSuggestion(
            route.pageContext,
            getEntryPoint(),
            Commons.capitalizeFirstLetter(contextToSend),
            optionSelected.map(selected => selected.suggestion),
            Commons.capitalizeFirstLetter(suggestionLabel.address),
            undefined,
            isHistoric.toString(),
            RapidSearch.TABS_ATTR[statusType],
            activeViewToSend,
        ));
        dispatch(clearSuggestion());
        dispatch(invalidateCruxAppError());
    };
    useAddressSuggestionType({
        optionSelected: optionSelected[optionSelected.length - 1],
        entryPoint,
        historic: isHistoric,
        setSuggestionTypeSelected,
        route,
        trackSearchEvent,
    });

    const hasLGASearchRole = () => Entitlements.hasLGASearchRole(usrDetail);
    const getLocationStateSuggestion = () => location?.state?.suggestion || [];

    useEffect(() => {
        const isAddressCollective = location?.state?.addressCollective;
        if (!isAddressCollective && (!optionSelected.length && getLocationStateSuggestion().length)) {
            setOptionSelected(getLocationStateSuggestion());
        }
    }, []);

    useEffect(() => {
        let filteredRecentSearches = recentSearches;
        if (!hasLGASearchRole()) {
            filteredRecentSearches = recentSearches
                .filter(result => SUGGESTION_TYPE_NO_LGA_SEARCH.includes(result.suggestionType));
        }

        if (isTenureSearch) {
            filteredRecentSearches = recentSearches
                .filter(result => SUGGESTION_TYPE_TENURE.includes(result.suggestionType));
        }

        if (suggestions.length > 0) {
            setFilteredOption(suggestions);
        } else if (hasRecentSearch && filteredRecentSearches?.length > 0) {
            setOptions(filteredRecentSearches);
        } else if (!optionSelected.length && filteredRecentSearches?.length === 0) {
            setOptions(NO_RECENT_SEARCH);
        } else {
            setOptions([]);
        }
    }, [suggestions, hasRecentSearch, recentSearches, statusType]);

    useEffect(() => {
        if (optionSelected.length) {
            if (!Commons.isEqual(location?.state?.suggestion, optionSelected)) {
                setSearchButtonEnabled(true);
            } else {
                setSearchButtonEnabled(isHistoric !== !!location?.state?.isActiveProperty);
            }
        } else {
            setSearchButtonEnabled(false);
        }
    }, [optionSelected.length, isHistoric]);

    const setFilteredOption = (_suggestions) => {
        const filteredSuggestions = _suggestions.filter(e => !optionSelected.some(option => option.suggestion === e.suggestion)).slice(0, 10);
        setOptions(filteredSuggestions);
    }

    const getSuggestionTypes = () => {
        let suggestionTypes = [];
        // if no LGA search entitlements role, limit the suggestion types
        if (!hasLGASearchRole()) {
            suggestionTypes = SUGGESTION_TYPE_NO_LGA_SEARCH;
        }
        if (isTenureSearch) {
            suggestionTypes =  SUGGESTION_TYPE_TENURE;
        }

        // Limit suggestions to same suggestion type if multiple
        if (suggestionTypeSelected) {
            suggestionTypes = [suggestionTypeSelected]
        }
        return suggestionTypes;
    }

    const getLimit = (keyword) => {
        const isStateSearch = STATES.includes(keyword.toUpperCase());
        return isAU && isStateSearch ? 2 : 3;
    };

    const onInputChange = (event, val) => {
        setKeyword(val);
        const limit = getLimit(val);
        let displayRecentSearch = true;
        if (val.length < limit || Commons.hasBackSlash(val)) {
            dispatch(clearSuggestion());
        } else {
            displayRecentSearch = false;
            clearTimeout(timeoutRequest);
            timeoutRequest = setTimeout(() => {
                dispatch(getSuggestions({
                    keyword: val,
                    suggestionTypes: getSuggestionTypes(),
                    includeHistoric: isHistoric,
                    limit: getSuggestionLimit(val),
                }));
            }, 500);
        }

        if (!optionSelected.length) {
            setHasRecentSearch(displayRecentSearch);
        }
    }

    const getSuggestionLimit = (keyword) => {
        // Get more suggestions when the search keyword overlaps with selected options
        let suggestionOverlapped = 0;
        optionSelected.forEach(option => {
            if (option.suggestion.toLowerCase().startsWith(keyword.toLocaleString())) {
                suggestionOverlapped += 1;
            }
        });
        return 10 + suggestionOverlapped;
    }

    const mapRecentSearchIntoSuggestion = (recentSearchValue, suggestionType) => {
        const splitRecentSearch = recentSearchValue.split(MULTIPLE_RECENT_SEARCH_DELIMITER);
        return splitRecentSearch.map(item => ({ suggestion: item, suggestionType, isRecentSearch: true }));
    }

    const onChange = (e, value) => {
        // Reset selected suggestion if all chips were deleted.
        if (!value.length) {
            setSuggestionTypeSelected('');
            setOptionSelected([]);
        }
        // Prevents value being selected if it is not part of the options
        if (typeof value[value.length - 1] === 'string') return;
        const isMultiLocalitySearch = !!value[0]?.searchString?.includes('|');
        const isStreetSearch = !!value[0]?.suggestionType?.includes(SuggestionTypes.STREET);
        const isVICSearch = !!value[0]?.searchString?.includes('VIC');
        const _featureFlag = new ExcludeVICFeatureFlag(featureFlag['crux.tenure.search']);

        if (value.length > 1 || isMultiLocalitySearch || isStreetSearch || (isVICSearch && _featureFlag.isExcludeVICEntitlements())) {
            if (isTenureSearch) {
                setStatusType(OTM.ALL);
            }
            setIsTenureDisabled(true);
        } else {
            setIsTenureDisabled(false);
        }

        const isNoRecentSearch = Commons.isEqual(value, NO_RECENT_SEARCH)
        if (isNoRecentSearch) {
            return setIsOpen(false)
        }
        const hasValue = !!value.length;
        const [first] = value || [];
        setHasRecentSearch(!hasValue);
        const recentSearchValue = first?.searchString;

        let optionSelectedToSet = value;
        if (recentSearchValue) {
            optionSelectedToSet = mapRecentSearchIntoSuggestion(recentSearchValue, first.suggestionType);
        }
        setOptionSelected(optionSelectedToSet);
        if (hasValue && !optionSelected.length) {
            setSuggestionTypeSelected(first.suggestionType);
        }
        if (!hasValue) {
            setSuggestionTypeSelected('');
        }
    };


    const getEndAdornment = (_suggestionType) => {
        if (!_suggestionType) return null;
        const style = {
            color: Colors.STAR_DUST,
            fontSize: '15px',
        };
        const addCircleStyle = { ...style, width: '20px', height: '20px' };
        return _suggestionType === ADDRESS ?
            <ArrowForwardIosIcon sx={style} /> :
            <AddCircleIcon sx={addCircleStyle} />
    };

    const renderOptions = (props, option, { inputValue }) => {
        const { suggestionType, isActiveProperty } = option;

        const suggestion = getOptionLabel(option);
        const matches = match(suggestion, Commons.removeComma(inputValue), { insideWords: true });
        const parts = parse(suggestion, matches);
        const isHistoricProperty = Commons.isHistoricProperty({ isActiveProperty });
        const suggestionTypeIconName = isNZ && suggestionType === TERRITORIAL_AUTHORITY ?
            COUNCIL_AREA :
            suggestionType;
        const _suggestionType = suggestionType === LOCALITY ? SUBURB : suggestionType;
        const OptionComponent = suggestionType ? StyledOption : StyledNoRecentSearchOption;
        const isRecentSearch = !!option.searchString;
        const ellipsisDependency = isRecentSearch ? 'inherit' : 'flow-root';
        const formattedSuggestion = Commons.formatPipeSeparatedAddress(suggestion);
        return (
            <OptionComponent {...props}>
                {
                    _suggestionType &&
                        <StyledTooltip
                            title={_suggestionType}
                            arrow
                        >
                            <SuggestionIcon
                                className="img-responsive"
                                src={encodeURI(getAssetDomain(`${suggestionTypeIconName}.svg`))}
                                alt={`${suggestionTypeIconName}-icon`}
                            />
                        </StyledTooltip>
                }
                <div style={{ minWidth: 300, display: ellipsisDependency }}>
                    {
                        suggestions.length > 0 ? (
                            parts.map((part, index) => {
                                const formattedPartText = Commons.formatPipeSeparatedAddress(part.text);
                                const isTextBold = part.highlight && suggestionType;
                                return (
                                    <StyledOptionText
                                        key={index}
                                        title={formattedPartText}
                                        style={{
                                            fontWeight: isTextBold ? 700 : 400,
                                            overflow: part.highlight ? 'inherit' : 'hidden',
                                        }}
                                    >
                                        {formattedPartText}
                                    </StyledOptionText>
                                );
                            })
                        ) : (
                            <StyledOptionText
                                key={`${props.id}-${formattedSuggestion}`}
                                title={formattedSuggestion}
                            >
                                {formattedSuggestion}
                            </StyledOptionText>
                        )
                    }
                </div>
                <EndAdornmentContainer>
                    {
                        isHistoricProperty && <HistoricalFlag />
                    }
                    {
                        getEndAdornment(_suggestionType)
                    }
                </EndAdornmentContainer>
            </OptionComponent>
        );
    };

    const handleHistoricCheckbox = (e) => {
        const limit = getLimit(keyword);
        if (keyword.length >= limit && !Commons.hasBackSlash(keyword)) {
            dispatch(getSuggestions({
                keyword,
                suggestionTypes: getSuggestionTypes(),
                includeHistoric: e.target.checked,
                limit: getSuggestionLimit(keyword),
            }));
        }
        setIsHistoric(e.target.checked);
    };

    const includeHistoricSearch = (route) => {
        if (isHistoric) {
            route.search = `?includeHistoric=${isHistoric}`;
        }
    };

    const renderContainer = (props) => {
        if (!options.length || !props.children[props.children.length - 1]) {
            return null;
        }
        const header = suggestions.length === 0 ? 'RECENT SEARCHES' : 'SUGGESTIONS';
        return (
            <Paper
                {...props}
                onMouseDown={event => event.preventDefault()}
                sx={{ marginTop: '5px' }}
                data-testid="multi-locality-suggestions"
            >
                <SuggestHeaderContainer>
                    {
                        includeHistoric &&
                        <IncludeHistoricCheckbox
                            customClass="historic-checkbox"
                            checked={isHistoric}
                            onChange={handleHistoricCheckbox}
                            hasTooltip
                        />
                    }
                    <SuggestionHeader>
                        <SuggestionHeaderText>{header}</SuggestionHeaderText>
                        {
                            ((recentSearches && recentSearches.length) && (suggestions && !suggestions.length)) ?
                                <StyledButton
                                    onMouseDown={handleDeleteDialogOpen}
                                    data-testid="remove-recent"
                                >
                                    <DeleteOutlineIcon sx={{ fill: Colors.STAR_DUST }} />
                                </StyledButton> : ''
                        }
                    </SuggestionHeader>
                </SuggestHeaderContainer>
                {props.children}
            </Paper>
        );
    };

    const getPlaceholderText = () => {
        if (location?.state?.addressCollective &&
            (!suggestionTypeSelected ||
                (suggestionTypeSelected && suggestionTypeSelected === SuggestionTypes.ADDRESS))) {
            return !optionSelected.length && location?.state?.suggestion[location?.state?.suggestion.length - 1]?.suggestion;
        }
        if (suggestionTypeSelected === SuggestionTypes.ADDRESS) {
            return '';
        }
        if (suggestionTypeSelected) {
            return `Search for a ${suggestionLabel[suggestionTypeSelected]}`;
        }
        return SLAS_PLACEHOLDER;
    };

    const getStatusType = () => {
        const hasProspectorRole = Entitlements.hasProspectorRole(usrDetail);
        if ([OTM.AGED, OTM.WITHDRAWN].includes(statusType) && !hasProspectorRole) {
            return STATUS_TYPE_OPTIONS_ADDRESS[0].value;
        } else if (statusType === OTM.TENURE) {
            const _featureFlag = new ExcludeVICFeatureFlag(featureFlag['crux.tenure.search']);
            if (_featureFlag.isEntitlements() && !hasProspectorRole) {
                return STATUS_TYPE_OPTIONS_ADDRESS[0].value;
            } else if (_featureFlag.isExcludeVICEntitlements()) {
                const hasVIC = optionSelected.some(option => option.suggestion.split(' ').includes(STATE.VIC.toUpperCase()));
                if (!hasProspectorRole) {
                    return STATUS_TYPE_OPTIONS_ADDRESS[0].value;
                } else if (hasVIC) {
                    return STATUS_TYPE_OPTIONS_ADDRESS[0].value;
                }
            }
        }
        return statusType;
    };

    const redirectSearch = (extraStateParams) => {
        const routePath = {
            pathname:  routeCodes.MULTI_LOCALITY_SEARCH.path,
            state: {
                entryPoint,
                suggestionType: suggestionTypeSelected,
                suggestion: optionSelected,
                isActiveProperty: isHistoric,
                statusType: getStatusType(),
                ...extraStateParams,
            },
        };
        includeHistoricSearch(routePath);
        dispatch(setSearchStatusType(routePath.state.statusType));
        history.push(routePath);
        trackSearchEvent();
        dispatch(clearSuggestion());
        dispatch(invalidateCruxAppError());
    };

    const navigateSearch = () => {
        if (optionSelected && optionSelected.length === 1) {
            const { suggestion, suggestionType } = optionSelected && optionSelected[0];
            const param = {
                suggestionType,
                searchString: suggestion,
            };
            dispatch(cruxLoader());
            const averageHold = {};
            const tenure = getStatusType() === OTM.TENURE;
            let localityHasAverageHoldPeriod;
            Clapi.getStatistics(param,  'averageHoldTrends').then((response) => {
                localityHasAverageHoldPeriod = SearchResultsFunctions.hasAverageHoldTrend(response);
                if (localityHasAverageHoldPeriod) {
                    averageHold.averageHoldPeriod = response;
                }
            }).catch((e) => {
                // Do nothing. There is a handler in `finally`
            }).finally(() => {
                dispatch(cruxUnloader());
                if (tenure && !localityHasAverageHoldPeriod) {
                    setHasAverageHoldTrend(false);
                } else {
                    redirectSearch(averageHold);
                }
            });
        } else {
            redirectSearch();
        }
    };

    const handlerMessage = Commons.get(handler, 'message');
    const handleSetStatusType = (value) => {
        if (!hasAverageHoldTrend) {
            setHasAverageHoldTrend(true);
        }
        setStatusType(value);
    }

    return (
        <MultiLocalitySearchBar data-testid="multi-locality-search">
            {
                !isHeader &&
                <StatusTypesRadio
                    isHeader={isHeader}
                    statusType={statusType}
                    onChange={handleSetStatusType}
                    clapiUsrDetail={usrDetail}
                    isTenureDisabled={isTenureDisabled}
                    featureFlag={featureFlag}
                    selectedAddress={optionSelected.map(opt => opt.suggestion)}
                />
            }
            <SearchBarContainer>
                <StyledAutoComplete
                    className="form-input-search-field"
                    filterOptions={(options) => options}
                    key={`autocomplete-${location?.state?.addressCollective ? location.key : ''}`}
                    value={optionSelected}
                    multiple
                    disableCloseOnSelect
                    disableClearable
                    freeSolo
                    disablePortal
                    autoHighlight
                    onInputChange={onInputChange}
                    onChange={onChange}
                    open={isOpen}
                    options={options}
                    popupIcon={null}
                    onClose={(e, r) => r !== 'toggleInput' && setIsOpen(false)}
                    isSearchBarActive={isSearchBarActive}
                    onOpen={() => setIsOpen(true)}
                    limitTags={isHeader ? 2 : 3}
                    getLimitTagsText={(more) => `+${more} more`}
                    id="crux-multi-locality-search"
                    isError={hasAverageHoldTrend}
                    getOptionLabel={(option) => option.suggestion || option.searchString || ''}
                    renderInput={
                        (params) => (
                            <div className="autocomplete-render-input">
                                <TextField
                                    {...params}
                                    onFocus={() => setIsSearchBarActive(true)}
                                    onBlur={() => setIsSearchBarActive(false)}
                                    placeholder={getPlaceholderText()}
                                    color="primary"
                                    error={!hasAverageHoldTrend}
                                />
                                <SearchValidation
                                    className="relative"
                                    message={tooltipText.TEXT.TENURE.NO_AVERAGE_HOLD}
                                    onError={!hasAverageHoldTrend}
                                />
                            </div>
                        )
                    }
                    renderTags={(tagValue, getTagProps) => (
                        tagValue.map((option, index) => {
                            const label = getOptionLabel(option);
                            return (
                                <Chip
                                    key={label}
                                    {...getTagProps({ index })}
                                    label={label}
                                    title={label}
                                    id={label}
                                />
                            )
                        })
                    )}
                    renderOption={renderOptions}
                    PaperComponent={renderContainer}
                />
                <StyledSearchButton
                    className="button-primary search-btn"
                    onClick={() => navigateSearch()}
                    disabled={!searchButtonEnabled}
                    sx={{ marginBottom: 0 }}
                >
                    <img
                        src={getAssetDomain('search_icon_white.png')}
                        className="search-icon"
                        alt="Search Button"
                    />
                </StyledSearchButton>
            </SearchBarContainer>
            <CruxDialogBox
                open={openDeleteDialog}
                id="remove-recent-search-modal"
                title="Are you sure you want to remove all recent searches for this search type?"
                subTitle="This will remove all recent searches and cannot be undone."
                onClose={handleDeleteDialogClose}
                onConfirm={handleRemoveRecentSearches}
            />
            <CruxSnackBar
                open={!!handlerMessage && !!handler.message}
                onClose={() => dispatch(resetHandler())}
                severity="error"
                message={handlerMessage && handler.message}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
            />
        </MultiLocalitySearchBar>
    );
};

MultiLocalitySearch.propTypes = {
    styles: PropTypes.object,
    slas: PropTypes.object,
    route: PropTypes.object.isRequired,
    entryPoint: PropTypes.string.isRequired,
    isHistoricVisible: PropTypes.bool,
    isHeader: PropTypes.bool,
    usrDetail: PropTypes.object,
    suggestions: PropTypes.array,
    recentSearches: PropTypes.array,
    includeHistoric: PropTypes.bool,
    location: PropTypes.object,
    history: PropTypes.object,
    dispatch: PropTypes.func,
    handler: PropTypes.object,
    featureFlag: PropTypes.object,
}

export default connect(state => ({
    usrDetail: state.clapi.get('usrDetail'),
    suggestions: state.clapi.get('suggestions'),
    recentSearches: state.msg.get('recentSearches'),
    handler: state.msg.get('handler'),
    includeHistoric: isAU && LDHelper.featureFlagSelector(state, 'crux.historic.property.data', false),
    featureFlag: state.launchDarkly.get('featureFlag'),
}))(withRouter(MultiLocalitySearch));
