const suggestionType = {
    ADDRESS: {
        suggestionTypeId: 1,
        type: 'address',
    },
    STREET: {
        suggestionTypeId: 2,
        type: 'street',
    },
    LOCALITY: {
        suggestionTypeId: 3,
        type: 'locality',
    },
    POSTCODE_AU: {
        suggestionTypeId: 4,
        type: 'postcode',
    },
    COUNCIL_AREA_LGA: {
        suggestionTypeId: 5,
        type: 'councilArea',
    },
    TERRITORIAL_AUTHORITY_NZ: {
        suggestionTypeId: 7,
        type: 'territorialAuthority',
    },
    STATE: {
        suggestionTypeId: 8,
        type: 'state',
    },
    COUNTRY: {
        suggestionTypeId: 9,
    },
};

export function getSuggestionTypeId(suggestionTypeVal) {
    switch (suggestionTypeVal) {
        case suggestionType.ADDRESS.type:
            return suggestionType.ADDRESS.suggestionTypeId
        case suggestionType.STREET.type:
            return suggestionType.STREET.suggestionTypeId
        case suggestionType.LOCALITY.type:
            return suggestionType.LOCALITY.suggestionTypeId
        case suggestionType.POSTCODE_AU.type:
            return suggestionType.POSTCODE_AU.suggestionTypeId
        case suggestionType.STATE.type:
            return suggestionType.STATE.suggestionTypeId
        case suggestionType.COUNCIL_AREA_LGA.type:
        case suggestionType.TERRITORIAL_AUTHORITY_NZ.type:
            return suggestionType.COUNCIL_AREA_LGA.suggestionTypeId
        default: return '';
    }
}

export default suggestionType;
