const exceptions = {
    REQUEST_TIMEOUT: {
        code: 408,
        error: 'Request timeout',
        message: 'Looks like the server is taking to long to respond, ' +
        'this can be caused by either poor connectivity or an error with our servers. ' +
        'Please try again in a while\n',
    },
    NOT_FOUND: {
        code: 404,
        error: 'Not found',
        message: 'Page not found',
    },
};

export default exceptions;
