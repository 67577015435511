import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '../../../../constants/tooltipText';
import CruxCheckbox from '../../../common/CruxCheckbox';

const CurrentOwnerCheckbox = ({ checked, onChange }) => (<CruxCheckbox
    checked={checked}
    label="Current owner only"
    tooltip={Tooltip.TEXT.NAME_SEARCH.OWNERNAME}
    tooltipId="current-owner"
    id="current-owner-only-checkbox"
    data-testid="current-owner-only-checkbox"
    onChange={onChange}
/>);

CurrentOwnerCheckbox.propTypes = {
    checked: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
};

export default CurrentOwnerCheckbox;
