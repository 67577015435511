import { STATE_UPPERCASE } from '../states';
import { isAU } from '../crux';

const {
    NSW, QLD, SA, VIC, WA, NT, ACT
} = STATE_UPPERCASE;
const ADDRESS = 'Address';
const NAME = 'Name';
const COMPANY = 'Company';
const BUILDING_NAME = 'Building Name';
const PARCEL = isAU ? 'Parcel' : 'Legal Description';
const VOLUME_FOLIO = 'Volume/Folio';
const TITLE_REFERENCE = 'Title Reference';
const ROLL_VALUATION = 'Roll/Valuation';
const REPORTS = 'Reports';

const SEARCH_OPTIONS_AU = {
    [ADDRESS]: { label: ADDRESS },
    [NAME]: { label: NAME },
    [COMPANY]: { label: COMPANY },
    [BUILDING_NAME]: { label: BUILDING_NAME },
    [PARCEL]: { label: PARCEL },
    [VOLUME_FOLIO]: { label: VOLUME_FOLIO },
    [REPORTS]: { label: REPORTS },
};

const SEARCH_OPTIONS_NZ = {
    [ADDRESS]: { label: ADDRESS },
    [NAME]: { label: NAME },
    [COMPANY]: { label: COMPANY },
    [PARCEL]: { label: PARCEL },
    [TITLE_REFERENCE]: { label: TITLE_REFERENCE },
    [ROLL_VALUATION]: { label: ROLL_VALUATION },
    [REPORTS]: { label: REPORTS },
};

const STATE_OPTIONS = {
    [NSW]: { label: NSW },
    [QLD]: { label: QLD },
    [WA]: { label: WA },
};

const STATE_OPTIONS_VIC_ENABLED = {
    ...STATE_OPTIONS,
    [VIC]: { label: VIC },
};

const STATE_OPTIONS_COMPANY_SEARCH = {
    [NSW]: { label: NSW },
    [QLD]: { label: QLD },
    [SA]: { label: SA },
    [WA]: { label: WA },
};
const STATE_OPTIONS_VOLUME_FOLIO_SEARCH = {
    [ACT]: { label: ACT },
    [NT]: { label: NT },
    [VIC]: { label: VIC },
    [WA]: { label: WA },
};
const STATE_OPTIONS_COMPANY_SEARCH_WITH_DEED7 = {
    ...STATE_OPTIONS_COMPANY_SEARCH,
    [VIC]: { label: VIC },
};

const TITLE = [
    { value: 'Mr', label: 'Mr' },
    { value: 'Mrs', label: 'Mrs' },
    { value: 'Ms', label: 'Ms' },
    { value: 'Miss', label: 'Miss' },
];
const ALL_DISPLAYED = 'all_displayed';
const ALL = 'all';
const CLEAR = 'clear';

const TIME_FRAMES = [
    { name: 'Previous Day', value: -1, displayedText: 'day' },
    { name: 'Current Day', value: 1, displayedText: 'day' },
    { name: 'Last 7 Days', value: 7, displayedText: '7 days' },
    { name: 'Last 30 Days', value: 30, displayedText: '30 days' },
    { name: 'Last 60 Days', value: 60, displayedText: '60 days' },
];

export default {
    ADDRESS,
    NAME,
    COMPANY,
    BUILDING_NAME,
    PARCEL,
    VOLUME_FOLIO,
    TITLE_REFERENCE,
    ROLL_VALUATION,
    SEARCH_OPTIONS_AU,
    SEARCH_OPTIONS_NZ,
    STATE_OPTIONS,
    STATE_OPTIONS_COMPANY_SEARCH,
    STATE_OPTIONS_VIC_ENABLED,
    NSW,
    QLD,
    WA,
    TITLE,
    ALL_DISPLAYED,
    ALL,
    CLEAR,
    TIME_FRAMES,
    STATE_OPTIONS_COMPANY_SEARCH_WITH_DEED7,
    STATE_OPTIONS_VOLUME_FOLIO_SEARCH,
    REPORTS,
};
