import { Map as IMap } from 'immutable';
import {
    GO_TO_SIGNATURE_REPORT_FAIL,
    GO_TO_SIGNATURE_REPORT_SUCCESS,
    GO_TO_SIGNATURE_REPORT,
    REMOVE_SIGNATURE_ERROR,
} from '../actions/signature';

export const initialState = IMap({
    isFetching: false,
    errorMessage: null,
});

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case GO_TO_SIGNATURE_REPORT:
            return state.merge(IMap({
                isFetching: true,
                errorMessage: '',
                subType: action.payload.subType || action.payload.reportType,
            }));
        case GO_TO_SIGNATURE_REPORT_SUCCESS:
            return state.merge(IMap({
                isFetching: false,
                subType: '',
            }));
        case GO_TO_SIGNATURE_REPORT_FAIL:
            return state.merge(IMap({
                isFetching: false,
                errorMessage: action.payload.errorMessage,
                subType: action.payload.subType || action.payload.reportType,
            }));
        case REMOVE_SIGNATURE_ERROR:
            return state.merge(IMap({
                errorMessage: '',
                subType: '',
            }));
        default:
            return state;
    }
}
