import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Commons from '../../helpers/Commons';

// This custom hook is responsible for doing undo / redo commands
const useMapCommands = (props) => {
    const [undoList, setUndoList] = useState([]);
    const [redoList, setRedoList] = useState([]);
    const [isEditedFromCommand, setIsEditedFromCommand] = useState(false);
    useEffect(() => {
        // resets redo when user makes changes
        if (props.isPolygonEdited) {
            setRedoList([]);
            setIsEditedFromCommand(false);

            props.setIsPolygonEdited(false);
        }
    }, [props.isPolygonEdited]);

    const record = (nextPath) => {
        setUndoList([...undoList, nextPath]);
    }

    const undo = () => {
        setIsEditedFromCommand(true);

        // Undo by popping Polygon History List items
        if (undoList.length > 1) {
            const newRedo = undoList.pop();
            setRedoList([...redoList, newRedo]);

            const newPolygonIndex = undoList.length > 1
                ? undoList.length - 1
                : 0;
            props.setPolygonPath([...undoList[newPolygonIndex]]);
            setUndoList([...undoList]);
            return;
        }

        // Undo by popping each original's Polygon vertices
        const [firstDrawnPolygon = []] = undoList;
        if (firstDrawnPolygon.length > 2) {
            const newPolygonPath = [...firstDrawnPolygon];
            const newRedo = newPolygonPath.pop();
            setRedoList([...redoList, [...newPolygonPath, newRedo]]);
            props.setPolygonPath(newPolygonPath);
            setUndoList([newPolygonPath]);
        } else {
            props.setDrawing(null);
            props.setPolygonPath([]);
            setUndoList([]);
            setRedoList([...redoList, [...firstDrawnPolygon]]);
        }
    };

    const redo = () => {
        if (redoList.length) {
            props.setDrawing(true);
            const newUndo = [...redoList.pop()];
            setUndoList([...undoList, newUndo]);
            props.setPolygonPath(newUndo);
            setIsEditedFromCommand(true);
        }
    }

    const clearRecord = () => {
        setUndoList([]);
        setRedoList([]);
    }

    const isEqualWithLastRecord = (nextPath) => Commons.isEqual(undoList[undoList.length - 1], nextPath);

    return {
        record,
        undo,
        redo,
        clearRecord,
        isEqualWithLastRecord,
        undoList,
        redoList,
        hasUndo: !!undoList.length,
        hasRedo: !!redoList.length,
        isEditedFromCommand,
    };
};

useMapCommands.propTypes = {
    setPolygonPath: PropTypes.func.isRequired,
    setDrawing: PropTypes.func.isRequired,
    setIsPolygonEdited: PropTypes.func.isRequired,
    isPolygonEdited: PropTypes.bool.isRequired,
}

export default useMapCommands;
