import { takeLatest, put, call, select, takeEvery } from 'redux-saga/effects';
import { LOGOUT, GET_USER_DETAILS, GET_USER_DETAILS_FAIL, GET_USER_DETAILS_SUCCESS } from '../actions/claud';
import AnalyticsGateway from '../lib/AnalyticsGateway';
import UserPreferences from '../api/userPreferences';
import Bugsnag, { setBugsnagUser } from '../bugsnag';
import { CRUX_APP_ERROR } from '../actions/errorHandler';

export const getUser = state => state.claud.get('user');

// ---------- LOGOUT

function createLogout() {
    return function* (options) {
        // TODO: create mixpanel event for LOGOUT
        try {
            const user = yield select(getUser);
            AnalyticsGateway.sendEvent({
                eventName: 'Logout',
                properties: [
                    { label: 'Page', value: options.payload },
                    { label: 'Username', value: user.userName },
                ],
            });
        } catch (error) {
            // yield put({ type: LOGOUT_FAIL });
        }
    };
}

// --------- SWITCH CHANNEL
function getUserDetailsFn() {
    return function* (options) {
        try {
            // call API to connect CRUX to corelogic's redis server
            console.log("Crux/Init -> sagas/claud -> getUserDetailsFN", options.payload)
            const user = yield call(UserPreferences.getUserDetail, options.payload);
            // trigger success switching of channel
            const nextAction = { type: GET_USER_DETAILS_SUCCESS, payload: user };
            if (user.userName && Bugsnag.user?.name !== user.userName) {
                setBugsnagUser(user.userName);
            }
            yield put(nextAction);
        } catch (error) {
            Bugsnag.notify(error);
            yield put({ type: CRUX_APP_ERROR, error });
            yield put({ type: GET_USER_DETAILS_FAIL });
        }
    };
}

export const getUserDetails = getUserDetailsFn();

export const logout = createLogout();

/**
 * @function getUserDetailWatcher "listener" to the GET_USER_DETAILS action
 */
export function* getUserDetailsWatcher() {
    yield takeEvery(GET_USER_DETAILS, getUserDetails);
}

/**
 * @function logoutWatcher "listener" to the LOGOUT action
 */
export function* logoutWatcher() {
    yield takeLatest(LOGOUT, logout);
}

/**
 * activate "listeners"
 */
export default [
    logoutWatcher(),
    getUserDetailsWatcher(),
];
