import API from './index';
import methods from '../constants/methods';
import { UriBuilder } from '../helpers/UriBuilder';
import CLAPI from './clapi';
import cruxProxy from "./cruxProxy";

/**
 * Query params reference:
 * https://msg-uat.corelogic.asia/swagger-ui/#/Activity%20Feeds/getNewActivityFeedsUsingGET
 */
const getNewUserActivityFeeds = (queryParams = []) => {
    const endpoint = new UriBuilder('/api/notification/newUserActivityFeeds');
    queryParams.forEach(param => endpoint.addParam(param.key, param.value));

    const requestOptions = {
        path: endpoint.build(),
        isProxy: true,
    };

    const headerOptions = {
        method: methods.GET.method,
    };

    return API.initiateSessionApiCall(requestOptions, headerOptions);
};

const getUnreadSavedSearch = () => {
    const queryParams = [
        {
            key: 'isRead',
            value: false,
        }, {
            key: 'types',
            value: 'savedSearch',
        },
    ];
    return getNewUserActivityFeeds(queryParams);
};

const readNotificationHistory = (queryParams = []) => {
    const endpoint = new UriBuilder('/api/notification/read');
    queryParams.forEach(param => endpoint.addParam(param.key, param.value));
    endpoint.addParam('clAppAccountUserGuid', CLAPI.getSelectedClAppAccountUserGuid());

    const requestOptions = {
        path: endpoint.build(),
        protected: true,
        isProxy: true,
    };

    const headerOptions = {
        method: methods.PUT.method,
        headers: {
            'Content-Type': 'application/json',
            ...cruxProxy.getXsrfHeader(),
        },
    };

    return API.initiateSessionApiCall(requestOptions, headerOptions);
};

const setReadByListId = (listId) => {
    const queryParams = [
        {
            key: 'listId',
            value: listId,
        },
    ];

    return readNotificationHistory(queryParams);
};

const setReadByNotificationIds = (notificationIds) => {
    const queryParams = [
        {
            key: 'notificationIds',
            value: notificationIds,
        },
    ];

    return readNotificationHistory(queryParams);
};

export default {
    getNewUserActivityFeeds,
    getUnreadSavedSearch,
    readNotificationHistory,
    setReadByListId,
    setReadByNotificationIds,
};
