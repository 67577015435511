import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';

import launchDarkly from 'js/reducers/launchDarkly';
import claud from 'js/reducers/claud';
import config from 'js/reducers/config';
import segment from 'js/reducers/segment';
import clapi from 'js/reducers/clapi';
import errorHandler from 'js/reducers/errorHandler';
import msg from 'js/reducers/msgApi';
import componentAccess from 'js/reducers/componentAccess';
import searchResults from 'js/reducers/searchResults';
import nameSearchResults from 'js/reducers/nameSearchResults';
import assets from 'js/reducers/assets';
import crux from 'js/reducers/crux';
import userPreferences from 'js/reducers/userPreferences';
import rapid from 'js/reducers/rapid';
import linkedAccounts from 'js/reducers/linkedAccounts';
import signature from 'js/reducers/signature';
import manageUsers from 'js/reducers/manageUsers';
import manageBranding from 'js/reducers/manageBranding';
import customiseReport from 'js/reducers/customiseReport';
import htmlToPdfReport from 'js/reducers/previewReport';
import map from 'js/reducers/map';
import printPdp from 'js/reducers/printPdp';
import genericHtmlToPdf from 'js/reducers/genericHtmlToPdf';
import favourites from 'js/reducers/favourites';
import savedSearch from 'js/reducers/savedSearch';
import watchList from 'js/reducers/watchList';
import territories from 'js/reducers/territories';
import reiForms from 'js/reducers/reiForms'
import pdfReports from 'js/reducers/pdfReports';

export default combineReducers({
    errorHandler,
    form,
    claud,
    launchDarkly,
    config,
    segment,
    clapi,
    msg,
    componentAccess,
    searchResults,
    nameSearchResults,
    assets,
    crux,
    userPreferences,
    rapid,
    signature,
    manageUsers,
    manageBranding,
    customiseReport,
    htmlToPdfReport,
    linkedAccounts,
    map,
    printPdp,
    favourites,
    savedSearch,
    genericHtmlToPdf,
    watchList,
    territories,
    reiForms,
    pdfReports,
});
