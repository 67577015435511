import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material';
import colors from '../../constants/colors';

const types = {
    light: {
        tooltip: {
            backgroundColor: colors.LIGHT_GRAY,
            color: 'rgba(0, 0, 0, 0.6)',
            fontSize: 12,
            fontWeight: 400,
            padding: '4px 8px',
            border: '1px solid rgba(0, 0, 0, 0.23)',
            marginTop: 5,
        },
        arrow: {
            color: colors.LIGHT_GRAY,
        }
    },
    default: {
        tooltip: {
            backgroundColor: colors.SECONDARY.MAIN,
            fontSize: 10,
            padding: '8px 21px',
            boxShadow: '0px 0px 5px 1px rgba(0, 0, 0, 0.16)',
            arrowColor: colors.SECONDARY.MAIN,
            fontWeight: 400,
        },
        arrow: {
            color: colors.SECONDARY.MAIN,
        },
    },
};

const MUITooltip = styled(({ className, ...props }) => (
    <Tooltip data-testid="mui-tooltip-testid" {...props} classes={{ popper: className }} />
), {
    shouldForwardProp: (prop) => prop !== 'type' && prop !== 'longTooltip',
})(({ theme, type = 'default', longTooltip }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        ...types[type].tooltip,
        ...(longTooltip ? { maxWidth: 500 } : {}),
    },
    [`& .${tooltipClasses.arrow}`]: {
        ...types[type].arrow,
    },
    // Setting the space between target element and tooltip the same as React tooltip for uniformity
    [`& .${tooltipClasses.tooltipPlacementBottom}`]: {
        marginTop: '9.5px !important',
    },
}));

export default MUITooltip;

