import Commons from 'js/helpers/Commons';
import { PRICE_PATTERN } from '../constants/regexPatterns';

const INVALID_VALUE = 'Invalid price provided.';
const formatPrice = (val, textFallback) => {
    try {
        if (val) {
            const cleanVal = `${val}`.replace(/[^\d\w.]/g, '');
            if (parseFloat(cleanVal)) {
                const numberMatcher = new RegExp(/^\d+(\.\d+)?$/g);
                if (numberMatcher.test(cleanVal)) {
                    return `$${Commons.numberWithCommas(cleanVal)}`;
                }
                return INVALID_VALUE;
            } else if (!Number.isSafeInteger(parseFloat(cleanVal))) {
                return INVALID_VALUE;
            }
        }
        return textFallback;
    } catch (e) {
        return INVALID_VALUE;
    }
};

const formatPriceFilter = (val) => {
    try {
        if (val) {
            const cleanVal = `${val}`.match(/^[$]?[\d,.kmb]+$/gi)?.[0];
            const numberMatcher = new RegExp(/^[$]?([\d,.]+)([kmb]|\.\d+)?$/i);

            if (cleanVal.includes('.') && cleanVal.split('.').length > 2) {
                return val;
            }

            if (numberMatcher.test(cleanVal)) {
                const { 1: numericPart, 2: suffix } = cleanVal.match(numberMatcher);
                const numericValue = parseFloat(numericPart.replace(/,/g, ''));

                let multiplier = 1;

                if (suffix) {
                    if (suffix.toLowerCase().includes('k')) {
                        multiplier = 1000;
                    } else if (suffix.toLowerCase().includes('m')) {
                        multiplier = 1000000;
                    } else if (suffix.toLowerCase().includes('b')) {
                        multiplier = 1000000000;
                    }
                }

                const formattedValue = Commons.numberWithCommas(numericValue * multiplier);
                return formattedValue === '0' ? val : `$${formattedValue}`;
            }  else {
               return INVALID_VALUE;
            }
        }
        return val;
    } catch (e) {
        return val;
    }
};

const validValue = (value) => new RegExp(PRICE_PATTERN).test(value) || value === '';

export default {
    formatPrice,
    formatPriceFilter,
    validValue,
};
