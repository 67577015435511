import OTM from './otm';

const SEARCH_TYPE = {
    ADDRESS: 'address',
    RADIUS: 'radius',
    NAME: 'name',
    COMPANY: 'company',
    BUILDING: 'building',
    PARCEL: 'parcel',
    LEGAL_DESCRIPTION: 'legal description',
    VOLUME_FOLIO: 'volumeFolio',
    TITLE_REFERENCE: 'title',
    ROLL_VALUATION: 'rollValuation',
    SAVED_LIST: 'savedList',
    SAVED_TERRITORY: 'savedTerritory',
};

// key should match its SEARCH_TYPE counterpart
const MIXPANEL_SEARCH_TYPE = {
    [SEARCH_TYPE.ADDRESS]: 'Address',
    [SEARCH_TYPE.RADIUS]: 'Radius',
    [SEARCH_TYPE.NAME]: 'Name',
    [SEARCH_TYPE.COMPANY]: 'Company',
    [SEARCH_TYPE.BUILDING]: 'Building Name',
    [SEARCH_TYPE.PARCEL]: 'Parcel',
    [SEARCH_TYPE.LEGAL_DESCRIPTION]: 'Legal Description',
    [SEARCH_TYPE.VOLUME_FOLIO]: 'Volume/Folio',
    [SEARCH_TYPE.TITLE_REFERENCE]: 'Title',
    [SEARCH_TYPE.ROLL_VALUATION]: 'Roll/Valuation',
    [SEARCH_TYPE.SAVED_LIST]: 'Saved List',
    [SEARCH_TYPE.SAVED_TERRITORY]: 'Saved Territory',
};

const EVENT_TAB = {
    ALL: 'all',
    RECENT_SALE: 'recent-sale',
    FOR_RENT: 'for-rent',
    FOR_SALE: 'for-sale',
    AGED_LISTING: 'aged-listing',
    WITHDRAWN_LISTING: 'withdrawn-listing',
    TENURE: 'tenure',
};

const getEventTab = (searchTabSelected) => {
    switch (searchTabSelected) {
        case OTM.ALL:
            return EVENT_TAB.ALL;
        case OTM.RECENT_SALE:
            return EVENT_TAB.RECENT_SALE;
        case OTM.FOR_RENT:
            return EVENT_TAB.FOR_RENT;
        case OTM.FOR_SALE:
            return EVENT_TAB.FOR_SALE;
        case OTM.AGED:
            return EVENT_TAB.AGED_LISTING;
        case OTM.WITHDRAWN:
            return EVENT_TAB.WITHDRAWN_LISTING;
        case OTM.TENURE:
            return EVENT_TAB.TENURE;
        default:
            return EVENT_TAB.ALL;
    }
};

export default {
    SEARCH_TYPE,
    EVENT_TAB,
    MIXPANEL_SEARCH_TYPE,
    getEventTab,
};
