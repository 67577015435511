export default {
    Intellival: {
        Source: 'intellival',
    },
    FSD: {
        Rental: {
            maxFsd: 30,
            high: {
                min: 0, max: 15,
            },
            medium: {
                min: 16, max: 22,
            },
            low: {
                min: 23, max: 29,
            },
        },
        Intellival: {
            maxFsd: 25,
            high: {
                min: 0, max: 8,
            },
            medium: {
                min: 9, max: 16,
            },
            low: {
                min: 17, max: 24,
            },
        },
    },
};
