import Commons from 'js/helpers/Commons';

export default class PropertyDetailCommonsResponseBody {
    constructor() {
        this.propertyId = null;
        this.location = null;
        this.attrCore = null;
        this.attrAdditional = null;
        this.lastSale = null;
        this.isActiveProperty = null;
        this.errors = null;
    }

    isComplete() {
        // check if no prop is null or undefined or empty object
        const { errors, ...rest } = this;
        const noValueProps = Object.values(rest)
            .filter(prop => prop === null || prop === undefined || Commons.isObjectEmpty(prop));
        return noValueProps.length === 0;
    }

    isValidProperty() {
        // Check if property it is valid,
        // propertyId is always present regardless of the response,
        // whether it successes or it fails, checking if some of the
        // other fields has values so we can say that it is a valid property or not
        const { propertyId, errors, ...rest } = this;
        const hasValues = Object.values(rest)
            .filter(prop => prop !== null && prop !== undefined && !Commons.isObjectEmpty(prop));
        return hasValues.length > 0;
    }

    static parse(obj) {
        // hydrate fields that only exist for PropertyDetailCommonsResponseBody
        let commons = null;
        if (obj) {
            commons = new PropertyDetailCommonsResponseBody();
            Object.keys(commons).forEach((prop) => {
                if (Object.prototype.hasOwnProperty.call(obj, prop)) {
                    commons[prop] = obj[prop];
                }
            });
        }
        return commons;
    }

    static merge(oldCommons, newCommons) {
        let commons = null;
        commons = new PropertyDetailCommonsResponseBody();
        Object.keys(commons).forEach((prop) => {
            commons[prop] = (oldCommons && oldCommons[prop]) || (newCommons && newCommons[prop]);
        });
        return commons;
    }
}
