import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { Button, Paper, Popper } from '@mui/material';
import LocalStorageHelper, { SEARCH_BAR_TOOLTIP_KEY } from '../../../helpers/LocalStorageHelper';
import Colors from '../../../constants/colors';

const StyledPopper = styled(Popper)({
    backgroundColor: Colors.OFF_WHITE,
    borderRadius: '4px',
    top: '10px !important',
    padding: '8px 12px',
    zIndex: 1200,
    cursor: 'default'
});

const FieldTooltip = ({
    id, className, isFlaggedWithLocalStorage = true,
    open, anchorEl, tooltipTxt, width,
    handleClose, searchType, placement = 'bottom',
}) => {
    const closePopper = (event) => {
        handleClose(event);
        // Set local storage value to true so it won't render the tooltip on current session
        if (isFlaggedWithLocalStorage) {
            const previousStoreValue = LocalStorageHelper.get(SEARCH_BAR_TOOLTIP_KEY);
            LocalStorageHelper.add(SEARCH_BAR_TOOLTIP_KEY, {
                ...(previousStoreValue || {}),
                [`is-${searchType}-${id}-viewed`]: true,
            });
        }
    };
    const _className = className ? ` ${className}` : '';
    return (
        <StyledPopper
            className={`tooltip-popper${_className}`}
            open={open}
            anchorEl={anchorEl}
            data-testid="field-tooltip"
            placement={placement}
            style={width ? { width } : {}}
            disablePortal
        >
            <Paper>
                <div
                    data-testid="tooltip-text"
                    dangerouslySetInnerHTML={{ __html: tooltipTxt }}
                />
                <Button className="close-popper" onClick={closePopper}>
                    GOT IT
                </Button>
            </Paper>
        </StyledPopper>
    );
};

FieldTooltip.propTypes = {
    id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]).isRequired,
    className: PropTypes.string,
    tooltipTxt: PropTypes.string.isRequired,
    searchType: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    anchorEl: PropTypes.object,
    handleClose: PropTypes.func,
    width: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    style: PropTypes.object,
};

export default FieldTooltip;
