const ROYAL_BLUE = '#3399ff';
const ROYAL_BLUE_OPACITY_DOWN = 'rgba(51, 153, 255, 0.5)';
const ROYAL_BLUE_LIGHTER = '#5badff';
const ROYAL_BLUE_DARKER = '#236bb2';
const ROYAL_BLUE_LIGHT_BORDER = '#66afe9';
const MIDNIGHT_BLUE = '#191970';
const CHARCOAL_GRAY = '#38424a';
const CHARCOAL_GRAY_LIGHTER = '#5f6467';
const CHARCOAL_GRAY_DARKER = '#272b2e';
const GRAY = '#949494';
const GUN_POWDER = '#4A4A4B';
const FIELD_ERROR_BG = '#fef5f4';
const FIELD_ERROR_HOVER = '#BA1011';
const RED = '#F44336';
const WHITE = '#fff';
const DIRTY_WHITE = '#fafafa';
const PENDING_BACKGROUND = 'rgba(255, 152, 0, 0.08)';
const PENDING_FILL_COLOR = '#FF9800';
const ACTIVE_FILTER_BG = '#f2f8fe';
const ACTIVE_SWITCH_SLIDER_BG = '#a2d3fc';
const BLACK = '#000';
const BLACK_4_OPAQUE = 'rgba(0, 0, 0, 0.04)';
const BLACK_8_OPAQUE = 'rgba(0, 0, 0, 0.08)';
const BLACK_12_OPAQUE = 'rgba(0, 0, 0, 0.12)';
const BLACK_87_OPAQUE = 'rgba(0, 0, 0, 0.87)';
const BLACK_25_OPAQUE = 'rgba(0, 0, 0, 0.25)';
const BLACK_30_OPAQUE = 'rgba(0, 0, 0, 0.30)';
const BLACK_38_OPAQUE = 'rgba(0, 0, 0, 0.38)';
const BLACK_60_OPAQUE = 'rgba(0, 0, 0, 0.6)';
const BLACK_23_OPAQUE = 'rgba(0, 0, 0, 0.23)';
const BLACK_54_OPAQUE = 'rgba(0, 0, 0, 0.54)';
const LIGHT_GRAY = '#F5F5F5';
const LIGHTER_GRAY = '#f3f2f1';
const MANTIS = '#5dc26b';
const GRAY_BACKGROUND = '#E0E0E0';
const GRAY_BORDER = '#DFDFDF';
const STAR_DUST = '#9c9b9c';
const ERROR = '#E31B0C';
const PALE_CLOUD = '#dadde9';
const ERROR_BTN = 'rgba(232, 0, 28, 1)';
const ERROR_FIELD_BORDER = '#f44336';
const BG_GRAY = '#d8d8d8';
const WATERMELON = '#F50057';
const FLAMING_BRONZE = '#C77700';
const OFF_WHITE = '#f5f5f5';
const POMEGRANATE = 'rgba(244, 67, 54, 1)';
const DISABLED = '#d9d9d9';
const DISABLED_SWITCH = '#bdbdbd';
const PLACE_HOLDER_TEXT = '#9E9E9E';
const DISABLED_DARK = '#b9b9b9';
const FOR_SALE_TAG = '#71A9DA';
const FOR_RENT_TAG = '#6BA753';
const RECENT_SALE_TAG = '#CD2D2E';
const RECENT_ADVICE_TAG = '#8E7DC1';
const HISTORICAL_TAG = '#FB7B36';
const DARK_BLUE_GREEN = 'rgba(56, 66, 76, 1)';

const colors = {
    PRIMARY: {
        MAIN: ROYAL_BLUE,
        OPACITY_DOWN: ROYAL_BLUE_OPACITY_DOWN,
        LIGHT: ROYAL_BLUE_LIGHTER,
        DARK: ROYAL_BLUE_DARKER,
    },
    SECONDARY: {
        MAIN: CHARCOAL_GRAY,
        LIGHT: CHARCOAL_GRAY_LIGHTER,
        DARK: CHARCOAL_GRAY_DARKER,
    },
    ERROR: {
        BACKGROUND: FIELD_ERROR_BG,
        HOVER: FIELD_ERROR_HOVER,
        BORDER: RED,
        TEXT: ERROR,
    },
    TEXT_PRIMARY: GRAY,
    TEXT_PRIMARY_FOCUSED: BLACK_25_OPAQUE,
    TEXT_ERROR: RED,
    TEXT_SUCCESS: MANTIS,
    WHITE,
    ACTIVE_FILTER_BG,
    ACTIVE_SWITCH_SLIDER_BG,
    BLACK,
    BLACK_8_OPAQUE,
    BLACK_12_OPAQUE,
    BLACK_87_OPAQUE,
    LIGHT_GRAY,
    BLACK_60_OPAQUE,
    BLACK_25_OPAQUE,
    BLACK_23_OPAQUE,
    PENDING_BACKGROUND,
    PENDING_FILL_COLOR,
    GRAY_BACKGROUND,
    BLACK_54_OPAQUE,
    BLACK_30_OPAQUE,
    PALE_CLOUD,
    ERROR_BTN,
    DIRTY_WHITE,
    MIDNIGHT_BLUE,
    ERROR_FIELD_BORDER,
    BG_GRAY,
    GUN_POWDER,
    BLACK_4_OPAQUE,
    GRAY_BORDER,
    LIGHTER_GRAY,
    WATERMELON,
    FLAMING_BRONZE,
    OFF_WHITE,
    POMEGRANATE,
    DISABLED,
    ROYAL_BLUE_LIGHT_BORDER,
    DISABLED_SWITCH,
    PLACE_HOLDER_TEXT,
    STAR_DUST,
    BLACK_38_OPAQUE,
    DISABLED_DARK,
    FOR_SALE_TAG,
    FOR_RENT_TAG,
    RECENT_SALE_TAG,
    RECENT_ADVICE_TAG,
    HISTORICAL_TAG,
    DARK_BLUE_GREEN,
};

export default colors;
