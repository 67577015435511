import React from 'react';
import PropTypes from 'prop-types';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
const QuestionIcon = props => (
    <span style={props.style}>
        <HelpOutlineOutlinedIcon sx={{fontSize: '16px'}}/>
    </span>
);

QuestionIcon.propTypes = {
    style: PropTypes.object,
};

export default QuestionIcon;
