import API from './index';
import methods from '../constants/methods';
import clapi from './clapi';
import cruxProxy from "./cruxProxy";

const PATH = {
    SALES_HISTORY: '/recentSales',
    ON_THE_MARKET: '/otmForSale',
    RENTAL_COMPARISON: '/rentalComparison',
    RADIUS: '/radius',
};

const getListReport = (requestBody, pathParams) => {
    const requestOptions = {
        path: `/api/reports/htmltopdf${pathParams}`,
        isProxy: true,
        timeout: API.getTimeouts().large,
    };

    const headerOptions = {
        method: methods.POST.method,
        body: JSON.stringify(requestBody),
        headers: {
            'Content-Type': 'application/json',
            ...cruxProxy.getXsrfHeader(),
        },
    };

    return API.initiateSessionApiCall(requestOptions, headerOptions);
};

const getDigitalPropertyReport = (requestBody, includeValuation = true) => {
    let path = '/api/dppr/reports';
    const selectedClAppAccountUserGuid = clapi.getSelectedClAppAccountUserGuid();
    if (selectedClAppAccountUserGuid) {
        path = `${path}?clAppAccountUserGuid=${selectedClAppAccountUserGuid}&includeValuation=${includeValuation}`;
    }
    const requestOptions = {
        path,
        isProxy: true,
        timeout: API.getTimeouts().large,
    };

    const headerOptions = {
        method: methods.POST.method,
        headers: {
            'Content-Type': 'application/json',
            ...cruxProxy.getXsrfHeader(),
        },
        body: JSON.stringify(requestBody),
    };

    return API.initiateSessionApiCall(requestOptions, headerOptions);
};

const convertToPdfReport = (requestBody) => {
    const requestOptions = {
        path: '/api/reports/htmltopdf/convert',
        isProxy: true,
        timeout: 160000,
    };

    const headerOptions = {
        method: methods.POST.method,
        body: JSON.stringify(requestBody),
        headers: {
            'Content-Type': 'application/json',
            ...cruxProxy.getXsrfHeader(),
        },
    };

    return API.initiateSessionApiCall(requestOptions, headerOptions);
};

export default {
    getListReport,
    PATH,
    getDigitalPropertyReport,
    convertToPdfReport,
};
