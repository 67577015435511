import { initialize } from 'launchdarkly-js-client-sdk';
import BBPromise from 'bluebird';
import { call, put, select } from 'redux-saga/effects';
import { LD_INIT_SUCCESS, LD_INIT_FAIL } from '../actions/launchDarkly';
import { CRUX_APP_ERROR } from '../actions/errorHandler';
import Commons from '../helpers/Commons';
import Bugsnag from '../bugsnag';
import exceptions from '../exceptions';

let ld;
export function getLD() {
    const ldPromise = BBPromise.promisify(ld.on);
    const request = BBPromise.race([
        ldPromise('ready'),
        new BBPromise(((resolve, reject) => {
            setTimeout(() => reject(new Error(exceptions.REQUEST_TIMEOUT.message)), 5000);
        })),
    ]);

    return request.then(() => ld.allFlags());
}

function initializeLaunchDarkly() {
    return function* () {
        const ldUser = {
            key: '',
            firstName: '',
            lastName: '',
            email: '',
            custom: {},
        };
        try {
            const state = yield select();
            console.log("sagas/launchDarkly.js launchDarklyKey: -> " + state.config.get('launchDarkly')?.key);
            console.log("sagas/launchDarkly.js, claud-userName: -> " + state.claud.get('user')?.userName);
            const launchDarklyKey = state.config.get('launchDarkly').key;
            const clapiUser = state.clapi.get('usrDetail');

            ldUser.key = state.claud.get('user').userName;
            if (clapiUser) {
                ldUser.firstName = Commons.get(clapiUser, 'firstName') || '';
                ldUser.lastName = Commons.get(clapiUser, 'lastName') || '';
                ldUser.email = Commons.get(clapiUser, 'emailAddress') || '';

                if (clapiUser.accountDetail) {
                    ldUser.custom['Trade Name'] = Commons.get(clapiUser, 'accountDetail.tradeName') || '';
                    ldUser.custom['Franchise ID'] = Commons.get(clapiUser, 'accountDetail.franchiseId') || '';
                    ldUser.custom['Industry Segment'] = Commons.get(clapiUser, 'accountDetail.industrySegment') || '';
                    ldUser.custom['CRM Account ID'] = Commons.get(clapiUser, 'accountDetail.externalAccountId') || '';
                }
            }
            ld = initialize(launchDarklyKey, Commons.removeEmptyValuesInObj(ldUser), {});
            const flags = yield call(getLD);
            const action = { type: LD_INIT_SUCCESS, payload: flags };
            yield put(action);
        } catch (error) {
            console.error("sagas/launchDarkly.js -> catch block error = " + error + "\nldUser = " + JSON.stringify(ldUser));
            Bugsnag.notify(error);
            yield put({ type: CRUX_APP_ERROR, error });
            yield put({ type: LD_INIT_FAIL });
        }
    };
}

export const initializeLD = initializeLaunchDarkly();
