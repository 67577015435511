const ONE_MONTH = '1months';
const THREE_MONTHS = '3months';
const SIX_MONTHS = '6months';
const NINE_MONTHS = '9months';
const TWELVE_MONTHS = '12months';
const EIGHTEEN_MONTHS = '18months';
const TWENTYFOUR_MONTHS = '24months';
const CUSTOM_DATE = 'custom';
const ALL = 'All';

const RAPID_CLASSIC_PRESET_OPTIONS = [
    {
        label: 'Last Month',
        value: ONE_MONTH,
    },
    {
        label: 'Last 3 Months',
        value: THREE_MONTHS,
    },
    {
        label: 'Last 6 Months',
        value: SIX_MONTHS,
    },
    {
        label: 'Last 9 Months',
        value: NINE_MONTHS,
    },
    {
        label: 'Last 12 Months',
        value: TWELVE_MONTHS,
    },
    {
        label: 'Last 18 Months',
        value: EIGHTEEN_MONTHS,
    },
    {
        label: 'Last 24 Months',
        value: TWENTYFOUR_MONTHS,
    },
];

const RAPID_ALL_PRESET_OPTIONS = [{
    label: 'All',
    value: ALL,
}].concat(RAPID_CLASSIC_PRESET_OPTIONS);

const OPTIONS = {
    [ALL]: {
        label: 'All',
        value: 'All',
        less: 1,
    },
    [THREE_MONTHS]: {
        label: 'Last 3 Months',
        value: 'Last 3',
        less: 3,
    },
    [SIX_MONTHS]: {
        label: 'Last 6 Months',
        value: 'Last 6',
        less: 6,
    },
    [NINE_MONTHS]: {
        label: 'Last 9 Months',
        value: 'Last 9',
        less: 9,
    },
    [TWELVE_MONTHS]: {
        label: 'Last 12 Months',
        value: 'Last 12',
        less: 12,
    },
    [EIGHTEEN_MONTHS]: {
        label: 'Last 18 Months',
        value: 'Last 18',
        less: 18,
    },
    [TWENTYFOUR_MONTHS]: {
        label: 'Last 24 Months',
        value: 'Last 24',
        less: 24,
    },
    [CUSTOM_DATE]: {
        label: 'Custom Date',
        value: 'Custom',
        less: 1,
    },
};

const RAPID_DATE_FORMAT = 'YYYYMMDD';
const DATE_FORMAT = 'DD MMM YYYY';
const MONTHS_UNIT = 'months';

export default {
    ALL,
    ONE_MONTH,
    THREE_MONTHS,
    SIX_MONTHS,
    NINE_MONTHS,
    TWELVE_MONTHS,
    EIGHTEEN_MONTHS,
    TWENTYFOUR_MONTHS,
    CUSTOM_DATE,
    OPTIONS,
    RAPID_ALL_PRESET_OPTIONS,
    RAPID_CLASSIC_PRESET_OPTIONS,
    RAPID_DATE_FORMAT,
    DATE_FORMAT,
    MONTHS_UNIT,
};
