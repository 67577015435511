import { takeLatest, call, put } from 'redux-saga/effects';
import { addCruxCmptError, rmCruxCmptError } from '../actions/errorHandler';
import Bugsnag from '../bugsnag';
import * as TYPE from '../actions/titles';
import TitlesApi from '../api/titles';
import AnalyticsGateway from '../lib/AnalyticsGateway';
import { STATE } from '../constants/states';
import { isAU } from '../constants/crux';

const LAUNCH_TITLE_DOCUMENT_SEARCH = 'Launch Title Document Search';
const LAUNCH_REIWA_TITLE_DOCUMENT_SEARCH = 'Launch REIWA Title Document Search';

function goToTitlesFn() {
    return function* (action) {
        try {
            const { propertyId, selectedClAppAccountUserGuid, state = '' } = action.payload;
            let _propertyId = propertyId;
            let launchTitle = LAUNCH_TITLE_DOCUMENT_SEARCH;

            if (state.toLowerCase() === STATE.VIC) {
                _propertyId = null;
            }
            if (isAU) {
                const url = yield call(TitlesApi.getTitlesUrl, _propertyId, selectedClAppAccountUserGuid);
                const isReiwa = `${url || ''}`.toLowerCase().indexOf('reiwa') > -1;
                const isWa = state.toLowerCase() === STATE.WA;
                launchTitle = (isReiwa && isWa) ? LAUNCH_REIWA_TITLE_DOCUMENT_SEARCH : launchTitle;
            }

            AnalyticsGateway.sendEvent({
                eventName: launchTitle,
                properties: [
                    { label: 'Page', value: 'Property Detail' },
                    { label: 'Property ID', value: propertyId },
                ],
            });

            yield put(rmCruxCmptError('Titles'));
        } catch (error) {
            Bugsnag.notify(error);
            yield put(addCruxCmptError('Titles', error));
        }
    };
}

export const goToTitles = goToTitlesFn();

export function* goToTitlesWatcher() {
    yield takeLatest(TYPE.GO_TO_TITLES, goToTitles);
}

export default [
    goToTitlesWatcher(),
];
