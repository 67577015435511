
const EVENT_PROPERTIES = {
    PAGE: 'Page',
    PAGE_TAB: 'Page Tab',
    SEARCH_TYPE: 'Search Type',
    SEARCH_STRING: 'Search String',
    SEARCH_TAB: 'Search Tab',
    SEARCH: 'Search',
    PROPERTY: 'Property',
    NAME: 'Name',
    COMPANY: 'Company',
    VOLUME_FOLIO: 'Volume/Folio',
    RADIUS: 'Radius',
    ADDRESS: 'Address',
    RADIUS_SEARCH_RESULTS: 'Radius Search Results',
    PROPERTY_ID: 'Property ID',
    PROPERTY_TYPE: 'Property Type',
    ENTRY_POINT: 'Entry Point',
    PROPERTY_DETAIL: 'Property Detail',
    REPORT_SECTIONS_INCLUDED: 'Report Sections Included',
    REPORT_SUB_SECTIONS_INCLUDED: 'Report Sub Sections Included',
    PANEL: 'Panel',
    SELECTED_FIELDS: 'Selected Fields',
    INTEGRATION: 'Integration',
    FORM_TYPE: 'Form Type',
    PDF: 'PDF',
    OBJECT_CONTEXT: 'Object Context',
    SEARCH_RESULTS: 'Search Results',
    HOUSEHOLD_INFORMATION: 'Household Information',
    OWNER_INFORMATION: 'Owner Information',
    PROPERTY_HISTORY: 'Property History',
    AGENCY_CAMPAIGN_MODAL: 'Agency Campaign Modal',
    PROPERTY_CAMPAIGN_MODAL: 'Property Campaign Modal',
    RENTAL_CAMPAIGN_MODAL: 'Rental Campaign Modal',
    RADIUS_SEARCH_MODAL: 'Radius Search Modal',
    PANEL_TAB: 'Panel Tab',
    TYPED: 'Typed',
    LAYER: 'Layer',
    CUSTOM_PRINT_PREVIEW: 'Custom Print Preview',
    MAP_MODAL_PANEL: 'Modal Map',
    MINI_MAP_MODAL_PANEL: 'Mini Map',
    DISTANCE: 'Distance',
    TARGET_SUBURB: 'Target Suburb',
    INCLUDE_HISTORIC: 'Include Historic',
    MAP_TYPE: 'Map Type',
    MAP_DATE: 'Map Date',
    LAYERS: 'Layers',
    BASE: 'Base',
    USER_PROFILE: 'User Profile',
    SAVED_SEARCHES: 'Saved Searches',
    TAB: 'Tab',
    NOTE: 'Note',
    UPDATE: 'Update',
    CHARACTER_COUNT: 'Character Count',
    ADDITIONAL_INFORMATION: 'Additional Information',
    UPDATE_CORELOGIC: 'Update Corelogic',
    RESULT_VIEW: 'Result View',
    PER_PAGE: 'Per Page',
    RP_DATA: 'RP Data',
    SORT_BY: 'Sort By',
    LIST: 'List',
    CARD: 'Card',
    VALUATION_ESTIMATES: 'Valuation Estimates',
    VALUATION_ESTIMATE_PANEL: 'Valuation Estimate Panel',
    USER_NAME: 'Username',
    SITE: 'Site',
    VALUATION_ESTIMATE_REPORT_MODAL: 'Valuation Estimation Report Modal',
    VALUATION_ESTIMATE_REPORT_FORMAT: 'PDF',
    REPORT_FORMAT: 'Report Format',
    PROPERTY_NOTES_PANEL: 'Property Notes Panel',
    PROPERTY_NOTES_MODAL: 'Property Notes Modal',
    CHANGE_PASSWORD: 'Change Password',
    REPORT_TYPE: 'Report Type',
    NEWS_FEED: 'News Feed',
    MARKET_INSIGHTS: 'Market Insights',
    PORTAL_SIDEBAR: 'Portal Sidebar',
    LEGAL_DESCRIPTION: 'Legal Description',
    LEGALS: 'Legals',
    INTERESTS: 'Interests',
    TITLE_INFORMATION: 'Title Information',
    RATING_VALUATION: 'Rating Valuation',
    DIGITAL_PROPERTY_REPORT: 'Digital Property Report',
    PROPOSAL: 'Proposal',
    HTML: 'HTML',
    REPORT_PREVIEW: 'Report Preview',
    SECTION: 'Section',
    RESULT: 'Result',
    CURRENT_OWNER: 'Current Owner',
    CHANGE_TYPE: 'Change Type',
    MY_ACCOUNT: 'My Account',
    MANAGE_USERS: 'Manage Users',
    SECTION_NAME: 'Section Name',
    SUB_SECTION_NAME: 'Sub Section Name',
    MANAGE_BRANDING: 'Manage Branding',
    SUCCESS: 'Success',
    FAILURE: 'Failure',
    TITLE_REPORT: 'Title Report',
    EXACT_MATCH: 'Exact Match',
    ROLL_VALUATION: 'Roll/Valuation',
    FAVOURITES: 'Favourites',
    NOTIFICATIONS: 'Notifications',
    PUSH_NOTIFICATIONS: 'Push Notifications',
    MULTI_LOCALITY: 'Multi Locality',
    EMAIL_DIGEST: 'Email Digest',
    FOR_SALE: 'For Sale',
    FOR_RENT: 'For Rent',
    AGENT_ADVISED_SALE: 'Agent Advised Sale',
    SAVED_SEARCH_RESULTS: 'Saved Search Results',
    LIST_PROPERTIES: 'List Properties',
    NUMBER_OF_LIST_PROPERTIES: 'Number of List Properties',
    NUMBER_OF_PROPERTIES: 'Number of Properties',
    SAVED_LIST_STAR: 'Saved List Star',
    MULTI_PROPERTY_ACTIVITIES_BAR: 'Multi Property Activities Bar',
    REPORT_OPTION_INCLUDE_AVM: 'Report Option Include AVM',
};
const EVENT_NAMES = {
    VIEW_PRINT_PAGE: 'View Print Page',
    ADD_SECTION_TO_REPORT: 'Add Section to Report',
    REMOVE_SECTION_FROM_REPORT: 'Remove Section from Report',
    VIEW_AGENCY_CAMPAIGN: 'View Agency Campaign',
    VIEW_PROPERTY_CAMPAIGN: 'View Property Campaign',
    VIEW_RENTAL_CAMPAIGN: 'View Rental Campaign',
    VIEW_DEVELOPMENT_DETAILS: 'View Development Details',
    VIEW_BUILDING_CONSENT: 'View Building Consent',
    VIEW_SALE_HISTORY: 'View Sale History',
    CHANGE_SECURITY_QUESTION: 'Change Security Question',
    UPDATE_MAP_LAYER: 'Update Map Layer',
    PROPERTY_SEARCH: 'Property Search',
    LAUNCH_RADIUS_SEARCH: 'Launch Radius Search',
    DOWNLOAD_MAP: 'Download Map',
    LAYER: 'Map Layer',
    MEASURE_DISTANCE: 'Measure Distance',
    MEASURE_AREA: 'Measure Area',
    DELETE_ALL: 'Delete All',
    UPDATE_PROPERTY_DATA: 'Update Property Data',
    CHANGE_LIST_VIEW_COLUMNS: 'Change List View Columns',
    CHANGE_LIST_VIEW_ORDER: 'Change List View Order',
    VIEW_MY_DETAILS: 'View My Details',
    VIEW_SAVED_SEARCHES: 'View Saved Searches',
    VIEW_SAVED_TERRITORY: 'View Saved Territory',
    VIEW_SAVED_LISTS: 'View Saved Lists',
    CREATE_NEW_SAVED_TERRITORY: 'Create New Saved Territory',
    OPEN_SAVED_TERRITORY: 'Open Saved Territory',
    DELETE_SAVED_TERRITORY: 'Delete Saved Territory',
    CONFIGURE_SAVED_TERRITORY: 'Configure Saved Territory',
    SWITCH_TABS: 'Switch Tabs',
    SHOW_MORE: 'Show More',
    UPDATE_PROPERTY_LOCATION: 'Update Property Location',
    SWITCH_RESULT_VIEW: 'Switch Result View',
    CHANGE_PER_PAGE: 'Change Per Page',
    CHANGE_SORT_ORDER: 'Change Sort Order',
    UPDATE_WORK_DETAILS: 'Update Work Contact Details',
    UPDATE_PERSONAL_DETAILS: 'Update Personal Details',
    RESET_PASSWORD: 'Reset Password',
    UPDATE_VALUATION_ESTIMATE: 'Update Valuation Estimate',
    DOWNLOAD_VALUATION_ESTIMATE_REPORT: 'Download Valuation Estimate Report',
    CHANGE_PASSWORD: 'Change Password',
    VIEW_CHANGE_PASSWORD: 'View Change Password',
    SWITCH_ACCOUNT: 'Switch Account',
    UPDATE_PROPERTY_NOTE: 'Update Property Note',
    LAUNCH_PROPERTY_LIST_REPORT: 'Launch Property List Report',
    LAUNCH_SALES_HISTORY_REPORT: 'Launch Sales History Report',
    LAUNCH_ON_THE_MARKET_REPORT: 'Launch On The Market Report',
    LAUNCH_AGED_LISTINGS_REPORT: 'Launch Aged Listings Report',
    LAUNCH_TENURE_REPORT: 'Launch Tenure Report',
    LAUNCH_WITHDRAWN_LISTINGS_REPORT: 'Launch Withdrawn Listings Report',
    LAUNCH_RENTAL_COMPARISON_REPORT: 'Launch Rental Comparison Report',
    LAUNCH_RP_CONNECT: 'Launch RP Connect',
    LAUNCH_SIGNATURE_REPORTS: 'Launch Signature Reports',
    LAUNCH_TITLE_DOCUMENT_SEARCH: 'Launch Title Document Search',
    LAUNCH_PLEZZEL: 'Launch Plezzel',
    LAUNCH_REIWA_TITLE_DOCUMENT_SEARCH: 'Launch REIWA Title Document Search',
    LAUNCH_MARKET_INSIGHTS: 'Launch Market Insights',
    MARKET_INSIGHTS_AGREEMENT: 'Market Insights Agreement',
    RETURN_HOME: 'Return Home',
    RELOAD_PAGE: 'Reload Page',
    PROPERTY_LIST_REPORT_PREVIEW: 'Property List Report Preview',
    SALES_HISTORY_REPORT_PREVIEW: 'Sales History Report Preview',
    ON_THE_MARKET_REPORT_PREVIEW: 'On The Market Report Preview',
    AGED_LISTINGS_REPORT_PREVIEW: 'Aged Listings Report Preview',
    WITHDRAWN_LISTINGS_REPORT_PREVIEW: 'Withdrawn Listings Report Preview',
    TENURE_REPORT_PREVIEW: 'Tenure Report Preview',
    RENTAL_COMPARISON_REPORT_PREVIEW: 'Rental Comparison Report Preview',
    GENERATE_REPORT: 'Generate Report',
    PDF: 'PDF',
    PROPERTY_LIST_REPORT: 'Property List Report',
    SALES_HISTORY_REPORT: 'Sales History Report',
    ON_THE_MARKET_REPORT: 'On The Market Report',
    AGED_LISTINGS_REPORT: 'Aged Listings Report',
    WITHDRAWN_LISTINGS_REPORT: 'Withdrawn Listings Report',
    TENURE_REPORT: 'Tenure Report',
    RENTAL_COMPARISON_REPORT: 'Rental Comparison Report',
    VIEW_NEWS_FEED: 'View News Feed',
    VIEW_NOTIFICATIONS: 'View Notifications',
    LAUNCH_RPP_CRUX: 'Launch RPP CRUX',
    VIEW_TITLE_INFORMATION: 'View Title Information',
    SHOW_HISTORICAL_VALUATIONS: 'Show Historical Valuations',
    SWITCH_RESULT_TAB: 'Switch Result Tab',
    VIEW_PROPERTY_DETAIL: 'View Property Detail',
    EXPORT_TO_CSV: 'Export to CSV',
    LAUNCH_RP_PROPOSALS: 'Launch RP Proposals',
    LAUNCH_PG_PROPOSALS: 'Launch PG Proposals',
    VIEW_TRAINING: 'View Training',
    YOUR_FEEDBACK: 'Your Feedback',
    COPY_REPORT_LINK: 'Copy Report Link',
    SHARE_REPORT: 'Share Report',
    RESET_FILTERS: 'Reset Filters',
    EDIT_ACCOUNT_DETAILS: 'Edit Account Details',
    VIEW_ACCOUNT_DETAILS: 'View Account Details',
    NAVIGATE_TO_RPP: 'Navigate to RPP',
    MY_DETAILS: 'My Details',
    CONFIGURE_SAVED_SEARCH: 'Configure Saved Search',
    CHANGE_SECTION_ORDER: 'Change Section Order',
    CREATE_FORM: 'Create Form',
};

export default {
    EVENT_PROPERTIES,
    EVENT_NAMES,
};

