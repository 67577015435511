export const CAR_SPACES_ATTR_VAL = 'carSpaces';
export const PROPERTY_TYPE_ATTR_VAL = 'propertyType';

const PROPERTY_ATTRIBUTE = [
    { label: 'Bedroom', value: 'bedrooms' },
    { label: 'Bathroom', value: 'bathrooms' },
    { label: 'Car', value: CAR_SPACES_ATTR_VAL },
    { label: 'Land Size', value: 'landArea' },
    { label: 'Floor Area', value: 'floorArea' },
];

export const UPDATE_DATA_ATTRIBUTES = [
    ...PROPERTY_ATTRIBUTE,
    { label: 'Property Type', value: PROPERTY_TYPE_ATTR_VAL },
];

export default PROPERTY_ATTRIBUTE;
