export const GET_SAVED_TERRITORIES = 'GET_SAVED_TERRITORIES';
export const GET_SAVED_TERRITORIES_SUCCESS = 'GET_SAVED_TERRITORIES_SUCCESS';
export const GET_SAVED_TERRITORIES_FAIL = 'GET_SAVED_TERRITORIES_FAIL';
export const SET_SELECTED_TERRITORY = 'SET_SELECTED_TERRITORY';
export const CLEAR_TERRITORIES = 'CLEAR_TERRITORIES';

export const getSavedTerritories = (payload) => ({
    type: GET_SAVED_TERRITORIES,
    payload,
});

export const setSelectedTerritory = (id) => ({
    type: SET_SELECTED_TERRITORY,
    payload: {
        id,
    }
});