import API from './index';
import methods from '../constants/methods';
import CLAPI from "./clapi";

const { large: longApiTimeout } = API.getTimeouts();
const getCoverage = (clUserId, polygon) => {
    const appAccountUserGuid = `clAppAccountUserGuid=${CLAPI.getSelectedClAppAccountUserGuid()}`;
    const requestOptions = {
        path: `/api/nearmap/coverage/${polygon}/?${appAccountUserGuid}`,
        isProxy: true,
        timeout: longApiTimeout,
    }
    const headerOptions = {
        method: methods.GET.method,
    }
    return API.initiateSessionApiCall(requestOptions, headerOptions);
}

export default {
    Nearmap: {
        getCoverage,
    }
}
