const REPORT_ENTITLEMENTS = [
    'property cma',
    'rentals cma',
    'cma generic report engine full',
    'profiler property 1 page',
    'property profiler',
    'valuation',
    'profiler suburb',
    'rental avm full',
    'digital_property_profile',
    'titles_report',
    'suburb statistics',
    'export_limit_pro',
    'proposal_point',
];

const DISPLAY_TYPE = {
    REPORT: 'REPORT',
    PANEL: 'PANEL',
};

const SIGNATURE_REPORT_TYPES = {
    SOI: 'soi',
    CMA: 'cma',
    RENTAL: 'rental',
};

const OWNER_NAMES = {
    available: 'Include Owner Names',
    notAvailable: 'Owner names not available due to supplier restrictions',
};

const PDF_REPORT_KEYS = {
    summary: 'summary',
    propertyProfile: 'propertyProfile',
    suburbProfile: 'suburbProfile',
    intellival: 'intellival',
    propertyInvestment: 'propertyInvestment',
};
export default {
    RENTAL_ESTIMATE: 'Rental Estimate Report',
    ONE_PAGE: '1 Page Report',
    PROPERTY_REPORT: 'Property Report',
    PROPERTY_INVESTMENT_REPORT: 'Property Investment Report',
    SUBURB_REPORT: 'Suburb Report',
    INTELLIVAL_AVM_REPORT: 'Intellival AVM Report',
    REPORT_ID: {
        valuationReport: 108,
        propertyReportWithoutAvm: 29,
        propertyReportWithAvm: 18,
        rentalAvmEstimateReport: 28,
        suburbReport: 4,
    },
    REPORT_ENTITLEMENTS,
    OWNER_NAMES,
    SIGNATURE_REPORT_TYPES,
    PDF_REPORT_KEYS,
    DISPLAY_TYPE,
};
