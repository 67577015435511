import { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import withPageErrorHandler from '../higherOrderComponents/WithPageErrorHandler';

export const CruxPageLayoutOpen = ({
    header, children, footer,
    isHomePage, isCopyDisabled,
}) => {
    useLayoutEffect(() => {
        // ON MOUNT
        const keyDownCallback = (e) => {
            // CTRL Key is for windows only particularly on Edge Browser
            // FF, CHROME, SAFARI, and Chromium Edge is working fine
            if (e.ctrlKey) { // "ctrl" key
                if ([67, 88].includes(e.keyCode)) { // "c|x" key
                    e.preventDefault();
                    return false;
                }
            }
            return true;
        };
        if (isCopyDisabled) {
            document.body.addEventListener('keydown', keyDownCallback);
        }
        // UNMOUNTING
        return () => {
            if (isCopyDisabled) {
                document.body.removeEventListener('keydown', keyDownCallback);
            }
        };
    }, [isCopyDisabled]);

    return (
        <div
            id="crux-layout"
            className={isHomePage && 'is-home-page'}
            onContextMenu={isCopyDisabled && (e => e.preventDefault())}
            onCopy={isCopyDisabled && (e => e.preventDefault())}
            onCut={isCopyDisabled && (e => e.preventDefault())}
        >
            {header}
            <div className="wrapper">
                {children}
                {footer}
            </div>
        </div>
    );
};

CruxPageLayoutOpen.propTypes = {
    children: PropTypes.element,
    header: PropTypes.element,
    footer: PropTypes.element,
    isHomePage: PropTypes.bool,
    isCopyDisabled: PropTypes.bool,
};

export default withPageErrorHandler(CruxPageLayoutOpen);
