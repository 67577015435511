import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Button, Grid } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import StarOutlineIcon from '@mui/icons-material/StarOutline';

import BlockUi from '../../blockUi/BlockUi';
import SavedListCard from './SavedListCard';
import 'css/crux/components/userFavorites/savedLists/savedLists.scss';
import { SAVED_LIST_TYPE } from '../../../constants/favourites/favourites';
import { getMoreSavedList, getSavedList, searchSavedList } from '../../../actions/watchList';
import { cruxLoader, cruxUnloader } from '../../../actions/crux';
import CruxSnackBar from '../../common/CruxSnackBar';
import SavedListDrawer from './SavedListDrawer';
import CruxDialogBox from '../../common/modals/CruxDialogBox';
import UserPreferences from '../../../api/userPreferences';
import ValidationStatus from '../../../constants/validationStatus';
import { trackEvent } from '../../../actions/segment';
import SegmentHelper from '../../../helpers/Segment';
import { selectSelectedAccount } from '../../../selectors/linkedAccount';
import Commons from '../../../helpers/Commons';
import { CREATE_SAVED_LIST } from '../../global/userFavoritesHome/savedList/SavedListPanelHome';
import InfoMsg from 'js/constants/infoMessage';
import UnsubscribedPage from '../../global/userFavoritesHome/UnsubscribedPage';
import Colors from '../../../constants/colors';
import Entitlements from '../../../helpers/Entitlements';

const DELETE_ERROR_MESSAGE = 'An error occurred, and your changes were not saved. Please try again.';
const FETCH_ERROR_MESSAGE = 'Sorry, there was an error fetching your lists. Please try again.';
export const SAVED_LIST_LIMIT = 1000;

const DisplayListCount = ({ hide = false, savedList }) => {
    const displayedSavedLists = (savedList?.list?.length || 0) + 1;
    const totalSavedLists = (savedList?.totalCount || 0) + 1;
    return (
        <div style={{ visibility: hide ? 'hidden': 'visible' }}>
            Displaying {Commons.numberWithCommas(displayedSavedLists)} of
            {` ${Commons.numberWithCommas(totalSavedLists)} List`}{totalSavedLists > 1 ? 's' : ''}
        </div>
    );
};

const SavedListsPanel = ({
    dispatch,
    savedList,
    watchList,
    usrDetail,
}) => {
    const [searchValue, setSearchValue] = useState(null);
    const [id, setId] = useState(null);
    const [name, setName] = useState(null);
    const [filtering, setFiltering] = useState(false);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [showSnackBar, setShowSnackBar] = useState(false);
    const [snackBarMessage, setSnackBarMessage] = useState('');
    const [snackBarSeverity, setSnackBarSeverity] = useState('success');
    const [loading, setLoading] = useState(null);
    const [fetchMore, setFetchMore] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const { selectedClAppAccountUserGuid } = useSelector(selectSelectedAccount);
    const page = useSelector(state => state?.watchList?.get('savedList'))?.page || 0;
    const hasSavedListRole = Entitlements.hasSavedListRole(usrDetail);

    // initial load
    useEffect(() => {
        // auto open drawer when create saved list button click from home page
        if (localStorage.getItem(CREATE_SAVED_LIST)) {
            newSavedList();
            localStorage.removeItem(CREATE_SAVED_LIST);
        }

        // call getSavedList only when it is not loaded
        if (!savedList?.success && !savedList?.list?.length && hasSavedListRole) {
            setLoading(true);
            dispatch(getSavedList());
        }

        // Effect to clear search when unmounting
        return () => {
            if (searchValue) {
                dispatch(searchSavedList());
            }
        };
    }, []);

    // load more button action
    const loadMore = () => {
        const nextPage = page + 1;
        setFetchMore(true);
        dispatch(getMoreSavedList({
            params: {
                page: nextPage,
                ...(searchValue ? { search: searchValue } : {}),
            }
        }));
    };

    const newSavedList = () => {
        setId(null);
        setName('');
        setOpenDrawer(true);
    };

    const editSavedList = (id, name) => {
        setId(id);
        setName(name);
        setOpenDrawer(true);
    };

    const openDeleteModal = (id, name) => {
        setId(id);
        setName(name);
        setShowDeleteModal(true);
    };

    const deleteSavedList = () => {
        setLoading(true);
        UserPreferences.deleteSavedList(selectedClAppAccountUserGuid, id)
            .then((res) => {
                if (res?.statusCode === 'OK') {
                    dispatch(trackEvent(SegmentHelper.recordSavedList({
                        eventName: 'Delete Saved List',
                        name,
                        page: 'Favourites',
                    })));
                    showMessage(`${name} list removed`, ValidationStatus.WARNING);
                    dispatch(getSavedList());
                } else {
                    setLoading(false);
                    showMessage(DELETE_ERROR_MESSAGE);
                }
            })
            .catch(() => {
                setLoading(false);
                showMessage(DELETE_ERROR_MESSAGE);
            });
    };

    const showMessage = (message, severity = ValidationStatus.ERROR) => {
        setSnackBarSeverity(severity);
        setSnackBarMessage(message);
        setShowSnackBar(true);
    };

    useEffect(() => {
        if (loading) {
            setLoading(false);
        }
    }, [savedList.list]);

    useEffect(() => {
        if (filtering && !savedList?.fetching) {
            setFiltering(false);
        }
    }, [savedList?.fetching]);

    // after fetching the initial load
    // or after filtering
    useEffect(() => {
        if (!savedList?.success && !savedList?.fetching) {
            if (filtering || loading) {
                setLoading(false);
            }
            if (loading === false) {
                showMessage(FETCH_ERROR_MESSAGE, ValidationStatus.ERROR);
            }
        }

    }, [savedList?.success, savedList?.fetching, loading]);

    // after fetching load more
    useEffect(() => {
        if (fetchMore && !savedList?.successToFetchMore && !savedList?.fetchingMore) {
            showMessage(FETCH_ERROR_MESSAGE, ValidationStatus.ERROR);
        }

        if (fetchMore && !savedList.fetchingMore) {
            setFetchMore(false);
        }
    }, [savedList?.successToFetchMore, savedList?.fetchingMore]);

    useEffect(() => {
        if (loading) {
            dispatch(cruxLoader());
        } else if (loading === false) { // strict check only if loading is false, so that
            // initial value of null won't dispatch
            dispatch(cruxUnloader());
        }
    }, [loading]);

    // filtering saved list
    useEffect(() => {
        const timeOutId = setTimeout(() => {
            if (searchValue !== null) {
                setFiltering(true);
                dispatch(getSavedList({ params: { search: searchValue } }));
            }
        }, 500);
        return () => clearTimeout(timeOutId);
    }, [searchValue]);

    if (!hasSavedListRole) {
        return (
            <UnsubscribedPage
                type="Saved Lists"
                Icon={<StarOutlineIcon
                    sx={{
                        width: '56px',
                        height: '56px',
                        flexShrink: '0',
                        color: Colors.PRIMARY.MAIN,
                    }}
                />}
            />
        );
    };

    return (<div id="saved-lists-panel">
        <div className="saved-lists-search">
            <DisplayListCount savedList={savedList} />
            <div style={{ display: 'flex', alignItems: 'center'}}>
                {
                    filtering &&
                    <CircularProgress
                        style={{
                            marginRight: 15,
                            width: 24,
                            height: 24,
                        }}
                    />
                }
                <TextField
                    variant="outlined"
                    InputProps={{
                        endAdornment: <InputAdornment position="start">
                            <SearchIcon />
                        </InputAdornment>,
                    }}
                    className="saved-lists-search-textfield"
                    value={searchValue || ''}
                    onChange={event => setSearchValue(event.target.value)}
                />
                <Button
                    variant="contained"
                    startIcon={<AddCircleOutlineIcon />}
                    onClick={newSavedList}
                >
                    Create Saved List
                </Button>
            </div>
        </div>
        <div className="saved-lists-cards-panel">
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <SavedListCard
                        type={SAVED_LIST_TYPE.WATCH_LIST}
                        name="My Watch List"
                        propertyCount={watchList?.propertiesCount}
                        alerts={watchList?.alertedPropertiesCount}
                        watchlistSuccess={watchList?.success}
                    />
                </Grid>
                {
                    savedList?.list?.map(({ id, description, propertiesCount }) =>
                        <Grid item xs={4} key={id}>
                            <SavedListCard
                                type={SAVED_LIST_TYPE.SAVED_LIST}
                                id={id}
                                name={description}
                                propertyCount={propertiesCount}
                                editSavedList={editSavedList}
                                openDeleteModal={openDeleteModal}
                            />
                        </Grid>
                    )
                }
            </Grid>
            <div className="saved-list-button">
                <DisplayListCount savedList={savedList} />
                {
                    savedList?.hasNext && !fetchMore && savedList?.list?.length < SAVED_LIST_LIMIT &&
                    <Button
                        variant="contained"
                        onClick={loadMore}
                    >
                        Show more
                    </Button>
                }
                {
                    savedList?.list?.length >= SAVED_LIST_LIMIT &&
                    <p className="result-count-limit">
                        {InfoMsg.MAX_RESULTS_RETURNED}
                    </p>
                }
                {
                    fetchMore &&
                    <BlockUi
                        blocking
                        containerProps={{
                            className: 'loader',
                            testId: 'loader',
                            position: 'relative',
                        }}
                        backdropProps={{
                            style: {
                                position: 'relative',
                                height: 20,
                            }
                        }}
                    />
                }
                <DisplayListCount hide savedList={savedList} />
            </div>
        </div>
        <SavedListDrawer
            open={openDrawer}
            onClose={() => setOpenDrawer(false)}
            id={id}
            description={name}
            showMessage={showMessage}
            setLoading={setLoading}
            setDescriptionFilter={setSearchValue}
        />
        <CruxDialogBox
            open={showDeleteModal}
            id="remove-saved-list-modal"
            title={`Are you sure you want to remove the Saved List "${name}"?`}
            subTitle="This will remove the list and cannot be undone."
            onClose={() => setShowDeleteModal(false)}
            onConfirm={deleteSavedList}
        />
        <CruxSnackBar
            open={showSnackBar}
            onClose={() => setShowSnackBar(false)}
            severity={snackBarSeverity}
            message={snackBarMessage}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            sx={{
                zIndex: 9999,
            }}
        />
    </div>);
};

SavedListsPanel.propTypes = {
    dispatch: PropTypes.func,
    savedList: PropTypes.object,
    watchList: PropTypes.object,
    usrDetail: PropTypes.object,
};
export default connect(state => ({
    savedList: state.watchList.get('savedList'),
    watchList: state.watchList.get('watchList'),
    usrDetail: state.clapi.get('usrDetail'),
}))(SavedListsPanel);
