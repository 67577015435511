import {
    ADDRESS_BUILDING_NAME_PARAM,
    IS_ACTIVE_FOR_SALE_CAMPAIGN_PARAM,
    SORT_PARAM
} from '../../constants/batchSearch/batchSearch';

const FIELDS_WITH_BUILDER = {
    [ADDRESS_BUILDING_NAME_PARAM]: (uriBuilder, value) => {
        uriBuilder.addParam(ADDRESS_BUILDING_NAME_PARAM, JSON.stringify(value));
    },
    [IS_ACTIVE_FOR_SALE_CAMPAIGN_PARAM]: (uriBuilder, value) => {
        if (value) {
            uriBuilder.addParam(IS_ACTIVE_FOR_SALE_CAMPAIGN_PARAM, value);
        }
    },
    [SORT_PARAM]: (uriBuilder, value) => {
        if (value.constructor === Array) {
            const sortList = value.map(item => (`${item.order}${item.attr}`));
            if (sortList.length) {
                uriBuilder.addParam(SORT_PARAM, sortList.toString());
            }
        } else {
            uriBuilder.addParam(SORT_PARAM, value);
        }
    },
};

const buildFromObject = (uri, node) => {
    const nodeFieldNames = Object.getOwnPropertyNames(node);
    for (const nodeFieldName of nodeFieldNames) {
        if (node[nodeFieldName] !== '') {
            uri.addParam(nodeFieldName, node[nodeFieldName]);
        }
    }
}

export default (uri, fieldNames, childNodes) => {
    if (fieldNames.length === 0) return;
    for (const fieldName of fieldNames) {
        const node = childNodes[fieldName];

        // this could be "if(node)", but there are properties that contains "false"
        // and should fall under the condition
        if (node !== undefined && node !== null && node !== '') {
            if (Object.keys(FIELDS_WITH_BUILDER).includes(fieldName)) {
                FIELDS_WITH_BUILDER[fieldName](uri, node);
            } else if (node.constructor === Object) {
                buildFromObject(uri, node);
            } else {
                uri.addParam(fieldName, node);
            }
        }
    }
};

export const conjunctUrlParams = params => Object
    .keys(params)
    .map(key => (params[key] ? `${key}=${params[key]}` : ''))
    .filter(param => param)
    .join('&');
