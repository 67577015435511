import TOOLTIP_TEXT from './tooltipText';

export const EVENT_TAGS = [
    {
        label: 'Recent Sale',
        color: 'RECENT_SALE_TAG',
        tooltipText: TOOLTIP_TEXT.TEXT.RECENT_SALE,
        flag: 'isRecentSale',
    },
    {
        label: 'Recent Advice',
        color: 'RECENT_ADVICE_TAG',
        tooltipText: TOOLTIP_TEXT.TEXT.RECENT_ADVICE,
        flag: 'isAgentAdvice',
    },
    {
        label: 'For Sale',
        color: 'FOR_SALE_TAG',
        tooltipText: TOOLTIP_TEXT.TEXT.FOR_SALE,
        flag: 'isForSaleCampaign',
    },
    {
        label: 'For Rent',
        color: 'FOR_RENT_TAG',
        tooltipText: TOOLTIP_TEXT.TEXT.FOR_RENT,
        flag: 'isForRentCampaign',
    },
    {
        label: 'Historical',
        color: 'HISTORICAL_TAG',
        tooltipText: TOOLTIP_TEXT.TEXT.HISTORICAL,
        flag: 'isHistoric',
    },
];

export const SAVED_SEARCH_EVENT_TAGS = [
    {
        name: 'All Properties'
    },
    {
        label: 'Recent Sale',
        color: '#CD2D2E',
        flag: 'isRecentlySold',
    },
    {
        label: 'For Sale',
        color: '#71A9DA',
        flag: 'isForSale',
    },
    {
        label: 'For Rent',
        color: '#6BA753',
        flag: 'isForRent',
    }
]
