import { routeCodes } from './routes';

export const entryPoints = {
    [routeCodes.PROPERTY.pageContext]: {
        entryPoint: 'Property Summary Panel',
        genericEntryPoint: 'Property Details Page',
    },
    [routeCodes.HOME.pageContext]: {
        entryPoint: 'Homepage SLAS Search Result',
    },
    [routeCodes.HOME.id]: {
        entryPoint: 'Homepage SLAS Search Result',
    },
    [routeCodes.SEARCH_RESULT.id]: {
        entryPoint: 'Location Search Results',
    },
    [routeCodes.NAME_SEARCH_RESULT.id]: {
        entryPoint: 'Search Results',
    },
    [routeCodes.RADIUS_SEARCH_RESULT.id]: {
        entryPoint: 'Radius Search Results',
    },
    [routeCodes.NAME_SEARCH_RESULT.id]: {
        entryPoint: 'Name Search Results',
    },
    [routeCodes.COMPANY_SEARCH_RESULT.id]: {
        entryPoint: 'Company Search Results',
    },
    [routeCodes.PARCEL_SEARCH_RESULT.id]: {
        entryPoint: 'Parcel Search Results',
    },
    [routeCodes.VOLUME_FOLIO_SEARCH_RESULT.id]: {
        entryPoint: 'Volume/Folio Search Results',
    },
    [routeCodes.LEGAL_DESCRIPTION_SEARCH_RESULT.id]: {
        entryPoint: 'Legal Description Search Results',
    },
    [routeCodes.TITLE_REFERENCE_SEARCH_RESULT.id]: {
        entryPoint: 'Title Reference Search Results',
    },
    [routeCodes.ROLL_VALUATION_SEARCH_RESULT.id]: {
        entryPoint: 'Roll/Valuation Search Results',
    },
    [routeCodes.BUILDING_SEARCH_RESULT.id]: {
        entryPoint: 'Building Name Search Results',
    },
    [routeCodes.SEARCH_RESULT.id]: {
        entryPoint: 'Address Search Results',
    },
    [routeCodes.PROPERTY.id]: {
        entryPoint: 'Property Details Page',
    },
    [routeCodes.ACCOUNT.id]: {
        entryPoint: 'Account',
    },
    [routeCodes.ACCOUNT.pageContext]: {
        entryPoint: 'Account',
    },
    [routeCodes.USER_FAVORITES.id]: {
        entryPoint: 'Favorites',
    },
    [routeCodes.USER_FAVORITES.pageContext]: {
        entryPoint: 'Favorites',
    },
    [routeCodes.SAVED_SEARCH.id]: {
        entryPoint: 'Saved Search',
    },
    [routeCodes.SAVED_LIST.id]: {
        entryPoint: 'Saved List',
    },
    [routeCodes.SAVED_TERRITORY.id]: {
        entryPoint: 'Saved Territory',
    },
    REPORTS_MENU: 'Reports Menu',
    VALUATION_ESTIMATE_PANEL: 'Valuation Estimate Panel',
    [routeCodes.MULTI_LOCALITY_SEARCH.id]: {
        entryPoint: 'Multi Locality Search',
    },
};
