import React from 'react';

const HistoricalFlag = () => (
    <div className="historical-flag" data-testid="historical-flag">
        <div className="historical-inner-container">
            <span>Historical</span>
        </div>
    </div>
);

export default HistoricalFlag;
