import React, { useContext, useEffect, useRef, useState } from 'react';
import { DEFAULT_MAP } from '../../helpers/Localization';
import MapTypeConstant from '../../constants/mapTypeConstant';

export const MapTypeContext = React.createContext({
    contextMapTypeId: DEFAULT_MAP,
    setContextMapTypeId: () => {/* do nothing */},
});
export const useMapTypeContext = () => useContext(MapTypeContext);

export function MapTypeContextProvider(props) {
    const [contextMapTypeId, setContextMapTypeId] = useState(DEFAULT_MAP);
    const mapTypeContextHandler = { contextMapTypeId, setContextMapTypeId };

    const addressRef = useRef();

    useEffect(() => {
        if (props.address && props.address.propertyId !== addressRef.current) {
            let selectedMapTypeId;
            if (props.selectedMapType) {
                selectedMapTypeId = (props.selectedMapType === MapTypeConstant.NEARMAP)
                    ? MapTypeConstant.SATELLITE : props.selectedMapType;
            } else {
                selectedMapTypeId = DEFAULT_MAP;
            }
            setContextMapTypeId(selectedMapTypeId);
            addressRef.current = props.address.propertyId;
        }
    }, [props.address]);

    return (
        <MapTypeContext.Provider value={mapTypeContextHandler}>
            {props.children}
        </MapTypeContext.Provider>
    );
}
