const FAILED_TO_CONNECT_MESSAGE = 'An error occurred, and your changes were not saved. Please try again.';
const FAILED_TO_DISCONNECT_MESSAGE = 'An error occurred, and your integration was not disconnected. Please try again.';

const FORM_TYPES = {
    REI_FORMS: 'REI_FORMS',
    REAL_WORKS: 'REAL_WORKS',
    FORMS_LIVE: 'FORMS_LIVE',
};

const FORM_ICONS = {
    [FORM_TYPES.REI_FORMS]: 'rei-icon-small.svg',
    [FORM_TYPES.REAL_WORKS]: 'real-works-icon-small.svg',
    [FORM_TYPES.FORMS_LIVE]: 'forms-live-icon-small.svg',
};

const FORM_LABEL = {
    [FORM_TYPES.REI_FORMS]: 'REI Forms Live',
    [FORM_TYPES.REAL_WORKS]: 'Realworks',
    [FORM_TYPES.FORMS_LIVE]: 'Forms Live',
};

const FORM_TYPES_STATE = {
    ACT: FORM_TYPES.REI_FORMS,
    NSW: FORM_TYPES.REI_FORMS,
    NT: FORM_TYPES.REI_FORMS,
    QLD: FORM_TYPES.REAL_WORKS,
    SA: FORM_TYPES.REI_FORMS,
    TAS: FORM_TYPES.REI_FORMS,
    VIC: FORM_TYPES.FORMS_LIVE,
    WA: FORM_TYPES.REI_FORMS,
};

const FORM_LOGOS = {
    [FORM_TYPES.REI_FORMS]: 'rei-forms-logo.svg',
    [FORM_TYPES.REAL_WORKS]: 'real-works-logo.svg',
    [FORM_TYPES.FORMS_LIVE]: 'forms-live-logo.svg',
};

const REI_FORMS_DATA = [
    {
        name: FORM_LABEL.REAL_WORKS,
        id: 'realworks',
        type: FORM_TYPES.REAL_WORKS,
        description: 'Save time and money with Realworks, the online agreements ' +
            'and forms product designed to make everyday business easier for you.',
    },
    {
        name: FORM_LABEL.REI_FORMS,
        id: 'reiForms',
        type: FORM_TYPES.REI_FORMS,
        description: 'Save time and money with REI Forms Live, the online agreements ' +
            'and forms product designed to make everyday business easier for you.',
    },
    {
        name: FORM_LABEL.FORMS_LIVE,
        id: 'formsLive',
        type: FORM_TYPES.FORMS_LIVE,
        description: 'Save time and money with Forms Live, the online agreements ' +
            'and forms product designed to make everyday business easier for you.',
    },
];

const FORM_TEMPLATES = [{
    label: 'Form',
    value: 1,
}, {
    label: 'Form from Template',
    value: 2,
}];

const UNCONNECTED_MESSAGE = 'feature is not currently connected. Click connect to use this feature';

const FORM_UNCONNECTED_MESSAGES = {
    [FORM_TYPES.REI_FORMS]: `The REI Forms Live ${UNCONNECTED_MESSAGE}`,
    [FORM_TYPES.REAL_WORKS]: `The Realworks ${UNCONNECTED_MESSAGE}`,
    [FORM_TYPES.FORMS_LIVE]: `The Forms Live ${UNCONNECTED_MESSAGE}`,
};

const CONNECTION_ERROR_MESSAGE = {
    [FORM_TYPES.REI_FORMS]: 'Your token is invalid or expired. Please check your token in the REI Forms Live application and update in Integrations',
    [FORM_TYPES.REAL_WORKS]: 'Your token is invalid or expired. Please check your token in the Realworks application and update in Integrations',
    [FORM_TYPES.FORMS_LIVE]: 'Your token is invalid or expired. Please check your token in the Forms Live application and update in Integrations',
};

const VALID_INDUSTRIES = [
    'Building Management Services',
    'Commercial Real Estate',
    'Property & Strata Management',
    'RE',
    'Real Estate',
    'Residential Real Estate',
    'Demo/Miscellaneous',
];

const STATE_MAPPING = {
    ACT: 'Australian Capital Territory',
    NSW: 'New South Wales',
    NT: 'Northern Territory',
    SA: 'South Australia',
    TAS: 'Tasmania',
    WA: 'Western Australia',
    QLD: 'Queensland',
    VIC: 'Victoria',
};

export default {
    FORM_TEMPLATES,
    REI_FORMS_DATA,
    FORM_TYPES,
    FORM_ICONS,
    FORM_LABEL,
    FORM_TYPES_STATE,
    FORM_LOGOS,
    FORM_UNCONNECTED_MESSAGES,
    CONNECTION_ERROR_MESSAGE,
    VALID_INDUSTRIES,
    FAILED_TO_CONNECT_MESSAGE,
    FAILED_TO_DISCONNECT_MESSAGE,
    STATE_MAPPING,
};
