const getDisclaimer = (state, componentErrorId, clapiDisclaimerId, errorMsg) => {
    const disclaimerErr = state.errorHandler.get('componentErrors').get(componentErrorId);
    return {
        context: !!disclaimerErr ? {
            disclaimer: errorMsg.replace('{status}', disclaimerErr.status),
            isFetching: false,
        } : state.clapi.get(clapiDisclaimerId),
        hasError: !!disclaimerErr,
    };
};

export default getDisclaimer;
