import React from 'react';
import PropTypes from 'prop-types';
import MenuItem from '@mui/material/MenuItem';
import CruxSplitButtonDropdown from '../../common/CruxSplitButtonDropdown';
import MUITooltip from '../../common/MUITooltip';
import Entitlements from '../../../helpers/Entitlements';

function SearchBarDropdown({
    searchOptions, onSelectionChange, userDetail, selected, children,
}) {
    const dropdown = React.useRef({});

    const handleOnSelect = (type, disabled, event) => {
        if (disabled) {
            event.preventDefault();
            return;
        }
        dropdown.current.close();
        onSelectionChange(type);
    };
    const getTooltipTitle = (disabled) => {
        if (disabled) {
            return Entitlements.isRolesAvailable(userDetail) ? 'This feature is not available on your subscription' :
                'This feature is not currently available please try again later';
        }
        return '';
    };
    return (
        <React.Fragment>
            <div className="address-name-form-fields">
                <CruxSplitButtonDropdown
                    className="crux-dropdown crux-dropdown--search-type-filter"
                    label={selected}
                    ref={dropdown}
                    width="auto"
                    height={165}
                >
                    {
                        searchOptions.map(({ type, disabled }) => (
                            <MUITooltip
                                key={`search-type-tooltip-${type}`}
                                title={getTooltipTitle(disabled)}
                                placement="right"
                            >
                                <MenuItem
                                    onClick={event => handleOnSelect(type, disabled, event)}
                                    className={`address-name-tab-title ${disabled ? 'disabled-dropdown' : ''}`}
                                    selected={type === selected}
                                >
                                    {type}
                                </MenuItem>
                            </MUITooltip>
                        ))
                    }
                </CruxSplitButtonDropdown>
                {children}
            </div>
        </React.Fragment>
    );
}

const SEARCH_TYPE_SHAPE = {
    type: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
};
SearchBarDropdown.propTypes = {
    searchOptions: PropTypes.arrayOf(PropTypes.shape(SEARCH_TYPE_SHAPE)),
    selected: PropTypes.oneOfType([
        PropTypes.shape(SEARCH_TYPE_SHAPE),
        PropTypes.string,
    ]),
    onSelectionChange: PropTypes.func,
};

export default SearchBarDropdown;
