import React, { useEffect, useState } from 'react';
import mapApi from '../../../../api/mapApi';

const MapOverlayImage = ({
    srcUrl, className, onImageLoad,
}) => {
    const [imgSrc, setImgSrc] = useState('');

    const checkForLayersOnTop = (thisImg, _className) => {
        thisImg.currentTarget.parentNode.className = `${_className}-container`;
    };

    useEffect(() => {
        mapApi.getMapLayerImage(srcUrl).then((res) => {
            setImgSrc(URL.createObjectURL(res));
        }).catch((error) => {
            console.log(error);
        });
    }, [srcUrl]);

    return (imgSrc && <img
        alt=""
        src={encodeURI(imgSrc)}
        className="overlay-view-image"
        onLoad={(thisImg) => {
            className && checkForLayersOnTop(thisImg, className);
            // Once the new image is loaded remove the first layer from list
            onImageLoad();
        }}
    />);
};

export default MapOverlayImage;
