import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import MenuList from '@mui/material/MenuList';
import MapTypeConstant from '../../../../../constants/mapTypeConstant';
import NearmapImg from '../../../../../../assets/img/nearMap.png';
import { clearNearmapCoverage, getNearmapCoverage } from '../../../../../actions/map';
import { DEFAULT_MAP } from '../../../../../helpers/Localization';
import MapType from './MapType';
import MUITooltip from '../../../../common/MUITooltip';
import { UNSUBSCRIBED } from '../../../../../constants/tooltipText';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';

const NearmapMapTypes = (props) => {
    const dispatch = useDispatch();
    const nearmap = props.mapTypeIdState === MapTypeConstant.NEARMAP;
    const [isNearmap, setNearmap] = useState(false);

    const handleOnClick = (event) => {
        const _checked = !nearmap;
        props.onClose(event);
        props.onMapTypeChange(_checked ? MapTypeConstant.NEARMAP : DEFAULT_MAP);
        if (_checked) {
            dispatch(getNearmapCoverage(props.bounds));
        }
        props.triggerMapViewChangeEvent(_checked ? MapTypeConstant.NEARMAP : DEFAULT_MAP);
    };
    useEffect(() => {
        if (nearmap) {
            setNearmap(true);
        } else if (isNearmap) {
            setNearmap(false);
            if (props.shouldClearCoverage) {
                dispatch(clearNearmapCoverage());
            }
        }
    }, [isNearmap, props, props.mapTypeIdState]);
    return (
        <React.Fragment>
            <hr />
            <div className="dropdown-map-type-icon">
                <img
                    alt="Nearmap"
                    data-testid="dropdown-nearmap-type"
                    src={NearmapImg}
                />
            </div>
            <MenuList>
                {
                    props.entitled ?
                        <MapType
                            onClick={handleOnClick}
                            checked={isNearmap}
                            data-testid="dropdown-nearmap-type-satellite"
                        >
                            Satellite
                        </MapType> :
                        <MUITooltip
                            key="dropdown-nearmap-type-tooltip"
                            placement="right"
                            title={UNSUBSCRIBED}
                            arrow
                        >
                            <MenuItem
                                data-testid="dropdown-nearmap-type-satellite"
                                disabled
                                sx={{
                                    height: '25px',
                                    paddingLeft: '1px',
                                    fontSize: '12px',
                                    pointerEvents: 'initial !important',
                                }}
                            >
                                <Checkbox
                                    color="default"
                                    disabled
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                                Satellite
                            </MenuItem>
                        </MUITooltip>
                }
            </MenuList>
        </React.Fragment>
    );
};

NearmapMapTypes.propTypes = {
    onClose: PropTypes.func.isRequired,
    onMapTypeChange: PropTypes.func.isRequired,
    mapTypeIdState: PropTypes.string,
    bounds: PropTypes.object,
    dispatch: PropTypes.func,
    shouldClearCoverage: PropTypes.bool,
    entitled: PropTypes.bool,
    triggerMapViewChangeEvent: PropTypes.func,
};

export default NearmapMapTypes;
