import * as BATCH_PARAMS from '../../constants/batchSearch/batchSearch';

const toOperatorFn = ({ field, value }, accum) => {
    const previousValue = accum[field] ? accum[field] : '-';
    return `${previousValue}${value}`;
};

const getIsCustomDateFromCustomFields = (customFields, selectedTab = 0) => {
    const selectedTabPresets = [
        BATCH_PARAMS.SALES_LAST_SALE_CONTRACT_DATE_PARAM,  // 0 = all preset
        BATCH_PARAMS.SALES_LAST_SALE_CONTRACT_DATE_PARAM,  // 1 = recently sold preset
        BATCH_PARAMS.SALES_LAST_CAMPAIGN_END_DATE_PARAM,   // 2 = for sale preset
        BATCH_PARAMS.RENTAL_LAST_CAMPAIGN_END_DATE_PARAM,  // 3 = for rent preset
    ];
    const statusTypePresetParam = selectedTabPresets[selectedTab];
    return [...customFields].map(item => statusTypePresetParam === item ? 'date' : item);
}

const joinStringV1Builder = ({ field, value }, accum = {}) => {
    const previousValue = accum[field] || '';
    return (`${previousValue}${previousValue !== '' ? ', ' : ''}${field === BATCH_PARAMS.TYPE_PARAM ? value.toUpperCase() : value}`);
};

const arrayToV1Builder = ({ field, value = '' }, accum = {}) => {
    const previousArray = accum[field] || [];
    return [...new Set([...previousArray, value])];
};

const keywordsBuilder = (item) =>  ({
    key: BATCH_PARAMS.KEYWORDS_PARAM,
    value: item?.filter?.filters?.map(filter => filter?.value),
});

const rangeToV1Builder = ({ field, value, operation }, accum = {}) => {
    const operatorFormats = {
        [BATCH_PARAMS.LESS_THAN_OPERATOR]: () => toOperatorFn({ field, value }, accum),
        [BATCH_PARAMS.GREATER_THAN_OPERATOR]: () => {
            const valueToReturn = value === 0 ? '' : value
            return `${valueToReturn}-`;
        },
        [BATCH_PARAMS.DOUBLE_EQUALS_OPERATOR]: () => `${value}`,
    }
    const formatAttribute = operatorFormats[operation];
    if (formatAttribute) {
        return formatAttribute();
    }
}
const defaultV1Builder = ({ value }) => value;

const dateRangeToV1Builder = ({ field, value, operation }, accum = {}) => {
    const formattedValue = value.replace(new RegExp(/-/, 'g'), '');
    const operatorFormats = {
        [BATCH_PARAMS.DATE_TO_OPERATOR]: () => toOperatorFn({ field, value: formattedValue }, accum),
        [BATCH_PARAMS.DATE_FROM_OPERATOR]: () => `${formattedValue}-`,
        [BATCH_PARAMS.DATE_ON_OPERATOR]: () => formattedValue,
    }
    const formatDate = operatorFormats[operation];
    if (formatDate) {
        return formatDate();
    }
}

export default {
    dateRangeToV1Builder,
    defaultV1Builder,
    rangeToV1Builder,
    joinStringV1Builder,
    getIsCustomDateFromCustomFields,
    arrayToV1Builder,
    keywordsBuilder,
}