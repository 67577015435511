import React, { Children } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import ScrollableContainer from 'js/components/common/ScrollableContainer';
import Colors from '../../constants/colors';

const CruxClassicModal = ({
    children, open, onToggleModal, isDisabledSubmit, submitButtonPosition, onSubmit,
    title, subTitle, isSubmitButtonDisplayed, modalClassName, disableBackdropClick,
    bodyHeight, bodyWidth, sx, isBlocking,
}) => {
    const [content, customFooter = null] = Children.toArray(children);
    return (
        <div
            data-testid="crux-classic-modal"
        >
            <Dialog
                open={open}
                onClose={(event, reason) => !disableBackdropClick && reason === 'backdropClick' && onToggleModal()}
                classes={{
                    scrollPaper: `crux-classic-modal ${modalClassName}`,
                }}
                sx={sx}
            >
                <DialogTitle
                    color="secondary"
                    className="crux-classic-modal-title tall-title"
                >
                    <span>{title}</span>
                    {
                        subTitle &&
                        <span className="sub-title" data-testid="modal-sub-title">
                            {subTitle}
                        </span>
                    }
                    <CloseIcon
                        data-testid="close-modal"
                        className="close-button-mui"
                        onClick={onToggleModal}
                        cursor="pointer"
                        sx={{
                            position: 'absolute',
                            right: '25px',
                            top: '25px',
                        }}
                    />
                </DialogTitle>
                <DialogContent sx={{ padding: '0 24px 24px' }}>
                    <ScrollableContainer height={bodyHeight} width={bodyWidth}>
                        { content }
                        <div className={`dialog-footer ${submitButtonPosition ? submitButtonPosition : ''}`}>
                            {
                                customFooter ||
                                (isSubmitButtonDisplayed && !isBlocking &&
                                <Button
                                    disabled={isDisabledSubmit}
                                    variant="contained"
                                    color="primary"
                                    onClick={!isDisabledSubmit ? onSubmit : () => {}}
                                    data-testid="submit-button"
                                    sx={{
                                        fontSize: '14px',
                                        '&.Mui-disabled': {
                                            backgroundColor: Colors.BLACK_12_OPAQUE,
                                            color: Colors.BLACK_38_OPAQUE,
                                            fontWeight: 500,

                                        }
                                    }}
                                >
                                        Export
                                </Button>)
                            }
                        </div>
                    </ScrollableContainer>
                </DialogContent>
            </Dialog>
        </div>
    );
};

CruxClassicModal.propTypes = {
    open: PropTypes.bool.isRequired,
    isDisabledSubmit: PropTypes.bool,
    onToggleModal: PropTypes.func.isRequired,
    onSubmit: PropTypes.func,
    title: PropTypes.string.isRequired,
    subTitle: PropTypes.string,
    submitButtonPosition: PropTypes.oneOf([
        'center', 'right', 'left',
    ]),
    isSubmitButtonDisplayed: PropTypes.bool,
    modalClassName: PropTypes.string,
    disableBackdropClick: PropTypes.bool,
    sx: PropTypes.object,
};

CruxClassicModal.defaultProps = {
    isSubmitButtonDisplayed: true,
    modalClassName: '',
    disableBackdropClick: false,
    bodyHeight: '100%',
    bodyWidth: 'auto',
};

export default CruxClassicModal;
