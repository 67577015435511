export const MARKET_INSIGHTS_AGREEMENT_KEY = 'market_insights_agreement';
export const PORTAL_SIDEBAR_KEY = 'portal_sidebar_viewed';
export const SEARCH_BAR_TOOLTIP_KEY = 'search_bar_tooltip_view';
const CRUX_KEY = 'crux';
const STRING_EMPTY_OBJECT = '{}';

const remove = (key) => {
    const crux = localStorage.getItem(CRUX_KEY) || STRING_EMPTY_OBJECT;
    const data = JSON.parse(crux);
    delete data[key];
    if (JSON.stringify(data) === STRING_EMPTY_OBJECT) {
        // if nothing is there anymore, remove entirely
        localStorage.removeItem(CRUX_KEY);
    } else {
        localStorage.setItem(CRUX_KEY, JSON.stringify(data));
    }
};

const add = (key, value) => {
    const crux = localStorage.getItem(CRUX_KEY) || STRING_EMPTY_OBJECT;
    const data = {
        ...(JSON.parse(crux)),
        [key]: value,
    };
    localStorage.setItem(CRUX_KEY, JSON.stringify(data));
};

const get = (key) => {
    const crux = localStorage.getItem(CRUX_KEY) || STRING_EMPTY_OBJECT;
    const data = JSON.parse(crux);
    return data[key];
};

export default {
    add,
    get,
    remove,
};
