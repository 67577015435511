import { call, put, takeLatest, select } from 'redux-saga/effects';
import { selectSelectedAccount } from '../selectors/linkedAccount';
import UserPreferences from '../api/userPreferences';
import * as REIFormsAction from '../actions/reiForms';
import ReiAPI from '../api/reiForms';

const getAllREITokensFn = () => function* (action) {
    try {
        const { selectedClAppAccountUserGuid } = yield select(selectSelectedAccount);
        const reiForms = yield call(
            UserPreferences.getAllREITokens,
            selectedClAppAccountUserGuid,
            action?.payload?.params,
        );

        yield put({
            type: REIFormsAction.GET_ALL_REI_TOKENS_SUCCESS,
            payload: reiForms,
        });

    } catch (error) {
        yield put({ type: REIFormsAction.GET_ALL_REI_TOKENS_FAIL });
    }
};
const connectReiTokenFn = () => function* (action) {
    try {
        const { selectedClAppAccountUserGuid } = yield select(selectSelectedAccount);
        const reiForms = yield call(
            UserPreferences.connectReiToken,
            selectedClAppAccountUserGuid,
            action?.payload,
        );
        const statusCode = reiForms?.statusCode || null;
        yield put(REIFormsAction.setIntegrationStatus({ connectStatus: statusCode }));
        yield put(REIFormsAction.connectionSuccess(action?.payload));
    } catch (error) {
        yield put(REIFormsAction.setIntegrationStatus({ connectStatus: error.status }));
        yield put(REIFormsAction.connectionFailed());
    }
};

const disconnectTokenFn = () => function* (action) {
    try {
        const { selectedClAppAccountUserGuid } = yield select(selectSelectedAccount);
        const reiForms = yield call(
            UserPreferences.disconnectREIToken,
            selectedClAppAccountUserGuid,
            action?.payload
        );
        const statusCode = reiForms?.statusCode || null;
        yield put(REIFormsAction.setIntegrationStatus({ disconnectStatus: statusCode }));
        yield put(REIFormsAction.disconnectSuccess(action?.payload));
    } catch (error) {
        yield put(REIFormsAction.setIntegrationStatus({ disconnectStatus: error.status }));
        yield put(REIFormsAction.disconnectFailed());
    }
};

const getFormTemplatesFn = () => function* (action) {
    try {
        const { selectedClAppAccountUserGuid } = yield select(selectSelectedAccount);
        const response = yield call(
            ReiAPI.getTemplates,
            action?.payload,
            selectedClAppAccountUserGuid,
        );
        yield put({
            type: REIFormsAction.GET_FORM_TEMPLATES_SUCCESS,
            payload: {
                [action?.payload]: response,
            },
        });
    } catch (error) {
        yield put({
            type: REIFormsAction.GET_FORM_TEMPLATES_FAIL,
            payload: action?.payload,
        });
    }
};

const getUserTemplatesFn = () => function* (action) {
    try {
        const { selectedClAppAccountUserGuid } = yield select(selectSelectedAccount);
        const response = yield call(
            ReiAPI.getUserTemplates,
            action?.payload,
            selectedClAppAccountUserGuid,
        );
        yield put({
            type: REIFormsAction.GET_USER_TEMPLATES_SUCCESS,
            payload: {
                [action?.payload]: response,
            },
        });
    } catch (error) {
        yield put({
            type: REIFormsAction.GET_USER_TEMPLATES_FAIL,
            payload: action?.payload,
        });
    }
};

export const getAllREITokens = getAllREITokensFn();

export const connectReiToken = connectReiTokenFn();

export const disconnectToken = disconnectTokenFn();

export const getFormTemplates = getFormTemplatesFn();

export const getUserTemplates = getUserTemplatesFn();

export function* getAllREITokensWatcher() {
    yield takeLatest(
        REIFormsAction.GET_ALL_REI_TOKENS,
        getAllREITokens,
    );
}

export function* connectReiTokenWatcher() {
    yield takeLatest(
        REIFormsAction.CONNECT_REI_TOKEN,
        connectReiToken,
    );
}

export function* disconnectTokenWatcher() {
    yield takeLatest(
        REIFormsAction.DISCONNECT_REI_TOKEN,
        disconnectToken,
    );
}

export function* getFormTemplatesWatcher() {
    yield takeLatest(
        REIFormsAction.GET_FORM_TEMPLATES,
        getFormTemplates,
    );
}

export function* getUserTemplatesWatcher() {
    yield takeLatest(
        REIFormsAction.GET_USER_TEMPLATES,
        getUserTemplates,
    );
}

export default [
    getAllREITokensWatcher(),
    connectReiTokenWatcher(),
    disconnectTokenWatcher(),
    getFormTemplatesWatcher(),
    getUserTemplatesWatcher(),
];