import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { MapContext } from '@react-google-maps/api';
import PropTypes from 'prop-types';
import CustomControl from './CustomControl';
import MapDropdown from './MapDropdown';
import { trackEvent } from '../../../../actions/segment';
import Segment from '../../../../helpers/Segment';
import MapTypeConstant from '../../../../constants/mapTypeConstant';
import saveAndGetUserSettings from '../../../../helpers/userPreferences/saveAndGetUserSetting';
import { SELECTED_MAP_VIEW } from '../../../../helpers/Localization';

export class CustomMapTypes extends PureComponent {
    static contextType = MapContext;
    static propTypes = {
        id: PropTypes.string.isRequired,
        isModal: PropTypes.bool.isRequired,
        dispatch: PropTypes.func.isRequired,
        propertyId: PropTypes.number.isRequired,
        onChangeMapLayer: PropTypes.func,
    }

    setMapTypeId = (mapTypeId) => {
        this.context?.setMapTypeId(mapTypeId);
    }

    triggerEvent = (mapTypeId) => {
        const {
            id, propertyId, dispatch, isModal,
        } = this.props;
        let panel;

        if (id === 'map-with-identify-popup' && !isModal) {
            panel = 'Mini Map';
        } else {
            panel = MapTypeConstant.PANEL_PROPERTIES[id];
        }

        if (mapTypeId !== MapTypeConstant.NEARMAP) {
            saveAndGetUserSettings(dispatch, {
                name: SELECTED_MAP_VIEW,
                value: MapTypeConstant.MAP_TYPES[mapTypeId].userSettingsLabel,
            });
        }
        dispatch(trackEvent(Segment.changeMapView({
            propertyId,
            panel,
            mapType: MapTypeConstant.MAP_TYPES[mapTypeId].mixpanel,
        })));
    }

    render() {
        const { onChangeMapLayer, bounds, id } = this.props;
        return (
            <CustomControl
                className="crux_map-custom-control"
                position={window.google.maps.ControlPosition.TOP_LEFT}
                style={{ position: 'relative' }}
            >
                <MapDropdown
                    id="crux-google-map-type-dropdown"
                    setMapTypeId={this.setMapTypeId}
                    onChangeMapLayer={onChangeMapLayer}
                    triggerEvent={this.triggerEvent}
                    bounds={bounds}
                    isMapPanel={id === 'map-with-identify-popup'}
                />
            </CustomControl>
        );
    }
}

export default connect()(CustomMapTypes);

