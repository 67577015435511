import { useEffect, useState } from 'react';
import { getAssetDomain } from '../../../constants/assets';
import RadiusSearchModal from '../../property/RadiusSearchModal';
import StyledMenuButton from '../../common/styledMUI/StyledMenuButton';
import Commons from '../../../helpers/Commons';
import MapHelper from '../../../helpers/MapHelper';
import Entitlements from '../../../helpers/Entitlements';
import TOOLTIP from '../../../constants/tooltipText';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';

const RadiusSearchButton = ({
    rapidV3Response,
    dispatch,
    route,
    propertyCommons,
    targetProperty,
    clapiUsrDetail,
    isPropertyExist,
    match = {},
}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [coordinates, setCoordinates] = useState({});
    const [tooltipMessage, setTooltipMessage] = useState('');
    const [isDisabledRadiusSearch, setIsDisabledRadiusSearch] = useState(false);

    useEffect(() => {
        setIsModalOpen(false);
    }, [match.url])

    useEffect(() => {
        if (!Entitlements.hasRadiusSearchRole(clapiUsrDetail)) {
            if (tooltipMessage !== TOOLTIP.TEXT.MENU.UNSUBSCRIBED) {
                setTooltipMessage(TOOLTIP.TEXT.MENU.UNSUBSCRIBED)
            }
        } else if (!isPropertyExist) {
            if (tooltipMessage !== TOOLTIP.TEXT.MENU.UNAVAILABLE) {
                setTooltipMessage(TOOLTIP.TEXT.MENU.UNAVAILABLE);
            }
        } else {
            setTooltipMessage('');
        }
    }, [isPropertyExist, clapiUsrDetail]);

    useEffect(() => {
        if (!Entitlements.hasRadiusSearchRole(clapiUsrDetail)) {
            setIsDisabledRadiusSearch(true);
        } else if (!targetProperty?.value || targetProperty?.isFetching) {
            setIsDisabledRadiusSearch(true);
        } else {
            setIsDisabledRadiusSearch(false);
        }
    }, [clapiUsrDetail, targetProperty])

    const onSubmitRadiusSearch = (callback) => {
        const address = Commons.get(propertyCommons, 'propertyCommons.location.singleLine');
        MapHelper.getCoordinateFromGeocoder(address, (coordinate) => {
            if (coordinate) {
                setCoordinates(coordinate)
            }
            callback();
        });
    };

    return (
        <div
            data-tip={tooltipMessage}
            data-for="radius-search-modal-button-tooltip"
            role="tooltip"
        >
            <RadiusSearchModal
                property={rapidV3Response}
                dispatch={dispatch}
                page={route.pageContext}
                coordinates={coordinates}
                onSubmit={onSubmitRadiusSearch}
                isRadiusSearchModalOpen={isModalOpen}
                onCloseRadiusSearchModal={() =>
                    setIsModalOpen(false)}
                setPropertyCoordinateOnMarkerUpdate={setCoordinates}
            >
                <StyledMenuButton
                    id="radius-search-modal-button"
                    variant="contained"
                    color="secondary"
                    disabled={isDisabledRadiusSearch}
                    onClick={() => `${!isPropertyExist ? '' : setIsModalOpen(true)}`}
                    sx={{  marginLeft: '8px' }}
                    startIcon={<img
                        src={getAssetDomain('radius-search-icon.png')}
                        alt=""
                    />}
                >
                    <span>Radius Search</span>
                </StyledMenuButton>
            </RadiusSearchModal>
            <ReactTooltip
                id="radius-search-modal-button-tooltip"
                className="custom-tool-tip custom-tooltip-disabled tooltip-text"
                place="bottom"
                effect="solid"
                event="mouseover"
                eventOff="mouseleave"
            />
        </div>
    );
};

RadiusSearchButton.propTypes = {
    rapidV3Response: PropTypes.object,
    dispatch: PropTypes.func,
    route: PropTypes.object,
    propertyCommons: PropTypes.object,
    targetProperty: PropTypes.object,
    clapiUsrDetail: PropTypes.object,
    isPropertyExist: PropTypes.bool,
    match: PropTypes.object,
};

export default RadiusSearchButton;
