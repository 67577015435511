/**
 * Convert data URI to blob
 * @param dataURI
 * @returns {Blob}
 */
const dataURItoBlob = (dataURI) => {
    const byteString = atob(dataURI.split(',')[1]);
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i += 1) {
        ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ab], { type: mimeString });
};

const downloadBlob = (blob, filename, link) => {
    try {
        const a = link || document.createElement('a');
        a.href = URL.createObjectURL(blob);
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    } catch (e) {
        console.error(e);
    }
};

/**
 * Download file
 * @param uri
 * @param filename
 */
const downloadFromUri = (uri, filename) => {
    try {
        const link = document.createElement('a');
        if (typeof link.download === 'string') {
            const blob = dataURItoBlob(uri);
            downloadBlob(blob, filename, link);
        } else {
            window.open(uri);
        }
    } catch (e) {
        console.error(e);
    }
};

const isFileSizeExceeded = (file, maxFileSize) => {
    const fileSizeInKB = file.size / 1000;
    return fileSizeInKB > maxFileSize;
};

const downloadPdfFromBase64 = (base64String, filename) => {
    try {
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.style.cssText = 'display: none';
        a.href = `data:application/pdf;base64,${base64String}`;
        a.download = filename;
        a.click();
        document.body.removeChild(a);
    } catch (e) {
        console.error(e);
    }
};

export default {
    dataURItoBlob,
    downloadFromUri,
    downloadBlob,
    isFileSizeExceeded,
    downloadPdfFromBase64,
};
