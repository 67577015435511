const sanitize = (url) => {
    const cleanUrl = url.replace(/--/g, '-');
    if (url.indexOf('--') >= 0) {
        return sanitize(cleanUrl);
    }
    return cleanUrl;
};

const clean = (url) => {
    let cleanUrl;
    if (url) {
        cleanUrl = url
            .toString()
            .toLowerCase()
            .replace(/[ /]/g, '-')
            .replace(/,/g, '');

        return sanitize(cleanUrl);
    }
    return '';
};

const cleanByPlus = (url) => {
    let cleanUrl;
    if (url) {
        cleanUrl = url
            .toString()
            .split(' ')
            .map(s => encodeURIComponent(s))
            .join('+');

        do {
            cleanUrl.replace('++', '+');
        } while (cleanUrl.includes('++'));

        return cleanUrl;
    }
    return '';
};

export default {
    clean,
    cleanByPlus,
};
