import mapApi from '../../../api/mapApi';

export default class NearmapCustomMapType {
    tileSize;
    surveyId;
    constructor(tileSize, surveyId) {
        this.tileSize = tileSize;
        this.surveyId = surveyId;
    }
    getTile(coord, zoom, ownerDocument) {
        const div = ownerDocument.createElement('img');

        let url;
        if (this.surveyId) {
            url = `/tiles/${this.surveyId}/${zoom}/${coord.x}/${coord.y}`;
        } else {
            url = `/tiles/${zoom}/${coord.x}/${coord.y}`;
        }
        this.getMapTileImageWithRetry(5, url, div);
        return div;
    }

    getMapTileImageWithRetry(retryCount, url, div) {
        mapApi.getMapTileImage(url).then((res) => {
            div.src = encodeURI(URL.createObjectURL(res));
        }).catch((error) => {
            if (retryCount > 0) {
                return this.getMapTileImageWithRetry(retryCount - 1, url, div);
            }
                console.log(`Error fetching nearmap tile: ${error}`)
        });
    }
}
