import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress } from '@mui/material';
import { getAssetDomain } from '../../constants/assets';
import Tooltip from '../common/Tooltip';
import { UNSUBSCRIBED } from '../../constants/tooltipText';

const ReportIcon = ({
    onClick, reportClass, asset,
    upperTitle, lowerTitle, disabled,
    isLoading,
}) => (
    <div
        className={`report-trigger${disabled ? ' disabled' : ''} ${reportClass}${isLoading ? ' is-loading-report' : ''}`}
        onClick={disabled ? () => {/* do nothing */} : onClick}
        role="presentation"
    >
        <img src={getAssetDomain(asset)} className="img-icon" alt="print-icon" />
        <span>
            { upperTitle }
            <br />
            { lowerTitle }
        </span>
        {
            isLoading &&
            <CircularProgress
                size={20}
                thickness={5}
                className="report-loader"
            />
        }
    </div>
);

// Tooltip is only intended for displaying message for user without entitlements
// We can always extend this tooltip to other scenarios, but for now, it is only for
// disabled report without entitlements
const ReportIconWithTooltip = (props) => {
    if (props.disabled || props.entitled === false) { // do not use falsy check for entitled props
        const tooltipTxt = props.tooltipTxt || UNSUBSCRIBED;
        const newProps = {
            ...props,
            disabled: true,
        };
        return (
            <Tooltip
                tooltipTxt={tooltipTxt}
                tooltipId={props.tooltipId}
                tooltipClass="custom-tool-tip tooltip-text"
                tooltipPosition="bottom"
            >
                <ReportIcon {...newProps} />
            </Tooltip>
        );
    }
    return (<ReportIcon {...props} />);
};

ReportIcon.propTypes = {
    onClick: PropTypes.func,
    reportClass: PropTypes.string,
    asset: PropTypes.string,
    upperTitle: PropTypes.string,
    lowerTitle: PropTypes.string,
    disabled: PropTypes.bool,
    isLoading: PropTypes.bool,
    entitled: PropTypes.bool,
};

ReportIcon.defaultProps = {
    onClick: null,
};
ReportIconWithTooltip.displayName = 'ReportIcon';
export default ReportIconWithTooltip;
