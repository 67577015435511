import React, { forwardRef, useState, useImperativeHandle, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';

// Components
import RPDSidebarToggle from './RPDSidebarToggle';

const RPDSidebar = forwardRef(({
    children,
    headerOffsetTop = 0,
    isOpen,
}, ref) => {
    const [isActivePane, setIsActivePane] = useState(false);

    const hasCustomTrigger = isOpen !== undefined;
    const toggleState = hasCustomTrigger ? isOpen : isActivePane;
    useImperativeHandle(ref, () => ({
        setActivePane: bool => setIsActivePane(bool),
    }));

    const handleOutsideClick = (e) => {
        // ignore clicks on the modal component itself
        const path = e.path || (e.composedPath && e.composedPath());
        const res = path && path.find(x => x.id === 'rpd-sidebar');

        if ((RPDSidebar.node && RPDSidebar.node.contains(e.target)) || res) {
            return;
        }
        setIsActivePane(false);
        document.removeEventListener('click', handleOutsideClick, false);
    };

    useLayoutEffect(() => {
        if (isActivePane) {
            // attach/remove event handler
            document.addEventListener('click', handleOutsideClick, false);
        }
    }, [isActivePane]);

    return (
        <div id="rpd-sidebar">
            {
                !hasCustomTrigger &&
                    <RPDSidebarToggle
                        setIsActivePane={setIsActivePane}
                    />
            }
            <div
                id="rpd-slider-pane"
                style={{
                    height: window.innerHeight - headerOffsetTop,
                    top: headerOffsetTop,
                    minHeight: 768,
                }}
                className={`${toggleState ? 'active' : 'inactive'}`}
            >
                <div id="rpd-redirection-portal">
                    { children }
                </div>
            </div>
        </div>
    );
});

RPDSidebar.propTypes = {
    headerOffsetTop: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
    ]),
    isOpen: PropTypes.bool,
};

RPDSidebar.displayName = 'RPDSidebar';

export default RPDSidebar;
