/* eslint-disable no-undef */
import { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import { DialogContent, DialogTitle } from '@mui/material';
import { Marker } from '@react-google-maps/api';
import CloseIcon from '@mui/icons-material/Close';

import Commons from '../../helpers/Commons';
import Checkbox from '../common/Checkbox';
import ComposedCruxMap from './map/CruxMap';
import MapHelper from '../../helpers/MapHelper';
import { getAssetDomain } from '../../constants/assets';
import { MAP_MARKER_IMAGE_NAME } from '../../constants/map';
import CruxMapCoordinates from '../../model/CruxMapCoordinates';
import { isAU } from '../../constants/crux';

const MAP_OPTIONS = {
    mapTypeControl: false,
    streetViewControl: false,
};

export default class RadiusPickerModal extends PureComponent {
    static propTypes = {
        address: PropTypes.string,
        open: PropTypes.bool.isRequired,
        resetCoordinates: PropTypes.func,
        onClose: PropTypes.func.isRequired,
        triggerSearch: PropTypes.func,
        setPropertyCoordinateOnMarkerUpdate: PropTypes.func.isRequired,
        isHistoric: PropTypes.bool,
    };

    constructor(props) {
        super(props);

        this.state = {
            isUpdateCorelogic: false,
            selectedCoordinate: {},
        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const { coordinates } = nextProps;
        const { selectedCoordinate } = prevState;
        const nextState = {};

        if (coordinates && selectedCoordinate && Commons.isObjectEmpty(selectedCoordinate)) {
            nextState.selectedCoordinate = coordinates;
        }

        return nextState;
    }

    /**
     * This will update the marker location
     * @param event - google event
     */
    onMarkerUpdate = (event) => {
        try {
            const { latLng } = event;
            const { lat, lng } = latLng.toJSON();
            const selectedCoordinate = { lat, lng };

            this.setState({ selectedCoordinate });
            this.props.setPropertyCoordinateOnMarkerUpdate(selectedCoordinate);
        } catch (err) {
            // err
        }
    };

    /**
     * Close modal and reset form values
     */
    onClose = () => {
        const { onClose } = this.props;

        this.setState({ selectedCoordinate: {}, isUpdateCorelogic: false });
        if (onClose) onClose(false);
        this.props.resetCoordinates();
    };

    /**
     * On submit
     * do close only for now
     */
    onSubmit = () => {
        this.onClose();
        this.props.triggerSearch(this.state.isUpdateCorelogic);
    };

    /**
     * Create icon props
     * @returns
     */
    getMarkerIcon() {
        try {
            if (window.google) {
                return {
                    url: getAssetDomain(MAP_MARKER_IMAGE_NAME.TARGET_PROPERTY_ACTIVE_PIN),
                    size: new google.maps.Size(45, 45),
                    scaledSize: new google.maps.Size(45, 45),
                };
            }
        } catch (err) {
            // err
        }

        return {};
    }

    /**
     * Toggle Update Corelogic checkbox
     */
    toggleUpdateCorelogic = () => {
        this.setState(state => ({ isUpdateCorelogic: !state.isUpdateCorelogic }));
    };

    render() {
        const {
            address, open, isHistoric,
        } = this.props;
        const { selectedCoordinate, isUpdateCorelogic } = this.state;
        const cruxCoordinates = new CruxMapCoordinates({
            latitude: selectedCoordinate.lat,
            longitude: selectedCoordinate.lng,
        });
        return (
            <Fragment>
                <Dialog
                    open={open}
                    className="radius-picker-modal"
                    onClose={this.onClose}
                    maxWidth={false}
                >
                    <DialogTitle className="title">
                        <span>Update property location</span>
                        <div role="presentation" className="close-icon" onClick={this.onClose}>
                            <CloseIcon className="close-button-mui" cursor="pointer" />
                        </div>
                    </DialogTitle>
                    <DialogContent className="content">
                        <p>
                            Property address could not be located. Please confirm the location of the address by placing a pin on the map below.
                        </p>
                        <p className="address">{address}</p>
                        <ComposedCruxMap
                            propertyCoordinates={cruxCoordinates}
                            hideStreetViewButton
                            streetViewVisible={false}
                            additionalOptions={MAP_OPTIONS}
                            onClick={this.onMarkerUpdate}
                        >
                            <Marker
                                position={selectedCoordinate}
                                icon={this.getMarkerIcon()}
                                draggable
                                onDragEnd={this.onMarkerUpdate}
                            />
                        </ComposedCruxMap>
                        {
                            MapHelper.hasGoogleMap() ?
                                <div className="footer">
                                    <Checkbox
                                        checked={isUpdateCorelogic}
                                        label="Update Corelogic"
                                        id="update-corelogic"
                                        onChange={this.toggleUpdateCorelogic}
                                        customClass={isAU && isHistoric ? 'hidden' : ''}
                                    />
                                    <Button
                                        onClick={this.onSubmit}
                                        className="button-primary"
                                    >
                                        <span>Confirm</span>
                                    </Button>
                                </div> : null
                        }
                    </DialogContent>
                </Dialog>
            </Fragment>
        );
    }
}
