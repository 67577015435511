import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import CampaignIcon from '@mui/icons-material/Campaign';
import React, { useState } from 'react';
import { CircularProgress } from '@mui/material';

import StyledMenuButton from 'js/components/common/styledMUI/StyledMenuButton';
import plezzelApi from 'js/api/plezzelApi';
import { trackEvent } from 'js/actions/segment';
import { createMixpanelProperties } from 'js/helpers/Segment';
import Segment from 'js/constants/segment';
import CruxSnackBar from 'js/components/common/CruxSnackBar';
import ValidationStatus from 'js/constants/validationStatus';

const PlezzelButton = ({
    propertyId,
}) => {
    const [fetching, setFetching] = useState(false);
    const [errorSnackBar, setErrorSnackBar] = useState(false);
    const featureFlag = useSelector(state => state.launchDarkly.get('featureFlag')['crux.plezzel.ads']);
    const dispatch = useDispatch();

    if (!featureFlag) {
        return null;
    }

    const handleClick = () => {
        setFetching(true);
        dispatch(trackEvent(createMixpanelProperties(Segment.EVENT_NAMES.LAUNCH_PLEZZEL, {
            page: Segment.EVENT_PROPERTIES.PROPERTY_DETAIL,
        })));
        plezzelApi.getPlezzelOrderForm(propertyId)
            .then((response) => {
                if (response.url) {
                    window.open(response.url, '_blank');
                }
            })
            .catch(() => {
                setErrorSnackBar(true);
            })
            .finally(() => {
                setFetching(false);
            });
    }

    return (
        <>
            <StyledMenuButton
                variant="contained"
                color="secondary"
                onClick={handleClick}
                startIcon={<CampaignIcon/>}
            >
                Advertise
                {
                    fetching &&
                    <div style={{
                        width: '100%',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: 'rgba(254, 254, 254, 0.6)',
                    }}>
                        <CircularProgress
                            size={20}
                            thickness={5}
                        />
                    </div>
                }
            </StyledMenuButton>
            <CruxSnackBar
                open={errorSnackBar}
                onClose={() => setErrorSnackBar(false)}
                severity={ValidationStatus.ERROR}
                message="Sorry, there was an error connecting. Please refresh the page to try again."
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                sx={{
                    zIndex: 9999,
                }}
            />
        </>
    );
};

PlezzelButton.propTypes = {
    propertyId: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
};

export default PlezzelButton;