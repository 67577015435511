/* eslint-disable no-undef */
import { PureComponent, Fragment } from 'react';
import { GoogleMap, MapContext } from '@react-google-maps/api';
import PropTypes from 'prop-types';
import HomeButton from './controls/HomeButton';
import { trackHomeOnMap } from '../../../actions/segment';
import { MAP_DISPLAY, LAYER_NZ } from '../../../constants/map';
import withPanelErrorHandler from '../../higherOrderComponents/WithPanelErrorHandler';
import CruxMapCoordinates from '../../../model/CruxMapCoordinates';
import CustomMapTypes from './controls/CustomMapTypes';
import BoundingBoxLayer from './layer/BoundingBoxLayer';
import NearmapTileLayer from './NearmapTileLayer';
import { MapTypeContext } from '../../context/MapTypeContext';
import MapTypeConstant from '../../../constants/mapTypeConstant';
import '../../../../css/crux/components/property/map/map.scss';

class CruxMap extends PureComponent {
    static contextType = MapContext;
    static propTypes = {
        propertyCoordinates: PropTypes.instanceOf(CruxMapCoordinates).isRequired,
        property: PropTypes.object,
        identifyMarker: PropTypes.object,
        gestureHandling: PropTypes.string,
        onClick: PropTypes.func,
        showHomeButton: PropTypes.bool,
        pageContext: PropTypes.string,
        dispatch: PropTypes.func,
        onReady: PropTypes.func,
        drawingMode: PropTypes.string,
        updateOverlay: PropTypes.func,
        overlayViews: PropTypes.array,
        updateOverlayView: PropTypes.func,
        onChangeMapType: PropTypes.func,
        onBoundsChange: PropTypes.func,
        onZoomChange: PropTypes.func,
        isLoaded: PropTypes.func,
        onIdle: PropTypes.func,
        additionalOptions: PropTypes.object,
        bounds: PropTypes.object,
    };

    static defaultProps = {
        showHomeButton: false,
    };

    constructor(props) {
        super(props);
        this.state = {
            baseMapLayer: undefined,
            zoom: 18,
            pristine: true,
            reRenderMaplayer: null,
            mapInstance: null,
        };
    }

    setPristine = () => {
        if (this.state.pristine) {
            this.setState({
                pristine: false,
            });
        }
    }

    getUpdatedBounds = () => {
        const { onBoundsChange } = this.props;
        this.setPristine();
        const map = this.state.mapInstance;
        if (map) {
            const center = map.getCenter();
            map.panTo(center);
        }
        onBoundsChange && onBoundsChange(map);
    }

    handleOnTilesLoaded = () => {
        this.props.isLoaded && this.props.isLoaded();
    };

    handleOnZoomChanged = () => {
        const map = this.state.mapInstance;
        if (map) {
            this.setState({ zoom: map.getZoom() });
        }
        this.getUpdatedBounds();
        this.props.onZoomChange && this.props.onZoomChange(map);
    };

    handleOnMapLoad = (mapInstance) => {
        this.setState({
            mapInstance,
        });
    };

    getInitialBounds = () => {
        if (this.state.mapInstance && !this.props.bounds) {
            this.getUpdatedBounds();
            this.props.onReady && this.props.onReady(this.state.mapInstance);
        }
    }

    onHomeClick = () => {
        const {
            onClick,
            propertyCoordinates,
            property,
            pageContext,
            dispatch,
            onBoundsChange,
        } = this.props;
        this.state.mapInstance?.panTo(propertyCoordinates);
        onClick(); // to hide bubble
        onBoundsChange && onBoundsChange(this.state.mapInstance);
        dispatch(trackHomeOnMap({
            pageContext,
            propertyId: property.propertyId,
            panel: MAP_DISPLAY.MODAL,
        }));
    };

    onMapClick = (e) => {
        const {
            onClick,
            onBoundsChange,
        } = this.props;
        onBoundsChange && onBoundsChange(this.state.mapInstance);
        onClick(e);
    }

    onMapTypeIdChanged = () => {
        const { reRenderMaplayer, mapInstance } = this.state;
        const { onChangeMapType } = this.props;

        if (mapInstance && onChangeMapType) {
            try {
                onChangeMapType(mapInstance.getMapTypeId());
            } catch (err) {
                console.log('Error from CruxMap :', err);
            }
        }
        this.setState({ reRenderMaplayer: !reRenderMaplayer });
    };

    onChangeMapLayer = (baseMapLayer) => {
        this.setState({ baseMapLayer: baseMapLayer });
    };

    getOptions() {
        const {
            activePropertyMarker,
            gestureHandling, identifyMarker, additionalOptions = {},
        } = this.props;
        const { baseMapLayer } = this.state;
        // gestureHandling 'cooperative' means it will have the ctrl+scroll before zoom.
        // setting the scrollwheel to false when there is an activePropertyMarker will
        // prevent the ctrl+scroll on showing in the map.
        // setting the scrollwheel to null and not true will
        // set it back to expected behavior (gestureHandling 'cooperative').
        const options = {
            ...additionalOptions,
            fullscreenControl: false,
            mapTypeControl: false,
            gestureHandling: gestureHandling || 'cooperative',
            scrollwheel: null,
            clickableIcons: false,
            styles: [
                {
                    featureType: "poi.business",
                    elementType: "labels",
                    stylers:
                        [
                            {
                                visibility: "off"
                            }
                        ]
                }
            ]
        };

        if (activePropertyMarker || identifyMarker) {
            options.scrollwheel = false;
        }

        if (baseMapLayer) {
            // to prevent 500 error from esri base map api
            const defaultZoom = 19;
            options.maxZoom = baseMapLayer.id.startsWith('nearmap') ? 21 : defaultZoom;
        } else {
            options.maxZoom = undefined;
        }

        return options;
    }

    renderCorelogicHybridMap() {
        const {
            zoom,
            baseMapLayer,
        } = this.state;

        const {
            bounds,
        } = this.props;

        const { CORELOGIC_SATELLITE } = MapTypeConstant;
        const corelogicSatelliteLayer =
            LAYER_NZ.baseMap.find(layer => layer.id === CORELOGIC_SATELLITE);
        // when corelogic-hybrid is selected, render both satellite and hybrid layer
        return (
            <Fragment>
                <BoundingBoxLayer
                    request={corelogicSatelliteLayer.request}
                    bounds={bounds}
                    layerName={corelogicSatelliteLayer.id}
                    layerCategory={corelogicSatelliteLayer.category}
                    zoom={zoom}
                />
                <BoundingBoxLayer
                    request={baseMapLayer.request}
                    bounds={bounds}
                    layerName={baseMapLayer.id}
                    layerCategory={baseMapLayer.category}
                    zoom={zoom}
                />
            </Fragment>
        );
    }
    handleOnIdle = () => {
        this.getInitialBounds();
        this.props.onIdle && this.props.onIdle();
    }
    render() {
        const {
            zoom,
            baseMapLayer,
        } = this.state;
        const {
            children,
            propertyCoordinates,
            showHomeButton,
            bounds,
            id,
            dispatch,
            property,
        } = this.props;

        const {
            CORELOGIC_HYBRID,
            CORELOGIC_SATELLITE,
            CORELOGIC_ROAD,
        } = MapTypeConstant;

        if (!propertyCoordinates || !propertyCoordinates.hasCoordinates()) {
            return (
                <div className="no-map-available">
                    No Map Available
                </div>
            );
        }
        return (
            <GoogleMap
                key="crux-map"
                id="map"
                mapContainerClassName="map-container"
                center={this.state.pristine && propertyCoordinates}
                zoom={zoom}
                options={this.getOptions()}
                tilt={0}
                onClick={this.onMapClick}
                onMapTypeIdChanged={this.onMapTypeIdChanged}
                onDragEnd={this.getUpdatedBounds}
                onZoomChanged={this.handleOnZoomChanged}
                onTilesLoaded={this.handleOnTilesLoaded}
                onLoad={this.handleOnMapLoad}
                onIdle={this.handleOnIdle}
            >
                { children }
                {
                    baseMapLayer && baseMapLayer.id.startsWith('nearmap') &&
                    <MapTypeContext.Consumer>{
                        ({ contextMapTypeId }) =>
                            <NearmapTileLayer
                                mapTypeId={contextMapTypeId}
                                bounds={bounds}
                                zoom={zoom}
                            />
                    }
                    </MapTypeContext.Consumer>
                }
                {
                    baseMapLayer && (baseMapLayer.id.startsWith('esri')
                        || baseMapLayer.id === CORELOGIC_SATELLITE
                        || baseMapLayer.id === CORELOGIC_ROAD) &&
                        <BoundingBoxLayer
                            request={baseMapLayer.request}
                            bounds={bounds}
                            layerName={baseMapLayer.id}
                            layerCategory={baseMapLayer.category}
                            zoom={zoom}
                        />
                }
                {
                    baseMapLayer && baseMapLayer.id === CORELOGIC_HYBRID &&
                    this.renderCorelogicHybridMap()
                }
                {
                    id &&
                    <CustomMapTypes
                        key="crux-custom-control"
                        id={id}
                        isModal={showHomeButton}
                        dispatch={dispatch}
                        propertyId={property && property.propertyId}
                        onChangeMapLayer={this.onChangeMapLayer}
                        bounds={bounds}
                    />
                }
                {
                    showHomeButton &&
                    <HomeButton onHomeClick={this.onHomeClick} />
                }
            </GoogleMap>
        );
    }
}

export const ComposedCruxMap = withPanelErrorHandler(CruxMap);
ComposedCruxMap.displayName = 'CruxMap';

export default ComposedCruxMap;
