const selectLinkedAccounts = ({ linkedAccounts }) => ({
    listedAccount: linkedAccounts.get('listedAccount'),
    selectedAccount: linkedAccounts.get('selectedAccount'),
    isMultipleAccounts: linkedAccounts.get('listedAccount').length > 1,
    tncPermanentlyAccepted: linkedAccounts.get('tncPermanentlyAccepted'),
    initializeStatus: linkedAccounts.get('initializeStatus'),
});
export const selectSelectedAccount = ({ linkedAccounts }) => ({
    selectedAccount: linkedAccounts.get('selectedAccount'),
    selectedClAppAccountUserGuid: linkedAccounts.get('selectedClAppAccountUserGuid'),
});


export default selectLinkedAccounts;
