import { createStore } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import { persistConfig } from './localStoragePersist';
import Middlewares, { sagaMiddleware } from './middlewares';
import Reducers from '../reducers';
import Sagas from '../sagas';

const createCruxStore = () => {
    const _store = createStore(
        persistReducer(persistConfig, Reducers),
        Middlewares,
    );
    sagaMiddleware.run(Sagas);
    const localStoragePersistor = persistStore(_store);
    return {
        store: _store,
        localStoragePersistor,
    };
};

const cruxStore = createCruxStore();

export default cruxStore;

