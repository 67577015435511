import { Map as IMap } from 'immutable';
import { REHYDRATE } from 'redux-persist';
import {
    PUBLIC_CONFIGURATION_INIT,
    PUBLIC_CONFIGURATION_INIT_SUCCESS,
    PUBLIC_CONFIGURATION_INIT_FAIL,
    CONFIG_INIT,
    CONFIG_INIT_FAIL,
    CONFIG_INIT_SUCCESS,
    BLANK_ACTION,
} from '../actions/config';

export const initialState = IMap({
    launchDarkly: null,
    segment: null,
    googleMapsApi: null,
    googleAnalytics: null,
    isInitialized: false,
    isPublicConfigurationInitialized: false,
    isPublicConfigurationInitializedSuccess: false,
    isInitializedGoogleAnalytics: false,
});

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case CONFIG_INIT:
            return state.merge(IMap({
                isInitialized: true,
                hash: action.payload.hash,
            }));
        case CONFIG_INIT_FAIL:
            return state.merge(IMap({
                isInitialized: false,
            }));
        case CONFIG_INIT_SUCCESS:
            return state.merge(IMap({
                ...action.payload,
            }));
        case PUBLIC_CONFIGURATION_INIT:
            return state.merge(IMap({
                isPublicConfigurationInitialized: true,
                hash: action.payload.hash,
            }));
        case PUBLIC_CONFIGURATION_INIT_FAIL:
            return state.merge(IMap({
                isPublicConfigurationInitialized: false,
                isPublicConfigurationInitializedSuccess: false,
            }));
        case PUBLIC_CONFIGURATION_INIT_SUCCESS:
            return state.merge(IMap({
                ...action.payload,
                isPublicConfigurationInitializedSuccess: true,
            }));
        case REHYDRATE:
            if (action.payload) {
                return state.merge(action.payload.config);
            }
            return state.merge(initialState);
        case BLANK_ACTION:
            return state;
        default:
            return state;
    }
}
