import { ReactComponent as Pan } from '../../assets/svg/map/pan.svg';
import { ReactComponent as Delete } from '../../assets/svg/map/delete-all.svg';
import { ReactComponent as Download } from '../../assets/svg/map/download.svg';
import { ReactComponent as Layers } from '../../assets/svg/map/layers.svg';
import { ReactComponent as MeasureArea } from '../../assets/svg/map/measure-area.svg';
import { ReactComponent as MeasureDistance } from '../../assets/svg/map/measure-distance.svg';
import { ReactComponent as GoogleLogo } from '../../assets/svg/map/google-logo.svg';
import { ReactComponent as GoogleIcon } from '../../assets/svg/map/google-icon.svg';
import { ReactComponent as EsriLabel } from '../../assets/svg/map/esri-label.svg';
import { ReactComponent as EsriIcon } from '../../assets/svg/map/esri-icon.svg';
import { ReactComponent as CorelogicIcon } from '../../assets/svg/map/Corelogic-logo-mark.svg';
import { ReactComponent as PolygonIcon } from '../../assets/svg/map/polygon.svg';
import Nearmap from '../../assets/img/nearMap.png';

export default {
    Pan,
    Delete,
    Download,
    Layers,
    MeasureArea,
    MeasureDistance,
    GoogleLogo,
    GoogleIcon,
    EsriLabel,
    EsriIcon,
    CorelogicIcon,
    Nearmap,
    PolygonIcon,
};
