import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '../../../../constants/tooltipText';
import CruxCheckbox from '../../../common/CruxCheckbox';

const IncludeHistoricCheckbox = ({
    checked,
    onChange,
    customClass,
    hasTooltip,
}) => (<CruxCheckbox
    checked={checked}
    label="Include historical properties"
    tooltip={hasTooltip && Tooltip.TEXT.HISTORIC}
    tooltipId="historic-properties"
    id="historic-properties-checkbox"
    onChange={onChange}
    customClass={customClass}
/>);

IncludeHistoricCheckbox.propTypes = {
    checked: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    customClass: PropTypes.string,
    hasTooltip: PropTypes.bool,
};

export default IncludeHistoricCheckbox;
