const RSS_ID_AU = 'BqyNCOhnklU7ryKf';
const RSS_ID_NZ = 'PG7sKT4MtkjD0MXa';

const STYLE = '.rssapp-list { max-width: 950px !important; }' +
    '.rssapp-list-title, .rssapp-list-card .list-card-title, .rssapp-list-card .list-card-description { font-family: "Poppins", sans-serif; } ' +
    '.rssapp-list-card .list-card-title { font-weight: 500; } ' +
    '.rssapp-feed-card-container .rssapp-image-container { width: 120px; height: 80px; } ' +
    '.rssapp-feed-card-container img { object-fit: cover; height: auto; width: 120px; border: none; border-radius: 0; margin-top: 5px; } ' +
    '.rssapp-feed-card-container { background-color: transparent; border: none; border-radius: 0; height: auto !important; margin-bottom: 0; } ' +
    '.rssapp-feed-card-container + .rssapp-feed-card-container { border-top: 1px solid #949494; padding-top: 10px; } ' +
    '.rssapp-feed-card-container h3 { margin-top: 0px; opacity: 0.79; } ' +
    '.rssapp-feed-card-container h3 a { color: white !important; } ' +
    '.rssapp-feed-card-container .rssapp-summary-container { min-height: 100px; height: auto; } ' +
    '.rssapp-list-container { padding: 0px !important } ' +
    '.rssapp-feed-card-container .rssapp-summary-container .rssapp-footer { display: none; }' +
    '.rssapp-edge .rssapp-image-container img {width : 120px; min-width: 120px; }';

const RSS_UNAVAILABLE = 'Sorry, there was an error fetching news feed data. Please refresh the page to try again.';

export default {
    STYLE,
    RSS_UNAVAILABLE,
    RSS_ID_AU,
    RSS_ID_NZ,
};
