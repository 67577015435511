import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import useDisclaimer from '../../../hooks/useDisclaimer';
import GetDisclaimer from '../../../selectors/getDisclaimer';
import {
    GET_VALUATION_DISCLAIMER_FAIL,
    getValuationDisclaimer
} from '../../../actions/clapi';
import { FOOTER } from '../../../helpers/Localization';
import Commons from '../../../helpers/Commons';

export const ValuationDisclaimer = (props) => {
    const { valuationDisclaimer, dispatch } = props;
    const action = useMemo(() =>
        !Commons.get(valuationDisclaimer, 'context') &&
        (getValuationDisclaimer('disclaimers', FOOTER.disclaimers.valuation)
    ), []);
    const disclaimer = useDisclaimer(valuationDisclaimer,
        action,
        dispatch,
    );

    const disclaimerText = Commons.get(disclaimer, 'context.disclaimer');
    const cleanDisclaimer = useMemo(() => (
        Commons.purifyDomElement(disclaimerText)
    ), [disclaimer]);
    return (
        disclaimer.context &&
        <p className="valuation-disclaimer" dangerouslySetInnerHTML={{ __html: `${cleanDisclaimer}` }} />
    )
};

const ConnectedValuationDislaimer = connect((state) => ({
    valuationDisclaimer: GetDisclaimer(state,
        GET_VALUATION_DISCLAIMER_FAIL,
        'valuationDisclaimer',
        'Unable to fetch Valuation disclaimer ({status})'
    ),
}))(ValuationDisclaimer);
ConnectedValuationDislaimer.displayName = 'ValuationDisclaimer';

export default ConnectedValuationDislaimer;
