if (!Array.prototype.at) {
    // eslint-disable-next-line no-extend-native
    Array.prototype.at = function(index) {
        // Convert the index to an integer
        index = Math.trunc(index) || 0;

        // Handle negative indices
        if (index < 0) {
            index += this.length;
        }

        // Return undefined if the index is out of range
        if (index < 0 || index >= this.length) {
            return undefined;
        }

        // Return the element at the given index
        return this[index];
    };
}
