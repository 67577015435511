export const PREVIEW_REPORT = 'PREVIEW_REPORT';
export const PREVIEW_REPORT_SUCCESS = 'PREVIEW_REPORT_SUCCESS';
export const PREVIEW_REPORT_FAIL = 'PREVIEW_REPORT_FAIL';

export const previewReport = payload => ({
    type: PREVIEW_REPORT,
    payload,
});

export const previewReportSuccess = payload => ({
    type: PREVIEW_REPORT_SUCCESS,
    payload,
});

export const previewReportFail = payload => ({
    type: PREVIEW_REPORT_FAIL,
    payload,
});
