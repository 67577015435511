import { isAU } from 'js/constants/crux';

const getSingleLineAddress = (address) => address?.singleLine || address?.street?.singleLine || '';
const getLocallyFormattedAddress = address => address?.locallyFormattedAddress || '';
const getAddress = (address) => {
    if (isAU) {
        return getSingleLineAddress(address);
    }
    return getLocallyFormattedAddress(address);
};

export default {
    get: getAddress,
    getSingleLine: getSingleLineAddress,
    getLocallyFormatted: getLocallyFormattedAddress,
    excludeUnit: (address) => {
        const tokens = address.split('/');
        if (tokens?.length > 1) {
            return tokens[1].replace(/\s/g, '').toLowerCase();
        }
        return address.replace(/\s/g, '').toLowerCase();
    },
    getLocalityName: (property) => {
        return property.addressSuburb || property.addressTown;
    }
}