import React from 'react';
import CopyRight from './CopyRight';
import GeneralDisclaimer from './GeneralDisclaimer';

const MarketInsightFooterDisclaimers = () => {
    return (
        <div data-testid="market-insight-footer-disclaimer" className="disclaimers">
            <CopyRight />
            <GeneralDisclaimer />
        </div>
    );
};

export default MarketInsightFooterDisclaimers;
