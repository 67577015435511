export const GET_NEW_USER_ACTIVITY_FEEDS = 'GET_NEW_USER_ACTIVITY_FEEDS';
export const GET_NEW_USER_ACTIVITY_FEEDS_SUCCESS = 'GET_NEW_USER_ACTIVITY_FEEDS_SUCCESS';
export const GET_NEW_USER_ACTIVITY_FEEDS_FAILURE = 'GET_NEW_USER_ACTIVITY_FEEDS_FAILURE';

export const getNewUserActivityFeeds = () => ({
    type: GET_NEW_USER_ACTIVITY_FEEDS,
});

export const getNewUserActivityFeedsSuccess = payload => ({
    type: GET_NEW_USER_ACTIVITY_FEEDS_SUCCESS, payload,
});

export const getNewUserActivityFeedsFailure = payload => ({
    type: GET_NEW_USER_ACTIVITY_FEEDS_FAILURE, payload,
});
