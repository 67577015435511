import PropTypes from 'prop-types';
import LinkIcon from '@mui/icons-material/Link';
import Portal from '../../portal/Portal';
import CruxClassicModal from '../../common/CruxClassicModal';
import CopyWithTooltip from '../../common/CopyWithTooltip';
import { trackEvent } from '../../../actions/segment';
import Segment from '../../../helpers/Segment';
import SEGMENT from '../../../constants/segment';
import { entryPoints } from '../../../constants/entryPoint';
import RetryReport from '../RetryReport';
import ShareReport from './ShareReport';
import '../../../../css/crux/components/reports/dpprPreviewModal.scss';

const DPPRPreviewModal = (props) => {
    const {
        propertyAddress,
        propertyId,
        dpprLink,
        dpprLinkExpiry,
        open,
        onClose,
        onRetry,
        dispatch,
        withError,
        entryPoint,
    } = props;
    const handleEventLogger = (eventName = SEGMENT.EVENT_NAMES.copyReportLink) => {
        const event = eventName === SEGMENT.EVENT_NAMES.copyReportLink ?
            Segment.copyReportLink : Segment.shareReportLink;
        dispatch(trackEvent(event({
            pageContext: SEGMENT.EVENT_PROPERTIES.REPORT_PREVIEW,
            propertyId,
            entryPoint,
        })));
    };

    return (
        <div id="dppr-preview">
            <CruxClassicModal
                title={propertyAddress}
                open={open}
                modalClassName="dppr-preview-modal"
                onToggleModal={onClose}
                disableBackdropClick
                bodyWidth="955px"
                bodyHeight="calc(100vh - 206px)"
                sx={{
                    '& .MuiBackdrop-root': {
                        backgroundColor: 'transparent',
                    },
                }}
            >
                {
                    withError ?
                        <RetryReport onClick={onRetry} /> :
                        <Portal src={dpprLink} />
                }
                <div className="dppr-preview-modal-footer">
                    <CopyWithTooltip
                        forCopy={dpprLink}
                        hidden={!dpprLink}
                        onCopyEventLogger={handleEventLogger}
                    >
                        <div className="dppr-preview-copy-link"><LinkIcon /><span>Copy Link</span></div>
                    </CopyWithTooltip>
                    {
                        dpprLink &&
                        <ShareReport
                            dpprLink={dpprLink}
                            dpprLinkExpiry={dpprLinkExpiry}
                            propertyAddress={propertyAddress}
                            onClickEventLogger={handleEventLogger}
                        />
                    }
                </div>
            </CruxClassicModal>
        </div>
    );
};

DPPRPreviewModal.propTypes = {
    propertyAddress: PropTypes.string,
    propertyId: PropTypes.number,
    dpprLink: PropTypes.string,
    dpprLinkExpiry: PropTypes.string,
    open: PropTypes.bool,
    withError: PropTypes.bool,
    onClose: PropTypes.func,
    onRetry: PropTypes.func,
    dispatch: PropTypes.func,
    entryPoint: PropTypes.string,
};

DPPRPreviewModal.defaultProps = {
    entryPoint: entryPoints.REPORTS_MENU,
};

export default DPPRPreviewModal;
