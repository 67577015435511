import { Map as IMap } from 'immutable';
import {
    MSG_GET_RECENT_ADDRESS_SEARCH_SUCCESS,
    MSG_GET_RECENT_ADDRESS_SEARCH_FAIL,
    MSG_SAVE_RECENT_ADDRESS_SEARCH_SUCCESS,
    MSG_SAVE_RECENT_ADDRESS_SEARCH_FAIL,
    MSG_SAVE_RECENT_PARCEL_SEARCH_SUCCESS,
    MSG_SAVE_RECENT_PARCEL_SEARCH_FAIL,
    MSG_GET_RECENT_PARCEL_SEARCH_SUCCESS,
    MSG_GET_RECENT_PARCEL_SEARCH_FAIL,
    MSG_GET_RECENT_TITLE_SEARCH_SUCCESS,
    MSG_GET_RECENT_TITLE_SEARCH_FAIL,
    MSG_SAVE_RECENT_TITLE_SEARCH_SUCCESS,
    MSG_SAVE_RECENT_TITLE_SEARCH_FAIL,
    MSG_REMOVE_RECENT_SEARCH_SUCCESS,
    MSG_REMOVE_RECENT_PARCEL_SEARCH_SUCCESS,
    MSG_REMOVE_RECENT_TITLE_SEARCH_SUCCESS,
    MSG_REMOVE_RECENT_SEARCH_FAIL,
    MSG_REMOVE_RECENT_PARCEL_SEARCH_FAIL,
    MSG_REMOVE_RECENT_TITLE_SEARCH_FAIL,
    MSG_RESET_HANDLER,
} from '../actions/msgApi';

// set initial state values
export const initialState = IMap({
    recentSearches: [],
    recentParcelSearches: [],
    recentTitleSearches: [],
    hasRecentSearchesList: false,
    handler: {
        isError: false,
        message: '',
    },
});

const filterDuplicateRecords = (recent = []) => recent.reduce((accumulator, current) => {
        if (!accumulator?.map(a => a.searchString)?.includes(current?.searchString)) {
            accumulator.push(current);
        }
        return accumulator;
    }, []);

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case MSG_SAVE_RECENT_ADDRESS_SEARCH_SUCCESS:
        case MSG_GET_RECENT_ADDRESS_SEARCH_SUCCESS:
            return state.merge(IMap({
                recentSearches: filterDuplicateRecords(action.payload.response.results),
            }));
        case MSG_SAVE_RECENT_ADDRESS_SEARCH_FAIL:
        case MSG_GET_RECENT_ADDRESS_SEARCH_FAIL:
            return state.merge(IMap({
                recentSearches: [],
            }));
        case MSG_SAVE_RECENT_PARCEL_SEARCH_SUCCESS:
        case MSG_GET_RECENT_PARCEL_SEARCH_SUCCESS:
            return state.merge(IMap({
                recentParcelSearches: filterDuplicateRecords(action.payload.response.results),
            }));
        case MSG_GET_RECENT_PARCEL_SEARCH_FAIL:
        case MSG_SAVE_RECENT_PARCEL_SEARCH_FAIL:
            return state.merge(IMap({
                recentParcelSearches: [],
            }));
        case MSG_GET_RECENT_TITLE_SEARCH_SUCCESS:
        case MSG_SAVE_RECENT_TITLE_SEARCH_SUCCESS:
            return state.merge(IMap({
                recentTitleSearches: filterDuplicateRecords(action.payload.response.results),
            }));
        case MSG_GET_RECENT_TITLE_SEARCH_FAIL:
        case MSG_SAVE_RECENT_TITLE_SEARCH_FAIL:
            return state.merge(IMap({
                recentTitleSearches: [],
            }));
        case MSG_REMOVE_RECENT_SEARCH_SUCCESS:
            return state.merge(IMap({
                recentSearches: [],
            }));
        case MSG_REMOVE_RECENT_PARCEL_SEARCH_SUCCESS:
            return state.merge(IMap({
                recentParcelSearches: [],
            }));
        case MSG_REMOVE_RECENT_TITLE_SEARCH_SUCCESS:
            return state.merge(IMap({
                recentTitleSearches: [],
            }));
        case MSG_REMOVE_RECENT_SEARCH_FAIL:
        case MSG_REMOVE_RECENT_PARCEL_SEARCH_FAIL:
        case MSG_REMOVE_RECENT_TITLE_SEARCH_FAIL:
            return state.merge(IMap({
                handler: action.payload,
            }));
        case MSG_RESET_HANDLER:
            return state.merge(IMap({
                handler: initialState.get('handler'),
            }));
        default:
            return state;
    }
}
