export const MSG_GET_RECENT_ADDRESS_SEARCH = 'MSG_GET_RECENT_ADDRESS_SEARCH';
export const MSG_GET_RECENT_ADDRESS_SEARCH_FAIL = 'MSG_GET_RECENT_ADDRESS_SEARCH_FAIL';
export const MSG_GET_RECENT_ADDRESS_SEARCH_SUCCESS = 'MSG_GET_RECENT_ADDRESS_SEARCH_SUCCESS';

export const MSG_SAVE_RECENT_ADDRESS_SEARCH = 'MSG_SAVE_RECENT_ADDRESS_SEARCH';
export const MSG_SAVE_RECENT_ADDRESS_SEARCH_FAIL = 'MSG_SAVE_RECENT_ADDRESS_SEARCH_FAIL';
export const MSG_SAVE_RECENT_ADDRESS_SEARCH_SUCCESS = 'MSG_SAVE_RECENT_ADDRESS_SEARCH_SUCCESS';

export const MSG_GET_RECENT_PARCEL_SEARCH = 'MSG_GET_RECENT_PARCEL_SEARCH';
export const MSG_GET_RECENT_PARCEL_SEARCH_FAIL = 'MSG_GET_RECENT_PARCEL_SEARCH_FAIL';
export const MSG_GET_RECENT_PARCEL_SEARCH_SUCCESS = 'MSG_GET_RECENT_PARCEL_SEARCH_SUCCESS';

export const MSG_SAVE_RECENT_PARCEL_SEARCH = 'MSG_SAVE_RECENT_PARCEL_SEARCH';
export const MSG_SAVE_RECENT_PARCEL_SEARCH_FAIL = 'MSG_SAVE_RECENT_PARCEL_SEARCH_FAIL';
export const MSG_SAVE_RECENT_PARCEL_SEARCH_SUCCESS = 'MSG_SAVE_RECENT_PARCEL_SEARCH_SUCCESS';

export const MSG_GET_RECENT_TITLE_SEARCH = 'MSG_GET_RECENT_TITLE_SEARCH';
export const MSG_GET_RECENT_TITLE_SEARCH_FAIL = 'MSG_GET_RECENT_TITLE_SEARCH_FAIL';
export const MSG_GET_RECENT_TITLE_SEARCH_SUCCESS = 'MSG_GET_RECENT_TITLE_SEARCH_SUCCESS';

export const MSG_SAVE_RECENT_TITLE_SEARCH = 'MSG_SAVE_RECENT_TITLE_SEARCH';
export const MSG_SAVE_RECENT_TITLE_SEARCH_FAIL = 'MSG_SAVE_RECENT_TITLE_SEARCH_FAIL';
export const MSG_SAVE_RECENT_TITLE_SEARCH_SUCCESS = 'MSG_SAVE_RECENT_TITLE_SEARCH_SUCCESS';

export const MSG_REMOVE_RECENT_SEARCH = 'MSG_REMOVE_RECENT_SEARCH';
export const MSG_REMOVE_RECENT_SEARCH_FAIL = 'MSG_REMOVE_RECENT_SEARCH_FAIL';
export const MSG_REMOVE_RECENT_SEARCH_SUCCESS = 'MSG_REMOVE_RECENT_SEARCH_SUCCESS';

export const MSG_REMOVE_RECENT_PARCEL_SEARCH = 'MSG_REMOVE_RECENT_PARCEL_SEARCH';
export const MSG_REMOVE_RECENT_PARCEL_SEARCH_FAIL = 'MSG_REMOVE_RECENT_PARCEL_SEARCH_FAIL';
export const MSG_REMOVE_RECENT_PARCEL_SEARCH_SUCCESS = 'MSG_REMOVE_RECENT_PARCEL_SEARCH_SUCCESS';

export const MSG_REMOVE_RECENT_TITLE_SEARCH = 'MSG_REMOVE_RECENT_TITLE_SEARCH';
export const MSG_REMOVE_RECENT_TITLE_SEARCH_FAIL = 'MSG_REMOVE_RECENT_TITLE_SEARCH_FAIL';
export const MSG_REMOVE_RECENT_TITLE_SEARCH_SUCCESS = 'MSG_REMOVE_RECENT_TITLE_SEARCH_SUCCESS';

export const MSG_RESET_HANDLER = 'MSG_RESET_HANDLER';

export function getRecentAddressSearches(payload = {}) {
    return {
        type: MSG_GET_RECENT_ADDRESS_SEARCH,
        payload,
    };
}

export function saveRecentAddressSearches(searchString, suggestionTypeId) {
    return {
        type: MSG_SAVE_RECENT_ADDRESS_SEARCH,
        payload: { searchString, suggestionTypeId },
    };
}


export function getRecentParcelSearches() {
    return {
        type: MSG_GET_RECENT_PARCEL_SEARCH,
        payload: {},
    };
}

export function saveRecentParcelSearches(searchString, suggestionType) {
    return {
        type: MSG_SAVE_RECENT_PARCEL_SEARCH,
        payload: { searchString, suggestionType },
    };
}

export function getRecentTitleSearches() {
    return {
        type: MSG_GET_RECENT_TITLE_SEARCH,
    };
}

export function saveRecentTitleSearches(searchString, suggestionType) {
    return {
        type: MSG_SAVE_RECENT_TITLE_SEARCH,
        payload: { searchString, suggestionType },
    };
}

export function removeRecentSearch(searchType) {
    return {
        type: MSG_REMOVE_RECENT_SEARCH,
        payload: searchType,
    };
}

export function removeRecentParcelSearch(searchType) {
    return {
        type: MSG_REMOVE_RECENT_PARCEL_SEARCH,
        payload: searchType,
    };
}

export function removeRecentTitleSearch(searchType) {
    return {
        type: MSG_REMOVE_RECENT_TITLE_SEARCH,
        payload: searchType,
    };
}

export function resetHandler () {
    return {
        type: MSG_RESET_HANDLER,
    }
}