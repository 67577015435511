import { Map as IMap } from 'immutable';
import {
    USER_SETTINGS,
    USER_SETTINGS_FAIL,
    USER_SETTINGS_SUCCESS,
    APP_CONFIGS,
    APP_CONFIGS_FAIL,
    APP_CONFIGS_SUCCESS,
    ACCOUNT_DETAILS_SUCCESS,
    ACCOUNT_DETAILS,
    ACCOUNT_DETAILS_FAIL,
    UPDATE_ACCOUNT_DETAILS,
    UPDATE_ACCOUNT_DETAILS_SUCCESS,
    UPDATE_ACCOUNT_DETAILS_FAIL,
    SET_IS_UPDATE_SUCCESS,
    SEARCH_ACCOUNT_ADDRESS_SUCCESS,
    RESET_SEARCH_ACCOUNT_ADDRESS,
    SET_UPLOAD_USER_PHOTO_STATUS,
    USER_SETTINGS_STATE_UPDATE,
} from '../actions/userPreferences';

export const initialState = IMap({
    userSettings: [],
    isBusy: false,
    appConfigs: {
        value: null,
        isBusy: false,
    },
    accountDetails: {
        isLoading: false,
        addresses: [],
        contacts: [],
        isInitialized: false,
        isUpdateSuccess: false,
    },
    addressSearchResults: {
        suggestions: [],
    },
    profilePhoto: {
        isUploadSuccess: false,
        isUploading: false,
        hasError: false,
    },
});

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case USER_SETTINGS:
            return state.merge(IMap({
                isBusy: true,
            }));
        case USER_SETTINGS_SUCCESS:
            return state.merge(IMap({
                userSettings: action.payload,
                isBusy: false,
            }));
        case USER_SETTINGS_FAIL:
            return state.merge(IMap({
                userSettings: [],
                isBusy: false,
            }));
        case USER_SETTINGS_STATE_UPDATE:
            const userSettings = state.get('userSettings', [])?.map(setting => (
                setting.name === action.payload.name
                    ? { ...setting, value: JSON.stringify(action.payload.value) }
                    : setting
            ));
            return state.merge(IMap({
                userSettings,
                isBusy: false,
            }));
        case APP_CONFIGS:
            return state.merge(IMap({
                appConfigs: Object.assign(state.get('appConfigs'), {
                    isBusy: true,
                }),
            }));
        case APP_CONFIGS_FAIL:
            return state.merge(IMap({
                appConfigs: {
                    value: null,
                    isBusy: false,
                },
            }));
        case APP_CONFIGS_SUCCESS:
            return state.merge(IMap({
                appConfigs: {
                    value: action.payload,
                    isBusy: false,
                },
            }));
        case ACCOUNT_DETAILS:
            return state.merge(IMap({
                accountDetails: Object.assign(state.get('accountDetails'), {
                    isLoading: true,
                    isInitialized: false,
                }),
            }));
        case ACCOUNT_DETAILS_SUCCESS:
            return state.merge(IMap({
                accountDetails: {
                    ...action.payload,
                    isInitialized: true,
                },
            }));
        case UPDATE_ACCOUNT_DETAILS:
            return state.merge(IMap({
                accountDetails: Object.assign(state.get('accountDetails'), {
                    isLoading: true,
                }),
            }));
        case UPDATE_ACCOUNT_DETAILS_SUCCESS:
            return state.merge(IMap({
                accountDetails: {
                    ...action.payload,
                    isLoading: false,
                    isInitialized: true,
                    isUpdateSuccess: true,
                },
            }));
        case SET_IS_UPDATE_SUCCESS:
            return state.merge(IMap({
                accountDetails: Object.assign(state.get('accountDetails'), {
                    isUpdateSuccess: action.payload.isUpdateSuccess,
                }),
            }));
        case RESET_SEARCH_ACCOUNT_ADDRESS:
            return state.merge(IMap({
                addressSearchResults: {
                    suggestions: [],
                },
            }));
        case SEARCH_ACCOUNT_ADDRESS_SUCCESS:
            return state.merge(IMap({
                addressSearchResults: {
                    suggestions: action.payload.suggestions,
                },
            }));
        case UPDATE_ACCOUNT_DETAILS_FAIL:
            return state.merge(IMap({
                accountDetails: Object.assign(state.get('accountDetails'), {
                    isUpdateSuccess: false,
                    isLoading: false,
                }),
            }));
        case ACCOUNT_DETAILS_FAIL:
            return state.merge(IMap({
                accountDetails: {
                    isLoading: false,
                    isInitialized: false,
                    isUpdateSuccess: false,
                    addresses: [],
                    contacts: [],
                },
            }));
        case SET_UPLOAD_USER_PHOTO_STATUS:
            return state.merge(IMap({
                profilePhoto: { ...action.payload },
            }));
        default: return state;
    }
}
