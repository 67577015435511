import React, { Fragment, useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Dialog, Button, DialogTitle, DialogActions, DialogContent, styled } from '@mui/material';
import PDF from 'react-pdf-infinite';
import moment from 'moment';
import CloseIcon from '@mui/icons-material/Close';
import ScrollableContainer from 'js/components/common/ScrollableContainer';
import { getAssetDomain } from '../../constants/assets';
import BlockUi from '../blockUi/BlockUi';
import ReportHelper from '../../helpers/report/ReportHelper';
import clapi from '../../api/clapi';
import RetryReport from './RetryReport';
import SegmentHelper from '../../helpers/Segment';
import SEGMENT from '../../constants/segment';
import { trackEvent } from '../../actions/segment';
import { entryPoints } from '../../constants/entryPoint';
import colors from '../../constants/colors';

const CANVAS = '.printable canvas';
const DOWNLOAD_REPORT = 'Download Report';
const PRINT_PDF_REPORT = 'Print PDF Report';
const TITLE_REPORT = 'Title_Report';
const NOT_FOUND = 404;

const StyledPdfButton = styled(Button)({
    width: 119,
    fontSize: 13,
    margin: '0px 4px',
});

const TitleDetailsReportModal = (props) => {
    const {
        toggleReportModal,
        propertyAddress,
        propertyId,
        route,
    } = props;

    const verticalScreenSpacing = '261px';

    const [url, setUrl] = useState(null);
    const [hasApiError, setHasApiError] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [isPdfLoading, setIsPdfLoading] = useState(true);
    const [pdfLoaded, setPdfLoaded] = useState(false);
    const dispatch = useDispatch();

    const getFilenameWithDate = () => {
        const dateNow = moment().format('dd-mm-yyyy');
        return ReportHelper.getFilename(`${TITLE_REPORT}_${propertyAddress}_${dateNow}`);
    };
    const getEntryPointByRoute = useCallback(() => (
        route.id === 'property' ?
            entryPoints.REPORTS_MENU :
            entryPoints[route.id].entryPoint
    ), [route.id]);
    const handleErrorMessage = (e) => {
        const hasErrors = e.response && e.response.errors.length;
        if (hasErrors && e.status === NOT_FOUND) {
            const [{ msg = null }] = e.response.errors;
            setErrorMessage(msg);
        }
    };

    const handleModalMixpanel = useCallback(() => {
        const properties = {
            propertyId,
            pageContext: route.pageContext,
            entryPoint: getEntryPointByRoute(),
            reportType: SEGMENT.EVENT_PROPERTIES.TITLE_REPORT,
            reportFormat: SEGMENT.EVENT_NAMES.PDF,
        };
        dispatch(trackEvent(SegmentHelper.generateReport(properties)));
    }, [dispatch, getEntryPointByRoute, propertyId, route.pageContext]);
    const handleMixPanelByEvent = (eventName) => {
        const properties = { propertyId, eventName, entryPoint: getEntryPointByRoute() };
        dispatch(trackEvent(SegmentHelper.generateTitleDetailReport(properties)));
    };

    const handlePrint = () => {
        ReportHelper.printByClassname(CANVAS);
        handleMixPanelByEvent(PRINT_PDF_REPORT);
    };
    const handleDownload = (e) => {
        e.preventDefault();
        ReportHelper.downloadFileByUrl(getFilenameWithDate(), url);
        handleMixPanelByEvent(DOWNLOAD_REPORT);
    };
    const refreshModal = () => {
        setIsPdfLoading(true);
        setUrl(null);
        setHasApiError(false);
    };

    const fetchTitleDetailsUri = useCallback(async () => {
        try {
            const response = await clapi.getTitleDetails(propertyId);
            setUrl(response.url);
            handleModalMixpanel();
            setPdfLoaded(true);
        } catch (error) {
            handleErrorMessage(error);
            setHasApiError(true);
        } finally {
            setIsPdfLoading(false);
        }
    }, [handleModalMixpanel, propertyId]);

    useEffect(() => {
        if (!url && !hasApiError) {
            fetchTitleDetailsUri();
        }
    }, [fetchTitleDetailsUri, url, hasApiError, handleModalMixpanel]);

    const renderErrorComponent = () => (
        errorMessage
            ? <div className="error-message">{errorMessage}</div>
            : <RetryReport onClick={refreshModal} />
    );
    const renderAssetByName = name =>
        <img className="pdf-button-icon" src={getAssetDomain(name)} alt={name} width="16px" />;
    return (
        <Dialog
            open
            fullWidth
            className="title-details-modal"
            data-testid="title-details-modal"
            maxWidth="md"
            PaperProps={{ className: 'title-details-modal-paper' }}
            sx={{
                '& .MuiBackdrop-root': {
                    backgroundColor: 'transparent'
                },
            }}
        >
            <DialogTitle
                sx={{
                    padding: '24px 24px 20px',
                    '& h2': {
                        wordBreak: 'break-all',
                        fontWeight: 500,
                        lineHeight: 1.2,
                        color: colors.GUN_POWDER,
                    },
                }}
            >
                <span>{propertyAddress}</span>
                <CloseIcon
                    data-testid="title-details-modal-close-icon"
                    className="close-button-mui pull-right"
                    cursor="pointer"
                    onClick={toggleReportModal}
                />
            </DialogTitle>
            <DialogContent
                className="title-details-modal-content printable"
                sx={{
                    padding: '0 24px 24px',
                    marginRight: 3,
                    height: 'calc(100vh - 237px)',
                    overflowY: 'hidden',
                }}
                maxWidth="lg"
            >
                {!hasApiError ? (
                    <BlockUi blocking={isPdfLoading} renderChildren={false}>
                        <div data-testid="title-details-modal-pdf" className="title-details-modal-pdf">
                            <ScrollableContainer
                                style={{
                                    height: '100%',
                                    overflowX: 'hidden',
                                }}
                                heightRelativeToParent={`calc(100vh - ${verticalScreenSpacing})`}
                            >
                                <PDF
                                    file={url}
                                    fullWidth
                                    loading={<BlockUi blocking />}
                                    scale={1.5}
                                />
                            </ScrollableContainer>
                        </div>
                    </BlockUi>
                ) : renderErrorComponent() }
            </DialogContent>
            <DialogActions
                sx={{
                    padding: '15px 35px',
                    margin: 0,
                }}
            >
                {pdfLoaded && (
                    <Fragment>
                        <StyledPdfButton
                            variant="contained"
                            color="secondary"
                            onClick={handlePrint}
                        >
                            {renderAssetByName('print@2x.png')}
                            Print
                        </StyledPdfButton>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={handleDownload}
                        >
                            {renderAssetByName('download@2x.png')}
                            Download
                        </Button>
                    </Fragment>
                )}
            </DialogActions>
        </Dialog>
    );
};

TitleDetailsReportModal.propTypes = {
    toggleReportModal: PropTypes.func.isRequired,
    propertyAddress: PropTypes.string.isRequired,
    propertyId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    route: PropTypes.object.isRequired,
};

export default TitleDetailsReportModal;
