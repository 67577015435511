import React from 'react';
import { renderAcceptableValues } from '../FormValidationHelper';
import { DISTANCE_PATTERN } from '../../constants/regexPatterns';

const isValidDistanceCharacters = (val) => {
    const regexp = new RegExp(DISTANCE_PATTERN);
    const regexpRes = regexp.test(val);
    return (val === '' || regexpRes);
};

const isValidDistanceValue = (val) => parseFloat(val) <= 50;
const validateDistance = (val) => {
    const isCharsValid = isValidDistanceCharacters(val);
    const isDistanceMeasureValid = isValidDistanceValue(val);
    return {
        isValid: isCharsValid && isDistanceMeasureValid,
        isDistanceMeasureValid,
        isCharsValid,
    };
};

const isMoreThanTwoDecimal = val => (val.toString().includes('.') &&
        parseFloat(val).toString().split('.')[1] && parseFloat(val).toString().split('.')[1].length > 2);

// used with isLastInputZero to stop user input hanging zeroes past 2 decimal places
// parseFloat removes hanging zeroes past decimal (i.e. 1.0000 to 1)
const isMoreThanTwoCharsAfterDecimal = val => (val.toString().includes('.') && val.toString().split('.')[1] && val.toString().split('.')[1].length > 2);

// handle hanging zero input past decimal
// parseFloat removes hanging zeroes past decimal (i.e. 1.120000 to 1.12)
const isLastInputZero = val => (val.substring(val.length - 1, val.length) === '0');

const addUnit = (value) => {
    // get numeric value then add km
    const distanceValue = parseFloat(value);
    return distanceValue.toString().concat('km');
};

const distanceErrorHandler = (nextState) => {
    const { value } = nextState.distance;
    let activeStyle = 'normalStyle';
    // distance cannot be 0 or greater than 50
    // max 2 decimal places (not in regex to handle different error message)
    // !isValid(parseFloat(value) is used to handle exponential notation caused by parseFloat
    if (!isValidDistanceCharacters(value) || !isValidDistanceCharacters(parseFloat(value)) || (isValidDistanceCharacters(value) &&
        (parseFloat(value) === 0 || parseFloat(value) > 50 || isMoreThanTwoDecimal(value)))) {
        activeStyle = 'errorStyle';
    }
    nextState.activeStyle = activeStyle;
    return nextState;
};

const validateDistanceInput = (activeStyle, distance) => {
    const cleanDistanceValue = parseFloat(distance.value);
    let errorMessage = null;
    const acceptableValues = ['0-9', '.', 'km'];

    if (activeStyle === 'errorStyle') {
        // validity of characters take priority over numeric value
        if (isValidDistanceCharacters(distance.value)) {
            if (cleanDistanceValue === 0) {
                errorMessage = 'Distance must not be empty';
            } else if (cleanDistanceValue > 50) {
                errorMessage = 'Please input value less than 50km';
            } else if (isMoreThanTwoDecimal(distance.value) ||
                !isValidDistanceCharacters(parseFloat(distance.value))) {
                // !DistanceHelper.isValid(parseFloat(distance.value) is used
                // to handle exponential notation caused by parseFloat
                errorMessage = 'Max 2 decimal places';
            }
        }
        return errorMessage ? (<p className="text-danger mb-0">{errorMessage}</p>) : (<p className="text-danger mb-0">Valid Characters include {renderAcceptableValues(acceptableValues)}</p>);
    }
    return null;
};
const formatRadiusValue = (radius) => {
    if (!radius) return '';
    const km = radius.slice(-2);
    if (km.toLowerCase() !== 'km') {
        return `${radius}Km`;
    }
    return `${radius}`.toLowerCase().replace('km', 'Km');
};

const distanceOnTypeValidation = (typedValue) => {
    if ((isMoreThanTwoDecimal(typedValue) &&
        !isLastInputZero(typedValue)) ||
        (isMoreThanTwoCharsAfterDecimal(typedValue) &&
            isLastInputZero(typedValue))) {
        return false;
    }
    return true;
};

export default {
    distanceErrorHandler,
    addUnit,
    validateDistance,
    isMoreThanTwoDecimal,
    isMoreThanTwoCharsAfterDecimal,
    isLastInputZero,
    validateDistanceInput,
    formatRadiusValue,
    distanceOnTypeValidation,
};
