import { call, put, select, takeLatest, race, take } from 'redux-saga/effects';
import * as PdfReportActions from 'js/actions/pdfReports';
import ClapiApi from 'js/api/clapi';
import MsgApi from 'js/api/msgApi';
import AnalyticsGateway from 'js/lib/AnalyticsGateway';
import { addCruxCmptError, rmCruxCmptError } from 'js/actions/errorHandler';
import Bugsnag from 'js/bugsnag';
import Commons from 'js/helpers/Commons';
import * as SegmentAction from 'js/actions/segment';
import ReportConstant from 'js/constants/reports';
import { selectSelectedAccount } from '../selectors/linkedAccount';
import ErrorMsg from 'js/constants/errorMsg';

function getOnePageReportFn() {
    return function* (action) {
        try {
            const state = yield select();
            const { payload } = action;
            const onePageReport = yield race({
                report: call(ClapiApi.getOnePageReport, state, payload),
                cancel: take(PdfReportActions.CLEAR_REPORT),
            });

            if (onePageReport.report) {
                const properties = [
                    { label: 'Page', value: payload.pageContext },
                    { label: 'Property ID', value: payload.propertyId },
                    { label: 'Report Option Owner Name', value: payload.isOwnerNameAllowed },
                    { label: 'Report Type', value: payload.reportType },
                    { label: 'Report Format', value: 'PDF' },
                ];
                if (payload.entryPoint) {
                    properties.push({ label: 'Entry Point', value: payload.entryPoint });
                }
                AnalyticsGateway.sendEvent({
                    eventName: 'Generate Report',
                    properties,
                });

                yield put({
                    type: PdfReportActions.PDF_REPORT_SUCCESS,
                    payload: {
                        url: onePageReport?.report?.url,
                        key: ReportConstant.PDF_REPORT_KEYS.summary,
                    },
                });
                yield put(rmCruxCmptError(PdfReportActions.GET_ONE_PAGE_REPORT_FAIL));
            }
        } catch (error) {
            yield put(addCruxCmptError(PdfReportActions.GET_ONE_PAGE_REPORT_FAIL, error));
            yield put({
                type: PdfReportActions.PDF_REPORT_FAIL,
                payload: PdfReportActions.GET_ONE_PAGE_REPORT_FAIL,
            });
            Bugsnag.notify(error);
        }
    };
}

function getPropertyProfileFn() {
    return function* (action) {
        try {
            const store = yield select();
            const propertyProfileReport = yield race({
                report: call(
                    ClapiApi.getPropertyProfile,
                    store,
                    action.payload.reportId,
                    action.payload.propertyId,
                    action.payload.customParams,
                ),
                cancel: take(PdfReportActions.CLEAR_REPORT),
            });
            if (propertyProfileReport.report && propertyProfileReport.report.pdfBytes) {
                const properties = [
                    { label: 'Page', value: action.payload.pageContext },
                    { label: 'Property ID', value: action.payload.propertyId },
                    { label: 'Report Type', value: action.payload.reportType },
                    { label: 'Report Format', value: 'PDF' },
                ];
                if (action.payload.entryPoint) {
                    properties.push({ label: 'Entry Point', value: action.payload.entryPoint });
                }
                AnalyticsGateway.sendEvent({
                    eventName: 'Generate Report',
                    properties,
                });
                yield put({
                    type: PdfReportActions.PDF_REPORT_SUCCESS,
                    payload: {
                        url: propertyProfileReport.report.pdfBytes,
                        key: ReportConstant.PDF_REPORT_KEYS.propertyProfile,
                    },
                });
                yield put(rmCruxCmptError(PdfReportActions.GET_PROPERTY_PROFILE_FAIL));
            }
        } catch (error) {
            Bugsnag.notify(error);
            yield put(addCruxCmptError(PdfReportActions.GET_PROPERTY_PROFILE_FAIL, error));
            yield put({
                type: PdfReportActions.PDF_REPORT_FAIL,
                payload: PdfReportActions.GET_PROPERTY_PROFILE_FAIL
            });
        }
    };
}

const getFranchiseId = state => Commons.get(state.clapi.get('usrDetail'), 'accountDetail.franchiseId') || null;
function getPropertyProfileReportFn() {
    return function* (action) {
        try {
            const store = yield select();
            const franchiseId = getFranchiseId(store);
            // trigger mixpanel event
            yield put(SegmentAction.trackPropertyProfileReport(action.payload));
            if (franchiseId) {
                action.payload.customParams.franchiseId = franchiseId;
            }
            const propertyProfileReport = yield race({
                report: call(
                    ClapiApi.getPropertyProfileReport,
                    store.clapi.get('auth'),
                    store.claud.get('session')?.clusrId,
                    action.payload.reportId,
                    action.payload.propertyId,
                    action.payload.customParams,
                ),
                cancel: take(PdfReportActions.CLEAR_REPORT),
            });
            if (propertyProfileReport.report && propertyProfileReport.report.url) {
                yield put({
                    type: PdfReportActions.PDF_REPORT_SUCCESS,
                    payload: {
                        url: propertyProfileReport.report.url,
                        key: ReportConstant.PDF_REPORT_KEYS.propertyProfile,
                    },
                });
            }
            yield put(rmCruxCmptError(PdfReportActions.GET_PROPERTY_PROFILE_REPORT_FAIL));
        } catch (error) {
            Bugsnag.notify(error);
            yield put(addCruxCmptError(PdfReportActions.GET_PROPERTY_PROFILE_REPORT_FAIL, error));
            yield put({
                type: PdfReportActions.PDF_REPORT_FAIL,
                payload: PdfReportActions.GET_PROPERTY_PROFILE_REPORT_FAIL
            });
        }
    };
}

function getSuburbProfileFn() {
    return function* (action) {
        try {
            const store = yield select();
            const franchiseId = getFranchiseId(store);
            const suburbProfileReport = yield race({
                report: call(
                    ClapiApi.getSuburbProfile,
                    franchiseId,
                    action.payload.locationId,
                    action.payload.customParams,
                ),
                cancel: take(PdfReportActions.CLEAR_REPORT),
            });
            if (suburbProfileReport.report && suburbProfileReport.report.pdfBytes) {
                const properties = [
                    { label: 'Page', value: action.payload.pageContext },
                    { label: 'Locality ID', value: action.payload.localityId },
                    { label: 'Property ID', value: action.payload.propertyId },
                    { label: 'Report Type', value: action.payload.reportType },
                    { label: 'Report Format', value: 'PDF' },
                    { label: 'Object Context', value: action.payload.suburbName },
                ];
                if (action.payload.entryPoint) {
                    properties.push({ label: 'Entry Point', value: action.payload.entryPoint });
                }
                AnalyticsGateway.sendEvent({
                    eventName: 'Generate Report',
                    properties,
                });
                yield put({
                    type: PdfReportActions.PDF_REPORT_SUCCESS,
                    payload: {
                        url: suburbProfileReport.report.pdfBytes,
                        key: ReportConstant.PDF_REPORT_KEYS.suburbProfile,
                    },
                });
                yield put(rmCruxCmptError(PdfReportActions.GET_SUBURB_PROFILE_REPORT_FAIL));
            }
        } catch (error) {
            Bugsnag.notify(error);
            yield put(addCruxCmptError(PdfReportActions.GET_SUBURB_PROFILE_REPORT_FAIL, error));
            yield put({
                type: PdfReportActions.PDF_REPORT_FAIL,
                payload: PdfReportActions.GET_SUBURB_PROFILE_REPORT_FAIL
            });
        }
    };
}

function getIntellivalAvmReportFn() {
    return function* (action) {
        try {
            const state = yield select();
            const { payload } = action;
            const intellivalAvmReport = yield race({
                report: call(ClapiApi.getIntellivalAvmReport, state, payload.propertyId),
                cancel: take(PdfReportActions.CLEAR_REPORT),
            });

            if (intellivalAvmReport.report) {
                yield put({
                    type: PdfReportActions.PDF_REPORT_SUCCESS,
                    payload: {
                        url: intellivalAvmReport.report.url,
                        key: ReportConstant.PDF_REPORT_KEYS.intellival,
                    },
                });
                yield put(rmCruxCmptError(PdfReportActions.GET_INTELLIVAL_AVM_REPORT_FAIL));
            }
        } catch (error) {
            yield put(addCruxCmptError(PdfReportActions.GET_INTELLIVAL_AVM_REPORT_FAIL, error));
            yield put({
                type: PdfReportActions.PDF_REPORT_FAIL,
                payload: PdfReportActions.GET_INTELLIVAL_AVM_REPORT_FAIL
            });
            Bugsnag.notify(error);
        }
    };
}

function getPropertyInvestmentReportFn() {
    return function* (action) {
        try {
            const { selectedClAppAccountUserGuid } = yield select(selectSelectedAccount);
            const propertyInvestmentReport = yield race({
                report: call(
                    MsgApi.getPropertyInvestmentReport,
                    selectedClAppAccountUserGuid,
                    action.payload.propertyId,
                    {
                        leaseStartDate: action.payload.leaseStartDate,
                        leaseEndDate: action.payload.leaseEndDate,
                        letAmount: action.payload.letAmount,
                    }
                ),
                cancel: take(PdfReportActions.CLEAR_REPORT),
            });
            if (propertyInvestmentReport?.report?.response?.result) {
                const properties = [
                    { label: 'Page', value: action.payload.pageContext },
                    { label: 'Property ID', value: action.payload.propertyId },
                    { label: 'Report Type', value: ReportConstant.PROPERTY_INVESTMENT_REPORT },
                    { label: 'Report Format', value: 'PDF' },
                    { label: 'Entry Point', value: action.payload.entryPoint },
                    {
                        label: 'Extra Information Entered',
                        value: !!action.payload.leaseStartDate
                            || !!action.payload.leaseEndDate
                            || !!action.payload.letAmount
                    },
                ];
                AnalyticsGateway.sendEvent({
                    eventName: 'Generate Report',
                    properties,
                });
                yield put({
                    type: PdfReportActions.PDF_REPORT_SUCCESS,
                    payload: {
                        url: propertyInvestmentReport?.report?.response?.result,
                        key: ReportConstant.PDF_REPORT_KEYS.propertyInvestment,
                    },
                });
                yield put(rmCruxCmptError(PdfReportActions.GET_PROPERTY_INVESTMENT_REPORT_FAIL));
            } else if (!propertyInvestmentReport.cancel) {
                yield put(addCruxCmptError(PdfReportActions
                    .GET_PROPERTY_INVESTMENT_REPORT_FAIL, ErrorMsg
                    .UNEXPECTED_ERROR));
                yield put({
                    type: PdfReportActions.PDF_REPORT_FAIL,
                    payload: PdfReportActions.GET_PROPERTY_INVESTMENT_REPORT_FAIL
                });
            }
        } catch (error) {
            Bugsnag.notify(error);
            yield put(addCruxCmptError(PdfReportActions.GET_PROPERTY_INVESTMENT_REPORT_FAIL, error));
            yield put({
                type: PdfReportActions.PDF_REPORT_FAIL,
                payload: PdfReportActions.GET_PROPERTY_INVESTMENT_REPORT_FAIL
            });
        }
    };
}

export const getPropertyProfileReport = getPropertyProfileReportFn();
export const getOnePageReport = getOnePageReportFn();
export const getPropertyProfile = getPropertyProfileFn();
export const getSuburbProfile = getSuburbProfileFn();
export const getIntellivalAvmReport = getIntellivalAvmReportFn();
export const getPropertyInvestmentReport = getPropertyInvestmentReportFn();

export function* getOnePageReportWatcher() {
    yield takeLatest(PdfReportActions.GET_ONE_PAGE_REPORT, getOnePageReport);
}
export function* getPropertyProfileReportWatcher() {
    yield takeLatest(
        PdfReportActions.GET_PROPERTY_PROFILE_REPORT,
        getPropertyProfileReport,
    );
}

export function* getPropertyProfileWatcher() {
    yield takeLatest(
        PdfReportActions.GET_PROPERTY_PROFILE,
        getPropertyProfile,
    );
}
export function* getSuburbProfileWatcher() {
    yield takeLatest(PdfReportActions.GET_SUBURB_PROFILE_REPORT, getSuburbProfile);
}

export function* getIntellivalAvmReportWatcher() {
    yield takeLatest(PdfReportActions.GET_INTELLIVAL_AVM_REPORT, getIntellivalAvmReport);
}

export function* getPropertyInvestmentReportWatcher() {
    yield takeLatest(PdfReportActions.GET_PROPERTY_INVESTMENT_REPORT, getPropertyInvestmentReport);
}

export default [
    getOnePageReportWatcher(),
    getPropertyProfileReportWatcher(),
    getPropertyProfileWatcher(),
    getSuburbProfileWatcher(),
    getIntellivalAvmReportWatcher(),
    getPropertyInvestmentReportWatcher(),
];
