import PropTypes from 'prop-types';
import { FormControlLabel, Checkbox, styled } from '@mui/material';
import Tooltip from './Tooltip';
import Colors from '../../constants/colors';

const StyledLabel = styled(FormControlLabel)({
    '& .MuiTypography-root': {
        fontSize: '12px',
        fontWeight: '400',
        lineHeight: '19.92px',
        letterSpacing: '0.4px',
    },
});
const StyledCheckbox = styled(Checkbox)({
    padding: '6px',
    '&.Mui-checked': {
        color: Colors.PRIMARY.MAIN,
    },
    '&:not(.Mui-checked)': {
        color: 'white',
        '&:after': {
            content: '""',
            backgroundColor: 'white',
            width: 15,
            height: 15,
            position: 'absolute',
            zIndex: -1,
        },
    },
    '& .MuiSvgIcon-root': {
        height: 22.28,
        width: 22.28,
    },
    '&:after': {
        width: 15,
        height: 15,
        content: '""',
        zIndex: -1,
        position: 'absolute',
        backgroundColor: 'white',
    },
})

const CruxCheckbox = ({
    id = '',
    label = '',
    customClass = '',
    checked,
    onChange,
    tooltip,
    tooltipId,
}) => {
    const _customClass = customClass ? ` ${customClass}` : '';
    return (
        <span className={`custom-checkbox${_customClass}`}>
            <StyledLabel
                onClick={(e) => e.stopPropagation()}
                control={
                    <StyledCheckbox
                        id={id}
                        data-testid={id}
                        aria-label={label}
                        checked={checked}
                        onChange={onChange}
                        className="crux-checkbox"
                    />
                }
                label={label}
            />
            {
                tooltip &&
                <Tooltip
                    tooltipTxt={tooltip}
                    tooltipId={tooltipId}
                    tooltipClass="white"
                    tooltipPosition="bottom"
                    iconType="infoIcon"
                />
            }
        </span>
    )
};

CruxCheckbox.propTypes = {
    id: PropTypes.string,
    label: PropTypes.string,
    customClass: PropTypes.string,
    checked: PropTypes.bool,
    onChange: PropTypes.func,
    tooltip: PropTypes.string,
    tooltipId: PropTypes.string,
};

export default CruxCheckbox;