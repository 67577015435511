import ReportProblemOutlined from '@mui/icons-material/ReportProblemOutlined';
import { MAX_SAVED_TERRITORIES_REACHED } from '../../constants/tooltipText';

const MaxTerritoryTooltipBody = () => (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
            <ReportProblemOutlined color="warning"/>
        </div>
        <div style={{ marginLeft: '4px' }}>{ MAX_SAVED_TERRITORIES_REACHED }</div>
    </div>
);

export default MaxTerritoryTooltipBody;