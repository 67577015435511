import EsriIcon from '../../assets/img/esri.png';
import CoreLogicIcon from '../../assets/img/Corelogic-logo-mark.png';
import NearmapIcon from '../../assets/img/nearMapIcon.png';

const LABELS = 'hybrid';
const SATELLITE = 'satellite';
const ROADMAP = 'roadmap';
const TERRAIN = 'terrain';
const ESRI_SATELLITE = 'esri-satellite';
const ESRI_ROAD = 'esri-road';
const CORELOGIC_SATELLITE = 'corelogic-satellite';
const CORELOGIC_ROAD = 'corelogic-road';
const CORELOGIC_HYBRID = 'corelogic-hybrid';
const NEARMAP = 'nearmap';

const MAP_TYPES = {
    [LABELS]: {
        label: 'Satellite',
        mixpanel: 'Google Satellite',
        userSettingsLabel: 'labels',
    },
    [SATELLITE]: {
        label: 'Satellite',
        mixpanel: 'Google Satellite',
        userSettingsLabel: 'google-satellite',
    },
    [ROADMAP]: {
        label: 'Road',
        mixpanel: 'Google Road',
        userSettingsLabel: 'google-road',
    },
    [TERRAIN]: {
        label: 'Road',
        mixpanel: 'Google Road',
        userSettingsLabel: 'terrain',
    },
    [ESRI_SATELLITE]: {
        label: 'Satellite',
        mixpanel: 'Esri Satellite',
        icon: EsriIcon,
        userSettingsLabel: 'esri-satellite',
    },
    [ESRI_ROAD]: {
        label: 'Road',
        mixpanel: 'Esri Road',
        icon: EsriIcon,
        userSettingsLabel: 'esri-road',
    },
    [CORELOGIC_SATELLITE]: {
        label: 'Satellite',
        mixpanel: 'CoreLogic Satellite',
        icon: CoreLogicIcon,
        userSettingsLabel: 'corelogic-satellite',
    },
    [CORELOGIC_ROAD]: {
        label: 'Road',
        mixpanel: 'CoreLogic Road',
        icon: CoreLogicIcon,
        userSettingsLabel: 'corelogic-road',
    },
    [CORELOGIC_HYBRID]: {
        label: 'Hybrid',
        mixpanel: 'CoreLogic Hybrid',
        icon: CoreLogicIcon,
        userSettingsLabel: 'corelogic-hybrid',
    },
    [NEARMAP]: {
        label: 'Satellite',
        mixpanel: 'Nearmap Satellite',
        icon: NearmapIcon,
        userSettingsLabel: 'nearmap',
    },
};

const PARCEL_BOUNDARIES_ID = 'parcelb';
const TARGET_PROPERTY = 'targetProperty';
const PROPERTY_BOUNDARIES = {
    id: 'pb',
    category: 'propertyData',
};
const TARGET_PROPERTY_BOUNDARY = {
    id: 'Target Property Boundary', category: 'boundary',
};

const DEFAULT_LAYERS_PER_TYPE_AU= {
    [LABELS]: [{ id: PARCEL_BOUNDARIES_ID, category: TARGET_PROPERTY, }, PROPERTY_BOUNDARIES],
    [SATELLITE]: [{ id: PARCEL_BOUNDARIES_ID, category: TARGET_PROPERTY, }, PROPERTY_BOUNDARIES],
    [ROADMAP]: [{ id: PARCEL_BOUNDARIES_ID, category: TARGET_PROPERTY }],
    [TERRAIN]: [{ id: PARCEL_BOUNDARIES_ID, category: TARGET_PROPERTY }],
    [ESRI_ROAD]: [{ id: PARCEL_BOUNDARIES_ID, category: TARGET_PROPERTY }, PROPERTY_BOUNDARIES],
    [ESRI_SATELLITE]: [{
        id: PARCEL_BOUNDARIES_ID,
        category: TARGET_PROPERTY,
    },PROPERTY_BOUNDARIES],
    [NEARMAP]: [{ id: PARCEL_BOUNDARIES_ID, category: TARGET_PROPERTY }],
};

const DEFAULT_LAYERS_PER_TYPE_NZ = {
    [LABELS]: [TARGET_PROPERTY_BOUNDARY, PROPERTY_BOUNDARIES],
    [SATELLITE]: [TARGET_PROPERTY_BOUNDARY, PROPERTY_BOUNDARIES],
    [ROADMAP]: [TARGET_PROPERTY_BOUNDARY],
    [TERRAIN]: [TARGET_PROPERTY_BOUNDARY],
    [CORELOGIC_ROAD]: [TARGET_PROPERTY_BOUNDARY, PROPERTY_BOUNDARIES],
    [CORELOGIC_HYBRID]: [TARGET_PROPERTY_BOUNDARY],
    [CORELOGIC_SATELLITE]: [TARGET_PROPERTY_BOUNDARY, PROPERTY_BOUNDARIES],
    [NEARMAP]: [TARGET_PROPERTY_BOUNDARY],
};

const PANEL_PROPERTIES = {
    'map-with-identify-popup': 'Modal Map',
    schoolMap: 'Nearby Schools Modal',
    propertyMap: 'Similar Properties Modal',
};

const GOOGLE_MAP_TYPES = [
    LABELS,
    SATELLITE,
    ROADMAP,
    TERRAIN,
];

export default {
    LABELS,
    SATELLITE,
    ROADMAP,
    TERRAIN,
    ESRI_SATELLITE,
    ESRI_ROAD,
    MAP_TYPES,
    PANEL_PROPERTIES,
    CORELOGIC_SATELLITE,
    CORELOGIC_ROAD,
    CORELOGIC_HYBRID,
    GOOGLE_MAP_TYPES,
    NEARMAP,
    DEFAULT_LAYERS_PER_TYPE_AU,
    DEFAULT_LAYERS_PER_TYPE_NZ,
};
