import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import ListItem from '@mui/material/ListItem';

const SavedListLoader = (props) => {
    if (!props.loading) return null;
    return (
        <ListItem sx={{ justifyContent: 'center' }}>
            <CircularProgress size={30} />
        </ListItem>
    );
}

SavedListLoader.propTypes = {
    loading: PropTypes.bool,
};

export default SavedListLoader;
