export default (rawParams) => {
    const searchParams = new URLSearchParams(rawParams);
    const searchParamsObject = {};
    searchParams.forEach((value, key) => {
        if (value !== 'null' && value) {
            Object.defineProperty(searchParamsObject, key, {
                value,
                enumerable: true,
                writable: true,
            });
        }
    });
    return searchParamsObject;
};
