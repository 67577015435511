import { isAU, isNZ } from '../../constants/crux';
import PropertyAttrFormatter from '../PropertyAttrFormatter';
import Parcels from '../Parcels';
import Strings from '../Strings';

const getRapidLocalitySingleLine = (rapidResponse) => {
    const {
        addressSuburb,
        addressState,
        addressPostcode,
        addressTown,
    } = rapidResponse || {};
    let separator = ' ';
    let collection = [addressSuburb];
    if (isAU) {
        collection.push(addressState);
    } else {
        collection.push(addressTown);
        separator = ', ';
    }
    collection.push(addressPostcode);
    collection = collection.filter(item => !!item);
    if (collection.length) {
        return collection.join(separator);
    }
    return '';
};

const getRapidLocalityParamObj = (rapidResponse) => {
    const {
        addressSuburb,
        addressState,
        addressPostcode,
        addressTown,
    } = rapidResponse || {};
    const queryStringObj = {
        addressSuburb: addressSuburb || undefined,
    };
    if (isAU) {
        if (addressPostcode && addressState) {
            queryStringObj.addressPostcodeState = `${addressPostcode} ${addressState}`;
        } else if (addressPostcode) {
            queryStringObj.addressPostcode = addressPostcode;
        }
    } else {
        queryStringObj.addressPostcode = addressPostcode || undefined;
        queryStringObj.addressTown = addressTown || undefined;
    }
    return queryStringObj;
};

const getLegalDetailsDisplayValue = (rapidSearchObject) => {
    if (isNZ) {
        return PropertyAttrFormatter.formatLegalDescription(rapidSearchObject.titles);
    }
    const { displayValue } = Parcels.reduceParcels(rapidSearchObject.parcelList || [], Strings.conjunct);
    return displayValue;
}

export default {
    getRapidLocalitySingleLine,
    getRapidLocalityParamObj,
    getLegalDetailsDisplayValue,
};
