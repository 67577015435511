export default {
    MIN_MAX_LENGTH: 'Must be at least 8 characters long',
    LOWER_UPPER_CASING: 'Must include both upper and lower case characters',
    HAS_NUMBER: 'Must include at least one digit',
    HAS_SPECIAL_CHARACTER: 'Must include at least one of the following special characters:!#"$%&*+,-./\':][;<=>?@^_`(){|}~"',
    EMPTY: 'Must not be empty',
    PASSWORD_MATCH: 'Password must match',
    CURRENT_PASSWORD: 'Current password is not correct',
    SECURITY_ANSWER: 'Length must be between 1 and 100 characters',
    HEX_CODE: 'Invalid hexcode',
    DISTANCE_MEASURE_ERROR: '*Please input value less than 50km',
    DISTANCE_CHARS_ERROR: '*Valid Characters include 0-9 . km',
    PRICE_FORMAT_ERROR: '*Valid Characters include 0-9 k m b $ ,',
};
