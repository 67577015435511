import React from 'react';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import PropTypes from 'prop-types';
import Tab from '@mui/material/Tab';
import MUITooltip from '../../common/MUITooltip';
import Entitlements from '../../../helpers/Entitlements';
import { UNAVAILABLE, UNSUBSCRIBED } from '../../../constants/tooltipText';

const SearchBarTabs = props => {
    const title = Entitlements.isRolesAvailable(props.userDetail)
        ? UNSUBSCRIBED
        : UNAVAILABLE;
    return (
        <TabContext value={props.selectedOption}>
            <TabList onChange={props.onTabChange}>
                { props.searchOptions.map(({ type, disabled, unavailable }) => (
                    disabled || unavailable ?
                        <MUITooltip
                            key={`mui-tooltip-${type}`}
                            title={title}
                            placement="right"
                            arrow
                        >
                            <Tab
                                label={type}
                                value={type}
                                className="address-name-tab-title disabled-tab"
                            />
                        </MUITooltip> :
                        <Tab
                            key={`mui-tooltip-${type}`}
                            label={type}
                            value={type}
                            className="address-name-tab-title"
                        />
                ))}
            </TabList>
            <div>
                { props.searchOptions.map(({ type }) => (
                    <TabPanel
                        sx={{
                            padding: '20px 0 0',
                        }}
                        value={type}
                        key={type}
                    >
                        <div className="address-name-form-fields">
                            { props.children }
                        </div>
                    </TabPanel>
                ))}
            </div>
        </TabContext>
    )
};

const SEARCH_TYPE_SHAPE = {
    type: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
};
SearchBarTabs.propTypes = {
    selectedOption: PropTypes.any,
    searchOptions: PropTypes.arrayOf(PropTypes.shape(SEARCH_TYPE_SHAPE)),
    onTabChange: PropTypes.func,
    userDetail: PropTypes.object,
};

export default SearchBarTabs;
