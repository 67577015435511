import { Map as IMap } from 'immutable';
import * as ClapiAction from '../actions/clapi';

export const initialState = IMap({
    nameSearchResultsIds: [],
    nameSearchResults: [],
    isFetchingMore: false,
    isFetchingInit: false,
});

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case ClapiAction.CLEAR_NAME_SEARCH_RESULTS:
            return state.merge(IMap({
                nameSearchResults: [],
            }));
        case ClapiAction.CLEAR_NAME_SEARCH_IDS:
            return state.merge(IMap({
                nameSearchResultsIds: [],
            }));
        default:
            return state;
    }
}
