import styled from '@emotion/styled';
import { TextField } from '@mui/material';
import Colors from '../../../constants/colors';

const SearchField = styled(TextField, {
    shouldForwardProp: (prop) => prop !== 'isError',
})(({ isError }) => ({
    width: '100%',
    height: '100%',
    '& .MuiInputBase-root': {
        height: '100%',
        backgroundColor: Colors.WHITE,
        '& .MuiInputBase-input': {
            padding: '0 12px',
            fontSize: 12,
        },
    },
}));

export default SearchField;
