import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Button, styled } from '@mui/material';
import AddAlertIcon from '@mui/icons-material/AddAlert';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { Link } from 'react-router-dom';
import Commons from '../../../helpers/Commons';
import { SAVED_LIST_TYPE } from '../../../constants/favourites/favourites';
import Colors from '../../../constants/colors';
import Themes from '../../../helpers/themes';
import { routeCodes } from '../../../constants/routes';
import { trackEvent } from '../../../actions/segment';
import SegmentHelper from '../../../helpers/Segment';


const StyledCardContainer = styled('div')({
    position: 'relative',
});

const SavedListCard = ({
    type,
    name,
    id,
    alerts,
    editSavedList,
    openDeleteModal,
    propertyCount = 0,
    watchlistSuccess = true,
}) => {
    const dispatch = useDispatch();

    const handleRemove = (e) => {
        e.preventDefault();
        e.stopPropagation();
        openDeleteModal(id, name)
    };

    const sendMixpanelEvent = () => {
        dispatch(trackEvent(SegmentHelper.recordSavedList({
            eventName: id ? 'Open Saved List' : 'Open Watch List',
            name,
        })));
    };

    return (
        <Link
            data-testid="search-card-link"
            target="_blank"
            to={routeCodes.SAVED_LIST.path(id)}
            onClick={sendMixpanelEvent}
        >
            <StyledCardContainer
                className="saved-list-card"
                data-testid={id ? `saved-list-${id}` : 'watch-list-card'}
            >
                <div className="saved-list-name">
                    <span
                        title={name}
                        style={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            width: 200,
                            whiteSpace: 'nowrap',
                        }}
                    >
                        {name}
                    </span>
                    {
                        type === SAVED_LIST_TYPE.SAVED_LIST &&
                        <IconButton
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                editSavedList(id, name);
                            }}
                            sx={{
                                position: 'absolute',
                                right: 12,
                                top: 8,
                            }}
                        >
                            <EditIcon
                                sx={{
                                    color: Colors.PRIMARY.MAIN,
                                    fontSize: 24,
                                    cursor: 'pointer',
                                }}
                            />
                        </IconButton>

                    }
                </div>
                <div className="saved-list-property-count" style={{ visibility: watchlistSuccess ? 'visible' : 'hidden'}}>
                    {Commons.numberWithCommas(propertyCount)} {Commons.getPropertyLabel(propertyCount)}
                </div>
                <div className="saved-list-alerts" style={{ visibility: type === SAVED_LIST_TYPE.WATCH_LIST ? 'visible' : 'hidden' }}>
                    {
                        alerts > 0 && watchlistSuccess &&
                        <div className="saved-list-alert-update">
                            <AddAlertIcon sx={{
                                color: Themes?.userFavoritesTheme?.palette?.addedPropertyNotification?.main,
                                fontSize: 16,
                            }} />
                            <span>
                            {alerts} {Commons.getPropertyLabel(alerts)} updated
                        </span>
                        </div>
                    }
                    {
                        // specific to false only; default value has
                        // changed to null
                        watchlistSuccess === false &&
                        <div className="saved-list-text-indicator">
                            Something went wrong!
                        </div>
                    }
                </div>
                <hr />
                {
                    type === SAVED_LIST_TYPE.WATCH_LIST ?
                        <div className="saved-list-notification">
                            <NotificationsIcon sx={{ color: Colors.BLACK_60_OPAQUE, fontSize: 18 }} />
                        </div>
                        :
                        <div className="saved-list-action">
                            <Button
                                variant="text"
                                onClick={handleRemove}
                            >
                                Remove
                            </Button>
                        </div>
                }
            </StyledCardContainer>
        </Link>
    );
};

SavedListCard.defaultProps = {
    propertyCount: 0,
}

SavedListCard.propTypes = {
    type: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    propertyCount: PropTypes.number.isRequired,
    id: PropTypes.number,
    alerts: PropTypes.number,
    editSavedList: PropTypes.func,
    watchlistSuccess: PropTypes.bool,
};

export default SavedListCard;
