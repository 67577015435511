import { Fragment } from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import { CircularProgress, Button } from '@mui/material';

const RPDSidebarLink = ({
    onClick, displayText, disabled,
    RPDSidebarRef, isNew, isComingSoon,
    tooltip, active, doNotClosePane,
    isLoading, displayIcon,
}) => {
    const linkOnClick = () => {
        if (active) {
            return;
        }

        if (!disabled) {
            if (RPDSidebarRef.current && !doNotClosePane) {
                RPDSidebarRef.current.setActivePane(false);
            }

            onClick();
        }
    };

    const withTooltip = (element) => {
        const elementId = `link-tooltip-message-${tooltip.name}`;
        return (
            <Fragment>
                <span
                    className="sidebar-link-tooltip"
                    data-tip={tooltip.message}
                    data-for={elementId}
                    data-html
                    data-trigger="hover"
                >
                    {element}
                </span>
                <ReactTooltip
                    html
                    id={elementId}
                    className="custom-tool-tip"
                    place="bottom"
                    effect="solid"
                    event="mouseover"
                    eventOff="mouseleave"
                />
            </Fragment>
        );
    };

    const buttonElem = (
        <Button
            variant="contained"
            color="pale"
            onClick={linkOnClick}
            disabled={disabled}
        >
            {
                displayIcon &&
                <span className="icon-tag">{displayIcon}</span>
            }
            { displayText }
            {
                (isNew && !isLoading) &&
                <span className="new-tag">new</span>
            }

            {
                !isNew && isComingSoon &&
                <span className="coming-soon-tag">Coming soon!</span>
            }
            {
                isLoading &&
                <CircularProgress size={20} sx={{ marginRight: '10px' }} />
            }
        </Button>
    );

    return (
        <div
            className={`rpd-link${(active && !disabled && !isComingSoon) ? ' rpd-sidebar-btn-active' : ''}`}
        >
            { tooltip
            && tooltip.enabled
            && tooltip.name
            && tooltip.message
                ? withTooltip(buttonElem)
                : buttonElem }
        </div>
    );
};

RPDSidebarLink.propTypes = {
    onClick: PropTypes.func,
    displayText: PropTypes.string,
    disabled: PropTypes.bool,
    isNew: PropTypes.bool,
    isComingSoon: PropTypes.bool,
    RPDSidebarRef: PropTypes.object,
    tooltip: PropTypes.object,
    active: PropTypes.bool,
    isLoading: PropTypes.bool,
    doNotClosePane: PropTypes.bool,
    displayIcon: PropTypes.any,
};

RPDSidebarLink.defaultProps = {
    onClick: () => {
        // This is intentional
    },
    displayText: 'RP Data',
    disabled: false,
    RPDSidebarRef: {},
    isNew: false,
    isComingSoon : false,
    tooltip: {},
    active: false,
    doNotClosePane: false,
    displayIcon: null,
};

export default RPDSidebarLink;
