export const INVALIDATE_CRUX_APP_ERROR = 'INVALIDATE_CRUX_APP_ERROR';
export const CRUX_APP_ERROR = 'CRUX_APP_ERROR';
export const ADD_CRUX_CMPT_ERROR = 'ADD_CRUX_CMPT_ERROR';
export const RM_CRUX_CMPT_ERROR = 'RM_CRUX_CMPT_ERROR';

export function invalidateCruxAppError() {
    return {
        type: INVALIDATE_CRUX_APP_ERROR,
    };
}

export function cruxAppError(msg, type) {
    return {
        type: CRUX_APP_ERROR,
        error: {
            message: msg,
            type,
        },
    };
}

export function addCruxCmptError(key, error) {
    return {
        type: ADD_CRUX_CMPT_ERROR,
        key,
        error,
    };
}

export function rmCruxCmptError(key) {
    return {
        type: RM_CRUX_CMPT_ERROR,
        key,
    };
}
