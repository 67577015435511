import Roles from 'js/constants/entitlementsRole';
export const SAVED_LISTS = 'saved_lists';
export const SAVED_SEARCHES = 'saved_searches';

export const SAVED_TERRITORIES = 'saved_territories';
export const NOTIFICATIONS = 'notifications';

export const FAVOURITES_TAB = [{
    label: 'Saved Lists',
    value: SAVED_LISTS,
    role: Roles.SAVED_LISTS,
}, {
    label: 'Saved Searches',
    value: SAVED_SEARCHES,
    role: Roles.SAVE_SEARCH,
}, {
    label: 'Saved Territories',
    value: SAVED_TERRITORIES,
    role: Roles.SAVED_TERRITORY,
}];

export const FAVOURITES_TAB_HOME = [
    {
        label: 'Saved Lists',
        value: SAVED_LISTS,
        role: Roles.SAVED_LISTS,
    },
    {
        label: 'Saved Searches',
        value: SAVED_SEARCHES,
        role: Roles.SAVE_SEARCH,
    },
    {
        label: 'Saved Territories',
        value: SAVED_TERRITORIES,
        role: Roles.SAVED_TERRITORY,
    },
];

export const FAVOURITES_UNSUBSCRIBED_MESSAGE = {
    'Saved Lists': 'To setup your Saved List and keep up with what matters for you',
    'Saved Searches': 'To save your frequent searches and stay updated',
    'Saved Territories': 'To create a Saved Territory and stay on top of your patch',
};

export const SAVED_LIST_TYPE = {
    WATCH_LIST: 0,
    SAVED_LIST: 1,
};
