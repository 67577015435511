import React from 'react';
import PropType from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Segment from '../../helpers/Segment';
import { trackEvent } from '../../actions/segment';
import { getRoute } from '../../constants/routes';

export const FeedbackLink = ({ className, children, dispatch, match }) => {
    const onClick = (e) => {
        e.preventDefault();
        const route = getRoute(match.path);
        dispatch(trackEvent(Segment.yourFeedback({ page: route.pageContext })));
    };
    return (
        <a
            href="#!"
            onClick={onClick}
            rel="noreferrer noopener"
            className={className}
            data-zendesk="feedback-link"
        >
            {children}
        </a>
    );
};

FeedbackLink.propTypes = {
    className: PropType.string,
    dispatch: PropType.func,
    match: PropType.object,
};

const ConnectedFeedbackLink = connect()(withRouter(FeedbackLink));
ConnectedFeedbackLink.displayName = 'FeedbackLink';

export default ConnectedFeedbackLink;
