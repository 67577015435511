import API from './index';
import methods from '../constants/methods';

const getConfig = () => {
    const requestOptions = {
        path: '/api/config',
        isProxy: true,
        timeout: 30000,
    };

    const headerOtions = {
        method: methods.GET.method,
    };

    return API.initiateSessionApiCall(requestOptions, headerOtions);
};

const getPublicConfiguration = () => {
    const requestOptions = {
        path: '/api/ui/configuration',
        isProxy: true,
        timeout: 30000,
    };

    const headerOptions = {
        method: methods.GET.method,
    };

    return API.initiateSessionApiCall(requestOptions, headerOptions);
};

export default {
    getConfig,
    getPublicConfiguration,
};
