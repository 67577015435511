import Colors from 'js/constants/colors';
import { styled, TextField } from '@mui/material';

const customPropsList = ['width', 'isHighlighted', 'isError'];
const SearchFilterTextFieldSecondary = styled(TextField, {
    shouldForwardProp: (prop) => !customPropsList.includes(prop),
})(({ theme, width, isHighlighted, isError }) => {
    const highLightText = isHighlighted ? theme.palette.primary.main : Colors.BLACK_30_OPAQUE;
    const highlightBG = isHighlighted ? Colors.ACTIVE_FILTER_BG : Colors.WHITE;
    const inputTextColor = isError ? Colors.BLACK_87_OPAQUE : highLightText;
    return {
        width,
        '& .MuiFormLabel-root': {
            color: Colors.BLACK_30_OPAQUE,
            fontSize: '14px',
            fontWeight: 400,
        },
        '& .MuiFormLabel-filled, .Mui-focused': {
            color: isError ? Colors.TEXT_ERROR : Colors.BLACK_60_OPAQUE,
        },
        '& .MuiInputBase-root': {
            height: 38,
            color: Colors.BLACK_60_OPAQUE,
            fontSize: '14px',
            paddingRight: '10px',
            paddingLeft: '10px',
            cursor: 'pointer',

            '.MuiInputAdornment-root:first-of-type svg': {
                color: isError ? Colors.TEXT_ERROR : Colors.BLACK_54_OPAQUE,
            },
        },
        '& .MuiOutlinedInput-root': {
            '&:hover fieldset': {
                borderColor: isError ? Colors.ERROR.BORDER : theme.palette.primary.main,
            },
            '&.Mui-focused fieldset': {
                borderWidth: 1,
            },
            '&.Mui-focused .MuiInputBase-input': {
                color: isError ? Colors.BLACK_87_OPAQUE : theme.palette.primary.main,
            },
            '&.Mui-focused .MuiInputAdornment-root:first-of-type svg': {
                color: isError ? Colors.TEXT_ERROR : theme.palette.primary.main,
            },
            '& .MuiTouchRipple-root': {
                display: 'none',
            },
            backgroundColor: isError ? Colors.WHITE : highlightBG,
            '& .MuiInputBase-input': {
                textAlign: 'left',
                paddingLeft: 0,
                color: inputTextColor,
                overflow: 'hidden',
            },
            '& fieldset': {
                borderWidth: 1,
                borderColor: inputTextColor,
                '& .tool-tip-component': {
                    marginLeft: 0,
                },
            },
        },
    };
})

export default SearchFilterTextFieldSecondary;
