import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, Button, styled } from '@mui/material';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import { selectSelectedAccount } from '../../../selectors/linkedAccount';
import Colors from '../../../constants/colors';
import Clapi from '../../../api/clapi';
import {
    METADATA_PARAM,
    TERRITORIES_SEARCH_FORMAT
} from '../../../constants/batchSearch/batchSearch';
import Commons from '../../../helpers/Commons';
import UserPreferences from '../../../api/userPreferences';
import { SAVE_SEARCH_ACCEPTABLE_CHARS } from '../../../constants/regexPatterns';
import ValidationStatus from '../../../constants/validationStatus';
import { cruxLoader, cruxUnloader } from '../../../actions/crux';
import { getRoute, routeCodes } from '../../../constants/routes';
import ErrorMessage from '../../../constants/errorMsg';
import { CLEAR_TERRITORIES, setSelectedTerritory } from '../../../actions/territories';
import { useFullPageModalContext } from '../../context/FullPageModalContext';
import useSavedTerritory from 'js/hooks/useSavedTerritory';
import { clearSearchFilters } from 'js/actions/clapi';
import { setSnackBar } from 'js/actions/componentAccess';
import ToastMessage from 'js/constants/toastMessage';
import MUITooltip from 'js/components/common/MUITooltip';
import MaxTerritoryTooltipBody from '../../common/MaxTerritoryTooltipBody';
import { trackEvent } from '../../../actions/segment';
import { createMixpanelProperties } from '../../../helpers/Segment';
import SEGMENT from '../../../constants/segment';
import { LONGITUDE_ERROR } from '../../../helpers/Localization';

const MAX_PROPERTY_LIMIT = 10000;
const MAX_PROPERTY_REACHED = 'The territory is too large to be saved, it contains more than 10,000 properties. Please decrease the size of your territory, then save.';
const StyledSavedTerritoryDrawer = styled('div')({
    padding: '16px',

    'hr': {
        margin: 0,
    },
});

const StyledDrawerButton = styled('div')({
    paddingTop: '12px',
    paddingBottom: '12px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
});

const StyledHeader = styled('div')({
    fontSize: '18px',
    color: Colors.BLACK_87_OPAQUE,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '16px',
    fontWeight: '500',
});

const StyledTerritoryDescriptionName = styled('div')({
    paddingTop: '12px',
    paddingBottom: '12px',
});

const StyledAlert = styled(Alert)({
    display: 'flex',
    alignItems: 'center',
});

const SavedTerritoryDrawer = (props) => {
    const {
        id,
        description = '',
        getCoordinates,
        drawing,
    } = props;
    const [name, setName] = useState(description);
    const [isValidRequest, setIsValidRequest] = useState(true);
    const [hasBatchSearchError, setHasBatchSearchError] = useState(false);
    const [nameErrorMsg, setNameErrorMsg] = useState('');
    const [totalPropertyCount, setTotalPropertyCount] = useState(0);

    const { setOpen, setConfirmDialogOpen } = useFullPageModalContext();
    const { selectedClAppAccountUserGuid } = useSelector(selectSelectedAccount);
    const savedTerritoriesCount = useSelector(state => state.territories.get('territories')?.totalCount);
    const savedTerritory = useSavedTerritory({
        territoryId: id,
        polygonRef: drawing,
        name,
    });
    const history = useHistory();
    const dispatch = useDispatch();

    const coordinates = getCoordinates() || {};
    const territoryPolygon = coordinates?.polygonCoordinates
        ?.map((value) => `(${value.lng}, ${value.lat})`)
        .join(',');

    useEffect(() => {
        if (!drawing && totalPropertyCount) {
            setTotalPropertyCount(0);
        }
    }, [drawing]);

    useEffect(() => {
        setName(description);
    }, [description]);

    useEffect(() => {
        if (coordinates?.polygonCoordinates?.length
            && coordinates.polygonCoordinates.length > 2) {
            if (coordinates?.polygonCoordinates.some(val => val.lng < 0)) {
                props.openSnackBar(ValidationStatus.WARNING, LONGITUDE_ERROR);
                setHasBatchSearchError(true);
                return;
            }
            dispatch(cruxLoader());
            Clapi.getBatchSearch({
                [METADATA_PARAM]: true,
                [TERRITORIES_SEARCH_FORMAT]: coordinates?.polygonCoordinates.map((value) => ({
                    lat: value.lat,
                    lon: value.lng,
                })),
            }).then((response) => {
                const totalProperties = Commons.get(response, 'metadata.totalElements');
                setTotalPropertyCount(totalProperties);
                setHasBatchSearchError(false);
            }).catch(() => {
                props.openSnackBar(ValidationStatus.ERROR, ErrorMessage.UNEXPECTED_ERROR);
                setHasBatchSearchError(true);
            }).finally(() => {
                dispatch(cruxUnloader());
            });
        }
    }, [JSON.stringify(coordinates)]);

    const handleAPIError = (e) => {
        if (e?.status === 409) {
            setIsValidRequest(false);
            setNameErrorMsg(ErrorMessage.DUPLICATE_SAVED_TERRITORY);
        } else if (e?.status === 400) {
            const message = e?.response?.errors ? e.response.errors[0].msg : ErrorMessage.CREATE_SAVED_LIST_ERROR;
            props.openSnackBar(ValidationStatus.ERROR, message);
        } else {
            props.openSnackBar(ValidationStatus.ERROR, ErrorMessage.CREATE_SAVED_LIST_ERROR);
        }
    };

    const sendMixpanelEvent = (eventName) => {
        dispatch(trackEvent(createMixpanelProperties(eventName, {
            page: getRoute(history?.location?.pathname)?.pageContext,
            pageTab: 'Saved Territory',
            name,
            searchString: JSON.stringify(coordinates?.polygonCoordinates.map(_map => ({
                lat: _map?.lat, lon: _map?.lng
            }))),
            numberOfProperties: totalPropertyCount ?? savedTerritory?.territory?.numberOfProperties,
        })));
    };

    const createTerritory = (successCallback) => {
        dispatch(cruxLoader());
        if (totalPropertyCount < MAX_PROPERTY_LIMIT) {
            UserPreferences.createSavedTerritory(selectedClAppAccountUserGuid, {
                areaSqm: coordinates?.area,
                territoryName: name,
                centerLatitude: coordinates?.centerLatLng?.lat,
                centerLongitude: coordinates?.centerLatLng?.lng,
                zoom: coordinates?.zoom,
                territoryPolygon,
                numberOfProperties: totalPropertyCount ?? savedTerritory?.territory?.numberOfProperties,
            })
                .then((res) => {
                    if (res.statusCode === 'CREATED') {
                        if (!!successCallback) {
                            successCallback();
                        }
                        sendMixpanelEvent(SEGMENT.EVENT_NAMES.CREATE_NEW_SAVED_TERRITORY);
                        closeDrawer();
                        history.push({
                            pathname: routeCodes.SAVED_TERRITORY.path(res.message),
                            state: {
                                newSavedTerritory: true,
                            }
                        });
                    }
                })
                .catch(handleAPIError)
                .finally(() => {
                    dispatch(cruxUnloader());
                });
        }
    };

    const update = () => {
        if (!new RegExp(SAVE_SEARCH_ACCEPTABLE_CHARS).test(name)) {
            setNameErrorMsg('Invalid character.');
            setIsValidRequest(false);
            return;
        }
        setIsValidRequest(true);
        dispatch(cruxLoader());
        savedTerritory.update({
            areaSqm: coordinates?.area,
            territoryName: name,
            centerLatitude: coordinates?.centerLatLng?.lat,
            centerLongitude: coordinates?.centerLatLng?.lng,
            zoom: coordinates?.zoom,
            territoryPolygon,
            numberOfProperties: totalPropertyCount ?? savedTerritory?.territory?.numberOfProperties,
        })
            .then(() => {
                sendMixpanelEvent(SEGMENT.EVENT_NAMES.CONFIGURE_SAVED_TERRITORY);
                closeDrawer();
                dispatch({
                    type: CLEAR_TERRITORIES
                });
                dispatch(clearSearchFilters());
                if (savedTerritory.forceUpdate) {
                    history.push({
                        pathname: routeCodes.SAVED_TERRITORY.path(id),
                        state: {
                            updateTerritory: true,
                        }
                    });
                } else {
                    dispatch(setSnackBar(true,
                        'success',
                        ToastMessage.UPDATE_SAVED_TERRITORY_SUCCESS.replace('{territoryName}', name || 'Saved')
                    ));
                }
            })
            .catch(handleAPIError)
            .finally(() => {
                dispatch(cruxUnloader());
            });
    };

    const openModal = () => {
        setConfirmDialogOpen(true);
    };

    const closeDrawer = () => {
        setOpen(false);
        setName(description);
    };

    const save = () => {
        if (!new RegExp(SAVE_SEARCH_ACCEPTABLE_CHARS).test(name)) {
            setNameErrorMsg('Invalid character.');
            setIsValidRequest(false);
            return;
        }

        setIsValidRequest(true);
        createTerritory(() => dispatch(setSelectedTerritory(null)));
    };

    const maxTerritoriesReached = savedTerritoriesCount >= 25;
    const territoryIsInvalid = name?.trim()?.length <= 0
        || name?.length > 255
        || coordinates?.polygonCoordinates?.length <= 2
        || totalPropertyCount > MAX_PROPERTY_LIMIT
        || hasBatchSearchError;
    return (
        <Box
            onClose={closeDrawer}
            data-testid="saved-territory-drawer"
        >
            <StyledSavedTerritoryDrawer>
                <StyledHeader>
                    <span>{id ? 'Update': 'Create'} Territory</span>
                </StyledHeader>
                <StyledTerritoryDescriptionName>
                    <TextField
                        variant="outlined"
                        label="Name"
                        style={{
                            width: '100%',
                        }}
                        sx={{
                            '&:hover': { borderColor: Colors.PRIMARY.MAIN },
                            '.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                                borderColor: isValidRequest ?  Colors.PRIMARY.MAIN : '#f44336',
                            }
                        }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={name}
                        onChange={event => setName(event.target.value)}
                        inputProps={{ maxLength: 255 }}
                        helperText={isValidRequest ? '' : nameErrorMsg}
                        error={!isValidRequest}
                    />
                </StyledTerritoryDescriptionName>
                <StyledDrawerButton>
                    <div>
                        <Button
                            variant="text"
                            onClick={openModal}
                        >
                            Cancel
                        </Button>
                    </div>
                    {
                        !!id &&
                        <div style={{ flexBasis: '185px', display: 'flex', justifyContent: 'space-between' }}>
                            <Button
                                variant="contained"
                                disabled={territoryIsInvalid
                                        || (!savedTerritory.isPolygonEdited()
                                            && !savedTerritory.isNameEdited())}
                                onClick={update}
                            >
                                Update
                            </Button>
                            {
                                maxTerritoriesReached ?
                                <MUITooltip arrow title={<><MaxTerritoryTooltipBody /></>}>
                                    <span>
                                        <Button
                                            variant="contained"
                                            disabled
                                            onClick={save}
                                        >
                                            Save as new
                                        </Button>
                                    </span>
                                </MUITooltip> :
                                <Button
                                    variant="contained"
                                    disabled={territoryIsInvalid
                                        || (!savedTerritory.isPolygonEdited()
                                            && !savedTerritory.isNameEdited())}
                                    onClick={save}
                                >
                                    Save as new
                                </Button>
                            }
                        </div>
                    }
                    {
                        !id &&
                        <Button
                            variant="contained"
                            disabled={territoryIsInvalid}
                            onClick={save}
                        >
                            Save
                        </Button>

                    }
                </StyledDrawerButton>
                <div>
                    {
                        totalPropertyCount > 10000 &&
                        <StyledAlert severity="warning">
                            { MAX_PROPERTY_REACHED }
                        </StyledAlert>
                    }
                </div>
            </StyledSavedTerritoryDrawer>
        </Box>
    );
};

SavedTerritoryDrawer.propTypes = {
    id: PropTypes.number,
    description: PropTypes.string,
    getCoordinates: PropTypes.func,
    drawing: PropTypes.object,
}

export default SavedTerritoryDrawer;
