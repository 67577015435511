const methods = {
    GET: {
        method: 'GET',
    },
    POST: {
        method: 'POST',
    },
    PUT: {
        method: 'PUT',
    },
    PATCH: {
        method: 'PATCH',
    },
    DELETE: {
        method: 'DELETE',
    },
};

export default methods;
