import React from 'react';
import PropTypes from 'prop-types';

const UpdateIcon = ({ disabled }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
        <path
            fill={disabled ? '#b9b9b9' : '#38424A'}
            fillRule="evenodd"
            d="M8.332 2L12 5.669l-7.966 7.966-3.27.36a.688.688 0 0 1-.76-.759l.364-3.273L8.332 2zm5.314-.133a1.209 1.209 0 0 1 0 1.71L12.223 5 9 1.777 10.423.354a1.209 1.209 0 0 1 1.71 0l1.513 1.513z"
        />
    </svg>);

UpdateIcon.propTypes = {
    disabled: PropTypes.bool,
};
export default UpdateIcon;
