import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { routeCodes } from '../../../constants/routes';
import CopyRight from './CopyRight';
import GeneralDisclaimer from './GeneralDisclaimer';
import ValuationDisclaimer from './ValuationDisclaimer';
import ContentDisclaimer from './ContentDisclaimer';
import StateDisclaimer from './StateDisclaimer';
import { isAU } from '../../../constants/crux';

export default class CruxFooterDisclaimers extends Component {
    static propTypes = {
        route: PropTypes.object,
        location: PropTypes.object,
    };

    shouldRenderDisclaimer = () => {
        const { route } = this.props;
        if (route.pageContext === routeCodes.PROPERTY.pageContext) {
            return true;
        }
        return false;
    };

    shouldRenderDisclaimerOnBoth = () => {
        const { route: { pageContext } } = this.props;
        switch (pageContext) {
            case routeCodes.PROPERTY.pageContext:
            case routeCodes.SEARCH_RESULT.pageContext:
            case routeCodes.MULTI_LOCALITY_SEARCH.pageContext:
            case routeCodes.PROPERTY_LIST_REPORT.pageContext:
                return true;
            default: return false;
        }
    };

    render() {
        const isRenderOnBoth = this.shouldRenderDisclaimerOnBoth();
        const isRender = this.shouldRenderDisclaimer();
        const {
            state: {
                nameSearchParams: { state } = {},
            } = {},
        } = this.props.location;
        return (
            <div data-testid="crux-footer-disclaimer" className="disclaimers">
                <CopyRight />
                {
                    isRenderOnBoth ?
                        <GeneralDisclaimer /> : null
                }
                {
                    isAU && isRenderOnBoth ?
                        <StateDisclaimer state={state} /> : null
                }
                {
                    isRender ?
                        <ValuationDisclaimer /> : null
                }
                {
                    isAU && isRender ?
                        <ContentDisclaimer /> : null
                }
            </div>
        );
    }
}

