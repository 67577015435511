export default {
    SERVER_SLOW_RESPONSE: 'Looks like the server is taking too long to respond, this can be caused' +
        ' by either poor connectivity or an error with our servers.',
    UNEXPECTED_ERROR: 'There was an unexpected error. Please try again later.',
    FAILED_TO_FETCH: 'There was an error retrieving the data. Please refresh to try again.',
    FAILED_TO_FETCH_FILTERS: 'Sorry, there was an error fetching the filter options.' +
        ' Please refresh the page to try again',
    FILTER_OPTIONS_NOT_FOUND: 'No matching filter options found',
    UNAVAILABLE: 'The information you have requested is currently unavailable. Please try again later.',
    DUPLICATE_SAVED_LIST: 'List name in use. Please enter a different saved list name',
    DUPLICATE_SAVED_TERRITORY: 'Territory name in use. Please enter a different saved territory name',
    CREATE_SAVED_LIST_ERROR: 'An error occurred, and your changes were not saved. Please try' +
        ' again.',
    START_DATE_RANGE_ERROR: 'Enter a Start date before the End date',
    END_DATE_RANGE_ERROR: 'Enter an End date after the Start date',
    MANDATORY_START_DATE: 'Please enter a Start date',
    MANDATORY_END_DATE: 'Please enter an End date',
};
