import { Button, darken, lighten, styled } from '@mui/material';
import Commons from '../../../helpers/Commons';
import Colors from '../../../constants/colors';

const CruxButton = styled(Button, {
    shouldForwardProp: (prop) =>
        prop !== 'colors'
        && prop !== 'backgroundColor'
        && prop !== 'fontSize'
        && prop !== 'fontSize'
        && prop !== 'minWidth'
        && prop !== 'opacity'
})((props) => {
    const backgroundColor = Commons.get(props, 'colors.backgroundColor');
    const borderColor = Commons.get(props, 'colors.borderColor');
    const disabledStyle = Commons.get(props, 'colors.disabled');
    const hoverColor = backgroundColor || borderColor || '#fff';
    return {
        color: Commons.get(props, 'colors.font') || Colors.WHITE,
        borderColor: borderColor || 'initial',
        backgroundColor: backgroundColor || 'initial',
        fontWeight: props.fontWeight || 500,
        fontSize: props.fontSize || '14px',
        letterSpacing: '0.4px',
        minWidth: props.minWidth || 'initial',
        '&.Mui-disabled': {
            color: Commons.get(props, 'colors.font') || 'initial',
            opacity: props.opacity || 0.6,
            ...(disabledStyle || {}),
        },
        '&:hover': {
            backgroundColor: backgroundColor ?
                darken(hoverColor, 0.25) :
                lighten(Commons.get(props, 'colors.font'), 0.9),
            borderColor: borderColor,
        },
    };
});

export default CruxButton;
