import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Calendar from 'rc-calendar';
import Util from '../../../helpers/Commons';

const formatStr = 'DD MMM YYYY';

export default class CalendarDatePicker extends Component {
    static propTypes = {
        value: PropTypes.object,
        onSelect: PropTypes.func,
        allowFutureDate: PropTypes.bool,
    };

    state = {
        defaultValue: null,
    };

    onSelect(value) {
        const selectedDate = Util.format(value, formatStr);
        this.setState({ defaultValue: value });
        this.props.onSelect(selectedDate);
    }

    disabledDate = (current) => {
        const date = new Date();
        const allowFutureDate = this.props.allowFutureDate;
        return allowFutureDate ? current.isBefore('1800-01-01', 'year') :
            current.isAfter(date) || current.isBefore('1800-01-01', 'year'); // can not select days after today
    }

    render() {
        return (
            <Calendar
                showToday={false}
                defaultValue={this.props.value}
                selectedValue={this.state.defaultValue}
                format={formatStr}
                onSelect={value => this.onSelect(value)}
                disabledDate={this.disabledDate}
            />);
    }
}
