import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import AddCircleOutlineOutlined from '@mui/icons-material/AddCircleOutlineOutlined';
import { styled, IconButton, Tooltip } from '@mui/material';
import SendInvitesModal from './SendInvitesModal';
import { inviteUsers } from '../../../actions/manageUsers';
import Commons from '../../../helpers/Commons';
import tooltipText from '../../../constants/tooltipText';
import { MAX_USERS_LIMIT_REACHED } from '../../../helpers/Localization';
import { routeCodes } from '../../../constants/routes';

const { get } = Commons;

export const getInvitedUsers = (successInvite) => {
    const newUsers = get(successInvite, 'newUsers') || [];
    const existingUsers = get(successInvite, 'existingUsers') || [];
    return [...newUsers, ...existingUsers];
};

const StyledAddIcon = styled(AddCircleOutlineOutlined)({
    width: 27,
    height: 27,
});

const SendInvitesButton = (props) => {
    const {
        dispatch, userInviteStatus, onHandleToast, isAdmin, isUsersLimitReached,
    } = props;
    const [isOpen, setIsOpen] = useState(false);
    const showToast = get(userInviteStatus, 'showToast');
    const isError = get(userInviteStatus, 'isError');
    const errorMessage = get(userInviteStatus, 'message');
    const successInvite = get(userInviteStatus, 'successInvite');
    const invitedUsers = getInvitedUsers(successInvite);
    const disabled = !isAdmin || isUsersLimitReached;

    let toastSeverity = 'error';
    let toastMessage = errorMessage;
    if (!isError) {
        toastMessage = `${invitedUsers.length} user/s have been sent an invitation to associate to this organisation`;
        toastSeverity = 'success';
    }

    const handleClick = () => setIsOpen(true);
    const handleClose = () => setIsOpen(false);
    const handleConfirm = (emails) => {
        const redirectUri = window.location.origin + routeCodes.JOIN_CRUX.path;
        dispatch(inviteUsers({ emails, redirectUri }));
        handleClose();
    };

    useEffect(() => {
        if (showToast) {
            onHandleToast(toastMessage, toastSeverity);
        }
    }, [onHandleToast, showToast, toastMessage, toastSeverity]);

    const ButtonWithTooltip = ({ children }) => {
        const tooltipMessage = isUsersLimitReached
            ? MAX_USERS_LIMIT_REACHED
            : tooltipText.TEXT.MANAGE_USERS.NO_ADMIN;
        return (
            disabled ? (
                <Tooltip
                    arrow
                    placement="right"
                    title={tooltipMessage}
                    componentsProps={{
                        tooltip: {
                            sx: {
                                '&.MuiTooltip-tooltip': {
                                    fontSize: '9.28571px',
                                },
                            }
                        }
                    }}
                >
                    <div>
                        {children}
                    </div>
                </Tooltip>
            ) : children
        );
    };

    return (
        <Fragment>
            <ButtonWithTooltip>
                <IconButton
                    data-testid="invite-user-btn"
                    color="primary"
                    aria-label="send invite"
                    disabled={disabled}
                    onClick={handleClick}
                    sx={{ padding: '12px' }}
                >
                    <StyledAddIcon />
                </IconButton>
            </ButtonWithTooltip>
            <SendInvitesModal
                open={isOpen}
                onClose={handleClose}
                onConfirm={handleConfirm}
            />
        </Fragment>
    );
};

SendInvitesButton.propTypes = {
    dispatch: PropTypes.func.isRequired,
    onHandleToast: PropTypes.func.isRequired,
    userInviteStatus: PropTypes.object.isRequired,
    isAdmin: PropTypes.bool.isRequired,
    isUsersLimitReached: PropTypes.bool.isRequired,
};

export default SendInvitesButton;
