/* eslint-disable func-names */
import { all, call, put, takeLatest } from 'redux-saga/effects';
import * as RapidAction from '../actions/rapid';
import ClapiApi from '../api/clapi';
import Bugsnag from '../bugsnag';
import ApiHelper from '../helpers/ApiHelper';
import { getAddressCollectiveSuccess, GET_ADDRESS_COLLECTIVE } from '../actions/clapi';
import {cruxAppError} from "../actions/errorHandler";
import ErrorMsg from "../constants/errorMsg";
import {ErrorType} from "../constants/redirect";

const getByPropertyIdFromList = (rapidResponse, propertyId) => {
    const { data } = rapidResponse || {};
    if (rapidResponse && data && data.length) {
        return data
            .find(response => `${response.id}` === `${propertyId}`);
    }
    return null;
};

function getPropertyByIDFn() {
    return function* (action) {
        try {
            const {
                payload: {
                    propertyId,
                },
            } = action;
            const filters = {
                id: propertyId,
                isActive: 'true,false',
            };
            const [rapidResponse, photos] = yield all([
                call(
                    ClapiApi.getBatchSearch,
                    filters,
                ),
                call(
                    ClapiApi.getPhotos,
                    propertyId,
                ),
            ]);
            if (rapidResponse && rapidResponse.errors) {
                throw new Error('Error at rapid search response.');
            }
            const property = getByPropertyIdFromList(rapidResponse, propertyId);
            yield put({
                type: RapidAction.GET_PROPERTY_BY_ID_SUCCESS,
                payload: {
                    ...property,
                    defaultPhoto: photos && photos.find(photo => photo.isDefaultPhoto),
                },
            });
        } catch (e) {
            Bugsnag.notify(e);
            yield put({
                type: RapidAction.GET_PROPERTY_BY_ID_FAIL,
            });
        }
    };
}

export const getPropertyByID = getPropertyByIDFn();
function* getPropertyByIDWatcher() {
    yield takeLatest(RapidAction.GET_PROPERTY_BY_ID, getPropertyByID);
}

function getPropertyByAddressFn() {
    return function* (action) {
        try {
            const {
                payload: {
                    propertyId,
                    addressComplete,
                },
            } = action;
            const filters = {
                addressComplete,
                isActive: 'true,false',
            };
            const rapidResponse = yield call(
                ClapiApi.getBatchSearch,
                filters,
            );

            if (rapidResponse && rapidResponse.errors) {
                throw new Error('Error at rapid search response.');
            }
            const property = getByPropertyIdFromList(rapidResponse, propertyId);
            yield put({
                type: RapidAction.GET_PROPERTY_BY_ADDRESS_SUCCESS,
                payload: property,
            });
        } catch (e) {
            Bugsnag.notify(e);
            yield put({
                type: RapidAction.GET_PROPERTY_BY_ADDRESS_FAIL,
            });
        }
    };
}

function getAddressCollectiveFn() {
    return function* (action) {
        try {
            if (action.payload) {
                const response = yield call(ClapiApi.getBatchSearch,
                    {
                        ...ApiHelper.buildSuggestionRapidSearchFilter(action.payload.suggestion),
                        limit: 1,
                        isActive: action.payload.historic ? 'true,false' : 'true',
                    });
                yield put(getAddressCollectiveSuccess(response));
            }
        } catch (error) {
            yield put(getAddressCollectiveSuccess());
            yield put(cruxAppError(ErrorMsg.FAILED_TO_FETCH, ErrorType.PAGE_ERROR));
        }
    }
}

export const getPropertyByAddress = getPropertyByAddressFn();
function* getPropertyByAddressWatcher() {
    yield takeLatest(RapidAction.GET_PROPERTY_BY_ADDRESS, getPropertyByAddress);
}

export const getAddressCollective = getAddressCollectiveFn();
function* getAddressCollectiveWatcher() {
    yield takeLatest(GET_ADDRESS_COLLECTIVE, getAddressCollective);
}

export default [
    getPropertyByIDWatcher(),
    getPropertyByAddressWatcher(),
    getAddressCollectiveWatcher(),
];
