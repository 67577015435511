import { Map as IMap } from 'immutable';
import { LD_INIT, LD_INIT_FAIL, LD_INIT_SUCCESS } from '../actions/launchDarkly';

export const initialState = IMap({
    featureFlag: {},
    isInitialized: false,
    isFeatureFlagInitialized: false,
    hasFailed: false,
});

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case LD_INIT:
            return state.merge(IMap({
                isInitialized: true,
            }));
        case LD_INIT_FAIL:
            return state.merge(IMap({
                hasFailed: true,
            }));
        case LD_INIT_SUCCESS:
            return state.merge(IMap({
                featureFlag: action.payload,
                isFeatureFlagInitialized: true,
            }));
        default:
            return state;
    }
}
