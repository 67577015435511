import { put, takeLatest, call } from 'redux-saga/effects';
import { LA_ACTION } from '../actions/linkedAccounts';
import { PUBLIC_CONFIGURATION_INIT_SUCCESS} from '../actions/config';
import { routeCodes } from '../constants/routes';
import Clapi from '../api/clapi';
import cruxProxy from '../api/cruxProxy';
import UserPreferences from '../api/userPreferences';
import API from '../api/index';

export function* fetchLinkedAccounts_TncAsync() {
    try {
        yield put({ type: LA_ACTION.FETCH_ACCOUNTS_FETCHING });
        const { listedAccount } = yield call(Clapi.getLinkedAccounts);
        const tncPermanentlyAccepted = yield call(UserPreferences.tncPermanentlyAccepted);
        const postJoinInProgress = yield call(cruxProxy.postJoinInProgress);
        const payload = {
            listedAccount,
            tncPermanentlyAccepted,
            postJoinInProgress,
        };
        yield put({ type: LA_ACTION.FETCH_ACCOUNTS_SUCCESS, payload });
    } catch (error) {
        console.log("sagas/linkedAccounts.js, error = " + JSON.stringify(error));

        if (API.logoutInitiated()) {
            cruxProxy.saveRequestedPath();
        } else {
            const errorMsg = error.response?.errors?.[0]?.msg || 'unknown error';
            window.location.replace(`${routeCodes.LOGIN_ERROR.path}?errorMsg=${errorMsg}`);
        }
    }
}

export function* fetchLinkedAccountsAsyncWatcherInit() {
    yield takeLatest(
        PUBLIC_CONFIGURATION_INIT_SUCCESS,
        fetchLinkedAccounts_TncAsync,
    );
}

export default [
    fetchLinkedAccountsAsyncWatcherInit(),
];
