import { call, put, select, takeLatest, takeEvery } from 'redux-saga/effects';
import {
    GET_USERS,
    GET_USERS_SUCCESS,
    GET_USERS_FAIL,
    SET_USER_ADMIN_ROLE,
    SEARCH_USERS,
    SEARCH_USERS_SUCCESS,
    SEARCH_USERS_FAIL,
    INVITE_USERS,
    SET_USER_TITLES,
    SET_USER_POI_ROLE,
    setUserUpdateStatus,
    setUserAdminRoleSuccess,
    setUserAdminRoleFailed,
    inviteUsersSuccess,
    inviteUsersFailed,
    setUserTitlesStatus,
    setUserTitlesFailed,
    setUserTitlesSuccess,
    setUserPOIRoleSuccess,
    setUserPOIRoleFailed,
    setUserPOIStatus,
} from '../actions/manageUsers';
import UserPrefApi from '../api/userPreferences';
import { trackEvent } from '../actions/segment';
import Segment from '../helpers/Segment';
import { getInvitedUsers } from '../components/account/manageUsers/SendInvitesButton';
import ToastMessage from '../constants/toastMessage';

const SUCCESS = 'Success';
const FAILURE = 'Failure';
const ADD_USER = 'Add User';

const handleAddUserEvent = properties => (trackEvent(Segment.manageUserEvent({
    eventName: ADD_USER, changeType: ADD_USER, ...properties,
})));

export function* handleInviteUsersMixPanelEvents(result, emailsFromPayload, data = {}) {
    const { errorInvites = [], successInvite = {} } = data;
    if (result === FAILURE) {
        yield put(handleAddUserEvent({ result, objectContext: emailsFromPayload }));
    } else {
        const successInviteList = getInvitedUsers(successInvite);
        if (errorInvites.length) {
            const failedEmails = errorInvites.map(invite => invite.email);
            yield put(handleAddUserEvent({ result: FAILURE, objectContext: failedEmails }));
            if (!successInviteList.length) {
                yield put(inviteUsersFailed({
                    message: ToastMessage.ERROR_INVITES_FAILURE,
                    isError: true,
                    showToast: true,
                }));
            }
        }

        if (successInviteList.length) {
            yield put(handleAddUserEvent({ result: SUCCESS, objectContext: successInviteList }));
            yield put(inviteUsersSuccess({
                ...data,
                isError: false,
                showToast: true,
                message: '',
            }));
        }
    }
}

function getUsers() {
    return function* (successType, failType, action) {
        try {
            const state = yield select();
            const users = yield call(
                UserPrefApi.getUsers,
                state.config.get('applicationDomain').userPreferences,
                state.clapi.get('usrDetail').accountDetail.externalAccountId,
                state.manageUsers.get('userFilters'),
            );
            yield put({
                type: successType,
                payload: users,
            });
        } catch (e) {
            yield put({
                type: failType,
                payload: {
                    handler: {
                        isError: true,
                        message: e.message,
                    },
                    userFilters: action.payload.previousFilters,
                },
            });
        }
    };
}

function setUserPOIRoleFn() {
    return function* (action) {
        let result;
        const { checked, sub, index } = action.payload;
        const { eventNameChangeType, objectContext } = action.mixpanel;
        try {
            yield put(setUserPOIStatus({
                index,
                isUpdateSuccess: false,
                isPOILoading: true,
                showToast: false,
            }));
            yield call(
                UserPrefApi.setCanPayInArrears,
                checked,
                sub,
            );
            yield put(setUserPOIRoleSuccess({ index, checked }));
            yield put(setUserPOIStatus({
                index,
                isUpdateSuccess: true,
                isPOILoading: false,
                showToast: true,
            }));
            result = SUCCESS;
        } catch (e) {
            yield put(setUserPOIRoleFailed({
                isError: true,
                message: e.message,
            }));
            yield put(setUserPOIStatus({
                index,
                isUpdateSuccess: false,
                isPOILoading: false,
                showToast: true,
            }));
            result = FAILURE;
        }
        yield put(trackEvent(Segment.manageUserEvent({
            objectContext,
            result,
            eventName: eventNameChangeType,
            changeType: eventNameChangeType,
        })));
        yield put(setUserPOIStatus({ index, showToast: false }));
    };
}

function setUserAdminRoleFn() {
    return function* (action) {
        let result;
        const { checked, sub, index } = action.payload;
        const { eventNameChangeType, objectContext } = action.mixpanel;
        try {
            const { config } = yield select();
            yield put(setUserUpdateStatus({
                index,
                isUpdateSuccess: false,
                isLoading: true,
                showToast: false,
            }));
            yield call(
                UserPrefApi.setUserAdminRole,
                config.get('applicationDomain').userPreferences,
                sub,
                checked,
            );
            yield put(setUserAdminRoleSuccess({ checked, index }));
            yield put(setUserUpdateStatus({
                index,
                isUpdateSuccess: true,
                isLoading: false,
                showToast: true,
            }));
            result = SUCCESS;
        } catch (e) {
            yield put(setUserAdminRoleFailed({
                isError: true,
                message: e.message,
            }));
            yield put(setUserUpdateStatus({
                index,
                isUpdateSuccess: false,
                isLoading: false,
                showToast: true,
            }));
            result = FAILURE;
        }
        yield put(trackEvent(Segment.manageUserEvent({
            objectContext,
            result,
            eventName: eventNameChangeType,
            changeType: eventNameChangeType,
        })));
        yield put(setUserUpdateStatus({ index, showToast: false }));
    };
}

function inviteUsers() {
    return function* (action) {
        let result = SUCCESS;
        let response;
        const { emails, redirectUri } = action.payload;
        try {
            const { config } = yield select();
            response = yield call(
                UserPrefApi.postInviteUsers,
                config.get('applicationDomain').userPreferences,
                emails,
                redirectUri,
            );
        } catch (e) {
            yield put(inviteUsersFailed({
                message: ToastMessage.NETWORK_ERROR,
                isError: true,
                showToast: true,
            }));
            result = FAILURE;
        }
        yield call(handleInviteUsersMixPanelEvents, result, emails, response);
    };
}

function setCanOrderTitlesFn() {
    return function* (action) {
        const { checked, sub, index } = action.payload;
        const { eventNameChangeType, objectContext } = action.mixpanel;
        let result;
        try {
            const { config } = yield select();
            yield put(setUserTitlesStatus({
                index,
                isUpdateSuccess: false,
                isLoading: true,
                showToast: false,
            }));
            yield call(
                UserPrefApi.setCanOrderTitles,
                config.get('applicationDomain').userPreferences,
                sub,
                checked,
            );
            yield put(setUserTitlesSuccess({ checked, index }));
            yield put(setUserTitlesStatus({
                index,
                isUpdateSuccess: true,
                isLoading: false,
                showToast: true,
            }));
            result = SUCCESS;
        } catch (e) {
            yield put(setUserTitlesFailed({
                isError: true,
                message: e.message,
            }));
            yield put(setUserTitlesStatus({
                index,
                isUpdateSuccess: false,
                isLoading: false,
                showToast: true,
            }));
            result = FAILURE;
        }
        yield put(trackEvent(Segment.manageUserEvent({
            objectContext,
            result,
            eventName: eventNameChangeType,
            changeType: eventNameChangeType,
        })));
        yield put(setUserTitlesStatus({ index, showToast: false }));
    };
}

export const setCanOrderTitles = setCanOrderTitlesFn();
export const setUserPOIRole= setUserPOIRoleFn();
export const setUserAdminRole = setUserAdminRoleFn();
export const getUsersGenerator = getUsers();
export const inviteUsersGenerator = inviteUsers();

function* getUsersWatcher() {
    yield takeLatest(GET_USERS, getUsersGenerator, GET_USERS_SUCCESS, GET_USERS_FAIL);
}

function* searchUsersWatcher() {
    yield takeLatest(SEARCH_USERS, getUsersGenerator, SEARCH_USERS_SUCCESS, SEARCH_USERS_FAIL);
}

function* setPOIRoleWatcher() {
    yield takeEvery(SET_USER_POI_ROLE, setUserPOIRole);
}
function* setUserAdminRoleWatcher() {
    yield takeEvery(SET_USER_ADMIN_ROLE, setUserAdminRole);
}
function* inviteUsersWatcher() {
    yield takeLatest(INVITE_USERS, inviteUsersGenerator);
}

function* setUserTitlesWatcher() {
    yield takeEvery(SET_USER_TITLES, setCanOrderTitles);
}

export default [
    getUsersWatcher(),
    setPOIRoleWatcher(),
    setUserAdminRoleWatcher(),
    searchUsersWatcher(),
    inviteUsersWatcher(),
    setUserTitlesWatcher(),
];
