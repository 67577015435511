import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import AddIcon from '@mui/icons-material/Add';
import { blue } from '@mui/material/colors';

import { addSavedList, addSavedListInfo } from 'js/actions/watchList';
import UserPreferences from 'js/api/userPreferences';
import { SAVE_SEARCH_ACCEPTABLE_CHARS } from 'js/constants/regexPatterns';
import { trackEvent } from 'js/actions/segment';
import SegmentHelper from 'js/helpers/Segment';
import ErrorMessage from 'js/constants/errorMsg';
import { selectSelectedAccount } from '../../../selectors/linkedAccount';

const CreateSavedList = ({
    handleSnackBar,
    listRef,
    page,
    properties,
    searchTab,
    isHistoricProperty,
}) => {
    const dispatch = useDispatch();
    const { selectedClAppAccountUserGuid } = useSelector(selectSelectedAccount, shallowEqual);

    const [newSavedList, setNewSavedList] = useState('');
    const [loading, setLoading] = useState(false);
    const [invalidName, setInvalidName] = useState(false);

    const validateChars = (submit) => {
        const acceptableValues = new RegExp(SAVE_SEARCH_ACCEPTABLE_CHARS);
        if (acceptableValues.test(newSavedList)) {
            submit();
        } else {
            setInvalidName(true);
            handleSnackBar('error', 'Invalid character entered');
        }
    };

    const handleChange = (e) => {
        if (invalidName) {
            setInvalidName(false);
        }
        setNewSavedList(e.target.value);
    };

    const handleSubmit = () => {
        validateChars(() => {
            if (!loading) {
                const listToAdd = {
                    description: newSavedList.trim(),
                    propertiesCount: properties?.length || 0,
                };

                setLoading(true);
                UserPreferences
                    .createSavedList(
                        selectedClAppAccountUserGuid,
                        {
                            ...listToAdd,
                            properties: {
                                propertyIds: properties,
                            },
                        },
                    )
                    .then((res) => {
                        if (res.statusCode === 'CREATED') {
                            dispatch(addSavedList({
                                ...listToAdd,
                                id: Number(res.message),
                            }));
                            dispatch(addSavedListInfo({
                                id: [res.message],
                                properties,
                            }));
                            handleSnackBar('success', `${newSavedList} list was created successfully`);
                            listRef.current.scrollTop = 0;
                            dispatch(trackEvent(SegmentHelper.createSavedList({
                                savedListName: newSavedList,
                                pageContext: page,
                                searchTab,
                            })))
                        }
                    })
                    .catch((e) => {
                        if (e?.response?.statusCode === 'CONFLICT') {
                            handleSnackBar('error', ErrorMessage.DUPLICATE_SAVED_LIST);
                        } else {
                            handleSnackBar('error', ErrorMessage.CREATE_SAVED_LIST_ERROR);
                        }
                    })
                    .finally(() => {
                        setLoading(false);
                        setNewSavedList('');
                    });
            }
        });
    };

    return (
        <div data-testid="create-saved-list-box">
            <Grid container spacing={0.5} mb={1} >
                <Grid item xs={10.2}>
                    <TextField
                        label="Create New"
                        variant="outlined"
                        size="small"
                        value={newSavedList}
                        onChange={handleChange}
                        disabled={loading || isHistoricProperty}
                        inputProps={{ maxLength: 255 }}
                        error={invalidName}
                        sx={{
                            width: '100%',
                            fontSize: 13,
                            '& .MuiInputBase-root': {
                                height: 40,
                                fontSize: 13,
                            },
                            '& .MuiOutlinedInput-notchedOutline legend': {
                                fontSize: 11,
                            },
                            '& .MuiFormLabel-root': {
                                fontSize: 14,
                            },
                        }}
                    />
                </Grid>
                <Grid
                    item
                    xs={1.8}
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                    }}
                >
                    <Box sx={{ position: 'relative' }}>
                        <Button
                            aria-label="add"
                            color="primary"
                            variant="contained"
                            disabled={loading || !newSavedList?.trim() || invalidName}
                            sx={{
                                height: 40,
                                width: 40,
                                minWidth: 40,
                            }}
                            onClick={handleSubmit}
                        >
                            <AddIcon />
                        </Button>
                        {loading && (
                            <CircularProgress
                                size={24}
                                sx={{
                                    color: blue[500],
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    marginTop: '-12px',
                                    marginLeft: '-12px',
                                }}
                            />
                        )}
                    </Box>
                </Grid>
            </Grid>
        </div>
    );
}

CreateSavedList.propTypes = {
    setOpenSnackBar: PropTypes.func,
    setSnackBarError: PropTypes.func,
    setSeverity: PropTypes.func,
    listRef: PropTypes.object,
    page: PropTypes.string,
    properties: PropTypes.array,
    searchTab: PropTypes.string,
    isHistoricProperty: PropTypes.bool,
};

export default CreateSavedList;
