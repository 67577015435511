import { takeLatest, put, call } from 'redux-saga/effects';
import Report from '../api/report';
import {
    CONVERT_HTML_TO_PDF_PDP,
    CONVERT_HTML_TO_PDF_PDP_FAIL,
    CONVERT_HTML_TO_PDF_PDP_SUCCESS,
} from '../actions/printPdp';
import {
    CONVERT_GENERIC_HTML_TO_PDF_SUCCESS,
    CONVERT_GENERIC_HTML_TO_PDF_FAIL,
    CONVERT_GENERIC_HTML_TO_PDF,
} from '../actions/genericHtmlToPdf';
import Bugsnag from '../bugsnag';

function convertHtmlToPdfFn() {
    return function* (successType, failType, action) {
        try {
            const response = yield call(Report.convertToPdfReport, action.payload);
            yield put({
                type: successType,
                payload: response.pdfBytes,
            });
        } catch (error) {
            Bugsnag.notify(error);
            yield put({
                type: failType,
                failedRequest: action.payload,
            });
        }
    };
}

export const convertHtmlToPdf = convertHtmlToPdfFn();

export function* convertHtmlToPdfPdpWatcher() {
    yield takeLatest(
        CONVERT_HTML_TO_PDF_PDP,
        convertHtmlToPdf,
        CONVERT_HTML_TO_PDF_PDP_SUCCESS,
        CONVERT_HTML_TO_PDF_PDP_FAIL
    );
}

export function* convertHtmlToPdfSearchCardWatcher() {
    yield takeLatest(
        CONVERT_GENERIC_HTML_TO_PDF,
        convertHtmlToPdf,
        CONVERT_GENERIC_HTML_TO_PDF_SUCCESS,
        CONVERT_GENERIC_HTML_TO_PDF_FAIL
    );
}

export default [
    convertHtmlToPdfPdpWatcher(),
    convertHtmlToPdfSearchCardWatcher(),
];
