import { Children, cloneElement } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import FeatureFlag from 'js/model/launchDarkly/FeatureFlag';
import Entitlements from 'js/helpers/Entitlements';

const Featured = ({
    flag,
    role,
    children,
    displayWhenFalse,
    displayWhenUnEntitled = true,
    forceHide = false,
    ...rest
}) => {
    const userDetail = useSelector(state => state?.clapi.get('usrDetail'));
    const featureFlags = useSelector(state => state?.launchDarkly.get('featureFlag'));
    const featureFlag = new FeatureFlag(featureFlags[flag]);

    if (forceHide) return null;

    const [Child] = Children.toArray(children);
    if (featureFlag.isTrue()) {
        return cloneElement(Child, {
            entitled: true,
            ...rest,
        });
    } else if (featureFlag.isEntitlements()) {
        let isEntitled = false;
        if (Entitlements.hasRole(userDetail, role)) {
            isEntitled = true;
        }
        return displayWhenUnEntitled || isEntitled ? cloneElement(Child, {
            entitled: isEntitled,
            ...rest,
        }) : null;
    }
    return displayWhenFalse
        ? children
        : null;
}

Featured.propTypes = {
    role: PropTypes.string,
    flag: PropTypes.string,
    displayWhenFalse: PropTypes.bool,
    displayWhenUnEntitled: PropTypes.bool,
    forceHide: PropTypes.bool,
};

export default Featured;
