import React from 'react';
import PropTypes from 'prop-types';
import { routeCodes, getRoute } from '../../constants/routes';
import { CORELOGIC_LOGO } from '../../helpers/Localization';
import { viewHomeEvent } from '../../actions/segment';

// PURPOSE OF THIS COMPONENT IS TO DISPLAY A VERY BASIC HEADER
// WHEN RANDOM COMPONENT THROWS AN ERROR DURING RUNTIME

const BareMenu = ({ dispatch, match, history }) => {
    const route = getRoute(match.path);

    const handleLogoOnClick = () => {
        const payload = {
            event: 'Click CoreLogic Header Logo',
            route,
        };
        dispatch(viewHomeEvent(payload));
        history.push(routeCodes.HOME.path);
    };

    return (
        <header id="crux-nav">
            <div className="crux-header">
                <div className="container-fluid heading-container">
                    <div className="nav-section">
                        <div className="logo">
                            <button data-testid="baremenu-cl-logo" className="cl-logo" onClick={handleLogoOnClick}>
                                <img data-testid="baremenu-cl-logo-img" className="cl-logo-img" alt="CoreLogic" src={CORELOGIC_LOGO} />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </header >
    );
};

BareMenu.propTypes = {
    dispatch: PropTypes.func,
    match: PropTypes.object,
    history: PropTypes.object,
};

export default BareMenu;
