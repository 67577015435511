import { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import Switch from '@mui/material/Switch';
import Checkbox from '@mui/material/Checkbox';
import MenuItem from '@mui/material/MenuItem';
import { styled, Tooltip } from '@mui/material';
import TextField from '@mui/material/TextField';
import InfoIcon from '@mui/icons-material/Info';
import Autocomplete from '@mui/material/Autocomplete';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import CircularProgress from '@mui/material/CircularProgress';
import InputAdornment from '@mui/material/InputAdornment';
import Colors from '../../constants/colors';
import ReduxBlockUi from '../blockUi/ReduxBlockUi';

const StyledSwitch = styled(Switch)({
    '& .MuiSwitch-switchBase': {
        '&.Mui-disabled': {
            color: Colors.DISABLED_SWITCH,
            '+ .MuiSwitch-track': {
                backgroundColor: Colors.BLACK,
            },
        },
    },
});

export const renderCheckbox = ({ input, label, ...custom }) => (
    <Checkbox
        label={label}
        onChange={input.onChange}
        {...custom}
    />
);

export const SmallSwitch = (props) => {
    const { label, input, ...custom } = props;
    return (
        <Switch
            size="small"
            label={label}
            onChange={input.onChange}
            {...custom}
        />
    )
};

export const CruxSwitch = (props) => {
    const {
        classes, className, disabled, checked, onChange,
    } = props;
    return (
        <StyledSwitch
            className={`${className}`}
            classes={classes}
            disableRipple
            disabled={disabled}
            checked={checked}
            onChange={onChange}
            value="checked"
            color="primary"
            // overrides bootstrap.min.css marginTop 4px
            inputProps={{ style: { margin: 0 } }}
        />
    );
};

CruxSwitch.propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    checked: PropTypes.bool,
    classes: PropTypes.object.isRequired,
};


export const RadiusSuburbOnlyToggle = (props) => {
    const { onChange, ...rest } = props;
    return (
        <CruxSwitch
            {...rest}
            onChange={(e) =>
                onChange({ name: 'suburbOnly', value: e.target.checked }, props.fieldName)}
        />
    )
};

RadiusSuburbOnlyToggle.propTypes = {
    onChange: PropTypes.func,
    fieldName: PropTypes.string,
    checked: PropTypes.bool,
};

const StyledInput = styled(TextField, {
    shouldForwardProp: (prop) => prop !== 'readOnly',
})(({ theme, readOnly }) => ({
    display: 'flex',
    margin: '15px 0',
    width: '100%',
    '& .MuiFormLabel-root': {
        color: readOnly ? Colors.BLACK_54_OPAQUE : Colors.BLACK_60_OPAQUE,
        lineHeight: '12px',
        letterSpacing: '0.15px',
        pointerEvents: 'auto',
        fontSize: 14.8571,
        width: '100%',
        marginBottom: 0,
        overflow: 'visible',
    },
    '& .MuiInputBase-input': {
        fontSize: '16px',
        lineHeight: '24px',
        letterSpacing: '0.15px',
        userSelect: readOnly ? 'none' : 'auto',
    },
    '& .MuiOutlinedInput-root:not(.Mui-error)': {
        '&:hover fieldset': {
            borderColor: theme.palette.primary.main,
        },
    },
    '& .MuiFormHelperText-root': {
        opacity: 1
    },
}));

export const MuiInput = (props) => {
    const {
        placeholder,
        type,
        maxLength = 100,
        label,
        name,
        readOnly,
        error,
        tooltipMessage,
        InputProps = {},
        InputLabelProps = {},
        inputProps = {},
        className,
        ...others
    } = props;
    const variant = readOnly ? 'standard' : 'outlined';
    const renderInputLabelTooltip = () => (
        <Fragment>
            <span>{label}</span>
            <Tooltip
                arrow
                placement="right"
                className={`${name}-tooltip`}
                title={tooltipMessage}
            >
                <InfoIcon
                    className="mui-info-icon"
                    fontSize="inherit"
                    data-testid={`${name}-info-icon`}
                />
            </Tooltip>
        </Fragment>
    );

    const textfieldLabel = tooltipMessage
        ? renderInputLabelTooltip()
        : label;
    const otherClassNames = className ? `${className} ` : '';

    return (
        <StyledInput
            className={`${otherClassNames}mui-input`}
            id={name}
            variant={variant}
            label={textfieldLabel}
            type={type}
            placeholder={placeholder}
            maxLength={maxLength}
            InputProps={{ ...InputProps, readOnly, disableUnderline: true }}
            InputLabelProps={{ ...InputLabelProps, shrink: true }}
            // eslint-disable-next-line react/jsx-no-duplicate-props
            inputProps={{ ...inputProps, maxLength, disabled: readOnly }}
            error={!!error}
            helperText={error}
            {...others}
        />
    );
};

MuiInput.propTypes = {
    placeholder: PropTypes.string,
    type: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    readOnly: PropTypes.bool,
};

MuiInput.defaultProps = {
    readOnly: false,
};

export const MuiAutoComplete = (props) => {
    const [open, setOpen] = useState(false);
    const {
        name,
        readOnly,
        value,
        onInputChange,
        onSelectOption,
        options,
        onChange,
        testId,
        optionLabel,
        ...restProps
    } = props;
    const inputValue = value || '';
    const handleOpen = () => !readOnly && setOpen(true);
    const handleClose = () => setOpen(false);
    const handleChange = (_, selectedValue) => {
        onChange({ target: { value: selectedValue[optionLabel] }});
        onSelectOption(selectedValue)
    };
    const handleInputChange = (_, newValue) => onInputChange(newValue);
    const handleOptionLabel = option => {
        const keyToMap = optionLabel ? option[optionLabel] : option.title;
        return keyToMap || '';
    };

    return (
        <Autocomplete
            id={name}
            open={open}
            className="mui-autocomplete"
            inputValue={inputValue}
            clearOnBlur={false}
            getOptionLabel={handleOptionLabel}
            onOpen={handleOpen}
            onClose={handleClose}
            onChange={handleChange}
            onInputChange={handleInputChange}
            options={options || []}
            data-testid={testId}
            renderOption={(_props, option, { _inputValue }) => {
                const matches = match(option[optionLabel], _inputValue);
                const parts = parse(option[optionLabel], matches);
                return (
                    <li {..._props} style={{ fontSize: '13px' }}>
                        <div>
                            {parts.map((part, index) => (
                                <span
                                    key={index}
                                    style={{
                                        fontWeight: part.highlight ? 500 : 400,
                                    }}
                                >
                  {part.text}
                </span>
                            ))}
                        </div>
                    </li>
                );
            }}
            renderInput={params => {
                const { InputProps, InputLabelProps, inputProps, ...restParams } = params;
                return (
                    <div title={inputValue}>
                        <MuiInput
                            {...restProps}
                            {...restParams}
                            type="text"
                            name={name}
                            readOnly={readOnly}
                            InputProps={InputProps}
                            InputLabelProps={InputLabelProps}
                            inputProps={inputProps}
                            onChange={onChange}
                        />
                    </div>
                )
            }}
            freeSolo
            disableClearable
        />
    )
};

MuiAutoComplete.propTypes = {
    name: PropTypes.string.isRequired,
    optionLabel: PropTypes.string,
    value: PropTypes.string,
    showAllOptions: PropTypes.bool,
    disableInput: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    onSelectOption: PropTypes.func.isRequired,
    onInputChange: PropTypes.func,
    readOnly: PropTypes.bool.isRequired,
};

export const MuiSelect = (props) => {
    const { options = [] } = props;
    const selectProps = {
        MenuProps: {
            anchorOrigin: {
                vertical: "bottom",
                horizontal: "left"
            },
            getContentAnchorEl: null,
        },
    };
    return (
        <MuiInput
            {...props}
            type="text"
            select
            SelectProps={selectProps}
        >
            {options.map(item => <MenuItem key={item} value={item}>{item}</MenuItem>)}
        </MuiInput>
    )
};

MuiSelect.propTypes = {
    options: PropTypes.array.isRequired,
};

export const MuiTextFieldLoader = (props) => {
    const { blocking, block, unblock, loaderClassName } = props;
    return (
        <InputAdornment
            position='end'
            sx={{
                width: '25px'
            }}
        >
            <ReduxBlockUi
                className={loaderClassName}
                loader={<CircularProgress size={20}/>}
                blocking={blocking}
                block={block}
                unblock={unblock}
                style={{
                    backgroundColor: 'unset',
                    position: 'relative',
                    right: '17px',
                }}
            />
        </InputAdornment>
    );
}
