import { useState, memo } from 'react';
import PropTypes from 'prop-types';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import MenuList from '@mui/material/MenuList';
import MapTypeConstant from '../../../../../constants/mapTypeConstant';
import { isNZ, isAU } from '../../../../../constants/crux';
import { DEFAULT_MAP } from '../../../../../helpers/Localization';
import MapType from './MapType';
import SubMapType from './SubMapType';

const {
    LABELS, SATELLITE, ROADMAP, TERRAIN, CORELOGIC_ROAD,
} = MapTypeConstant;

const GoogleMapTypes = memo(({
    setMapTypeIdState,
    handleClose,
    mapTypeIdState,
    triggerMapViewChangeEvent,
}) => {
    // roadMap and terrain are both options for Google Map
    const [roadMap, setRoadMap] =
        useState(mapTypeIdState === ROADMAP || mapTypeIdState === TERRAIN);
    const [terrain, setTerrain] = useState(mapTypeIdState === TERRAIN);
    // satellite and label are both options for Google Satellite
    const [satellite, setSatellite] =
        useState(mapTypeIdState === SATELLITE || mapTypeIdState === LABELS);
    const [label, setLabel] = useState(mapTypeIdState === LABELS);

    const updateGoogleBaseMapOptions = (isChecked, isParentOnly) => {
        setRoadMap(isChecked);
        if (isParentOnly) return;
        setTerrain(isChecked);
    };

    const updateGoogleBaseSatelliteOptions = (isChecked, isParentOnly) => {
        setSatellite(isChecked);
        if (isParentOnly) return;
        setLabel(isChecked);
    };

    const onChangeSatellite = (event) => {
        const isChecked = !satellite;
        if (isChecked) {
            updateGoogleBaseSatelliteOptions(true);
            updateGoogleBaseMapOptions(false);
            triggerMapViewChangeEvent(LABELS);
            setMapTypeIdState(LABELS);
        } else {
            updateGoogleBaseSatelliteOptions(false);
            updateGoogleBaseMapOptions(isAU, true);
            triggerMapViewChangeEvent(DEFAULT_MAP);
            setMapTypeIdState(DEFAULT_MAP);
        }
        handleClose(event);
    };

    const onChangeLabel = (event) => {
        const isChecked = !label;
        if (isChecked) {
            updateGoogleBaseSatelliteOptions(true);
            updateGoogleBaseMapOptions(false);
            triggerMapViewChangeEvent(LABELS);
            setMapTypeIdState(LABELS);
        } else {
            setLabel(false);
            triggerMapViewChangeEvent(SATELLITE);
            setMapTypeIdState(SATELLITE);
        }
        handleClose(event);
    };

    const onChangeRoadMap = (event) => {
        const isChecked = !roadMap;
        if (isChecked) {
            updateGoogleBaseMapOptions(true, true);
            updateGoogleBaseSatelliteOptions(false);
            triggerMapViewChangeEvent(ROADMAP);
            setMapTypeIdState(ROADMAP);
        } else {
            updateGoogleBaseMapOptions(false);
            updateGoogleBaseSatelliteOptions(isAU, true);
            triggerMapViewChangeEvent(isNZ ? CORELOGIC_ROAD : LABELS);
            setMapTypeIdState(isNZ ? CORELOGIC_ROAD : LABELS);
        }
        handleClose(event);
    };

    const onChangeTerrain = (event) => {
        const isChecked = !terrain;
        if (isChecked) {
            updateGoogleBaseMapOptions(true);
            updateGoogleBaseSatelliteOptions(false);
            triggerMapViewChangeEvent(TERRAIN);
            setMapTypeIdState(TERRAIN);
        } else {
            setTerrain(false);
            triggerMapViewChangeEvent(ROADMAP);
            setMapTypeIdState(ROADMAP);
        }
        handleClose(event);
    };

    return (
        <ClickAwayListener onClickAway={handleClose}>
            <MenuList>
                <MapType onClick={onChangeSatellite} checked={satellite}>
                    Satellite
                </MapType>
                <SubMapType onClick={onChangeLabel} checked={label}>
                    Labels
                </SubMapType>
                <MapType onClick={onChangeRoadMap} checked={roadMap}>
                    Road
                </MapType>
                <SubMapType onClick={onChangeTerrain} checked={terrain}>
                    Terrain
                </SubMapType>
            </MenuList>
        </ClickAwayListener>
    );
});
GoogleMapTypes.propTypes = {
    setMapTypeIdState: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
    mapTypeIdState: PropTypes.string.isRequired,
    triggerMapViewChangeEvent: PropTypes.func,
};
export default GoogleMapTypes;
