import { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm, reset, formValueSelector } from 'redux-form';
import PropTypes from 'prop-types';
import Reports from '../../../constants/reports';
import ReportForm, { GenerateReportButton } from '../ReportForm';
import { ReportSwitch, ReportTextField } from '../ReportFormFields';
import ReportHelper from '../../../helpers/report/ReportHelper';
import ReportsAPI from '../../../api/report';
import Commons from '../../../helpers/Commons';
import SegmentHelper from '../../../helpers/Segment';
import SEGMENT from '../../../constants/segment';
import { trackEvent } from '../../../actions/segment';
import { entryPoints } from '../../../constants/entryPoint';
import DPPRPreviewModal from './DPPRPreviewModal';
import { routeCodes } from '../../../constants/routes';

const selector = formValueSelector('digitalPropertyReportForm');
export class DigitalPropertyReport extends Component {
    static propTypes = {
        dispatch: PropTypes.func,
        toggleReportModal: PropTypes.func,
        clapiUsrDetail: PropTypes.object,
        entryPoint: PropTypes.string,
        propertyId: PropTypes.number,
        propertyAddress: PropTypes.string,
        suburbAddress: PropTypes.string,
        route: PropTypes.object,
    };

    constructor(props) {
        super(props);
        this.state = {
            includePhone: true,
            includeValuation: true,
            isReportModalOpen: false,
            digitalPropertyReport: null,
            isErrorFetching: false,
        };

        const { email, phoneNumber } = ReportHelper.getUserContactDetail(props.clapiUsrDetail);
        this.userName = `${Commons.get(this.props, 'clapiUsrDetail.firstName')} ${Commons.get(this.props, 'clapiUsrDetail.lastName')}`;
        this.email = email;
        this.phoneNumber = phoneNumber;
    }

    resetForm = () => {
        this.props.dispatch(reset('digitalPropertyReportForm'));
    };

    handleKeyPress = (e) => {
        if (e.key === 'Enter') e.preventDefault();
    };

    handleIOSToggle = (e, changedState) => {
        this.setState({ [changedState]: e.target.checked });
    };

    renderFields = () => {
        const { includePhone, includeValuation } = this.state;
        return (
            <div>
                <div className="form-group container-divider">
                    <p>Edit Report Details</p>
                </div>
                <hr />
                <ReportTextField
                    handleKeyPress={this.handleKeyPress}
                    name="preparedFor"
                    id="prepared-for"
                    placeholder="Enter recipient's name"
                    maxLength="112"
                    label="Prepared for"
                    autoFocus
                />
                <ReportSwitch
                    id="prepared-by-switch"
                    field="prepared by"
                    fieldValue={this.userName}
                    hideSwitch
                />
                <ReportSwitch
                    field="email"
                    fieldValue={this.email}
                    hideSwitch
                />
                <ReportSwitch
                    field="phone"
                    fieldValue={this.phoneNumber}
                    stateToChange="includePhone"
                    onChange={this.handleIOSToggle}
                    checked={includePhone}
                />
                <ReportSwitch
                    field="include corelogic automated valuation"
                    stateToChange="includeValuation"
                    onChange={this.handleIOSToggle}
                    checked={includeValuation}
                />
            </div>
        );
    };

    handleResetForm = () => {
        this.props.toggleReportModal();
        this.resetForm();
    };

    getEntryPoint = () => {
        const { route } = this.props;
        if (route.id === routeCodes.SEARCH_RESULT.id ||
            route.id === routeCodes.RADIUS_SEARCH_RESULT.id ||
            route.id === routeCodes.NAME_SEARCH_RESULT.id ||
            route.id === routeCodes.HOME.id) {
            return entryPoints[route.id].entryPoint;
        } else {
            return entryPoints.REPORTS_MENU;
        }
    }

    generateDPPR = async () => {
        const {
            propertyId, preparedFor, dispatch, route,
        } = this.props;
        const { includePhone, includeValuation } = this.state;
        this.setState({ isReportModalOpen: true, isErrorFetching: false, });
        const { pageContext } = route;
        dispatch(trackEvent(SegmentHelper.generateDpprReport({
            pageContext,
            entryPoint: route.id === 'property' ?
                entryPoints.REPORTS_MENU :
                entryPoints[route.id].entryPoint,
            propertyId,
            reportType: SEGMENT.EVENT_PROPERTIES.DIGITAL_PROPERTY_REPORT,
            reportFormat: SEGMENT.EVENT_PROPERTIES.HTML,
            includeAvm: includeValuation,
        })));

        const requestBody = {
            propertyId,
            email: this.email,
            phone: includePhone ? this.phoneNumber : null,
            preparedBy: this.userName,
            preparedFor,
        };

        try {
            const dpprResponse = includeValuation
                ? await ReportsAPI.getDigitalPropertyReport(requestBody)
                : await ReportsAPI.getDigitalPropertyReport(requestBody, false);
            this.setState({ digitalPropertyReport: dpprResponse });
        } catch (e) {
            this.setState({ isErrorFetching: true });
        }

    };
    render() {
        const { propertyId, route, propertyAddress, toggleReportModal, dispatch } = this.props;
        const { digitalPropertyReport, isReportModalOpen, isErrorFetching } = this.state;
        const fields = this.renderFields();
        return (
            <div>
                {
                    !isReportModalOpen &&
                    <ReportForm
                        propertyId={propertyId}
                        icon="property-report-white.png"
                        title="Digital Property Report"
                        fields={fields}
                        toggleReportModal={this.handleResetForm}
                        reportType={Reports.SUBURB_REPORT}
                        reportName={this.props.suburbAddress}
                        filename={this.state.filename}
                        entryPoint={entryPoints[route.id].genericEntryPoint}
                        renderPdfViewer={false}
                    >
                        <GenerateReportButton
                            onClick={this.generateDPPR}
                        />
                    </ReportForm>
                }

                <DPPRPreviewModal
                    open={isReportModalOpen}
                    dpprLink={digitalPropertyReport && digitalPropertyReport.reportLink}
                    dpprLinkExpiry={digitalPropertyReport && digitalPropertyReport.expirationDate}
                    propertyAddress={propertyAddress}
                    onRetry={this.generateDPPR}
                    propertyId={propertyId}
                    onClose={toggleReportModal}
                    dispatch={dispatch}
                    withError={isErrorFetching}
                    entryPoint={this.getEntryPoint()}
                />
            </div>
        );
    }
}
const ConnectedDigitalPropertyReport = connect(state => ({
    preparedFor: selector(state, 'preparedFor'),
    clapiUsrDetail: state.clapi.get('usrDetail'),
}))(reduxForm({
    form: 'digitalPropertyReportForm',
    initialValues: {
        preparedFor: '',
    },
    destroyOnUnmount: true,
})(DigitalPropertyReport));
ConnectedDigitalPropertyReport.displayName = 'DigitalPropertyReport';
ConnectedDigitalPropertyReport.propTypes = DigitalPropertyReport.propTypes;

export default ConnectedDigitalPropertyReport;
