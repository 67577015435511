import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import { CruxSwitch } from 'js/components/forms/MaterialFormUi';
import { getOnePageReport } from 'js/actions/pdfReports';
import AnalyticsGateway from 'js/lib/AnalyticsGateway';
import ReportForm from 'js/components/reports/ReportForm';
import Reports from 'js/constants/reports';
import Commons from 'js/helpers/Commons';

export class OnePagePDF extends Component {
    static propTypes = {
        pageContext: PropTypes.string,
        title: PropTypes.string,
        property: PropTypes.object,
        user: PropTypes.object,
        dispatch: PropTypes.func,
        toggleReportModal: PropTypes.func,
        entryPoint: PropTypes.string,
    };

    static defaultProps = {
        entryPoint: null,
    };

    constructor(props) {
        super(props);
        this.state = {
            preparedByChecked: true,
            preparedByDisabled: false,
        };

        this.disabledPropertyStates = ['VIC', 'ACT', 'TAS', 'NT'];
    }

    componentDidMount() {
        const { property } = this.props;
        if (property && JSON.stringify(property) !== JSON.stringify({})) {
            this.propertyState = Commons.get(property, 'address.street.locality.postcode.state');
            this.updateStateThatMustBeDisabled();
        }
    }

    onSwitchChange = (e) => {
        this.setState({ preparedByChecked: e.target.checked });
    };

    updateStateThatMustBeDisabled() {
        if (this.disabledPropertyStates.includes(this.propertyState)) {
            this.setState({ preparedByChecked: false, preparedByDisabled: true });
            return;
        }
        this.setState({ preparedByChecked: true, preparedByDisabled: false });
    }

    handleOnePageReport = () => {
        const {
            property,
            dispatch,
            user,
            entryPoint,
            pageContext,
        } = this.props;
        dispatch(getOnePageReport(
            property.id,
            this.state.preparedByChecked,
            pageContext,
            entryPoint,
        ));
        AnalyticsGateway.addUserLastGeneratedReport(user, '1 Page Report');
    };

    renderFields(label, enabled, isSwitchDisabled) {
        const isCheck = isSwitchDisabled ? false : enabled;
        const fieldLabel = isSwitchDisabled ? Reports.OWNER_NAMES.notAvailable : label;

        return (
            <div className="flex-row">
                <div className="flex-label">
                    <span>{fieldLabel}</span>
                </div>
                <div className="flex-input switch-container">
                    <CruxSwitch
                        checked={isCheck}
                        onChange={this.onSwitchChange}
                        disabled={isSwitchDisabled}
                    />
                </div>
            </div>
        );
    }

    render() {
        const {
            property, toggleReportModal, entryPoint,
        } = this.props;
        const {
            preparedByChecked, preparedByDisabled,
        } = this.state;

        const fields = this.renderFields(
            Reports.OWNER_NAMES.available,
            preparedByChecked,
            preparedByDisabled,
        );
        const fileName = property.address && property.address.singleLine;

        return (
            <div id="print-modal">
                <ReportForm
                    propertyId={property.id}
                    icon="1-page-pdf-white.png"
                    title={this.props.title}
                    fields={fields}
                    toggleReportModal={toggleReportModal}
                    triggerHandler={this.handleOnePageReport}
                    reportType={Reports.ONE_PAGE}
                    reportName={fileName}
                    fileName={fileName}
                    preparedByChecked={preparedByChecked}
                    entryPoint={entryPoint}
                />
            </div>
        );
    }
}

export default connect(state => ({
    user: state.claud.get('user'),
}))(OnePagePDF);
