export const PRINT_PROPERTY_HISTORY_AU = [
    'Sale Summary',
    'Sale History',
    'Listing Summary',
    'Listing Property Campaign',
    'Listing Agency Campaign',
    'For Sale Listing Description',
    'Rental Summary',
    'Rental Property Campaign',
    'For Rent Listing Description',
    'Development Application Summary',
    'Development Details',
];

export const PRINT_PROPERTY_HISTORY_NZ = [
    'Sale Summary',
    'Sale History',
    'Listing Summary',
    'Listing Property Campaign',
    'Listing Agency Campaign',
    'For Sale Listing Description',
    'Rental Summary',
    'Rental Property Campaign',
    'For Rent Listing Description',
    'Building Consent Summary',
    'Building Consent Details',
];

export const PRINT_GENERAL = [
    'Property Photo',
    'Map',
    'Property Summary',
    'Property Notes',
];

export const PRINT_HOUSEHOLD_INFORMATION = [
    'Owner Information',
    'Marketing Contacts',
];

export const PRINT_VALUATION_ESTIMATE_AU = [
    'Valuation Estimate',
    'Rental Estimate',
];

export const PRINT_VALUATION_ESTIMATE_NZ = [
    'Valuation Estimate',
];

export const PRINT_ADDITIONAL_INFORMATION_AU = [
    'Legal Description',
    'Property Features',
    'Land Values',
    'Additional Lots',
];

export const PRINT_ADDITIONAL_INFORMATION_NZ = [
    'Legal Description',
    'Property Features',
    'Additional Lots',
];

export const PRINT_RATING_VALUATION = [
    'Rating Valuation',
    'Property Valuation History',
];

export const PRINT_TITLES_INFORMATION = [
    'Title Information',
    'Legal & References',
    'Interests',
];

export const PRINT_NEARBY_SCHOOLS = [
    'In Catchment',
    'All Nearby',
];

export const PRINT_SIMILAR_PROPERTIES = [
    'Recently Sold',
    'For Sale',
    'For Rent',
];

export const ACTIVE_TOGGLE_KEYS = {
    LOGO: 'logo',
    SHOW_OWNER_AND_OR_VENDOR_NAMES: 'showOwnerAndOrVendorNames',
    PROPERTY_PHOTO: 'propertyPhoto',
    MAP: 'map',
    PROPERTY_SUMMARY: 'propertySummary',
    PROPERTY_NOTES: 'propertyNotes',
    OWNER_INFORMATION: 'ownerInformation',
    MARKETING_CONTACTS: 'marketingContacts',
    SALES_SUMMARY: 'saleSummary',
    SALES_HISTORY: 'saleHistory',
    LISTING_SUMMARY: 'listingSummary',
    LISTING_PROPERTY_CAMPAIGN: 'listingPropertyCampaign',
    LISTING_AGENCY_CAMPAIGN: 'listingAgencyCampaign',
    FOR_SALE_LISTING_DESCRIPTION: 'forSaleListingDescription',
    RENTAL_SUMMARY: 'rentalSummary',
    RENTAL_PROPERTY_CAMPAIGN: 'rentalPropertyCampaign',
    FOR_RENT_LISTING_DESCRIPTION: 'forRentListingDescription',
    DEVELOPMENT_APPLICATION_SUMMARY: 'developmentApplicationSummary',
    DEVELOPMENT_DETAILS: 'developmentDetails',
    BUILDING_CONSENT_SUMMARY: 'buildingConsentSummary',
    BUILDING_CONSENT_DETAILS: 'buildingConsentDetails',
    MARKET_TRENDS: 'marketTrends',
    VALUATION_ESTIMATE: 'valuationEstimate',
    RENTAL_ESTIMATE: 'rentalEstimate',
    LEGAL_DESCRIPTION: 'legalDescription',
    PROPERTY_FEATURES: 'propertyFeatures',
    LAND_VALUES: 'landValues',
    ADDITIONAL_LOTS: 'additionalLots',
    TITLE_INFORMATION: 'titleInformation',
    LEGAL_REFERENCES: 'legalReferences',
    INTERESTS: 'interests',
    RATING_VALUATION: 'ratingValuation',
    PROPERTY_VALUATION_HISTORY: 'propertyValuationHistory',
    IN_CATCHMENT: 'inCatchment',
    ALL_NEARBY: 'allNearby',
    RECENTLY_SOLD: 'recentlySold',
    FOR_SALE: 'forSale',
    FOR_RENT: 'forRent',
};

export const ACTIVE_TOGGLE_KEY_LIST = Object.values(ACTIVE_TOGGLE_KEYS).map(val => val);
