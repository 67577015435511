export const USER_SETTINGS = 'USER_SETTINGS';
export const USER_SETTINGS_SUCCESS = 'USER_SETTINGS_SUCCESS';
export const USER_SETTINGS_FAIL = 'USER_SETTINGS_FAIL';
export const USER_SETTINGS_STATE_UPDATE = 'USER_SETTINGS_STATE_UPDATE';
export const APP_CONFIGS = 'APP_CONFIGS';
export const APP_CONFIGS_SUCCESS = 'APP_CONFIGS_SUCCESS';
export const APP_CONFIGS_FAIL = 'APP_CONFIGS_FAIL';
export const ACCOUNT_DETAILS = 'ACCOUNT_DETAILS';
export const ACCOUNT_DETAILS_SUCCESS = 'ACCOUNT_DETAILS_SUCCESS';
export const ACCOUNT_DETAILS_FAIL = 'ACCOUNT_DETAILS_FAIL';
export const UPDATE_ACCOUNT_DETAILS = 'UPDATE_ACCOUNT_DETAILS';
export const UPDATE_ACCOUNT_DETAILS_SUCCESS = 'UPDATE_ACCOUNT_DETAILS_SUCCESS';
export const UPDATE_ACCOUNT_DETAILS_FAIL = 'UPDATE_ACCOUNT_DETAILS_FAIL';
export const SET_IS_UPDATE_SUCCESS = 'SET_IS_UPDATE_SUCCESS';
export const SEARCH_ACCOUNT_ADDRESS = 'SEARCH_ACCOUNT_ADDRESS';
export const SEARCH_ACCOUNT_ADDRESS_SUCCESS = 'SEARCH_ACCOUNT_ADDRESS_SUCCESS';
export const SEARCH_ACCOUNT_ADDRESS_FAIL = 'SEARCH_ACCOUNT_ADDRESS_FAIL';
export const RESET_SEARCH_ACCOUNT_ADDRESS = 'RESET_SEARCH_ACCOUNT_ADDRESS';
export const GET_USERS = 'GET_USERS';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_FAIL = 'GET_USERS_FAIL';
export const UPLOAD_USER_PHOTO = 'UPLOAD_USER_PHOTO';
export const SET_UPLOAD_USER_PHOTO_STATUS = 'SET_UPLOAD_USER_PHOTO_STATUS';
export const SET_TNC_ACCEPTED_STATUS = 'SET_TNC_ACCEPTED_STATUS';

export const getUsers = payload => ({
    type: GET_USERS,
    payload,
});

export function getUserSettings() {
    return {
        type: USER_SETTINGS,
    };
}
export function getAppConfigurations() {
    return {
        type: APP_CONFIGS,
    };
}
export function getAccountDetails() {
    return {
        type: ACCOUNT_DETAILS,
        payload: true,
    };
}
export function getAccountDetailsSuccess(payload) {
    return {
        type: ACCOUNT_DETAILS_SUCCESS,
        payload: { isLoading: false, ...payload },
    };
}

export function getAccountDetailsFail() {
    return {
        type: ACCOUNT_DETAILS_FAIL,
    };
}
export function updateAccountDetails(payload, mixPanelProps, addressToRemove) {
    return {
        type: UPDATE_ACCOUNT_DETAILS,
        payload,
        mixPanelProps,
        addressToRemove,
    };
}
export function updateAccountDetailsSuccess(payload) {
    return {
        type: UPDATE_ACCOUNT_DETAILS_SUCCESS,
        payload,
    };
}
export function updateAccountDetailsFail() {
    return {
        type: UPDATE_ACCOUNT_DETAILS_FAIL,
    };
}
export function setIsUpdateSuccess(isUpdateSuccess) {
    return {
        type: SET_IS_UPDATE_SUCCESS,
        payload: { isUpdateSuccess },
    };
}
export function resetAddressSearchOptions() {
    return {
        type: RESET_SEARCH_ACCOUNT_ADDRESS,
    };
}
export function searchAccountAddress(payload) {
    return {
        type: SEARCH_ACCOUNT_ADDRESS,
        payload,
    };
}
export function searchAccountAddressSuccess(payload) {
    return {
        type: SEARCH_ACCOUNT_ADDRESS_SUCCESS,
        payload,
    };
}

export function searchAccountAddressFail() {
    return {
        type: SEARCH_ACCOUNT_ADDRESS_FAIL,
    };
}
export function uploadUserPhoto(payload) {
    return {
        type: UPLOAD_USER_PHOTO,
        payload,
    };
}

export function setUploadUserPhotoStatus(payload) {
    return {
        type: SET_UPLOAD_USER_PHOTO_STATUS,
        payload,
    };
}

export function setTncAcceptedStatus(tncPermanentlyAccepted, tncCurrentlyAccepted) {
    return {
        type: SET_TNC_ACCEPTED_STATUS,
        payload: { tncPermanentlyAccepted, tncCurrentlyAccepted },
    };
}

export function updateUserSettingState(payload) {
    return {
        type: USER_SETTINGS_STATE_UPDATE, payload,
    };
}
