import { Component } from 'react';
import PropTypes from 'prop-types';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import '../../../../css/crux/components/global/dropdownModal.scss';

export default class DropdownModal extends Component {
    static propTypes = {
        customClass: PropTypes.string,
        classNameForEvent: PropTypes.string,
        isVisible: PropTypes.bool,
        customStyleInline: PropTypes.object,
        onModalClose: PropTypes.func,
    };

    handleClickAway = (e) => {
        this.props.onModalClose && this.props.onModalClose(e);
    };


    render() {
        const customClass = this.props.customClass ? ` ${this.props.customClass}` : '';
        if (!this.props.isVisible) return null;
        return (
            <ClickAwayListener onClickAway={(e) => this.handleClickAway(e)}>
                <div
                    id="dropdown-modal"
                    data-testid="crux-dropdown-modal"
                    className={`dropdown-modal${customClass}`}
                    style={this.props.customStyleInline}
                >
                    {
                        this.props.children
                    }
                </div>
            </ClickAwayListener>

        );
    }
}
