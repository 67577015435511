import Commons from '../helpers/Commons';
import { PER_PAGE_LIMIT } from '../helpers/Localization';

export default (state) => {
    const limit = state?.searchResults?.get('limit');
    const userSettings = state?.userPreferences?.get('userSettings') || [];
    const userSettingLimit = Commons.getUserSetting(userSettings, PER_PAGE_LIMIT, null);

    const finalLimit = (!limit && userSettingLimit)
        ? userSettingLimit
        : limit;

    return finalLimit ? parseInt(finalLimit, 10) : 20;
}