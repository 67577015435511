import React, { Fragment } from 'react';
import { Button } from '@mui/material';
import PropTypes from 'prop-types';
import MUITooltip from 'js/components/common/MUITooltip';

const ButtonWithTooltip = (props) => {
    const {
        children, className, tooltipMessage, tooltipPosition,
        onClick, isDisabled, displayTooltip, color,
        sx, startIcon,
    } = props;
    return (
        <Fragment>
            <MUITooltip
                title={displayTooltip ? tooltipMessage : ''}
                arrow
                placement={tooltipPosition}
                longTooltip
            >
                <div>
                    <Button
                        variant="contained"
                        color={color}
                        className={`button-with-tooltip ${className}${isDisabled ? ' bwt-disabled' : ''}`}
                        onClick={!isDisabled ? onClick : undefined}
                        sx={sx}
                        startIcon={startIcon}
                    >
                        {children}
                    </Button>
                </div>
            </MUITooltip>
        </Fragment>
    );
};

ButtonWithTooltip.propTypes = {
    className: PropTypes.string,
    tooltipMessage: PropTypes.string,
    tooltipPosition: PropTypes.string,
    tooltipId: PropTypes.string,
    onClick: PropTypes.func,
    isDisabled: PropTypes.bool,
    displayTooltip: PropTypes.bool,
    startIcon: PropTypes.object,
};

ButtonWithTooltip.defaultProps = {
    className: '',
    isDisabled: false,
    displayTooltip: true,
    color: 'primary',
};

export default ButtonWithTooltip;
