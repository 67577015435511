import { isNZ } from '../../constants/crux';

export default (rawParams) => {
    const _params = new URLSearchParams(rawParams);
    const params = {
        companyName: _params.get('companyName') === 'null' ? '' :
            _params.get('companyName'),
        ownerFirstName: _params.get('ownerFirstName') === 'null' ?
            '' :
            _params.get('ownerFirstName'),
        ownerLastName: _params.get('ownerLastName'),
        addressState: isNZ ? null : _params.get('addressState'),
        isCurrentOwner: _params.get('isCurrentOwner'),
        addressSuburb: _params.get('addressSuburb') === 'null' ?
            '' :
            _params.get('addressSuburb'),
        exactSearch: _params.get('exactSearch') === 'null' ? '' :
            _params.get('exactSearch'),
    };
    Object.keys(params).forEach((key) => {
        if (!params[key]) delete params[key];
    });
    return params;
};
