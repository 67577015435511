import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import Button from '@mui/material/Button';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import { styled, Paper, TextField, MenuItem } from '@mui/material';
import STATES, { STATE }   from 'js/constants/states';
import { isAU } from 'js/constants/crux';
import Reports from 'js/constants/reports';
import Commons from 'js/helpers/Commons';
import {
    CLEAR_ADDRESS_COLLECTIVE,
    clearSuggestion,
    getAddressCollective,
    getSuggestions,
} from 'js/actions/clapi';
import StyledTooltip from '../../../common/styledMUI/StyledTooltip';
import Colors from 'js/constants/colors';
import SuggestionTypes  from 'js/constants/suggestionTypes';
import { getAssetDomain } from 'js/constants/assets';
import LDHelper from 'js/helpers/LaunchDarklyHelper';
import { UNSUBSCRIBED } from 'js/constants/tooltipText';
import CruxSplitButtonDropdown from 'js/components/common/CruxSplitButtonDropdown';
import CruxButton from 'js/components/common/styledMUI/CruxButton';
import {
    SuggestHeaderContainer,
    StyledAutoComplete,
    StyledOption,
    StyledNoRecentSearchOption,
    SuggestionIcon,
    SuggestionHeader,
    SuggestionHeaderText,
    StyledOptionText,
} from '../styledComponents/searchbarStyles';
import MUITooltip from '../../../common/MUITooltip';
import useLocationSearch from '../../../../hooks/multiLocality/useLocationSearch';
import { REPORT_TYPES } from '../../../../helpers/Localization';
import { routeCodes } from '../../../../constants/routes';
import ReportModal from '../../../reports/ReportModal';
import SuburbReport from '../../../reports/SuburbReport';
import ReportHelper  from '../../../../helpers/report/ReportHelper';

import { EXACT_ADDRESS_PARAM } from '../../../../constants/batchSearch/batchSearch';
import { goToSignatureReport } from '../../../../actions/signature';
import ValuationReport from '../../../reports/ValuationReport';
import RentalEstimateReport from '../../../reports/RentalEstimateReport';
import Clapi from '../../../../api/clapi';
import { VALUATION } from '../../../../constants/propertyDetail';
import DialogMessage from '../../../common/modals/DialogMessage';
import { cruxLoader, cruxUnloader } from '../../../../actions/crux';
import TitleDetailsReportModal from '../../../reports/TitleDetailsReportModal';
import OnePagePDF from '../../../reports/OnePagePDF';
import DigitalPropertyReport from '../../../reports/dppr/DigitalPropertyReport';
import PropertyInvestmentReport from '../../../reports/PropertyInvestmentReport';
import PropertyReport from '../../../reports/PropertyReport';
import { trackEvent } from '../../../../actions/segment';
import Segment from '../../../../helpers/Segment';
import Flags from '../../../../constants/featureFlags';
import SEGMENT from '../../../../constants/segment';

const {
    ADDRESS, LOCALITY, SUBURB,
} = SuggestionTypes;

const SUGGESTION_LIMIT = 10;

export const ReportSearchBarContainer = styled('div')({
    display: 'flex',
    width: '100%',
});

const StyledSplitDropdown = styled(CruxSplitButtonDropdown)({
    marginRight: '8px',
    whiteSpace: 'nowrap',
    '& .MuiButtonGroup-root': {
        minWidth: '166px',
        '& .MuiButtonBase-root': {
            height: '40px',
            fontSize: '14px',
            '&:first-of-type': {
                width: 'max-content',
            },
        },
    },
    '& .MuiList-root': {
        width: '222px',
    }
});

const ReportAddressLocalitySearchBar = styled('div')({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
});

const REPORT_SIGNATURE_MAPPING = {
    cmaReport: Reports.SIGNATURE_REPORT_TYPES.CMA,
    rentalCmaReport: Reports.SIGNATURE_REPORT_TYPES.RENTAL,
    soi: Reports.SIGNATURE_REPORT_TYPES.SOI,
};

const _REPORT_TYPES = JSON.parse(JSON.stringify(REPORT_TYPES));

export const ReportAddressSearch = ({
    suggestions,
    location,
    dispatch,
    route,
    entryPoint,
    address,
    history,
    usrDetail,
    propertyInvestmentReportFlag,
    applicationDomain,
    proposalFlag,
 }) => {
    const dropdown = useRef({});
    const [options, setOptions] = useState([]);
    const [optionSelected, setOptionSelected] = useState(null);
    const [isSearchBarActive, setIsSearchBarActive] = useState(false);
    const [reportType, setReportType] = useState(null);
    const [suggestionTypes, setSuggestionTypes] = useState([]);
    const [openReportModal, setOpenReportModal] = useState(false);
    const [dialogMessage, setDialogMessage] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [addressName, setAddressName] = useState(null);

    const reportRoles = ReportHelper.getRolesWithExcludedRoles(usrDetail);

    const valuationTrigger = useRef(null);
    const rentalTrigger = useRef(null);

    const {
        isHistoric,
        isOpen,
        setIsOpen,
        getOptionLabel,
        keyword,
        setKeyword,
    } = useLocationSearch({ location, route, dispatch });

    useEffect(() => {
        if (suggestions.length > 0) {
            setOptions(suggestions);
        } else {
            setOptions([]);
        }
    }, [suggestions]);

    useEffect(() => {
        const reportGenerator = {
            onePageReport: toggleReportModal,
            digitalReport: toggleReportModal,
            propertyReport: toggleReportModal,
            titleReport: toggleReportModal,
            propertyInvestment: toggleReportModal,
            cmaReport: redirectToSignature,
            rentalCmaReport: redirectToSignature,
            soi: redirectToSignature,
            valuationEstimate: handleValuationReport,
            rentalEstimate: handleValuationReport,
            proposal: handleProposalsRedirect,
        };

        if (!address) return; // do nothing when address collective cleared
        if (address?.metadata?.totalElements > 1) {
            dispatch({
                type: CLEAR_ADDRESS_COLLECTIVE,
            });
            history.push({
                pathname: routeCodes
                    .SEARCH_RESULT
                    .path(optionSelected?.suggestionType, optionSelected?.suggestion),
                state: {
                    suggestion: [{ ...optionSelected, suggestionType: EXACT_ADDRESS_PARAM }],
                    addressCollective: true,
                    entryPoint: entryPoint,
                    isActiveProperty: true,
                },
            });
        } else if (!!reportGenerator[reportType?.value]) {
            reportGenerator[reportType?.value]();
        }
    }, [address]);

    useEffect(() => {
        return () => {
            dispatch(clearSuggestion());
        };
    }, []);

    useEffect(() => {
        handleEntitlements('propertyInvestment', propertyInvestmentReportFlag);
    }, [propertyInvestmentReportFlag]);

    useEffect(() => {
        handleEntitlements('proposal', proposalFlag);
    }, [proposalFlag]);

    useEffect(() => {
        return () => {
            dispatch(clearSuggestion());
        };
    }, []);

    const handleValuationReport = () => {
        const params = {
            'valuationEstimate': {
                valuationError: VALUATION.VALUATION_ESTIMATE_UNAVAILABLE,
                avmCall: () => Clapi
                    .getIntellivalValuationAvm(address?.data[0]?.id),
                fsd: (response) => response?.fsd,
                lowValuationConfidence: `${VALUATION.VALUATION_ESTIMATE_UNAVAILABLE} ${VALUATION.CONFIDENCE_TOO_LOW}`,
                trigger: valuationTrigger,
                fsdThreshold: 25,
            },
            'rentalEstimate': {
                valuationError: VALUATION.RENTAL_ESTIMATE_UNAVAILABLE,
                avmCall: () => Clapi
                    .getRentalAvm(address?.data[0]?.id),
                fsd: (response) => response?.rentalAvmDetail?.rentalAvmYieldFsdScore,
                lowValuationConfidence: `${VALUATION.RENTAL_ESTIMATE_UNAVAILABLE} ${VALUATION.CONFIDENCE_TOO_LOW}`,
                trigger: rentalTrigger,
                fsdThreshold: 30,
            }
        }

        const valuation = params[reportType?.value];
        let valuationError = valuation.valuationError;

        dispatch(cruxLoader());
        valuation.avmCall()
            .then((response) => {
                const fsd = valuation.fsd(response);
                if (fsd > 0 && fsd < valuation.fsdThreshold) {
                    valuationError = null;
                } else if (fsd >= valuation.fsdThreshold) {
                    valuationError = valuation.lowValuationConfidence;
                }
            })
            .finally(() => {
                dispatch(cruxUnloader());
                if (valuationError) {
                    setErrorMsg(valuationError);
                    setDialogMessage(true);
                } else {
                    valuation.trigger?.current?.click();
                }
                resetReport();
            });
    };

    const handleProposalsRedirect = () => {
        const proposalUrl = `${applicationDomain?.rpProposals}?link=${JSON.stringify({
            page: 'createProposal',
            corelogicPropertyId: optionSelected?.suggestionId,
        })}`;

        dispatch(trackEvent(Segment.generateReport({
            pageContext: route.pageContext,
            entryPoint,
            propertyId: optionSelected?.suggestionId,
            reportType: SEGMENT.EVENT_PROPERTIES.PROPOSAL,
            reportFormat: SEGMENT.EVENT_PROPERTIES.HTML,
        })));
        window.open(proposalUrl, '_blank');
        resetReport();
    }

    const redirectToSignature = () => {
        dispatch(goToSignatureReport(optionSelected?.suggestionId,
            REPORT_SIGNATURE_MAPPING[reportType.value],
            null,
            entryPoint,
            route.pageContext));
        resetReport();
    };

    const handleEntitlements = (key, flag) => {
        const _flag = flag && flag.toLowerCase();
        if (Object.keys(_REPORT_TYPES).includes(key)) {
            if (_flag === 'false') {
                delete _REPORT_TYPES[key];
            } else if (_flag === 'true') {
                _REPORT_TYPES[key].entitlements = '';
            }
        }
    };

    const onInputChange = (event, val) => {
        setKeyword(val);
        const isStateSearch = STATES.includes(val.toUpperCase());
        const LIMIT = isAU && isStateSearch ? 2 : 3;
        if (optionSelected) {
            setOptionSelected(null);
        }
        if (val.length < LIMIT || Commons.hasBackSlash(val)) {
            dispatch(clearSuggestion());
            if (!val.length) {
                // Reset selected suggestion if all chips were deleted.
                setOptionSelected(null);
            }
        } else {
            dispatch(getSuggestions({
                keyword: val,
                suggestionTypes,
                includeHistoric: isHistoric,
                limit: SUGGESTION_LIMIT,
                withDetails: reportType === _REPORT_TYPES.suburbStats,
            }));
        }
    }

    const onChange = (e, value) => {
        // Prevents value being selected if it is not part of the options
        if (typeof value !== 'string') {
            setOptionSelected(value);
        }
    };

    const renderOptions = (props, option, { inputValue }) => {
        const { suggestionType } = option;

        const suggestion = getOptionLabel(option);
        const matches = match(suggestion, Commons.removeComma(inputValue), { insideWords: true });
        const parts = parse(suggestion, matches);
        const _suggestionType = suggestionType === LOCALITY ? SUBURB : ADDRESS;
        const OptionComponent = suggestionType ? StyledOption : StyledNoRecentSearchOption;
        const formattedSuggestion = Commons.formatPipeSeparatedAddress(suggestion);
        return (
            <OptionComponent {...props}>
                {
                    _suggestionType &&
                        <StyledTooltip
                            title={_suggestionType}
                            arrow
                        >
                            <SuggestionIcon
                                className="img-responsive"
                                src={encodeURI(getAssetDomain(`${suggestionType}.svg`))}
                                alt={`${suggestionType}-icon`}
                            />
                        </StyledTooltip>
                }
                <div style={{ minWidth: 300, display: 'flow-root' }}>
                    {
                        suggestions.length > 0 ? (
                            parts.map((part, index) => {
                                const formattedPartText = Commons
                                    .formatPipeSeparatedAddress(part.text);
                                const isTextBold = part.highlight && suggestionType;
                                return (
                                    <StyledOptionText
                                        key={index}
                                        title={formattedPartText}
                                        style={{
                                            fontWeight: isTextBold ? 700 : 400,
                                            overflow: part.highlight ? 'inherit' : 'hidden',
                                        }}
                                    >
                                        {formattedPartText}
                                    </StyledOptionText>
                                );
                            })
                        ) : (
                            <StyledOptionText
                                key={`${props.id}-${formattedSuggestion}`}
                                title={formattedSuggestion}
                            >
                                {formattedSuggestion}
                            </StyledOptionText>
                        )
                    }
                </div>
            </OptionComponent>
        );
    };

    const renderContainer = (props) => {
        if (!options.length || !props.children[props.children.length - 1]) {
            return null;
        }
        return (
            <Paper
                {...props}
                onMouseDown={event => event.preventDefault()}
                sx={{ marginTop: '5px' }}
                data-testid="multi-locality-suggestions"
            >
                <SuggestHeaderContainer>
                    <SuggestionHeader>
                        <SuggestionHeaderText>SUGGESTIONS</SuggestionHeaderText>
                    </SuggestionHeader>
                </SuggestHeaderContainer>
                {props.children}
            </Paper>
        );
    };

    const resetReport = () => {
        setOptionSelected('');
        dispatch({
            type: CLEAR_ADDRESS_COLLECTIVE,
        });
    };

    const closeReportModal = () => {
        if (!openReportModal) return;
        setOpenReportModal(false);
        resetReport();
    };

    const toggleReportModal = () => {
        setOpenReportModal(!openReportModal);
        closeReportModal();
    };

    const createReport = () => {
        if (reportType === _REPORT_TYPES.suburbReport) {
            toggleReportModal();
        } else if (reportType === _REPORT_TYPES.suburbStats) {
            dispatch(trackEvent(Segment.suburbStatisticReport({
                pageContext: route.pageContext,
                objectContext: optionSelected?.suggestion,
                entryPoint,
            })))
            window
                .open(routeCodes
                    .SUBURB_STATS_REPORT
                    .path(
                        optionSelected?.suggestion,
                        optionSelected?.localityId,
                        optionSelected?.lgaIds.toString().split(',').join(' '),
                    ))
            resetReport();
        }
        setAddressName(optionSelected?.suggestion);
        // Should only handle addresses for collective search
        if (suggestionTypes[0] === ADDRESS) {
            dispatch(getAddressCollective({
                suggestion: optionSelected.suggestion,
                suggestionType: EXACT_ADDRESS_PARAM,
            }, false));
        }
    };

    const onReportTypeChange = (key) => {
        if (suggestions.length) {
            dispatch(clearSuggestion());
        }
        setOptionSelected(null);
        setKeyword('');
        const reportTypeSelected = _REPORT_TYPES[key];
        dropdown.current.close();
        setReportType(reportTypeSelected);
        setSuggestionTypes(reportTypeSelected?.suggestionType || [ADDRESS, SUBURB]);
    };

    const getSuggestionLabel = () => {
        const suggestLabels = {
            locality: 'Search for a suburb',
            address: 'Search for an address',
            selectReport: 'Select a report to begin',
        };

        return suggestionTypes.length
            ? suggestLabels[suggestionTypes[0]]
            : suggestLabels.selectReport;
    };

    const filterOptions = (options) => {
        if (reportType === _REPORT_TYPES.soi) {
            return options.filter(opt => opt?.suggestion?.split(' ').includes(STATE.VIC.toUpperCase()));
        }
        return options;
    }
    return (
        <ReportAddressLocalitySearchBar data-testid="multi-locality-search">
            <ReportSearchBarContainer>
                <StyledSplitDropdown
                    className="crux-dropdown"
                    label={reportType?.label || 'Select Report'}
                    testId="report-search-dropdown"
                    width="auto"
                    ref={dropdown}
                >
                    {
                        Object.keys(_REPORT_TYPES).map((key) => {
                            const { entitlements, label, value } = _REPORT_TYPES[key];
                            const isEntitled = ReportHelper.hasEntitlementInReportRoles(entitlements, reportRoles);
                            return (
                                <MUITooltip
                                    key={label}
                                    title={!isEntitled ? UNSUBSCRIBED : ''}
                                    placement="right"
                                    arrow
                                    sx={{ marginLeft: '-18px !important'}}
                                >
                                    <div>
                                        <MenuItem
                                            key={value}
                                            disabled={!isEntitled}
                                            onClick={() => onReportTypeChange(key)}
                                            selected={reportType?.label === label}
                                            sx={{
                                                color: Colors.BLACK_60_OPAQUE,
                                                fontSize: '14px',
                                                '&.Mui-selected': {
                                                    color: Colors.BLACK_87_OPAQUE,
                                                    backgroundColor: 'white',
                                                    '&:hover': { backgroundColor: 'white' },
                                                },
                                            }}
                                        >
                                            {label}
                                        </MenuItem>
                                    </div>
                                </MUITooltip>
                            );
                        })
                    }

                </StyledSplitDropdown>
                <StyledAutoComplete
                    sx={{
                        minWidth: 586,
                    }}
                    className="form-input-search-field"
                    filterOptions={filterOptions}
                    key={`autocomplete-${location?.state?.addressCollective ? location.key : ''}`}
                    value={optionSelected}
                    inputValue={keyword}
                    disabled={!suggestionTypes.length}
                    disableClearable
                    freeSolo
                    disablePortal
                    autoHighlight
                    onInputChange={onInputChange}
                    onChange={onChange}
                    open={isOpen}
                    options={options}
                    popupIcon={null}
                    onClose={(e, r) => r !== 'toggleInput' && setIsOpen(false)}
                    isSearchBarActive={isSearchBarActive}
                    onOpen={() => setIsOpen(true)}
                    minWidth={585}
                    id="crux-report-search"
                    getOptionLabel={(option) => option.suggestion || option.searchString || ''}
                    renderOption={renderOptions}
                    PaperComponent={renderContainer}
                    renderInput={
                        (params) => (
                            <div className="autocomplete-render-input">
                                <TextField
                                    {...params}
                                    onFocus={() => setIsSearchBarActive(true)}
                                    onBlur={() => setIsSearchBarActive(false)}
                                    placeholder={getSuggestionLabel()}
                                    color="primary"
                                />
                            </div>
                        )
                    }
                />
                <CruxButton
                    colors={{
                        font: Colors.WHITE,
                        backgroundColor: Colors.PRIMARY.MAIN,
                    }}
                    opacity="1"
                    sx={{
                        height: 40,
                    }}
                    onClick={createReport}
                    disabled={!optionSelected}
                >
                    Create Report
                </CruxButton>
            </ReportSearchBarContainer>
            <ReportModal
                open={openReportModal}
                onClose={toggleReportModal}
            >
                {
                    reportType === _REPORT_TYPES.suburbReport &&
                    <SuburbReport
                        suburbAddress={suggestions[0]?.suggestion}
                        localityId={suggestions[0]?.suggestionId}
                        route={route}
                        entryPoint={entryPoint}
                        toggleReportModal={toggleReportModal}
                    />
                }
                {
                    reportType === _REPORT_TYPES.onePageReport &&
                    <OnePagePDF
                        property={{
                            id: address?.data[0].id,
                            // address.street.locality.postcode.state
                            address: {
                                street: {
                                    locality: {
                                        postcode: {
                                            state: address?.data[0].addressState,
                                        },
                                    },
                                },
                                singleLine: suggestions[0]?.suggestion,
                            },
                        }}
                        pageContext={route.pageContext}
                        title="1 Page PDF Report"
                        entryPoint={entryPoint}
                        toggleReportModal={toggleReportModal}
                    />
                }
                {
                    reportType === _REPORT_TYPES.digitalReport &&
                    <DigitalPropertyReport
                        suburbAddress={`${address?.data[0]?.addressSuburb} ${address?.data[0]?.addressState} ${address?.data[0]?.addressPostcode}`}
                        propertyAddress={optionSelected?.suggestion}
                        propertyId={optionSelected?.suggestionId}
                        route={route}
                        toggleReportModal={toggleReportModal}
                    />
                }
                {
                    reportType === _REPORT_TYPES.propertyReport &&
                    <PropertyReport
                        propertyAddress={optionSelected?.suggestion}
                        propertyId={optionSelected?.suggestionId}
                        route={route}
                        entryPoint={entryPoint}
                        toggleReportModal={toggleReportModal}
                    />
                }
                {
                    reportType === _REPORT_TYPES.titleReport &&
                    <TitleDetailsReportModal
                        propertyAddress={optionSelected?.suggestion}
                        propertyId={optionSelected?.suggestionId}
                        toggleReportModal={toggleReportModal}
                        dispatch={dispatch}
                        route={route}
                    />
                }
                {
                    reportType === _REPORT_TYPES.propertyInvestment &&
                    <PropertyInvestmentReport
                        propertyState={address?.data?.at(0)?.addressState}
                        propertyAddress={optionSelected?.suggestion}
                        propertyId={optionSelected?.suggestionId}
                        pageContext={route.pageContext}
                        entryPoint={entryPoint}
                        toggleReportModal={toggleReportModal}
                    />
                }
            </ReportModal>
            <ValuationReport
                propertyAddress={addressName}
                propertyId={optionSelected?.suggestionId || 0}
                trigger={<Button sx={{display: 'none'}} ref={valuationTrigger} />}
                pageContext={route.pageContext}
                entryPoint={entryPoint}
                dispatch={dispatch}
            />
            <RentalEstimateReport
                trigger={<Button sx={{display: 'none'}} ref={rentalTrigger} />}
                propertyAddress={addressName}
                propertyId={optionSelected?.suggestionId || 0}
                pageContext={route.pageContext}
                entryPoint={entryPoint}
                dispatch={dispatch}
            />
            <DialogMessage
                id="report-dialog-message"
                open={dialogMessage}
                onClose={() => setDialogMessage(false)}
                message={errorMsg}
            />
        </ReportAddressLocalitySearchBar>
    );
};

ReportAddressSearch.propTypes = {
    styles: PropTypes.object,
    slas: PropTypes.object,
    route: PropTypes.object.isRequired,
    entryPoint: PropTypes.string.isRequired,
    isHistoricVisible: PropTypes.bool,
    isHeader: PropTypes.bool,
    usrDetail: PropTypes.object,
    suggestions: PropTypes.array,
    includeHistoric: PropTypes.bool,
    location: PropTypes.object,
    history: PropTypes.object,
    dispatch: PropTypes.func,
    handler: PropTypes.object,
    proposalFlag: PropTypes.string,
    propertyInvestmentReportFlag: PropTypes.string,
}

export default connect(state => ({
    usrDetail: state.clapi.get('usrDetail'),
    suggestions: state.clapi.get('suggestions'),
    handler: state.msg.get('handler'),
    includeHistoric: isAU && LDHelper.featureFlagSelector(state, 'crux.historic.property.data', false),
    address: state.rapid.get('address'),
    proposalFlag: LDHelper.featureFlagSelector(state, Flags.RP_PROPOSALS_REPORT, false),
    applicationDomain: state.config.get('applicationDomain'),
    propertyInvestmentReportFlag: LDHelper.featureFlagSelector(state, 'crux-property-investment-report', false),
}))(withRouter(ReportAddressSearch));
