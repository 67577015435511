export default {
    normalStyle: {
        borderColor: '#e4e4e4',
        borderColorOnFocus: '#39f',
        borderColorOnHover: '#39f',
        backgroundColor: '#fff',
        backgroundColorOnFocus: '#fafafa',
    },
    errorStyle: {
        borderColor: '#f65c5d',
        borderColorOnFocus: '#f65c5d',
        borderColorOnHover: '#f65c5d',
        backgroundColor: '#fef5f4',
        backgroundColorOnFocus: '#fef5f4',
    },
};
