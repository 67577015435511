export default (uri, fieldNames, childNodes) => {
    if (fieldNames.length === 0) return;
    for (const fieldName of fieldNames) {
        const node = childNodes.filters[fieldName];

        // this can be "if(node)", but there are properties that contains "false"
        // and should fall under the condition
        if (node !== undefined && node !== null && node !== '') {
            if (node.constructor === Object) {
                const nodeFieldNames = Object.getOwnPropertyNames(node);
                for (const nodeFieldName of nodeFieldNames) {
                    if (node[nodeFieldName] !== '') {
                        uri.addParam(nodeFieldName, node[nodeFieldName]);
                    }
                }
            } else if (node.constructor === Array && node.constructor.length > 0) {
                for (const items of node) {
                    uri.addParam('sort', `${items.attr},${items.order}`);
                }
            } else {
                uri.addParam(fieldName, node);
            }
        }
    }
};

export const conjunctUrlParams = params => Object
    .keys(params)
    .map(key => (params[key] ? `${key}=${encodeURIComponent(params[key])}` : ''))
    .filter(param => param)
    .join('&');
