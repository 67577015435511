import { takeLatest, call, put } from 'redux-saga/effects';
import PropertyDetail, { COMMONS_FIELDS } from '../constants/propertyDetail';
import ClapiApi from '../api/clapi';
import * as CruxAction from '../actions/crux';
import * as ErrorAction from '../../js/actions/errorHandler';
import PropertyCommons from '../model/PropertyDetailCommonsResponseBody';

const NOT_FOUND = '404 Not Found';
const PROPERTY_NOT_FOUND = 'Property Not Found';

function getPropertyCommonsFn() {
    return function* (options) {
        const { payload: { propertyId } = {} } = options;
        try {
            const propertyCommons = yield call(
                ClapiApi.getPropertyDetail,
                propertyId,
                PropertyDetail.COMMONS,
            );

            if (propertyCommons.errors && propertyCommons.errors[0].msg === NOT_FOUND) {
                throw new Error(PROPERTY_NOT_FOUND);
            }

            if (propertyCommons.errors && propertyCommons.errors.length) {
                propertyCommons.errors.forEach((error) => {
                    if (COMMONS_FIELDS[error.key]) {
                        propertyCommons[COMMONS_FIELDS[error.key]] = {};
                    }
                });
            }

            const _propertyCommons = PropertyCommons.parse(propertyCommons);
            yield put(CruxAction.getPropertyCommonsSuccess(_propertyCommons));
            yield put(ErrorAction.rmCruxCmptError(CruxAction.PROPERTY_COMMONS));
        } catch (error) {
            if (error.message === PROPERTY_NOT_FOUND) {
                yield put({ type: ErrorAction.CRUX_APP_ERROR, error });
            } else {
                yield put({
                    type: CruxAction.PROPERTY_COMMONS_FAIL,
                    payload: {
                        propertyId,
                    },
                });
            }
            yield put(ErrorAction.addCruxCmptError(CruxAction.PROPERTY_COMMONS, error));
        }
    };
}

export const getPropertyCommons = getPropertyCommonsFn();

export function* getPropertyCommonsWatcher() {
    yield takeLatest(CruxAction.PROPERTY_COMMONS, getPropertyCommons);
}

export default [
    getPropertyCommonsWatcher(),
];
