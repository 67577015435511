import { call, put, takeLatest, select, fork, takeEvery } from 'redux-saga/effects';
import SavedSearchesApi from '../api/savedSearchesApi';
import SavedSearchHelper from '../helpers/search/SavedSearchHelper';
import * as SavedSearchAction from '../actions/savedSearch';
import SagaHelper from './helper';
import Commons from '../helpers/Commons';
import { addCruxCmptError, rmCruxCmptError } from '../actions/errorHandler';
import { formatSavedSearchesAsV1 } from '../helpers/search/SavedSearchV1FormatBuilder';
import ApiHelper from '../helpers/ApiHelper';

function removeSavedSearchFn() {
    return function* (action) {
        const clUserId = yield select(SagaHelper.getClUsrId);
        return yield call(
            SavedSearchesApi.removeSavedSearch,
            action.payload,
            clUserId,
        );
    };
}
export const removeSavedSearch = removeSavedSearchFn();

function saveSearchFn() {
    return function* (action) {
        const { saveSearch } = action.payload;
        saveSearch.filters = ApiHelper.buildBatchSearchParams(saveSearch.filters);
        return yield call(
            SavedSearchesApi.saveSearch,
            saveSearch,
        );
    };
}
export const saveSearch = saveSearchFn();

function transformSavedSearchFilterFn() {
    return function* ({ savedSearches, shouldRefresh, type }) {
        let [savedSearch] = Commons.get(savedSearches, 'response.result.items') || [];
        let noResults = true;
        if (savedSearch) {
            noResults = false;
            const statusType = SavedSearchHelper.getStatusTypeBySelectedTab(savedSearch.selectedTab)
            const searchType = SavedSearchHelper.getSearchTypeByCategoryId(savedSearch.category);
            yield put(SavedSearchAction.setStatusType(statusType));
            yield put(SavedSearchAction.setSearchType(searchType));
            savedSearch = formatSavedSearchesAsV1(savedSearch);
        }
        yield put({
            type,
            payload: {
                ...(savedSearch || {}),
                shouldRefresh,
                noResults,
            },
        });
    };
}
export const transformSavedSearchFilter = transformSavedSearchFilterFn();

function getNotificationHistoryFn() {
    return function* (action) {
        try {
            const clUserId = yield select(SagaHelper.getClUsrId);
            const { response: { status, result } = {} } = yield call(
                SavedSearchesApi.getNotificationHistory,
                action.payload,
                clUserId,
            );

            if (status === 'success') {
                const { notificationHistoryList } = result;

                yield put({
                    type: SavedSearchAction.GET_NOTIFICATION_HISTORY_SUCCESS,
                    payload: {
                        notificationHistoryList,
                        isError: false,
                    },
                });
            }
        } catch (e) {
            yield put({
                type: SavedSearchAction.GET_NOTIFICATION_HISTORY_FAIL,
                payload: {
                    isError: true,
                },
            });
        }
    };
}
export const getNotificationHistory = getNotificationHistoryFn();

function updateSavedSearchSettingsFn() {
    return function* (action) {
        try {
            const clUserId = yield select(SagaHelper.getClUsrId);
            const updateSavedSearchRequestBody = {
                itemId: action.payload.savedSearch.id,
                searchName: action.payload.savedSearch.name,
                notificationSetting: {
                    pushEnabled: action.payload.savedSearch.pushEnabled,
                    emailEnabled: action.payload.savedSearch.emailEnabled,
                    notifyRemove: action.payload.savedSearch.notifyRemove,
                    notifyAdd: action.payload.savedSearch.notifyAdd,
                    notifySold: action.payload.savedSearch.notifySold,
                    notifyForSale: action.payload.savedSearch.notifyForSale,
                    notifyForRent: action.payload.savedSearch.notifyForRent,
                    notificationsEnabled: action.payload.savedSearch.notificationsEnabled,
                    emailPeriod: action.payload.savedSearch.emailPeriod,
                },
            };

            yield call(
                SavedSearchesApi.updateSavedSearch,
                updateSavedSearchRequestBody,
                clUserId,
            );

            const itemIds = [];
            itemIds.push(action.payload.savedSearch.id);
            const savedSearches = yield call(
                SavedSearchesApi.getSavedSearch,
                itemIds,
                clUserId,
            );
            const [savedSearch] = Commons.get(savedSearches, 'response.result.items') || [];
            let noResults = true;
            if (savedSearch) {
                noResults = false;
            }
            yield put({
                type: SavedSearchAction.UPDATE_SAVED_SEARCH_SETTINGS_SUCCESS,
                payload: {
                    ...(savedSearch || {}),
                    noResults,
                },
            });
            yield put(rmCruxCmptError(SavedSearchAction.UPDATE_SAVED_SEARCH_SETTINGS));
            yield put(SavedSearchAction.setToastStatus(true));
        } catch (error) {
            yield put({ type: SavedSearchAction.UPDATE_SAVED_SEARCH_SETTINGS_FAIL });
            yield put(addCruxCmptError(SavedSearchAction.UPDATE_SAVED_SEARCH_SETTINGS, error));
        } finally {
            yield put(SavedSearchAction.setToastStatus(false));
        }
    };
}

function updateSavedSearchFn() {
    return function* (action) {
        const { savedSearch } = action.payload;
        try {
            yield call(
                removeSavedSearch,
                SavedSearchAction.removeSavedSearch(savedSearch.itemId),
            );
            const updatedSavedSearchResponse = yield call(
                saveSearch,
                SavedSearchAction.saveSearch(savedSearch),
            );
            const [updatedSavedSearch] = Commons.get(updatedSavedSearchResponse, 'response.result.items') || [];
            const _updatedSavedSearch = formatSavedSearchesAsV1(updatedSavedSearch);
            yield put({
                type: SavedSearchAction.UPDATE_SAVED_SEARCH_SUCCESS,
                payload: {
                    ...(_updatedSavedSearch || {}),
                    shouldRefresh: true,
                    noResults: !updatedSavedSearch,
                },
            });
            yield put(rmCruxCmptError(SavedSearchAction.UPDATE_SAVED_SEARCH));
        } catch (error) {
            yield put({ type: SavedSearchAction.UPDATE_SAVED_SEARCH_FAIL });
            yield put(addCruxCmptError(SavedSearchAction.UPDATE_SAVED_SEARCH, error));
        }
    };
}

function saveSearchFeatureFn() {
    return function* (action) {
        try {
            const savedSearches = yield call(
                saveSearch,
                action,
            );
            if (savedSearches.response.result instanceof Object) {
                const [savedSearch] = Commons.get(savedSearches, 'response.result.items') || [];
                yield put({
                    type: SavedSearchAction.SAVE_SEARCH_SUCCESS,
                    payload: {
                        ...(savedSearch || {}),
                        newSavedSearch: true,
                    },
                });
                yield put(rmCruxCmptError(SavedSearchAction.SAVE_SEARCH));
            } else {
                const error = new Error(savedSearches.response.result);
                yield put(addCruxCmptError(SavedSearchAction.SAVE_SEARCH, error));
            }
        } catch (error) {
            yield put({ type: SavedSearchAction.SAVE_SEARCH_FAIL });
            yield put(addCruxCmptError(SavedSearchAction.SAVE_SEARCH, error));
        }
    };
}

function getSavedSearchFn() {
    return function* (action) {
        try {
            const clUserId = yield select(SagaHelper.getClUsrId);
            const savedSearches = yield call(
                SavedSearchesApi.getSavedSearch,
                action.payload.itemIds,
                clUserId,
            );
            yield fork(
                transformSavedSearchFilter,
                {
                    type: SavedSearchAction.GET_SAVED_SEARCH_SUCCESS,
                    savedSearches,
                    shouldRefresh: false,
                },
            );
            yield call(
                getNotificationHistory,
                { payload: action.payload.itemIds },
            );
        } catch (error) {
            yield put({ type: SavedSearchAction.GET_SAVED_SEARCH_FAIL });
        }
    };
}

function removeSavedSearchFeatureFn() {
    return function* (action) {
        try {
            yield call(
                removeSavedSearch,
                action,
            );
            yield put({
                type: SavedSearchAction.REMOVE_SAVED_SEARCH_SUCCESS,
                payload: {
                    removed: true,
                },
            });
            yield put(rmCruxCmptError(SavedSearchAction.REMOVE_SAVED_SEARCH));
        } catch (error) {
            yield put({ type: SavedSearchAction.REMOVE_SAVED_SEARCH_FAIL });
            yield put(addCruxCmptError(SavedSearchAction.REMOVE_SAVED_SEARCH, error));
        }
    };
}

export const getSavedSearch = getSavedSearchFn();
export const updateSavedSearchSettings = updateSavedSearchSettingsFn();
export const updateSavedSearch = updateSavedSearchFn();
export const saveSearchFeature = saveSearchFeatureFn();
export const removeSavedSearchFeature = removeSavedSearchFeatureFn();

export function* getSavedSearchWatcher() {
    yield takeLatest(SavedSearchAction.GET_SAVED_SEARCH, getSavedSearch);
}

export function* updateSavedSearchSettingsWatcher() {
    yield takeLatest(SavedSearchAction.UPDATE_SAVED_SEARCH_SETTINGS, updateSavedSearchSettings);
}

export function* updateSavedSearchWatcher() {
    yield takeLatest(SavedSearchAction.UPDATE_SAVED_SEARCH, updateSavedSearch);
}

export function* saveSavedSearchFeatureWatcher() {
    yield takeLatest(SavedSearchAction.SAVE_SEARCH, saveSearchFeature);
}

export function* getNotificationHistoryWatcher() {
    yield takeEvery(SavedSearchAction.GET_NOTIFICATION_HISTORY, getNotificationHistory);
}

export function* removeSavedSearchFeatureWatcher() {
    yield takeEvery(SavedSearchAction.REMOVE_SAVED_SEARCH, removeSavedSearchFeature);
}

export default [
    getSavedSearchWatcher(),
    updateSavedSearchSettingsWatcher(),
    updateSavedSearchWatcher(),
    saveSavedSearchFeatureWatcher(),
    getNotificationHistoryWatcher(),
    removeSavedSearchFeatureWatcher(),
];
