import methods from '../constants/methods';

export default (url, params, requireNewTab) => {
    const form = document.createElement('form');
    const inputs = [];
    form.setAttribute('method', methods.POST.method);
    form.setAttribute('action', url);
    if (requireNewTab) {
        form.setAttribute('target', '_blank');
        form.setAttribute('data-testid', 'hidden-form');
    } else {
        form.setAttribute('data-testid', 'hidden-form-without-target');
    }
    Object.keys(params).forEach((key, index) => {
        inputs[index] = document.createElement('input');
        inputs[index].setAttribute('data-testid', `hidden-form-input-${index}`);
        Object.keys(params[key].attributes).forEach((attrKey) => {
            inputs[index].setAttribute(attrKey, params[key].attributes[attrKey]);
        });
        form.appendChild(inputs[index]);
    });

    document.body.appendChild(form);

    return form;
};
