/* eslint-disable no-undef */
import { useCallback, useState } from 'react';
import { styled } from '@mui/material';
import { useRouteMatch } from 'react-router-dom';
import { ACTIVE_COUNTRY } from '../../../helpers/Localization';
import { DrawingManager, GoogleMap, Marker, Polygon } from '@react-google-maps/api';
import { DRAWING_MODE, MAP_MARKER_IMAGE_NAME } from '../../../constants/map';
import CreateTerritoryMapToolBox from './CreateTerritoryMapToolBox';
import withMap from 'js/components/higherOrderComponents/WithMap';
import { getAssetDomain } from '../../../constants/assets';
import CruxSnackBar from '../../common/CruxSnackBar';
import useMapCommands from '../../../hooks/savedTerritories/useMapCommands';
import SavedTerritoryDrawer from './SavedTerritoryDrawer';
import { routeCodes } from 'js/constants/routes';
import Colors from 'js/constants/colors';
import useSavedTerritory from 'js/hooks/useSavedTerritory';

const DEFAULT_MAP_STYLE = {
    width: '100%',
    height: '100vh',
};

const MAP_OPTIONS = {
    fullscreenControl: false,
    mapTypeControl: false,
    streetViewControl: false,
};

const POLYGON_OPTIONS = {
    editable: true,
    strokeColor: Colors.PRIMARY.MAIN,
    strokeWeight: 2,
    fillColor: 'transparent',
    geodesic: true,
}

const SHAPE_ERROR = 'The shape must have a minimum of three (3) points. Please add another to complete the shape.';

const convertPathsToArray = (path) => {
    return path.getPath()
        .getArray()
        .map((latLng) => {
            return { lat: latLng.lat(), lng: latLng.lng() };
        });
}

const StyledTerritoryContainer = styled('div')({
    display: 'flex',
    flexWrap: 'nowrap',
});

const StyledMap = styled('div')({
    flexGrow: 1,
});

const StyledSideBarContainer = styled('div')({
    width: 365,
});

const CreateTerritoryMap = () => {
    const [drawingMode, setDrawingMode] = useState('');
    const [drawing, setDrawing] = useState(null);
    const [marker, setMarker] = useState(null);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [severity, setSeverity] = useState('');
    const [map, setMap] = useState(null);
    const [polygonPath, setPolygonPath] = useState([]);
    const [isPolygonEdited, setIsPolygonEdited] = useState(false);
    const [editPolygonDeleted, setEditPolygonDeleted] = useState(false);
    const mapCommands = useMapCommands({
        setPolygonPath,
        isPolygonEdited,
        setIsPolygonEdited,
        setDrawing,
    });
    const [googlePolygon, setGooglePolygon] = useState(null);
    const match = useRouteMatch(routeCodes.SAVED_TERRITORY.path(':itemId'));
    const savedTerritory = useSavedTerritory({
        territoryId: match?.params?.itemId,
        polygonRef: googlePolygon,
        actions: {
            setPolygonPath,
            mapCommands,
            map,
        },
        editPolygonDeleted,
    });

    const onLoad = useCallback((map) => {
        // set bounds to Australia
        const geocoder = new window.google.maps.Geocoder();
        geocoder.geocode({
            address: ACTIVE_COUNTRY.name,
        }, (results, status) => {
            if (status === 'OK') {
                if (!savedTerritory?.territory?.polygon?.length) {
                    map.setCenter(results[0].geometry.location);
                    map.fitBounds(results[0].geometry.viewport);
                    map.setZoom(ACTIVE_COUNTRY.zoom);
                }
                map.disableDefaultUI = true;
                map?.drawingManager?.setDrawingMode(window?.google.maps.drawing.OverlayType.POLYGON)
            }
        });

        setMap(map);
    }, [])

    const onUnmount = useCallback(function callback() {
        setMap(null);
    }, [])
    const openSnackBar = (severity, message) => {
        setError(true);
        setSeverity(severity);
        setErrorMessage(message);
    };

    // Sets the polygon to state after drawing for reference when editing/moving points
    const onOverlayComplete = (e) => {
        const newPath = convertPathsToArray(e.overlay);

        if (newPath.length < 3) {
            e.overlay.setMap(null);
            openSnackBar('error', SHAPE_ERROR);
            return;
        }

        setDrawingMode('');
        setDrawing(e);

        // replace Drawing Manager's polygon with <Polygon />
        setPolygonPath(newPath)
        mapCommands.record(newPath);

        // remove Drawing Manager's polygon as it is a read-only
        e.overlay.setMap(null);
        setIsPolygonEdited(true);
    };

    const getCoordinates = () => {
        // This will return the list of coordinates in array
        const polygonPathArray = googlePolygon?.getPath()?.getArray()
            || drawing?.overlay?.getPath()?.getArray()
            || [];
        let polygonCoordinates = polygonPathArray?.map(coords => coords?.toJSON());
        if (mapCommands.isEditedFromCommand) {
            // prevents outdated geoPolygon values when changes is made via undo/redo
            polygonCoordinates = polygonPath;
        }
        const area = polygonPathArray && window.google?.maps?.geometry?.spherical?.computeArea(polygonPathArray);
        const zoom = map?.getZoom();
        const centerLatLng = polygonPathArray
            ?.reduce((prev, curr) =>
                prev?.extend(curr), new window.google.maps.LatLngBounds())
            .getCenter()
            ?.toJSON();
        return {
            zoom,
            area,
            centerLatLng,
            polygonCoordinates,
        };
    };


    const onPolygonEdit = () => {
        if (googlePolygon) {
            const nextPath = convertPathsToArray(googlePolygon);
            // prevents duplicate consecutive polygon vertex being recorded in the undo list
            if (!mapCommands.isEqualWithLastRecord(nextPath)) {
                mapCommands.record(nextPath);
                setIsPolygonEdited(true);
            }
        }
    }

    // Bind refs to current Polygon
    const onPolygonLoad = useCallback((polygon) => {
        setGooglePolygon(polygon);

        // hides the mini undo button that pops up
        // when you edit an edge/vertex
        polygon.set('suppressUndo', true);
    }, [onPolygonEdit]);

    return (
        <StyledTerritoryContainer>
            <StyledMap>
                <GoogleMap
                    mapContainerStyle={DEFAULT_MAP_STYLE}
                    onLoad={onLoad}
                    onUnmount={onUnmount}
                    options={MAP_OPTIONS}
                >
                    <DrawingManager
                        drawingMode={drawingMode !== DRAWING_MODE.POLYGON ? '' : DRAWING_MODE.POLYGON}
                        options={{
                            drawingControlOptions: {
                                drawingModes: [DRAWING_MODE.POLYGON],
                                position: window?.google.maps.ControlPosition.TOP_CENTER,
                            },
                            polygonOptions: POLYGON_OPTIONS,
                        }}
                        onOverlayComplete={onOverlayComplete}
                    />
                    {
                        !!marker &&
                        <Marker
                            position={marker}
                            icon={{
                                url: getAssetDomain(MAP_MARKER_IMAGE_NAME.TARGET_PROPERTY_INACTIVE_PIN),
                                scaledSize: new window.google.maps.Size(35, 35),
                                origin: new window.google.maps.Point(-5, 0),
                            }}
                            zIndex={1}
                        />
                    }
                    {
                        !!polygonPath.length &&
                        <Polygon
                            options={POLYGON_OPTIONS}
                            editable
                            path={polygonPath}
                            onMouseUp={onPolygonEdit}
                            onLoad={onPolygonLoad}
                        />
                    }
                </GoogleMap>
                {
                    !!map &&
                    <CreateTerritoryMapToolBox
                        setEditPolygonDeleted={setEditPolygonDeleted}
                        editPolygonDeleted={editPolygonDeleted}
                        setDrawingMode={setDrawingMode}
                        drawingMode={drawingMode}
                        drawing={drawing}
                        setDrawing={setDrawing}
                        mapInstance={map}
                        setMarker={setMarker}
                        openSnackBar={openSnackBar}
                        setPolygonPath={setPolygonPath}
                        polygonPath={polygonPath}
                        setGooglePolygon={setGooglePolygon}
                        savedTerritory={savedTerritory}
                        mapCommands={mapCommands}
                    />
                }
            </StyledMap>
            <StyledSideBarContainer>
                <SavedTerritoryDrawer
                    id={savedTerritory?.territory?.id}
                    description={savedTerritory?.territory?.territoryName}
                    getCoordinates={getCoordinates}
                    drawing={googlePolygon}
                    openSnackBar={openSnackBar}
                />
            </StyledSideBarContainer>
            <CruxSnackBar
                severity={severity}
                open={error}
                message={errorMessage}
                onClose={() => setError(false)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
            />
        </StyledTerritoryContainer>
    );
}

export default withMap(CreateTerritoryMap);
