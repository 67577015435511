import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import { Button, Skeleton, styled, Tab, Tabs } from '@mui/material';
import { BookmarkBorder, StarOutline, ShareLocation } from '@mui/icons-material';
import {
    FAVOURITES_TAB_HOME,
    SAVED_LISTS,
    SAVED_SEARCHES,
    SAVED_TERRITORIES
} from '../../../constants/favourites/favourites';
import FeaturedV2 from '../../common/FeaturedV2';
import { createMixpanelProperties } from '../../../helpers/Segment';
import SEGMENT from '../../../constants/segment';
import { trackEvent } from '../../../actions/segment';
import Colors from '../../../constants/colors';
import SaveSearchPanelHome from './savedSearch/SavedSearchPanelHome';
import SavedTerritoryPanelHome from './savedTerritories/SavedTerritoryPanelHome';
import SavedListPanelHome from './savedList/SavedListPanelHome';

export const CONTENT_HEIGHT = '255px';

export const PlaceholderSkeleton = <Skeleton
    variant="text"
    sx={{ backgroundColor: 'gray' }}
    data-testid="placeholder-skeleton"
/>;
export const StyledButton = styled(Button)({
    display: 'flex',
    padding: '4px 5px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    height: '24px',
    gap: '8px',
    fontSize: '13px',
    lineHeight: '22px',
    letterSpacing: '0.46px',
    marginTop: '10px',
});

const StyledContainer = styled('div')({
    display: 'block',
    height: '380px',
    padding: '16px',
    paddingTop: '11px',
    margin: '10px auto',
    flexDirection: 'column',
    alignItems: 'flex-start',
    flexShrink: 0,
    borderRadius: '7px',
    backdropFilter: 'blur(5px)',
    backgroundBlendMode: 'multiply',
    background: Colors.BLACK_60_OPAQUE,

    '& .unsubscribed-page-container': {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '8px',
        flex: '1 0 0',
        alignSelf: 'stretch',
        width: '479px',
        height: '234px',
        color: Colors.WHITE,
        textAlign: 'center',

        'a': {
            cursor: 'pointer',
        },
    }
});

const StyledTabs = styled(Tabs)({
    width: '100%',
    marginBottom: '16px',
    '& .MuiTabs-flexContainer': {
        justifyContent: 'space-between',
    }
});

const StyledTab = styled(Tab)({
    color: Colors.WHITE,
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '400',
    textAlign: 'center',
    lineHeight: '24px',
    letterSpacing: '0.4px',
    pointerEvents: 'auto !important',
    display: 'flex',
    flexDirection: 'row',
    padding: '11px 8px',
    gap: '4px',
    minHeight: 48,
    '& .MuiSvgIcon-root': {
        marginBottom: '0',
    },
});

const USER_FAVORITES_ICONS = {
    [SAVED_SEARCHES]: <BookmarkBorder />,
    [SAVED_LISTS]: <StarOutline />,
    [SAVED_TERRITORIES]: <ShareLocation />,
};

const FavouriteTab = (props) => {
    const { entitled, role, ...rest } = props;
    return (
        <StyledTab
            {...rest}
            key={rest.value}
            className="tab-menu"
        />
    );
};

const TabPanel = (props) => {
    const { children, tab, role, value, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={tab !== value}
            id={`simple-tabpanel-${value}`}
            aria-labelledby={`simple-tab-${value}`}
            {...other}
        >
            {children}
        </div>
    );
};

const UserFavoritesHome = (props) => {
    const dispatch = useDispatch();
    const [activeTab, setActiveTab] = useState(SAVED_LISTS);

    useEffect(() => {
        setActiveTab(SAVED_LISTS);
    }, [props.usrDetail?.accountDetail?.clAccountGuid]);
    const handleTabChange = (event, tabValue) => {
        const MIXPANEL_PROPERTIES = {
            [SAVED_SEARCHES]: createMixpanelProperties(SEGMENT.EVENT_NAMES.VIEW_SAVED_SEARCHES, {
                page: 'Homepage',
                pageTab: 'Saved Search',
            }),
            [SAVED_LISTS]: createMixpanelProperties(SEGMENT.EVENT_NAMES.VIEW_SAVED_LISTS, {
                page: 'Homepage',
                pageTab: 'Saved List',
            }),
            [SAVED_TERRITORIES]: createMixpanelProperties(SEGMENT.EVENT_NAMES.VIEW_SAVED_TERRITORY, {
                page: 'Homepage',
                pageTab: 'Saved Territory',
            }),
        };
        dispatch(trackEvent(MIXPANEL_PROPERTIES[tabValue]));

        setActiveTab(tabValue);
    };

    return (
        <StyledContainer>
            <StyledTabs
                value={activeTab}
                className="user-favorites-home-tab"
                indicatorColor="primary"
                textColor="primary"
                onChange={handleTabChange}
            >
                {
                    FAVOURITES_TAB_HOME.map(({ role, value, label }) => (
                        <FeaturedV2
                            key={value}
                            role={role}
                            value={value}
                        >
                            <FavouriteTab
                                key={value}
                                label={label}
                                value={value}
                                icon={USER_FAVORITES_ICONS[value]}
                            />
                        </FeaturedV2>
                    ))
                }
            </StyledTabs>

            <TabPanel value={activeTab} tab={SAVED_LISTS}>
                <SavedListPanelHome />
            </TabPanel>

            <TabPanel value={activeTab} tab={SAVED_SEARCHES}>
                <SaveSearchPanelHome />
            </TabPanel>

            <TabPanel value={activeTab} tab={SAVED_TERRITORIES}>
                <SavedTerritoryPanelHome />
            </TabPanel>
        </StyledContainer>

    );
};

UserFavoritesHome.propTypes = {
    usrDetail: PropTypes.object,
};

export default connect(state => ({
    usrDetail: state.clapi.get('usrDetail'),
}))(UserFavoritesHome);
