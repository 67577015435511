export const REQUEST_PARAMS = {
    INCLUDE_COMMONS: 'includeCommons',
};

export const PANEL = {
    IMAGE_GALLERY: 'image gallery',
    PROPERTY_SUMMARY: 'property summary',
    MARKET_TRENDS: 'market trends',
    ADDITIONAL_INFORMATION: 'additional information',
    HOUSEHOLD_INFORMATION: 'household information',
    NEARBY_SCHOOLS: 'nearby schools',
    PROPERTY_NOTES: 'property notes',
    VALUATION_ESTIMATE: 'valuation estimate',
    RENTAL_ESTIMATE: 'rental estimate',
    SIMILAR_PROPERTIES: 'similar properties',
    TITLE_INFORMATION: 'title information',
    PROPERTY_HISTORY: 'property history',
    AVM: 'avm',
    RATING_VALUATION: 'rating valuation',
    ADVERTISEMENTS: 'advertisements',
};

export const VALUATION = {
    VALUATION_ESTIMATE_UNAVAILABLE: 'Sorry, the CoreLogic Valuation Estimate is not available for this property.',
    RENTAL_ESTIMATE_UNAVAILABLE: 'Sorry, the CoreLogic Rental Estimate is not available for this property.',
    CONFIDENCE_TOO_LOW: 'The Valuation confidence is too low.',
    DOWNLOAD_VALUATION_ERROR_MSG: 'There was an error downloading your report. Please try again.',
    PROPERTY_TYPE_NOT_ACCEPTED: 'Sorry, the CoreLogic Valuation Estimate is only available for Houses and Units.',
}

const PROPERTY_DETAIL = {
    PHOTOS: 'photos',
    HOUSEHOLD_INFORMATION: 'householdInformation',
    PROPERTY_SUMMARY: 'propertySummary',
    COMMONS: 'commons',
    ADDITIONAL_INFORMATION: 'additionalInformation',
    TITLE_INFORMATION: 'titleInformation',
    SIMILAR_PROPERTIES: 'similarProperties',
    RATING_VALUATION: 'ratingValuation',
    PROPERTY_TIMELINE: 'propertyTimeline',
    TARGET_PROPERTY_DETAILS: 'targetPropertyDetail',
};

export const COMMONS_FIELDS = {
    location: 'location',
    attributes: 'attributes',
    attributesAdditional: 'attrAdditional',
    attrAdditional: 'attrAdditional',
    lastSale: 'lastSale',
};

export const HISTORIC = {
    FEATURE_NOT_AVAILABLE: 'This feature is not available for historical properties',
};

export default PROPERTY_DETAIL;
