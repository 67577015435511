import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Grid from "@mui/material/Grid";
import { connect } from 'react-redux';
import DropdownArrowIcon
    from '../../searchResults/rapidSearchFiltersV2/cruxFilterDropdown/components/DropdownArrowIcon';
import DropdownCheckboxOptions from './nameSearch/DropdownCheckboxOptions';
import SearchValidation from './SearchValidation';

const StateSelect = ({
    key,
    options,
    toolTipText,
    showToolTip,
    addressState,
    userSettings,
    handleStateChange,
    isStateFieldError,
    stateFieldErrorMessage,
    userSettingKey,
}) => {

    const [ showStateModal, setShowStateModal ] = useState(false);
    const [ selectedState, setSelectedState ] = useState(addressState);

    useEffect(() => {
        const lastSelectedState = userSettings
            .find(userSetting => userSetting.name === userSettingKey)?.value;
        if (!addressState && lastSelectedState && options.hasOwnProperty(lastSelectedState)){
            setSelectedState(lastSelectedState);
        }
    }, [userSettings]);

    useEffect(() => {
        handleStateChange(selectedState);
    }, [selectedState]);

    const onSelectionChange = (type) => {
        setShowStateModal(false);
        setSelectedState(type);
    };

    return (
        <Grid key={`grid-${key}`} item className="item" xs={3}>
            <div
                data-testid="search-state-dropdown-container"
                key={key}
                className={`form-input-search-field state-field-container${isStateFieldError ? ' error' : ''}${showStateModal ? ' state-on-focus' : ''}`}
            >
                <div
                    data-testid="search-state-dropdown"
                    className="state-field form-control"
                    onClick={() => setShowStateModal(true)}
                >
                        <span data-testid='selected-state-option'
                              className={selectedState !== '' ? 'has-value' : ''}>
                            {selectedState === '' ? 'State' : selectedState}
                        </span>
                    <DropdownArrowIcon isOpen={showStateModal}/>
                </div>
                {
                    showStateModal &&
                    <DropdownCheckboxOptions
                        options={options}
                        tooltipText={toolTipText}
                        selectOption={selectedState}
                        onModalClose={() => setShowStateModal(false)}
                        showModal={showStateModal}
                        onSelectionChange={onSelectionChange}
                        showTooltip={showToolTip}
                        customClass="home-state-options-modal"
                        customClassName="home-state-options"
                    />
                }
                <SearchValidation message={stateFieldErrorMessage} onError={isStateFieldError}/>
            </div>
        </Grid>
    );
}

StateSelect.prototype = {
    key: PropTypes.string,
    options: PropTypes.array,
    toolTipText: PropTypes.string,
    addressState: PropTypes.string,
    isStateFieldError: PropTypes.bool,
    stateFieldErrorMessage: PropTypes.string,
    userSettingKey: PropTypes.string,
    handleStateChange: PropTypes.func,
    showToolTip: PropTypes.bool,
}

export default connect(state => ({
    userSettings: state.userPreferences.get('userSettings'),
}))(StateSelect);