import Commons from '../helpers/Commons';

export default class CruxMapCoordinates {
    constructor(obj) {
        this.lat = null;
        this.lng = null;

        if (obj && !Commons.isObjectEmpty(obj)) {
            this.lat = parseFloat(obj.latitude);
            this.lng = parseFloat(obj.longitude);
        }
    }

    hasCoordinates() {
        return !!(this.lat && this.lng);
    }
}
