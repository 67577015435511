export default {
    NETWORK_ERROR: 'A network error occurred, and your changes were not saved. Please try again.',
    REMOVE_USER_FAILED: 'There was an issue removing this user, please contact our Customer Care team to assist.',
    ERROR_FETCHING: 'Sorry. There was an error fetching your details. Please reload the page.',
    CROP_IMAGE_FAILED: 'There was an issue saving the image, please select another photo and try again.',
    ERROR_INVITES_FAILURE: '\'0\' user/s have been sent an invitation to associate to this organisation. The user/s may already be associated to this organisation.',
    SAVE_SEARCH_SUCCESS: 'Search filters and results saved.',
    SAVE_TERRITORY_SUCCESS: '{territoryName} territory was created successfully',
    UPDATE_SAVED_SEARCH_SUCCESS: 'Saved search updated successfully.',
    UPDATE_SAVED_TERRITORY_SUCCESS: '{territoryName} territory was updated successfully',
    REMOVE_SAVED_SEARCH_SUCCESS: 'Saved search removed.',
    SAVED_LIST_ERROR: 'Sorry, there was an error fetching your lists.',
    UPDATED_PROPERTIES_ERROR: 'Sorry, there was an error fetching your updated properties. Please try again.',
    NO_ADDITIONAL_PROPERTIES_FOUND: 'No additional properties found',
};
