import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import TOOLTIP from '../../../constants/tooltipText';

const CustomValEstimateLink = (props) => {
    const {
        reportRoles,
        openLiveAvmModal,
    } = props;

    const isDisabled = !reportRoles?.includes('Valuation')
    const className = isDisabled ? 'not-hyperlink' : 'hyper-link';

    const onClick = () => {
        if (!isDisabled) {
            openLiveAvmModal();
        }
    }
    return (
        <Fragment>
            {
                <div data-testid="live-avm-link" className={`live-avm-link ${className}`}>
                    <p
                        role="presentation"
                        className="mb-0"
                        onClick={onClick}
                        data-tip={isDisabled ? TOOLTIP.TEXT.MENU.UNSUBSCRIBED : ''}
                        data-for="live-avm-link-link"
                    >
                        Create customised Valuation Estimate
                    </p>
                    <ReactTooltip
                        id="live-avm-link-link"
                        className="custom-tool-tip"
                        effect="solid"
                        event="mouseover"
                        eventOff="mouseleave"
                    />
                </div>
            }
        </Fragment>
    );
};

CustomValEstimateLink.propTypes = {
    reportRoles: PropTypes.array,
    openLiveAvmModal: PropTypes.func,
};

export default CustomValEstimateLink;
