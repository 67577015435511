import { call, put, takeLatest, } from 'redux-saga/effects';
import ReportApi from '../api/report';
import { PREVIEW_REPORT, PREVIEW_REPORT_FAIL, PREVIEW_REPORT_SUCCESS } from '../actions/previewReport';

function previewReport() {
    return function* (action) {
        const { requestBodyParams, reportPath } = action.payload;
        try {
            const response = yield call(
                ReportApi.getListReport,
                requestBodyParams,
                reportPath,
            );
            yield put({
                type: PREVIEW_REPORT_SUCCESS,
                payload: {
                    isError: false,
                    failedRequest: null,
                    ...response,
                },
            });
        } catch (e) {
            yield put({
                type: PREVIEW_REPORT_FAIL,
                payload: {
                    isError: true,
                    failedRequest: {
                        requestBodyParams,
                        reportPath,
                    }
                },
            });
        }
    };
}

export const previewReportGenerator = previewReport();

function* previewReportWatcher() {
    yield takeLatest(PREVIEW_REPORT, previewReportGenerator);
}

export default [
    previewReportWatcher(),
];
