const ERROR_FETCHING_EXPORT_DATA = 'Sorry, there was an error fetching the export data. ' +
    'Please refresh the page to try again.';
const NO_EXPORT_DATA = 'No data found for your search.';
const FILTER_OPTION_FORMAT = [
    {
        label: 'Exclude Do Not Call',
        key: 'exclude_do_not_call',
        checked: true,
    },
    {
        label: 'Exclude Do Not Mail',
        key: 'exclude_do_not_mail',
        checked: true,
    }
];
const DISPLAY_OPTION_FORMAT = [
    {
        label: 'Show header',
        key: 'show_header',
        checked: true,
    },
    {
        label: 'Show refine search criteria',
        key: 'show_refine_search_criteria',
        checked: true,
    },
];

const ADDITIONAL_FORMATS = [
    {
        label: 'Contact Export',
        key: 'contact_export',
        value: 'allContacts',
        values: [
            {
                value: 'allContacts',
                label: 'All contacts',
                key: 'all_contacts',
            },
            {
                value: 'oneContactPerProperty',
                label: 'One contact per property',
                key: 'one_contact_per_property',
            },
        ],
    },
    {
        label: 'Address to',
        key: 'address_to',
        value: 'Not Selected',
        values: [
            {
                value: 'Not Selected',
                label: 'Not Selected',
            },
            {
                value: 'The Homeowner',
                label: 'The Homeowner',
            },
            {
                value: 'The Householder',
                label: 'The Householder',
            },
            {
                value: 'The Proprietor',
                label: 'The Proprietor',
            },
        ],
    }
];

const DISPLAY_OPTIONS_DIRECT_MARKETING_AU = {
    filterFormat: FILTER_OPTION_FORMAT,
    format: DISPLAY_OPTION_FORMAT,
    additionalFormat: ADDITIONAL_FORMATS,
    headers: [
        {
            label: 'Mail To',
            key: 'mailTo',
            checked: true,
        },
        {
            label: 'First Name',
            key: 'person.firstName',
            checked: true,
        },
        {
            label: 'Surname',
            key: 'person.lastName',
            checked: true,
        },
        {
            label: 'Street Address',
            key: 'streetAddress',
            checked: true,
        },
        {
            label: 'Suburb',
            key: 'mailingAddress.suburb',
            checked: true,
        },
        {
            label: 'State',
            key: 'mailingAddress.state',
            checked: true,
        },
        {
            label: 'Postcode',
            key: 'mailingAddress.postcode',
            checked: true,
        },
        {
            label: 'Phone',
            key: 'phone',
            checked: true,
        },
        {
            label: 'On Do Not Mail Register',
            key: 'mailingAddress.isDoNotMail',
            checked: true,
        },
        {
            label: 'Gender',
            key: 'gender',
            checked: true,
        },
    ],
};

const DISPLAY_OPTIONS_DIRECT_MARKETING_NZ = {
    filterFormat: FILTER_OPTION_FORMAT,
    format: DISPLAY_OPTION_FORMAT,
    additionalFormat: ADDITIONAL_FORMATS,
    headers: [
        {
            label: 'Mail To',
            key: 'mailTo',
            checked: true,
        },
        {
            label: 'First Name',
            key: 'person.firstName',
            checked: true,
        },
        {
            label: 'Surname',
            key: 'person.lastName',
            checked: true,
        },
        {
            label: 'Street Address',
            key: 'mailingAddress.address',
            checked: true,
        },
        {
            label: 'Phone',
            key: 'phone',
            checked: true,
        },
        {
            label: 'On Do Not Mail Register',
            key: 'mailingAddress.doNotMail',
            checked: true,
        },
    ],
};

const DISPLAY_OPTIONS_AU = {
    format: DISPLAY_OPTION_FORMAT,
    headers: [
        {
            label: 'Property Photo*',
            key: 'thumbnailPhotoUrl',
            checked: true,
        },
        {
            label: 'Street Address',
            key: 'addressComplete',
            checked: true,
            children: [
                { label: 'Street Address', key: 'addressFirstLine', checked: true },
                { label: 'Suburb', key: 'addressSuburb', checked: true },
                { label: 'State', key: 'addressState', checked: true },
                { label: 'Postcode', key: 'addressPostcode', checked: true },
            ],
        },
        {
            label: 'Property Type',
            key: 'type',
            checked: true,
        },
        {
            label: 'Attributes',
            key: 'attributes',
            checked: true,
            children: [
                { label: 'Bed', key: 'beds', checked: true },
                { label: 'Bath', key: 'baths', checked: true },
                { label: 'Car', key: 'carSpaces', checked: true },
                { label: 'Land Size (m²)', key: 'landArea', checked: true },
            ],
        },
        {
            label: 'Floor Size (m²)',
            displayOptionLabel: 'Floor Size',
            key: 'floorArea',
            checked: true,
        },
        {
            label: 'Year Built',
            key: 'yearBuilt',
            checked: true,
        },
        {
            label: 'First Rental Price',
            key: 'rentalLastCampaignFirstListedPriceDescription',
            checked: true,
            view: ['forRent'],
        },
        {
            label: 'First Rental Date',
            key: 'rentalLastCampaignStartDate',
            checked: true,
            view: ['forRent'],
        },
        {
            label: 'Last Rental Price',
            key: 'rentalLastCampaignLastListedPriceDescription',
            checked: true,
            view: ['forRent'],
        },
        {
            label: 'Last Rental Date',
            key: 'rentalLastCampaignEndDate',
            checked: true,
            view: ['forRent'],
        },
        {
            label: 'Days on Market',
            key: 'rentalLastCampaignDaysOnMarket',
            checked: true,
            view: ['forRent'],
        },
        {
            label: 'Agency',
            key: 'rentalLastCampaignAgency',
            checked: true,
            view: ['forRent'],
        },
        {
            label: 'Agent',
            key: 'rentalLastCampaignAgent',
            checked: true,
            view: ['forRent'],
        },
        {
            label: 'Active Listing',
            key: 'isActiveForRentCampaign',
            checked: true,
            view: ['forRent'],
        },
        {
            label: 'First Listed Price',
            key: 'salesLastCampaignFirstListedPriceDescription',
            checked: true,
            view: ['forSale', 'aged', 'withdrawn'],
        },
        {
            label: 'First Listed Date',
            key: 'salesLastCampaignStartDate',
            checked: true,
            view: ['forSale', 'aged', 'withdrawn'],
        },
        {
            label: 'Last Listed Price',
            key: 'salesLastCampaignLastListedPriceDescription',
            checked: true,
            view: ['forSale', 'aged', 'withdrawn'],
        },
        {
            label: 'Last Listed Date',
            key: 'salesLastCampaignEndDate',
            checked: true,
            view: ['forSale', 'aged', 'withdrawn'],
        },
        {
            label: 'Listing Type',
            key: 'salesLastCampaignListedType',
            checked: true,
            view: ['forSale', 'aged', 'withdrawn'],
        },
        {
            label: 'Days on Market',
            key: 'salesLastCampaignDaysOnMarket',
            checked: true,
            view: ['forSale', 'aged', 'withdrawn'],
        },
        {
            label: 'Sale Price',
            key: 'salesLastSoldPrice',
            checked: true,
            view: ['all', 'recentSale', 'tenure'],
        },
        {
            label: 'Sale Date',
            key: 'salesLastSaleContractDate',
            checked: true,
            view: ['all', 'recentSale', 'tenure'],
        },
        {
            label: 'Settlement Date',
            key: 'salesLastSaleSettlementDate',
            checked: true,
            view: ['all', 'recentSale', 'tenure'],
        },
        {
            label: 'Sale Type',
            key: 'salesLastSaleTypeDescription',
            checked: true,
            view: ['all', 'recentSale', 'tenure'],
        },
        {
            label: 'Agency',
            key: 'salesLastCampaignAgency',
            checked: true,
            view: ['all', 'recentSale', 'forSale', 'aged', 'withdrawn', 'tenure'],
        },
        {
            label: 'Agent',
            key: 'salesLastCampaignAgent',
            checked: true,
            view: ['all', 'recentSale', 'forSale', 'aged', 'withdrawn', 'tenure'],
        },
        {
            label: 'Active Listing',
            key: 'isActiveForSaleCampaign',
            checked: true,
            view: ['forSale', 'aged', 'withdrawn'],
        },
        {
            label: 'Land Use',
            key: 'landUse',
            checked: true,
        },
        {
            label: 'Development Zone',
            key: 'developmentZoneDescription',
            checked: true,
        },
        {
            label: 'Parcel Details',
            key: 'parcelList',
            checked: true,
        },
        {
            label: 'Owner Name',
            key: 'ownerName',
            checked: true,
            view: ['all', 'recentSale', 'tenure'],
            children: [
                { label: 'Owner 1 Name', key: 'ownerName1', checked: true },
                { label: 'Owner 2 Name', key: 'ownerName2', checked: true },
                { label: 'Owner 3 Name', key: 'ownerName3', checked: true },
            ],
        },
        {
            label: 'Owner Type',
            key: 'occupancyType',
            checked: true,
        },
        {
            label: 'Vendor Name',
            key: 'previousOwners',
            checked: true,
            view: ['all', 'recentSale', 'tenure'],
            children: [
                { label: 'Vendor 1 Name', key: 'previousOwnerName1', checked: true },
                { label: 'Vendor 2 Name', key: 'previousOwnerName2', checked: true },
                { label: 'Vendor 3 Name', key: 'previousOwnerName3', checked: true },
            ],
        },
        {
            label: 'Open in RPData',
            key: 'goToRPData',
            checked: true,
        },
    ],
};

const DISPLAY_OPTIONS_NZ = {
    format: DISPLAY_OPTION_FORMAT,
    headers: [
        {
            label: 'Property Photo*',
            key: 'thumbnailPhotoUrl',
            checked: true,
        },
        {
            label: 'Street Address',
            key: 'addressComplete',
            checked: true,
            children: [
                { label: 'Street Address', key: 'addressFirstLine', checked: true },
                { label: 'Suburb', key: 'addressSuburb', checked: true },
                { label: 'Postcode', key: 'addressPostcode', checked: true },
            ],
        },
        {
            label: 'Property Type',
            key: 'type',
            checked: true,
        },
        {
            label: 'Attributes',
            key: 'attributes',
            checked: true,
            children: [
                { label: 'Bed', key: 'beds', checked: true },
                { label: 'Bath', key: 'baths', checked: true },
                { label: 'Car', key: 'carSpaces', checked: true },
                { label: 'Land Size (m²)', key: 'landArea', checked: true },
            ],
        },
        {
            label: 'Floor Size (m²)',
            displayOptionLabel: 'Floor Size',
            key: 'floorArea',
            checked: true,
        },
        {
            label: 'Year Built',
            key: 'yearBuilt',
            checked: true,
        },
        {
            label: 'First Rental Price',
            key: 'rentalLastCampaignFirstListedPriceDescription',
            checked: true,
            view: ['forRent'],
        },
        {
            label: 'First Rental Date',
            key: 'rentalLastCampaignStartDate',
            checked: true,
            view: ['forRent'],
        },
        {
            label: 'Last Rental Price',
            key: 'rentalLastCampaignLastListedPriceDescription',
            checked: true,
            view: ['forRent'],
        },
        {
            label: 'Last Rental Date',
            key: 'rentalLastCampaignEndDate',
            checked: true,
            view: ['forRent'],
        },
        {
            label: 'Days on Market',
            key: 'rentalLastCampaignDaysOnMarket',
            checked: true,
            view: ['forRent'],
        },
        {
            label: 'Agency',
            key: 'rentalLastCampaignAgency',
            checked: true,
            view: ['forRent'],
        },
        {
            label: 'Agent',
            key: 'rentalLastCampaignAgent',
            checked: true,
            view: ['forRent'],
        },
        {
            label: 'Active Listing',
            key: 'isActiveForRentCampaign',
            checked: true,
            view: ['forRent'],
        },
        {
            label: 'First Listed Price',
            key: 'salesLastCampaignFirstListedPriceDescription',
            checked: true,
            view: ['forSale', 'aged', 'withdrawn'],
        },
        {
            label: 'First Listed Date',
            key: 'salesLastCampaignStartDate',
            checked: true,
            view: ['forSale', 'aged', 'withdrawn'],
        },
        {
            label: 'Last Listed Price',
            key: 'salesLastCampaignLastListedPriceDescription',
            checked: true,
            view: ['forSale', 'aged', 'withdrawn'],
        },
        {
            label: 'Last Listed Date',
            key: 'salesLastCampaignEndDate',
            checked: true,
            view: ['forSale', 'aged', 'withdrawn'],
        },
        {
            label: 'Listing Type',
            key: 'salesLastCampaignListedType',
            checked: true,
            view: ['forSale', 'aged', 'withdrawn'],
        },
        {
            label: 'Days on Market',
            key: 'salesLastCampaignDaysOnMarket',
            checked: true,
            view: ['forSale', 'aged', 'withdrawn'],
        },
        {
            label: 'Sale Price',
            key: 'salesLastSoldPrice',
            checked: true,
            view: ['all', 'recentSale'],
        },
        {
            label: 'Sale Date',
            key: 'salesLastSaleContractDate',
            checked: true,
            view: ['all', 'recentSale'],
        },
        {
            label: 'Settlement Date',
            key: 'salesLastSaleSettlementDate',
            checked: true,
            view: ['all', 'recentSale'],
        },
        {
            label: 'Sale Type',
            key: 'salesLastSaleTypeDescription',
            checked: true,
            view: ['all', 'recentSale'],
        },
        {
            label: 'Agency',
            key: 'salesLastCampaignAgency',
            checked: true,
            view: ['all', 'recentSale', 'forSale', 'aged', 'withdrawn'],
        },
        {
            label: 'Agent',
            key: 'salesLastCampaignAgent',
            checked: true,
            view: ['all', 'recentSale', 'forSale', 'aged', 'withdrawn'],
        },
        {
            label: 'Active Listing',
            key: 'isActiveForSaleCampaign',
            checked: true,
            view: ['forSale', 'aged', 'withdrawn'],
        },
        {
            label: 'Capital Value',
            key: 'valuationCapitalValue',
            checked: true,
        },
        {
            label: 'Land Value',
            key: 'valuationLandValue',
            checked: true,
        },
        {
            label: 'Valuation Date',
            key: 'valuationAssessmentDate',
            checked: true,
        },
        {
            label: 'Land Use',
            key: 'landUse',
            checked: true,
        },
        {
            label: 'Development Zone',
            key: 'developmentZoneDescription',
            checked: true,
        },
        {
            label: 'Parcel Details',
            key: 'parcelList',
            checked: true,
        },
        {
            label: 'Owner Type',
            key: 'occupancyType',
            checked: true,
        },
        {
            label: 'Open in Property Guru',
            key: 'goToPropertyGuru',
            checked: true,
        },
    ],
};

const ANALYSIS_EXPORT = {
    title: 'Analysis Export',
    subTitle: 'For internal research only. Contact details not included.',
    exportType: 'ANALYSIS_EXPORT',
};

const MARKETING_EXPORT = {
    title: 'Marketing Export',
    subTitle: 'Marketing details only available on licensed records.',
    entitlementChecker: 'hasMarketingFullRole',
    exportType: 'MARKETING_EXPORT',
};

const ANALYSIS_REPORT_DISCLAIMER = '*If the Property Photo is included, the file will export as' +
    ' Excel format.';

const MARKETING_EXPORT_COMPLIANCE_ERROR = 'Sorry, there was an error fetching the compliance data.' +
    ' Please refresh the page to try again';

const ANALYSIS_EXPORT_DISCLAIMER_AU = 'I acknowledge and agree that I must not use this' +
    ' information to direct market. I agree to ONLY use this extract for internal' +
    ' research purposes and to comply with RP Data’s terms and conditions found ' +
    '<a href="https://www.corelogic.com.au/legals/terms-conditions" target="_blank">here</a>.';

const ANALYSIS_EXPORT_DISCLAIMER_NZ = 'I acknowledge and agree that I must not use this' +
    ' information to direct market. I agree to ONLY use this extract for internal research' +
    ' purposes and to comply with Property Guru\'s terms and conditions found' +
    ' <a href="https://www.corelogic.co.nz/legals/product-terms-and-conditions"' +
    ' target="_blank">here</a>.';

const MARKETING_EXPORT_DISCLAIMER_AU = 'I agree to ONLY use this extract to identify which' +
    ' of my clients are more likely to sell. I acknowledge and agree to comply with RP' +
    ' Data’s terms and conditions found ' +
    '<a href="https://www.corelogic.com.au/legals/terms-conditions" target="_blank">here</a>.';

const MARKETING_EXPORT_DISCLAIMER_NZ = 'I agree to ONLY use this extract to identify which of' +
    ' my clients are more likely to sell. I acknowledge and agree to comply with Property Guru’s' +
    ' terms and conditions found <a href="https://www.corelogic.co.nz/legals/product' +
    '-terms-and-conditions" target="_blank">here</a>.'

const MARKETING_EXPORT_TTT_STATEMENT_AU = 'CoreLogic acquires a licence to the consumer contact' +
    ' data from a prominent supplier in the marketing database industry. CoreLogic does not use' +
    ' any personal information collected from government suppliers in relation to property' +
    ' transactions in the consumer contact data.';

const MARKETING_EXPORT_TTT_STATEMENT_NZ = 'CoreLogic acquires a licence to the consumer contact' +
    ' data from a prominent supplier in the marketing database industry. CoreLogic' +
    ' does not use any personal information collected from LINZ in relation to property' +
    ' transactions in the consumer contact data.';

const STATEMENT_LIST_AU = [
    {
        title: 'Phone Listings',
        detail: 'There is still an obligation on you the subscriber, to check if any' +
            ' phone numbers that are extracted have been placed on the DNC list just prior to' +
            ' making any outbound sales and marketing calls.',
    },
    {
        title: 'Head of Household',
        detail: 'This is generally a modelled attribute based on either a survey response or' +
            ' an assumption, i.e. the only occupant of a one person household. As such it is' +
            ' an indicative attribute only.',
    },
    {
        title: 'Gender',
        detail: 'This is generally a modelled attribute based on either the source of data or,' +
            ' more often, an analysis of the Christian names of the person. Hence \'Robert\' is' +
            ' deemed a male and \'Lesley\' a female.',
    },
];

const CONTACT_STATEMENT_NZ = 'This consumer contact data (that is names and phone' +
    ' numbers associated with a residential address) is sourced from' +
    ' a range of sources including telephone directory listings, historical electoral roles' +
    ' and subscriptions to magazines and special interest groups.';

const RECORD_STATEMENT_NZ = 'The number of records' +
    ' available from the Marketing Database for export vary by area. The data is regularly' +
    ' updated to maintain compliance and users should ensure that they use up to date records' +
    ' and only extract what they need.';

const CONTACT_STATEMENT_AU = 'This consumer contact data (that is names and phone' +
    ' numbers associated with a residential address) is sourced from' +
    ' a range of sources including telephone directory listings, historical electoral roles' +
    ' and subscriptions to magazines and special interest groups.';

const RECORD_STATEMENT_AU = 'The number of records' +
    ' available from the Marketing Database for export vary by area. The data is regularly' +
    ' updated to maintain compliance and users should ensure that they use up to date records' +
    ' and only extract what they need.';

const STATEMENT_LIST_NZ = [STATEMENT_LIST_AU[0]];

const MARKETING_EXPORT_DISPLAY_OPTIONS = 'MARKETING_EXPORT_DISPLAY_OPTIONS';
const MARKETING_EXPORT_DISPLAY_OPTIONS_NZ = 'MARKETING_EXPORT_DISPLAY_OPTIONS_NZ';
const ANALYSIS_EXPORT_DISPLAY_OPTIONS = 'EXPORT_DISPLAY_OPTIONS';
const ANALYSIS_EXPORT_DISPLAY_OPTIONS_NZ = 'EXPORT_DISPLAY_OPTIONS_NZ';

export default {
    DISPLAY_OPTIONS_AU,
    DISPLAY_OPTIONS_NZ,
    DISPLAY_OPTIONS_DIRECT_MARKETING_NZ,
    DISPLAY_OPTIONS_DIRECT_MARKETING_AU,
    ERROR_FETCHING_EXPORT_DATA,
    ANALYSIS_EXPORT_DISCLAIMER_AU,
    ANALYSIS_EXPORT_DISCLAIMER_NZ,
    ANALYSIS_EXPORT,
    MARKETING_EXPORT,
    ANALYSIS_REPORT_DISCLAIMER,
    MARKETING_EXPORT_DISCLAIMER_AU,
    MARKETING_EXPORT_DISCLAIMER_NZ,
    MARKETING_EXPORT_TTT_STATEMENT_AU,
    MARKETING_EXPORT_TTT_STATEMENT_NZ,
    STATEMENT_LIST_AU,
    STATEMENT_LIST_NZ,
    CONTACT_STATEMENT_NZ,
    RECORD_STATEMENT_NZ,
    CONTACT_STATEMENT_AU,
    RECORD_STATEMENT_AU,
    MARKETING_EXPORT_COMPLIANCE_ERROR,
    ADDITIONAL_FORMATS,
    MARKETING_EXPORT_DISPLAY_OPTIONS,
    MARKETING_EXPORT_DISPLAY_OPTIONS_NZ,
    ANALYSIS_EXPORT_DISPLAY_OPTIONS,
    ANALYSIS_EXPORT_DISPLAY_OPTIONS_NZ,
    NO_EXPORT_DATA,
};
