import { Map as IMap } from 'immutable';
import * as Action from '../actions/watchList';

export const initialState = IMap({
    savedList: {
        list: [],
        fetching: null,
        success: null,
        fetchingMore: false,
        successToFetchMore: true,
        searching: false,
        successSearch: true,
    },
    watchList: {
        propertiesCount: 0,
        alertedPropertiesCount: 0,
        fetching: null,
        success: null,
    },
    listContent: {
        fetching: false,
    },
    info: {
        watchList: [],
        savedList: [],
    },
});

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case Action.GET_SAVED_LIST_INFO_MULTIPLE_SUCCESS: {
            const watchList = action.payload.reduce((accumulator, current) => {
                if (current.existsInWatchList && !accumulator.includes(...current.propertyIds)) {
                    accumulator.push(...current.propertyIds)
                }
                return accumulator;
            }, JSON.parse(JSON.stringify(state.get('info')?.watchList)));

            const savedList = action.payload.reduce((accumulator, current) => {
                if (current.existsInSavedLists) {
                    const { savedLists = [], propertyIds = [], } = current;
                    savedLists.forEach(({ id }) => {
                        const targetSavedList = accumulator.find(list => list.id === id);
                        if (targetSavedList) {
                            targetSavedList.properties = [...new Set([
                                ...targetSavedList.properties,
                                ...propertyIds,
                            ])];
                        } else {
                            accumulator.push({
                                id,
                                properties: propertyIds,
                            });
                        }
                    });
                }
                return accumulator;
            }, JSON.parse(JSON.stringify(state.get('info')?.savedList)));

            return state.merge(IMap({
                info: {
                    watchList,
                    savedList,
                },
            }));
        }
        case Action.ADD_WATCH_LIST_INFO: {
            return state.merge(IMap({
                info: {
                    ...state.get('info'),
                    watchList: [...new Set([
                        ...state.get('info')?.watchList,
                        ...action?.payload || [],
                    ])],
                },
            }));
        }
        case Action.REMOVE_WATCH_LIST_INFO: {
            const watchListProperties = state.get('info')?.watchList;
            const finalWatchList = action?.payload?.reduce((accumulator, current) => {
                const index = accumulator.findIndex(id => id === current);
                if (index >= 0) {
                    accumulator.splice(index, 1);
                }

                return accumulator;
            }, watchListProperties);

            return state.merge(IMap({
                info: {
                    ...state.get('info'),
                    watchList: finalWatchList,
                },
            }));
        }
        case Action.ADD_SAVED_LIST_INFO: {
            const stateSavedList = JSON.parse(JSON.stringify(state.get('info')?.savedList));
            const { id = [], properties = [] } = action?.payload;

            id.forEach(savedListId => {
                const targetSavedList = stateSavedList.find(list => Number(list.id) === Number(savedListId));
                if (targetSavedList) {
                    targetSavedList.properties = [...new Set([
                        ...targetSavedList.properties,
                        ...properties,
                    ])];
                } else {
                    stateSavedList.push({
                        id: Number(savedListId),
                        properties,
                    });
                }
            });

            return state.merge(IMap({
                info: {
                    ...state.get('info'),
                    savedList: stateSavedList,
                },
            }));
        }
        case Action.REMOVE_SAVED_LIST_INFO: {
            const stateSavedList = JSON.parse(JSON.stringify(state.get('info')?.savedList));
            const { id, properties = [] } = action?.payload;
            const targetSavedList = stateSavedList.find(s => s.id === id);

            targetSavedList.properties = properties?.reduce((accumulator, current) => {
                const index = accumulator.findIndex(id => id === current);
                if (index >= 0) {
                    accumulator.splice(index, 1);
                }

                return accumulator;
            }, targetSavedList?.properties || []);

            return state.merge(IMap({
                info: {
                    ...state.get('info'),
                    savedList: stateSavedList,
                },
            }));
        }
        case Action.ADD_SAVED_LIST: {
            const _savedList = JSON.parse(JSON.stringify(state.get('savedList')));
            if (_savedList.hasNext) {
                _savedList.list.pop();
            }
            return state.merge(IMap({
                savedList: {
                    ...JSON.parse(JSON.stringify(state.get('savedList'))),
                    list: [
                        action.payload,
                        ..._savedList.list,
                    ],
                },
            }));
        }
        case Action.UPDATE_SAVED_LIST: {
            const listCopy = JSON.parse(JSON.stringify(state.get('savedList').list))
            const modIndex = listCopy.findIndex(list => Number(action.payload.id) === Number(list.id));
            listCopy[modIndex] = { ...listCopy[modIndex], ...action.payload }
            return state.merge(IMap({
                savedList: {
                    ...state.get('savedList'),
                    list: listCopy,
                },
            }));
        }
        case Action.UPDATE_WATCH_LIST: {
            return state.merge(IMap({
                watchList: {
                    ...state.get('watchList'),
                    propertiesCount: action.payload.propertiesCount,
                },
            }));
        }
        case Action.GET_SAVED_LIST: {
            return state.mergeDeep(IMap({
                savedList: {
                    fetching: true,
                },
            }));
        }
        case Action.GET_SAVED_LIST_SUCCESS: {
            const { savedLists, ...rest } = action.payload;
            return state.merge(IMap({
                savedList: {
                    ...state.get('savedList'),
                    ...rest,
                    list: savedLists,
                    fetching: false,
                    success: true,
                },
            }));
        }
        case Action.GET_SAVED_LIST_FAIL: {
            return state.merge(IMap({
                savedList: {
                    ...state.get('savedList'),
                    fetching: false,
                    success: false,
                },
            }));
        }
        case Action.GET_MORE_SAVED_LIST: {
            return state.merge(IMap({
                savedList: {
                    ...state.get('savedList'),
                    fetchingMore: true,
                },
            }));
        }

        case Action.GET_MORE_SAVED_LIST_SUCCESS: {
            const { savedLists, ...rest } = action.payload;
            return state.merge(IMap({
                savedList: {
                    ...state.get('savedList'),
                    list: [...state.get('savedList').list, ...savedLists],
                    fetchingMore: false,
                    successToFetchMore: true,
                    ...rest,
                },
            }));
        }
        case Action.GET_MORE_SAVED_LIST_FAIL: {
            return state.merge(IMap({
                savedList: {
                    ...state.get('savedList'),
                    successToFetchMore: false,
                    fetchingMore: false,
                },
            }));
        }
        case Action.SEARCH_SAVED_LIST: {
            return state.merge(IMap({
                savedList: {
                    ...state.get('savedList'),
                    searching: true,
                },
            }));
        }
        case Action.SEARCH_SAVED_LIST_SUCCESS: {
            const { savedLists, ...rest } = action.payload;
            return state.merge(IMap({
                savedList: {
                    ...state.get('savedList'),
                    ...rest,
                    list: savedLists,
                    searching: false,
                    successSearch: true,
                },
            }));
        }
        case Action.SEARCH_SAVED_LIST_FAIL: {
            return state.merge(IMap({
                savedList: {
                    ...state.get('savedList'),
                    searching: false,
                    successSearch: false,
                },
            }));
        }
        case Action.GET_WATCHLIST_COUNT: {
            return state.merge(IMap({
                watchList: {
                    ...state.get('watchList'),
                    fetching: true,
                },
            }));
        }
        case Action.GET_WATCHLIST_COUNT_SUCCESS: {
            return state.merge(IMap({
                watchList: {
                    ...action.payload,
                    fetching: false,
                    success: true,
                },
            }));
        }
        case Action.GET_WATCHLIST_COUNT_FAIL: {
            return state.merge(IMap({
                watchList: {
                    ...initialState.get('watchList'),
                    fetching: false,
                    success: false,
                },
            }));
        }
        case Action.GET_PROPERTIES_ON_SAVED_OR_WATCH_LIST: {
            return state.merge(IMap({
                listContent: {
                    ...state.get('listContent'),
                    fetching: true,
                },
            }));
        }
        case Action.GET_MORE_PROPERTIES_ON_SAVED_OR_WATCH_LIST_SUCCESS: {
            const { properties, propertyIds, ...rest } = action.payload;
            const prevListContent = state.get('listContent');
            return state.merge(IMap({
                listContent: {
                    ...prevListContent,
                    ...rest,
                    propertyIds: [
                        ...(prevListContent.propertyIds || []),
                        (properties || propertyIds)
                    ].flat(),
                    fetching: false,
                },
            }));
        }
        case Action.GET_PROPERTIES_ON_SAVED_OR_WATCH_LIST_SUCCESS: {
            const { properties, propertyIds, ...rest } = action.payload;
            const prevListContent = state.get('listContent');
            return state.merge(IMap({
                listContent: {
                    ...prevListContent,
                    ...rest,
                    propertyIds: properties || propertyIds,
                    fetching: false,
                },
            }));
        }
        case Action.GET_PROPERTIES_ON_SAVED_OR_WATCH_LIST_FAIL: {
            return state.merge(IMap({
                listContent: {
                    ...initialState.get('properties'),
                    fetching: false,
                },
            }));
        }
        case Action.GET_RAPID_PROPERTIES_ON_SAVED_OR_WATCH_LIST_SUCCESS:
        case Action.GET_MORE_RAPID_PROPERTIES_ON_SAVED_OR_WATCH_LIST_SUCCESS: {
            return state.merge(IMap({
                listContent: {
                    ...state.get('listContent'),
                    page: action.payload,
                },
            }));
        }
        default:
            return state;
    }
}
