import React, { Fragment } from 'react';
import ReactTooltip from 'react-tooltip';
import PropTypes from 'prop-types';
import InfoIcon from './InfoIcon';
import QuestionIcon from './QuestionIcon';
import { ReactComponent as RadiusSearchIcon } from '../../../assets/svg/search/radius-search-card.svg';
import UpdateIcon from '../searchResults/views/card/button/UpdateIcon';

const getIconType = (iconType, style, isHistoricProperty) => {
    switch (iconType) {
        case 'infoIcon':
            return <InfoIcon style={style} size={18}/>;
        case 'questionIcon':
            return <QuestionIcon style={style} />;
        case 'updateIcon':
            return <UpdateIcon isHistoricProperty={isHistoricProperty} />;
        case 'radiusSearchIcon':
            return <RadiusSearchIcon />;
        default:
            return null;
    }
};

const Tooltip = ({
    tooltipTxt, tooltipId, tooltipClass, tooltipPosition, iconType, style, isHistoricProperty,
    children,
}) => {
    const className = tooltipClass ? ` ${tooltipClass}` : '';
    return (
        <Fragment>
            <span data-testid="tooltip" data-tip={tooltipTxt} data-for={tooltipId} className={`tool-tip-component${className}`}>
                { children || getIconType(iconType, style, isHistoricProperty) }
            </span>
            <ReactTooltip
                html
                id={tooltipId}
                place={tooltipPosition}
                className={iconType === 'questionIcon' ? null : `custom-tool-tip${className}`}
                effect="solid"
                event="mouseover"
                eventOff="mouseleave"
                aria-label="tooltip-text"
            />
        </Fragment>
    );
};


Tooltip.propTypes = {
    tooltipTxt: PropTypes.string,
    tooltipId: PropTypes.string,
    tooltipClass: PropTypes.string,
    tooltipPosition: PropTypes.string,
    iconType: PropTypes.string,
    style: PropTypes.object,
    isHistoricProperty: PropTypes.bool,
};

export default Tooltip;
