import React, { useEffect, useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import BlockUi from './BlockUi';
import { register, unregister } from './middleware/BlockUiMiddleware';

const ReduxBlockUi = ({
                          children,
                          block, unblock,
                          blocking: propBlocking, loader,
                          id, className, style: propStyle}) => {

    const [blocking, setBlocking] = useState(0);
    const style = useMemo(() => ({
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        height: '100vh',
        zIndex: 1200,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden',
        position: 'fixed',
    }), []);

    const mergedStyle = useMemo(() => ({
        ...style,
        ...propStyle
    }), [style, propStyle]);

    const middleware = useCallback((action) => {
        const checkAction = (checkList, shouldBlock) => {
            const checks = Array.isArray(checkList) ? checkList : [checkList];
            const isMatch = checks.some(value => value === action.type);
            if (isMatch) {
                setBlocking(prev => shouldBlock ? prev + 1 : Math.max(prev - 1, 0));
            }
        };

        checkAction(block, true);
        checkAction(unblock, false);
    }, [block, unblock]);

    useEffect(() => {
        register(middleware);
        return () => {
            try {
                unregister(middleware);
            } catch (err) {
                console.error("Failed to unregister middleware:", err);
            }
        };
    }, [middleware]);

    useEffect(() => {
        if (blocking) {
            document.body.classList.add('block-ui-no-scroll');
        } else {
            document.body.classList.remove('block-ui-no-scroll');
        }
        return () => {
            document.body.classList.remove('block-ui-no-scroll');
        };
    }, [blocking]);

    return (
        <BlockUi
            blocking={propBlocking || blocking > 0}
            backdropProps={{  style: mergedStyle }}
            containerProps={{ id, className }}
            loader={loader}
        >
            {children}
        </BlockUi>
    );
};

ReduxBlockUi.propTypes = {
    children: PropTypes.node,
    block: PropTypes.arrayOf(PropTypes.string),
    unblock: PropTypes.arrayOf(PropTypes.string),
    blocking: PropTypes.bool,
    loader: PropTypes.node,
    id: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object
};

ReduxBlockUi.defaultProps = {
    block: [],
    unblock: [],
    blocking: false,
    loader: null,
    id: 'redux-block-ui-id',
    className: '',
    style: {}
};
export default ReduxBlockUi;
