import methods from '../constants/methods';
import API from './index';
import { UriBuilder } from '../helpers/UriBuilder';
import CLAPI from './clapi';
import cruxProxy from './cruxProxy';

const getSavedSearches = () => {
    const appAccountUserGuid = `clAppAccountUserGuid=${CLAPI.getSelectedClAppAccountUserGuid()}`;
    const requestOptions = {
        path: `/api/saved/search/v2?sortBy=dateModified&orderBy=desc&allowMultiple=true&${appAccountUserGuid}`,
        isProxy: true,
    };
    const headerOptions = {
        method: methods.GET.method,
    };
    return API.initiateSessionApiCall(requestOptions, headerOptions);
};

const removeSavedSearch = (itemId) => {
    const appAccountUserGuid = `clAppAccountUserGuid=${CLAPI.getSelectedClAppAccountUserGuid()}`;
    const path = new UriBuilder(`/api/saved/search?itemIds=${itemId}&${appAccountUserGuid}`);
    const requestOptions = {
        path: path.build(),
        isProxy: true,
        protected: true,
    };
    const headerOptions = {
        method: methods.DELETE.method,
        headers: {
            'Content-Type': 'application/json',
            ...cruxProxy.getXsrfHeader(),
        },
    };
    return API.initiateSessionApiCall(requestOptions, headerOptions);
};

const getNotificationHistory = (itemId) => {
    const requestOptions = {
        path: `/api/notification/saved/search/${itemId}?clAppAccountUserGuid=${CLAPI.getSelectedClAppAccountUserGuid()}`,
        isProxy: true,
    };
    const headerOptions = {
        method: methods.GET.method,
    };
    return API.initiateSessionApiCall(requestOptions, headerOptions);
};

const saveSearch = (requestBody) => {
    const appAccountUserGuid = `clAppAccountUserGuid=${CLAPI.getSelectedClAppAccountUserGuid()}`;
    const requestOptions = {
        path: `/api/saved/search/v2?${appAccountUserGuid}`,
        isProxy: true,
    };

    const headerOptions = {
        method: methods.POST.method,
        body: requestBody,
        headers: {
            'Content-Type': 'application/json',
            ...cruxProxy.getXsrfHeader(),
        },
    };

    return API.initiateSessionApiCall(requestOptions, headerOptions);
};

const getSavedSearch = (itemIds) => {
    const endpoint = new UriBuilder('/api/saved/search/v2');
    endpoint.addParam('allowMultiple', true);
    itemIds.forEach(itemId => endpoint.addParam('itemIds', itemId));
    endpoint.addParam('clAppAccountUserGuid', CLAPI.getSelectedClAppAccountUserGuid());

    const requestOptions = {
        path: endpoint.build(),
        isProxy: true,
    };

    const headerOptions = {
        method: methods.GET.method,
    };

    return API.initiateSessionApiCall(requestOptions, headerOptions);
};

const updateSavedSearch = (savedSearch) => {
    const appAccountUserGuid = `clAppAccountUserGuid=${CLAPI.getSelectedClAppAccountUserGuid()}`;

    const requestOptions = {
        path: `/api/saved/search/v2?${appAccountUserGuid}`,
        isProxy: true,
    };

    const headerOptions = {
        method: methods.PUT.method,
        body: savedSearch,
        headers: {
            'Content-Type': 'application/json',
            ...cruxProxy.getXsrfHeader(),
        },
    };

    return API.initiateSessionApiCall(requestOptions, headerOptions);
};

export default {
    getSavedSearches,
    removeSavedSearch,
    getNotificationHistory,
    getSavedSearch,
    updateSavedSearch,
    saveSearch,
};
