import React, { Component } from 'react';
import Modal from '@mui/material/Modal';
import PropTypes from 'prop-types';

export default class ReportModal extends Component {
    static propTypes = {
        open: PropTypes.bool,
        onClose: PropTypes.func,
    };

    render() {
        return (
            <Modal
                open={this.props.open}
                onClose={this.props.onClose}
                id="report-options-modal"
                className="modal-bg-opacity"
                data-testid="report-options-modal"
                sx={{
                    overflow: 'auto',
                }}
            >
                <div className="report-options-container">
                    {this.props.children}
                </div>
            </Modal>
        );
    }
}
