import { Map as IMap } from 'immutable';
import {
    GET_NEW_USER_ACTIVITY_FEEDS_FAILURE,
    GET_NEW_USER_ACTIVITY_FEEDS_SUCCESS,
} from '../actions/favourites';

export const initialState = IMap({
    newUserActivityFeeds: {
        activityFeeds: [],
        pages: null,
    },
});

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case GET_NEW_USER_ACTIVITY_FEEDS_SUCCESS:
            return state.merge(IMap({
                newUserActivityFeeds: action.payload,
            }));
        case GET_NEW_USER_ACTIVITY_FEEDS_FAILURE:
            return state.merge(IMap({
                newUserActivityFeeds: initialState.get('newUserActivityFeeds'),
            }));
        default:
            return state;
    }
}
