import { useEffect, useState } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import PropTypes from 'prop-types';

import ReiForms from 'js/constants/reiForms';
import UnconnectedForm from 'js/components/property/reiForms/formsDropdown/UnconnectedForm';
import FormsButton from 'js/components/property/reiForms/formsDropdown/FormsButton';
import FormsDialogue from 'js/components/property/reiForms/FormsDialogue';
import CLAPI from 'js/api/clapi';
import { cruxLoader, cruxUnloader } from 'js/actions/crux';
import ReiAPI from 'js/api/reiForms';
import { getAllREITokens } from 'js/actions/reiForms';
import {
    INITIALIZE,
    UNSUBSCRIBED,
    NOT_IN_HISTORICAL,
    UNAVAILABLE,
} from 'js/constants/tooltipText';

const FormsDropdown = ({ addressState, entitled, historical }) => {
    const dispatch = useDispatch();

    const [formsActive, setFormsActive] = useState(false);
    const [formsDialogueActive, setFormsDialogueActive] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [validToken, setValidToken] = useState(null);

    const reiForms = useSelector(state => state.reiForms.get('reiForms'));
    const industry = useSelector(state => state
        .clapi
        .get('usrDetail'))
        ?.accountDetail
        ?.industrySegment;
    const stateToken = reiForms?.tokens?.find(token => token.state === addressState);

    const formType = ReiForms.FORM_TYPES_STATE[addressState];

    const isIndustryValid = ReiForms.VALID_INDUSTRIES.includes(industry);

    useEffect(() => {
        if (isIndustryValid) {
            dispatch(getAllREITokens());
        }
    }, []);

    useEffect(() => {
        if (validToken !== null) {
            setValidToken(null);
        }
    }, [addressState]);


    const onClickAway = () => setFormsActive(false);

    const handleButtonClick = (event) => {
        if (historical) return;
        if (stateToken?.token) {
            // token hasn't validated yet
            if (validToken === null) {
                dispatch(cruxLoader());
                ReiAPI.validateToken(addressState, CLAPI.getSelectedClAppAccountUserGuid())
                    .then((response) => {
                        setValidToken(response?.statusCode === 200);
                    })
                    .finally(() => {
                        dispatch(cruxUnloader());
                        setFormsDialogueActive(true);
                    });
            } else {
                setFormsDialogueActive(true);
            }
        } else {
            setFormsActive(true);
            setAnchorEl(event?.currentTarget || event?.target);
        }
    };

    const getTooltipText = () => {
        if (historical) {
            return NOT_IN_HISTORICAL;
        } else if (!entitled) {
            return UNSUBSCRIBED;
        } else if (reiForms?.fetching) {
            return INITIALIZE;
        } else if (reiForms?.error) {
            return UNAVAILABLE;
        }
        return '';
    }

    if (!formType || !isIndustryValid) return null;

    return (
        <>
            <ClickAwayListener onClickAway={onClickAway}>
                <div>
                    <FormsButton
                        formType={formType}
                        handleButtonClick={handleButtonClick}
                        disabled={reiForms?.fetching || !entitled || historical || reiForms?.error}
                        tooltip={getTooltipText()}
                    />
                    <UnconnectedForm
                        formsActive={formsActive}
                        anchorEl={anchorEl}
                        formType={formType}
                    />
                </div>
            </ClickAwayListener>
            <FormsDialogue
                formType={formType}
                formsDialogueActive={formsDialogueActive}
                setFormsDialogueActive={setFormsDialogueActive}
                stateToken={stateToken}
                validToken={validToken}
            />
        </>
    );
}

FormsDropdown.propTypes = {
    addressState: PropTypes.string.isRequired,
    entitled: PropTypes.bool,
    historical: PropTypes.bool,
}

export default FormsDropdown;

