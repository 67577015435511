import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { styled } from '@mui/material';
import Colors from '../../constants/colors';
import MUITooltip from '../common/MUITooltip';
import Segment from '../../helpers/Segment';
import { trackEvent } from '../../actions/segment';
import { routeCodes } from '../../constants/routes';
import { ReactComponent as NotificationIcon } from '../../../assets/svg/menu/notification.svg';
import { UNSUBSCRIBED } from '../../constants/tooltipText';
import Entitlements from '../../helpers/Entitlements';
import Commons from '../../helpers/Commons';

const MAX_COUNT = 99;
const FALSE = 'False';
const TRUE = 'True';
const ENTITLEMENTS = 'Entitlements';

const StyledContainer = styled('div')(({ disabled }) => ({
    width: 69,
    position: 'relative',
    maxHeight: 66,
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'center',
    '& div svg': {
        width: 23,
        height: 30,
        '& path:first-child': {
            fill: Colors.WHITE,
            opacity: disabled ? 0.25 : 1,
            cursor: disabled ? 'auto' : 'pointer',
        },
    },
    '& div': {
        display: 'flex',
    },
}));

const NotificationNumber = styled('div')(() => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxSizing: 'border-box',
    color: Colors.WHITE,
    backgroundColor: Colors.WATERMELON,
    padding: 6,
    height: 20,
    fontSize: 12,
    minWidth: 19,
    fontWeight: 500,
    marginRight: 3,
    borderRadius: 64,
    marginBottom: 30,
}));

const getAccess = (hasNotificationRole, notificationsFlag) => {
    if (notificationsFlag === TRUE) {
        return true;
    }

    return notificationsFlag === ENTITLEMENTS
        ? !!hasNotificationRole
        : false;
};

const NotificationBell = ({
    pageContext,
    dispatch,
    history,
    featureFlag = {},
    clapiUsrDetail,
    newUserActivityFeeds,
    alertedPropertiesCount = 0,
}) => {
    const notificationsFlag = featureFlag['crux.notifications'];
    if (notificationsFlag === FALSE) return null;

    const hasNotificationRole = Entitlements.hasNotificationRole(clapiUsrDetail);
    const savedSearchCount = Commons.get(newUserActivityFeeds, 'pages.totalElements') || 0;

    const hasSavedSearchRole = Entitlements.hasSaveSearchRole(clapiUsrDetail);
    let notificationCount = 0;
    if (hasSavedSearchRole) {
        notificationCount += savedSearchCount;
    }

    const hasSavedListRole = Entitlements.hasSavedListRole(clapiUsrDetail);
    if (hasSavedListRole) {
        notificationCount += alertedPropertiesCount;
    }

    const notificationEmpty = notificationCount === 0;
    const disabled = !getAccess(hasNotificationRole, notificationsFlag);
    const tooltipTitle = disabled ? UNSUBSCRIBED : '';
    const count = notificationCount > MAX_COUNT ? `${MAX_COUNT}+` : notificationCount;
    const showCount = !disabled && !notificationEmpty;

    const handleOnClick = () => {
        if (disabled) return;
        dispatch(trackEvent(Segment.viewNotificationBell(pageContext)));
        history.push(routeCodes.USER_FAVORITES.path);
    };

    return (
        <StyledContainer className="notification-bell-container" disabled={disabled}>
            <MUITooltip
                key="notification-bell-tooltip"
                placement="bottom"
                title={tooltipTitle}
                arrow
            >
                <div>
                    <NotificationIcon
                        data-testid="notification-icon"
                        onClick={handleOnClick}
                    />
                </div>
            </MUITooltip>
            {showCount && (
                <NotificationNumber>
                    <span>{count}</span>
                </NotificationNumber>
            )}
        </StyledContainer>
    );
};

NotificationBell.propTypes = {
    notificationCount: PropTypes.number.isRequired,
    dispatch: PropTypes.func.isRequired,
    hasNotificationRole: PropTypes.bool,
    notificationsFlag: PropTypes.string,
    pageContext: PropTypes.string,
    history: PropTypes.object,
};


export default connect(state => ({
    newUserActivityFeeds: state.favourites.get('newUserActivityFeeds'),
    alertedPropertiesCount: state.watchList.get('watchList')?.alertedPropertiesCount,
}))(NotificationBell);
