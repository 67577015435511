import React from 'react';

const BlockUiLoader = React.memo(({
                                      size = "60px",
                                      display = "block"
                                  }) => {
    return (
        <svg viewBox="0 0 100 100" preserveAspectRatio="xMidYMid" width={size} height={size} style={{ shapeRendering: 'auto', display, background: 'transparent' }}>
            <g>
                <circle className="crux-loader-svg" fill="currentColor" r="10" cy="50" cx="84">
                    <animate begin="0s" keySplines="0 0.5 0.5 1" values="10;0" keyTimes="0;1" calcMode="spline" dur="0.45454545454545453s" repeatCount="indefinite" attributeName="r" />
                </circle>

                <circle className="crux-loader-svg" fill="currentColor" r="10" cy="50" cx="16">
                    <animate begin="0s" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" values="0;0;10;10;10" keyTimes="0;0.25;0.5;0.75;1" calcMode="spline" dur="1.8181818181818181s" repeatCount="indefinite" attributeName="r" />
                    <animate begin="0s" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" values="16;16;16;50;84" keyTimes="0;0.25;0.5;0.75;1" calcMode="spline" dur="1.8181818181818181s" repeatCount="indefinite" attributeName="cx" />
                </circle>

                <circle className="crux-loader-svg" fill="currentColor" r="10" cy="50" cx="50">
                    <animate begin="-0.45454545454545453s" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" values="0;0;10;10;10" keyTimes="0;0.25;0.5;0.75;1" calcMode="spline" dur="1.8181818181818181s" repeatCount="indefinite" attributeName="r" />
                    <animate begin="-0.45454545454545453s" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" values="16;16;16;50;84" keyTimes="0;0.25;0.5;0.75;1" calcMode="spline" dur="1.8181818181818181s" repeatCount="indefinite" attributeName="cx" />
                </circle>

                <circle className="crux-loader-svg" fill="currentColor" r="10" cy="50" cx="84">
                    <animate begin="-0.9090909090909091s" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" values="0;0;10;10;10" keyTimes="0;0.25;0.5;0.75;1" calcMode="spline" dur="1.8181818181818181s" repeatCount="indefinite" attributeName="r" />
                    <animate begin="-0.9090909090909091s" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" values="16;16;16;50;84" keyTimes="0;0.25;0.5;0.75;1" calcMode="spline" dur="1.8181818181818181s" repeatCount="indefinite" attributeName="cx" />
                </circle>

                <circle className="crux-loader-svg" fill="currentColor" r="10" cy="50" cx="16">
                    <animate begin="-1.3636363636363635s" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" values="0;0;10;10;10" keyTimes="0;0.25;0.5;0.75;1" calcMode="spline" dur="1.8181818181818181s" repeatCount="indefinite" attributeName="r" />
                    <animate begin="-1.3636363636363635s" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" values="16;16;16;50;84" keyTimes="0;0.25;0.5;0.75;1" calcMode="spline" dur="1.8181818181818181s" repeatCount="indefinite" attributeName="cx" />
                </circle>
            </g>
        </svg>
    );
});

export default BlockUiLoader;
