import { styled, Button } from '@mui/material';
import Colors from 'js/constants/colors';

const StyledMenuButton = styled(Button)({
    marginLeft: '8px',
    '&.Mui-disabled': {
        color: Colors.WHITE,
    },
});

export default StyledMenuButton;
