import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';

import ReiForms from 'js/constants/reiForms';
import FormsError from './FormsError';
import FormsTemplate from './FormsTemplate';
import { getAssetDomain } from '../../../constants/assets';

const FormsDialogue = ({
    formType,
    formsDialogueActive,
    setFormsDialogueActive,
    validToken,
    stateToken,
}) => {

    const handleClose = () => {
        setFormsDialogueActive(false);
    };

    return (
        <Dialog
            open={formsDialogueActive}
            onClose={handleClose}
        >
            <DialogTitle
                sx={{
                    paddingBottom: '24px',
                }}
            >
                <img
                    className="img-icon"
                    src={getAssetDomain(ReiForms.FORM_LOGOS[formType])}
                    alt={ReiForms.FORM_LABEL[formType]}
                />
            </DialogTitle>
            {
                validToken ?
                    <FormsTemplate
                        addressState={stateToken?.state}
                        handleClose={handleClose}
                    /> :
                    <FormsError
                        formType={formType}
                    />
            }
        </Dialog>
    );
}

FormsDialogue.propTypes = {
    formType: PropTypes.string,
    formsDialogueActive: PropTypes.bool,
    setFormsDialogueActive: PropTypes.func,
    stateToken: PropTypes.object,
    validToken: PropTypes.bool,
};

export default FormsDialogue;
