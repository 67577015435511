import { takeLatest, call, put } from 'redux-saga/effects';
import Bugsnag from '../bugsnag';
import * as TYPE from '../actions/signature';
import SignatureApi from '../api/signature';
import AnalyticsGateway from '../lib/AnalyticsGateway';
import ReportType from '../constants/signatureReportType';
import Commons from '../helpers/Commons';

function goToSignatureReportFn() {
    return function* (action) {
        try {
            const {
                pageContext,
                propertyId,
                reportType,
                entryPoint,
                subType,
            } = action.payload;
            const mixpanelProperties = {
                propertyId: { label: 'Property ID', value: propertyId },
                reportType: { label: 'Report Type', value: ReportType[reportType] },
                entryPoint: { label: 'Entry Point', value: entryPoint },
                pageContext: { label: 'Page', value: pageContext },
            };
            AnalyticsGateway.sendEvent({
                eventName: 'Launch Signature Reports',
                properties: Object.keys(mixpanelProperties).map((key) => {
                    if (action.payload[key]) {
                        return mixpanelProperties[key];
                    }
                    return null;
                }).filter(property => !!property),
            });

            const response = yield call(
                SignatureApi.getSignatureUrlByType,
                propertyId,
                reportType,
            );
            if (Commons.get(response, 'message')) {
                yield put({
                    type: TYPE.GO_TO_SIGNATURE_REPORT_FAIL,
                    payload: {
                        errorMessage: response.message,
                        subType: subType || reportType,
                    },
                });
            } else {
                yield put({ type: TYPE.GO_TO_SIGNATURE_REPORT_SUCCESS });
            }
        } catch (error) {
            Bugsnag.notify(error);
            const {
                reportType,
                subType,
            } = action.payload;
            yield put({
                type: TYPE.GO_TO_SIGNATURE_REPORT_FAIL,
                payload: {
                    subType: subType || reportType,
                    errorMessage: error.message,
                },
            });
        }
    };
}

export const goToSignatureReport = goToSignatureReportFn();

export function* goToSignatureReportWatcher() {
    yield takeLatest(TYPE.GO_TO_SIGNATURE_REPORT, goToSignatureReport);
}

export default [
    goToSignatureReportWatcher(),
];
