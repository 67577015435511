import { Map as IMap } from 'immutable';
import {
    GET_BRANDING_SUCCESS,
    GET_BRANDING,
    GET_BRANDING_FAIL,
    RESET_HANDLER,
    REMOVE_DRAFT,
    ADD_BRANDING,
    SAVE_BRANDING_SUCCESS,
    SAVE_BRANDING_FAIL,
    EDIT_BRANDING_SUCCESS,
    EDIT_BRANDING_FAIL,
    DELETE_BRANDING_SUCCESS,
    DELETE_BRANDING_FAIL,
    ACTIVATE_THEME_SUCCESS,
    ACTIVATE_THEME_FAIL,
    REMOVE_THEME_LOGO_FAIL,
    REMOVE_THEME_LOGO_SUCCESS,
    VALIDATE_THEME_LOGO_FAIL,
} from '../actions/manageBranding';

export const initialState = IMap({
    themes: null,
    handler: {
        isError: false,
        message: '',
    },
});

const draftTheme = {
    footerDividerColour: '#E23D28',
    headerBackgroundColour: '#888888',
    headerDividerColour: '#E23D28',
    headerTextColour: '#888888',
    headingTextColour: '#000000',
    subHeadingTextColour: '#000000',
    themeName: '',
    themePoid: 10000000000,
    anchored: true,
    newlyAdded: true,
    editable: true,
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case GET_BRANDING: {
            return state.merge(IMap({
                themes: state.get('themes'),
                handler: initialState.get('handler'),
            }));
        }
        case GET_BRANDING_SUCCESS:
            return state.merge(IMap({
                themes: action.payload.themes,
            }));
        case RESET_HANDLER:
            return state.merge(IMap({
                handler: initialState.get('handler'),
            }));
        case ADD_BRANDING:
            return state.merge(IMap({
                themes: [...state.get('themes'), draftTheme],
            }));
        case ACTIVATE_THEME_FAIL:
        case SAVE_BRANDING_SUCCESS:
        case SAVE_BRANDING_FAIL:
        case DELETE_BRANDING_SUCCESS:
        case DELETE_BRANDING_FAIL:
        case REMOVE_THEME_LOGO_FAIL:
        case REMOVE_THEME_LOGO_SUCCESS:
        case VALIDATE_THEME_LOGO_FAIL:
            return state.merge(IMap({
                handler: action.payload,
            }));
        case EDIT_BRANDING_SUCCESS:
        case EDIT_BRANDING_FAIL: {
            const { themes, ...restOfPayload } = action.payload;
            if (themes) {
                return state.merge(IMap({
                    themes,
                    handler: restOfPayload,
                }));
            }
            return state.merge(IMap({
                handler: restOfPayload,
            }));
        }
        case GET_BRANDING_FAIL:
            return state.merge(IMap({
                handler: {
                    ...action.payload,
                    global: true,
                },
            }));
        case REMOVE_DRAFT: {
            const themesClone = JSON.parse(JSON.stringify(state.get('themes')));
            themesClone.pop();
            return state.merge(IMap({
                themes: themesClone,
            }));
        }
        case ACTIVATE_THEME_SUCCESS: {
            const themesClone = JSON.parse(JSON.stringify(state.get('themes')));
            const activeTheme = themesClone.find(theme => theme.active);
            activeTheme.active = false;
            themesClone[action.payload.index].active = true;
            return state.merge(IMap({
                handler: action.payload,
                themes: themesClone,
            }));
        }
        default:
            return state;
    }
};
