import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuList from '@mui/material/MenuList';
import DropdownArrowIcon from '../searchResults/rapidSearchFiltersV2/cruxFilterDropdown/components/DropdownArrowIcon';

const CruxSplitButtonDropdown = (props) => {
    const {
        className, children, label, testId, forwardedRef,
    } = props;
    const anchorRef = React.useRef(null);
    const [isOpen, setOpen] = React.useState(false);
    React.useImperativeHandle(forwardedRef, () => ({
        close: () => setOpen(false),
    }));
    return (
        <div className={className} data-testid={testId}>
            <ButtonGroup
                variant="contained"
                color="primary"
                ref={anchorRef}
                aria-label="split button"
                sx={{
                    '&': {
                        boxShadow: 'none',
                    },
                    '& .MuiButtonGroup-grouped': {
                        minWidth: 'initial',
                        minHeight: 38,
                    }
                }}
            >
                <Button sx={{ flexGrow: 1 }} >{label}</Button>
                <Button
                    sx={{
                        width: '28px',
                    }}
                    size="small"
                    aria-controls={isOpen ? 'split-button-menu' : undefined}
                    aria-expanded={isOpen ? 'true' : undefined}
                    aria-label="crux-second-split-button"
                    aria-level="2"
                    aria-haspopup="menu"
                    onClick={() => setOpen(true)}
                >
                    <DropdownArrowIcon isOpen={isOpen} />
                </Button>
            </ButtonGroup>
            <Popper
                open={isOpen}
                anchorEl={anchorRef.current}
                disablePortal
                placement="bottom-start"
                sx={{
                    zIndex: 10,
                }}
            >
                <Paper
                    sx={{
                        mt: '8px',
                        boxShadow: '0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px rgba(0, 0, 0, 0.14), 0 1px 3px rgba(0, 0, 0, 0.12)',
                    }}
                >
                    <ClickAwayListener onClickAway={() => setOpen(false)}>
                        <MenuList
                            id="split-button-menu"
                            sx={{ outline: 'none' }}
                        >
                            { children }
                        </MenuList>
                    </ClickAwayListener>
                </Paper>
            </Popper>
        </div>
    );
};

CruxSplitButtonDropdown.propTypes = {
    className: PropTypes.string,
    testId: PropTypes.string,
    label: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

CruxSplitButtonDropdown.defaultProps = {
    testId: 'crux-split-button-dropdown',
    height: 38,
    width: 113,
};

export default React.forwardRef((props, ref) =>
    <CruxSplitButtonDropdown {...props} forwardedRef={ref} />);
