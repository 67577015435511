import { Map as IMap } from 'immutable';
import * as Action from '../actions/savedSearch';

export const initialState = () => IMap({
    savedSearch: null,
    searchType: null,
    statusType: null,
    notificationHistory: null,
    isFetching: null,
});

export default function reducer(state = initialState(), action = {}) {
    switch (action.type) {
        case Action.GET_SAVED_SEARCH_SUCCESS:
        case Action.UPDATE_SAVED_SEARCH_SETTINGS_SUCCESS:
        case Action.UPDATE_SAVED_SEARCH_SUCCESS:
        case Action.SAVE_SEARCH_SUCCESS: {
            return state.merge(IMap({
                savedSearch: action.payload,
                isFetching: false,
                isError: false,
            }));
        }
        case Action.UPDATE_SAVED_SEARCH_SETTINGS: {
            return state.merge(IMap({
                isFetching: true,
            }));
        }
        case Action.SET_SEARCH_TYPE: {
            return state.merge(IMap({
                searchType: action.payload,
            }));
        }
        case Action.SET_STATUS_TYPE: {
            return state.merge(IMap({
                statusType: action.payload,
            }));
        }
        case Action.GET_SAVED_SEARCH:
        case Action.GET_SAVED_SEARCH_FAIL:
        case Action.SAVE_SEARCH_FAIL: {
            return state.merge(IMap({
                savedSearch: null,
                searchType: null,
                statusType: null,
            }));
        }
        case Action.UPDATE_SAVED_SEARCH_SETTINGS_FAIL: {
            return state.merge(IMap({
                isError: true,
                isFetching: false,
            }));
        }
        case Action.UPDATE_SAVED_SEARCH: {
            return state.merge(IMap({
                savedSearch: null,
            }));
        }
        case Action.REMOVE_SAVED_SEARCH_SUCCESS: {
            return state.merge(IMap({
                savedSearch: {
                    ...state.get('savedSearch'),
                    ...action.payload,
                },
            }));
        }
        case Action.GET_NOTIFICATION_HISTORY: {
            return state.merge(IMap({
                notificationHistory: {
                    loading: true,
                },
            }));
        }
        case Action.GET_NOTIFICATION_HISTORY_SUCCESS:
        case Action.GET_NOTIFICATION_HISTORY_FAIL: {
            return state.merge(IMap({
                notificationHistory: {
                    ...action.payload,
                    loading: false,
                },
            }));
        }
        case Action.RESET_NOTIFICATION_HISTORY: {
            return state.merge(IMap({
                notificationHistory: null,
            }));
        }
        case Action.SET_TOAST_STATUS: {
            return state.merge(IMap({
                savedSearch: {
                    ...state.get('savedSearch'),
                    showToast: action.payload,
                },
            }));
        }
        case Action.SET_SHOULD_REFRESH: {
            return state.merge(IMap({
                savedSearch: {
                    ...state.get('savedSearch'),
                    shouldRefresh: action.payload,
                },
            }));
        }
        case Action.CLEAR_SAVED_SEARCH: {
            return initialState();
        }
        default:
            return state;
    }
}
