import { Map as IMap } from 'immutable';
import * as TYPE from '../../js/actions/assets';

export const initialState = IMap({
    home: {},
    landUseOptions: [],
    devZoneOptions: [],
    propertySubTypeOptions: [],
});

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case TYPE.SAVE_HERO_IMAGE:
            return state.merge(IMap({
                home: action.payload,
            }));
        case TYPE.SAVE_LAND_USE_OPTIONS:
            return state.merge(IMap({
                landUseOptions: action.payload,
            }));
        case TYPE.SAVE_DEV_ZONE_OPTIONS:
            return state.merge(IMap({
                devZoneOptions: action.payload,
            }));
        case TYPE.SAVE_PROPERTY_SUB_TYPE_OPTIONS:
            return state.merge(IMap({
                propertySubTypeOptions: action.payload,
            }));
        default:
            return state;
    }
}
