import Constant from '../constants/constant';
import { getAssetDomain } from '../constants/assets';
import { DataRule } from './DataRule';

const CONFIDENCE = {
    HIGH: 'HIGH',
    MEDIUM_HIGH: 'MEDIUM_HIGH',
    MEDIUM: 'MEDIUM',
    MEDIUM_LOW: 'MEDIUM_LOW',
    LOW: 'LOW',
};

const confidenceBasis = Constant.FSD.Intellival;

const getInfoBasedOnConfidenceLevel = (confidenceLevel, tooltipText) => {
    switch (confidenceLevel) {
        case CONFIDENCE.HIGH: {
            return {
                confidenceToolTip: tooltipText,
                valuationRange: getAssetDomain('intellival-valuation-range-high.png'),
                confidenceLevelLabel: {
                    color: '#39B54A',
                    label: 'High Confidence',
                },
            };
        }
        case CONFIDENCE.MEDIUM_HIGH: {
            return {
                confidenceToolTip: tooltipText,
                valuationRange: getAssetDomain('intellival-valuation-range-mid-to-high.png'),
                confidenceLevelLabel: {
                    color: '#8ABA14',
                    label: 'Medium to High Confidence',
                },
            };
        }
        case CONFIDENCE.MEDIUM: {
            return {
                confidenceToolTip: tooltipText,
                valuationRange: getAssetDomain('intellival-valuation-range-mid.png'),
                confidenceLevelLabel: {
                    color: '#EFA000',
                    label: 'Medium Confidence',
                },
            };
        }
        case CONFIDENCE.MEDIUM_LOW: {
            return {
                confidenceToolTip: tooltipText,
                valuationRange: getAssetDomain('intellival-valuation-range-low-to-mid.png'),
                confidenceLevelLabel: {
                    color: '#F0763E',
                    label: 'Low to Medium Confidence',
                },
            };
        }
        default: {
            return {
                confidenceToolTip: tooltipText,
                valuationRange: getAssetDomain('intellival-valuation-range-low.png'),
                confidenceLevelLabel: {
                    color: '#FF0F0F',
                    label: 'Low Confidence',
                },
            };
        }
    }
};

const hasNullEstimateValues = avm => !(avm.estimate
    && avm.lowEstimate
    && avm.highEstimate);

function buildValuationRangeProps(avm, tooltipDisclaimer) {
    const valuation = {
        avmHasDetails: true,
        confidenceTooLow: false,
    };
    if (avm.fsd >= confidenceBasis.maxFsd || hasNullEstimateValues(avm)) {
        valuation.avmHasDetails = false;
        valuation.confidenceTooLow = true;
    } else {
        const dataRule = new DataRule();
        const priceFormatter = dataRule.formatPriceNoRounding;
        const infoBasedOnFSD = getInfoBasedOnConfidenceLevel(avm.confidence, tooltipDisclaimer);
        valuation.confidenceLevel = infoBasedOnFSD.confidenceLevelLabel;
        valuation.tooltipData = infoBasedOnFSD.confidenceToolTip;
        valuation.img = infoBasedOnFSD.valuationRange;
        valuation.estimate = priceFormatter(avm.estimate);
        valuation.lowEstimate = priceFormatter(avm.lowEstimate);
        valuation.highEstimate = priceFormatter(avm.highEstimate);
    }

    return valuation;
}

const isConfidenceTooLow = (avm) => {
    if (!avm || avm.errors) {
        return true;
    }

    if (!(avm.estimate && avm.lowEstimate && avm.highEstimate)) {
        return true;
    }

    return avm.fsd >= confidenceBasis.maxFsd;
};

export default {
    buildValuationRangeProps,
    isConfidenceTooLow,
    hasNullEstimateValues,
};
