import { call, put, takeLatest, takeEvery } from 'redux-saga/effects';
import MsgApi from '../api/msgApi';
import {
    MSG_GET_RECENT_ADDRESS_SEARCH,
    MSG_GET_RECENT_ADDRESS_SEARCH_FAIL,
    MSG_GET_RECENT_ADDRESS_SEARCH_SUCCESS,
    MSG_GET_RECENT_PARCEL_SEARCH,
    MSG_GET_RECENT_PARCEL_SEARCH_FAIL,
    MSG_GET_RECENT_PARCEL_SEARCH_SUCCESS,
    MSG_GET_RECENT_TITLE_SEARCH,
    MSG_GET_RECENT_TITLE_SEARCH_FAIL,
    MSG_GET_RECENT_TITLE_SEARCH_SUCCESS,
    MSG_SAVE_RECENT_ADDRESS_SEARCH,
    MSG_SAVE_RECENT_ADDRESS_SEARCH_FAIL,
    MSG_SAVE_RECENT_ADDRESS_SEARCH_SUCCESS,
    MSG_SAVE_RECENT_PARCEL_SEARCH,
    MSG_SAVE_RECENT_PARCEL_SEARCH_FAIL,
    MSG_SAVE_RECENT_PARCEL_SEARCH_SUCCESS,
    MSG_SAVE_RECENT_TITLE_SEARCH,
    MSG_SAVE_RECENT_TITLE_SEARCH_FAIL,
    MSG_SAVE_RECENT_TITLE_SEARCH_SUCCESS,
    MSG_REMOVE_RECENT_SEARCH,
    MSG_REMOVE_RECENT_SEARCH_SUCCESS,
    MSG_REMOVE_RECENT_SEARCH_FAIL,
    MSG_REMOVE_RECENT_PARCEL_SEARCH,
    MSG_REMOVE_RECENT_PARCEL_SEARCH_SUCCESS,
    MSG_REMOVE_RECENT_PARCEL_SEARCH_FAIL,
    MSG_REMOVE_RECENT_TITLE_SEARCH,
    MSG_REMOVE_RECENT_TITLE_SEARCH_SUCCESS,
    MSG_REMOVE_RECENT_TITLE_SEARCH_FAIL,
} from '../actions/msgApi';
import Bugsnag from '../bugsnag';
import suggestionType from '../constants/msg/suggestionType';

const NETWORK_ERROR = 'A network error occurred. Please try again.';

function getRecentAddressSearchesFn() {
    return function* () {
        try {
            const property = yield call(
                MsgApi.getRecentAddressSearches, suggestionType.ADDRESS.type, true,
            );

            // store the returned property in reducer
            const actionSuccess = {
                type: MSG_GET_RECENT_ADDRESS_SEARCH_SUCCESS,
                payload: property,
            };
            yield put(actionSuccess);
        } catch (error) {
            Bugsnag.notify(error);
            yield put({ type: MSG_GET_RECENT_ADDRESS_SEARCH_FAIL });
        }
    };
}

function removeRecentSearchFn() {
    return function* (successType, failType, action) {
        try {
            const property = yield call(
                MsgApi.removeRecentSearch,
                action.payload,
            );
            const actionSuccess = {
                type: successType,
                payload: property,
            };
            yield put(actionSuccess);
        } catch (error) {
            Bugsnag.notify(error);
            yield put({
                type: failType,
                payload: {
                    isError: true,
                    message: NETWORK_ERROR,
                },
            });
        }
    };
}

function saveRecentAddressSearchesFn() {
    return function* (action) {
        try {
            const property = yield call(
                MsgApi.saveRecentAddressSearches,
                action.payload.searchString,
                action.payload.suggestionTypeId,
            );

            // store the returned property in reducer
            const actionSuccess = {
                type: MSG_SAVE_RECENT_ADDRESS_SEARCH_SUCCESS,
                payload: property,
            };
            yield put(actionSuccess);
        } catch (error) {
            Bugsnag.notify(error);
            yield put({ type: MSG_SAVE_RECENT_ADDRESS_SEARCH_FAIL });
        }
    };
}

function getRecentParcelSearchesFn() {
    return function* () {
        try {
            const property = yield call(MsgApi.getRecentParcelSearches);

            // store the returned property in reducer
            const actionSuccess = {
                type: MSG_GET_RECENT_PARCEL_SEARCH_SUCCESS,
                payload: property,
            };
            yield put(actionSuccess);
        } catch (error) {
            Bugsnag.notify(error);
            yield put({ type: MSG_GET_RECENT_PARCEL_SEARCH_FAIL });
        }
    };
}

function saveRecentParcelSearchesFn() {
    return function* (action) {
        try {
            const property = yield call(
                MsgApi.saveRecentParcelSearches,
                action.payload.searchString,
                action.payload.suggestionType,
            );

            // store the returned property in reducer
            const actionSuccess = {
                type: MSG_SAVE_RECENT_PARCEL_SEARCH_SUCCESS,
                payload: property,
            };
            yield put(actionSuccess);
        } catch (error) {
            Bugsnag.notify(error);
            yield put({ type: MSG_SAVE_RECENT_PARCEL_SEARCH_FAIL });
        }
    };
}

function getRecentTitleSearchesFn() {
    return function* () {
        try {
            const property = yield call(MsgApi.getRecentTitleSearches);

            // store the returned property in reducer
            const actionSuccess = {
                type: MSG_GET_RECENT_TITLE_SEARCH_SUCCESS,
                payload: property,
            };
            yield put(actionSuccess);
        } catch (error) {
            Bugsnag.notify(error);
            yield put({ type: MSG_GET_RECENT_TITLE_SEARCH_FAIL });
        }
    };
}

function saveRecentTitleSearchesFn() {
    return function* (action) {
        try {
            const property = yield call(
                MsgApi.saveRecentTitleSearches,
                action.payload.searchString,
                action.payload.suggestionType,
            );

            // store the returned property in reducer
            const actionSuccess = {
                type: MSG_SAVE_RECENT_TITLE_SEARCH_SUCCESS,
                payload: property,
            };
            yield put(actionSuccess);
        } catch (error) {
            Bugsnag.notify(error);
            yield put({ type: MSG_SAVE_RECENT_TITLE_SEARCH_FAIL });
        }
    };
}

export const getRecentAddressSearches = getRecentAddressSearchesFn();
export const saveRecentAddressSearches = saveRecentAddressSearchesFn();
export const getRecentParcelSearches = getRecentParcelSearchesFn();
export const saveRecentParcelSearches = saveRecentParcelSearchesFn();
export const getRecentTitleSearches = getRecentTitleSearchesFn();
export const saveRecentTitleSearches = saveRecentTitleSearchesFn();
export const removeRecentSearch = removeRecentSearchFn();

export function* getRecentAddressSearchesWatcher() {
    yield takeLatest(MSG_GET_RECENT_ADDRESS_SEARCH, getRecentAddressSearches);
}

export function* removeRecentSearchWatcher() {
    yield takeEvery(
        MSG_REMOVE_RECENT_SEARCH,
        removeRecentSearch,
        MSG_REMOVE_RECENT_SEARCH_SUCCESS,
        MSG_REMOVE_RECENT_SEARCH_FAIL,
    );
}

export function* saveRecentAddressSearchesWatcher() {
    yield takeLatest(MSG_SAVE_RECENT_ADDRESS_SEARCH, saveRecentAddressSearches);
}

export function* getRecentParcelSearchesWatcher() {
    yield takeLatest(MSG_GET_RECENT_PARCEL_SEARCH, getRecentParcelSearches);
}

export function* removeRecentParcelSearchWatcher() {
    yield takeEvery(
        MSG_REMOVE_RECENT_PARCEL_SEARCH,
        removeRecentSearch,
        MSG_REMOVE_RECENT_PARCEL_SEARCH_SUCCESS,
        MSG_REMOVE_RECENT_PARCEL_SEARCH_FAIL,
    );
}

export function* saveRecentParcelSearchesWatcher() {
    yield takeLatest(MSG_SAVE_RECENT_PARCEL_SEARCH, saveRecentParcelSearches);
}

export function* getRecentTitleSearchesWatcher() {
    yield takeLatest(MSG_GET_RECENT_TITLE_SEARCH, getRecentTitleSearches);
}

export function* removeRecentTitleSearchWatcher() {
    yield takeEvery(
        MSG_REMOVE_RECENT_TITLE_SEARCH,
        removeRecentSearch,
        MSG_REMOVE_RECENT_TITLE_SEARCH_SUCCESS,
        MSG_REMOVE_RECENT_TITLE_SEARCH_FAIL,
    );
}

export function* saveRecentTitleSearchesWatcher() {
    yield takeLatest(MSG_SAVE_RECENT_TITLE_SEARCH, saveRecentTitleSearches);
}

export default [
    getRecentAddressSearchesWatcher(),
    removeRecentSearchWatcher(),
    saveRecentAddressSearchesWatcher(),
    getRecentParcelSearchesWatcher(),
    removeRecentParcelSearchWatcher(),
    saveRecentParcelSearchesWatcher(),
    getRecentTitleSearchesWatcher(),
    removeRecentTitleSearchWatcher(),
    saveRecentTitleSearchesWatcher(),
];
