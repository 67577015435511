import PropTypes from 'prop-types';
import StyledMenuButton from 'js/components/common/styledMUI/StyledMenuButton';
import ReiForms from 'js/constants/reiForms';
import MUITooltip from 'js/components/common/MUITooltip';
import { getAssetDomain } from '../../../../constants/assets';

const FormsButton = (props) => {
    const handleClick = (event) => {
        props.handleButtonClick(event);
    }

    return (
        <div>
            <MUITooltip
                title={props.tooltip || ''}
                arrow
                placement="bottom"
                longTooltip
            >
                <div>
                    <StyledMenuButton
                        variant="contained"
                        color="secondary"
                        onClick={handleClick}
                        disabled={props.disabled}
                        startIcon={
                            <img
                                src={getAssetDomain(ReiForms.FORM_ICONS[props.formType])}
                                width="14px"
                                alt={ReiForms.FORM_LABEL[props.formType]}
                            />
                        }
                    >
                        <span>{ReiForms.FORM_LABEL[props.formType]}</span>
                    </StyledMenuButton>
                </div>
            </MUITooltip>
        </div>
    );
}

FormsButton.propTypes = {
    formType: PropTypes.string,
    handleButtonClick: PropTypes.func,
    disabled: PropTypes.bool,
    tooltip: PropTypes.string,
}

export default FormsButton;
