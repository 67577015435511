import PropTypes from 'prop-types';
import { Box, Button, Modal, Paper, Typography } from '@mui/material';
import colors from '../../../constants/colors';
import Commons from '../../../helpers/Commons';

const btnStyle = {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '24px',
    letterSpacing: '0.4px',
    margin: '0 5px',
};

export const ADD = 'add';
export const REMOVE = 'remove';

const CruxDialogBox = (props) => {
    const {
        id,
        open,
        onClose,
        onConfirm,
        type = REMOVE,
        confirmBtnLabel,
        title,
        subTitle,
    } = props;
    const isAddType = type === ADD;
    const addConfirmBtnLabel = confirmBtnLabel || Commons.capitalizeFirstLetter(type);
    const buttonLabel = isAddType ? addConfirmBtnLabel : Commons.capitalizeFirstLetter(type);
    const handleConfirm = () => {
        onConfirm(isAddType);
        onClose();
    };
    return (
        <Modal data-testid={`${id}-users-dialog-box`} open={open} onClose={onClose}>
            <Box height="100%" display="flex" alignItems="center" justifyContent="center">
                <Box
                    component={Paper}
                    sx={{
                        minHeight: 160,
                        width: 600,
                        boxSizing: 'border-box',
                        padding: '16px 24px',
                    }}
                    elevation={3}
                    square
                >
                    <Box mb={2}>
                        <Typography
                            sx={{
                                color: colors.BLACK,
                                opacity: '0.87',
                                overflowWrap: 'break-word',
                            }}
                            variant="h6"
                        >
                            {title}
                        </Typography>
                    </Box>
                    <Box mb={2}>
                        {
                            subTitle &&
                            <Typography sx={{ color: colors.BLACK_60_OPAQUE }} >
                                {subTitle}
                            </Typography>
                        }
                    </Box>
                    <Box mt={2} display="flex" justifyContent="flex-end">
                        <Button
                            data-testid={`${id}-cancel-btn`}
                            size="medium"
                            sx={{
                                ...btnStyle,
                                color: colors.BLACK,
                                opacity: '0.6',
                            }}
                            onClick={onClose}
                        >
                            Cancel
                        </Button>
                        <Button
                            data-testid={`${id}-confirm-btn`}
                            size="medium"
                            variant="contained"
                            color="primary"
                            sx={{
                                ...btnStyle,
                                ...(!isAddType ? {
                                    backgroundColor: colors.ERROR_BTN,
                                    color: colors.WHITE,
                                    opacity: 1,
                                    '&:hover': {
                                        backgroundColor: colors.ERROR.HOVER,
                                    },
                                } : {}),
                            }}
                            onClick={handleConfirm}
                        >
                            {buttonLabel}
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Modal>
    );
};

CruxDialogBox.propTypes = {
    confirmBtnLabel: PropTypes.string,
    subTitle: PropTypes.string,
    open: PropTypes.bool.isRequired,
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    type: PropTypes.string,
    onClose: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
};
export default CruxDialogBox;
