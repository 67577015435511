import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, styled, Typography } from '@mui/material';
import colors from '../../constants/colors';
import DownloadCustomPrintButton from './DownloadCustomPrintButton';

const StyledHeader = styled(Box)({
    backgroundColor: colors.WHITE,
    minHeight: 52,
    display: 'flex',
    alignItems: 'center',
    width: '100%',
});

const StyledTypography = styled(Typography)({
    color: colors.BLACK_87_OPAQUE,
});

const PropertyPrintHeader = ({ dispatch, address, match }) => (
    <StyledHeader>
        <Grid container spacing={3} alignItems="center">
            <Grid item xs={9}>
                <StyledTypography variant="h6">{address}</StyledTypography>
            </Grid>
            <Grid item xs={3}>
                <Box display="flex" justifyContent="flex-end">
                    <DownloadCustomPrintButton
                        dispatch={dispatch}
                        match={match}
                        address={address}
                    />
                </Box>
            </Grid>
        </Grid>
    </StyledHeader>
);

PropertyPrintHeader.propTypes = {
    address: PropTypes.string.isRequired,
    dispatch: PropTypes.func.isRequired,
    match: PropTypes.object,
};

export default PropertyPrintHeader;
