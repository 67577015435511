import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { routeCodes } from 'js/constants/routes';
import StyledMenuButton from 'js/components/common/styledMUI/StyledMenuButton';

const ConnectButton = (props) => {
    const handleConnect = () => {
        props.history.push({
            pathname: routeCodes.ACCOUNT.path,
            state: {
                activeTab: 'integration-tab',
            },
        });
    };

    return (
        <StyledMenuButton
            onClick={handleConnect}
            color="primary"
            variant="contained"
            sx={{
                ml: 0,
                ...props.sx,
            }}
        >
            Connect
        </StyledMenuButton>
    );
}

ConnectButton.displayName = 'ConnectButton';
ConnectButton.propTypes = {
    sx: PropTypes.object,
};

export default withRouter(ConnectButton);
