import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import ENVIRONMENTS from '../constants/environments';
import beforeSend from './beforeSend';

const bugsnagClient = Bugsnag.start({
    apiKey: process.env.REACT_APP_BUGSNAG_KEY,
    plugins: [new BugsnagPluginReact()],
    appVersion: process.env.REACT_APP_VERSION,
    autoCaptureSessions: false,
    autoNotify: true,
    releaseStage: process.env.REACT_APP_ENV ? ENVIRONMENTS[process.env.REACT_APP_ENV] : ENVIRONMENTS.PRODUCTION,
    notifyReleaseStages: [ENVIRONMENTS.DEVELOPMENT, ENVIRONMENTS.UAT, ENVIRONMENTS.PRODUCTION],
    onError: beforeSend,
});

export const setBugsnagUser = (userName) => {
    bugsnagClient.user = {
        name: userName,
    };
};

export default bugsnagClient;
