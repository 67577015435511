import { Map as IMap } from 'immutable';
import { PREVIEW_REPORT, PREVIEW_REPORT_FAIL, PREVIEW_REPORT_SUCCESS } from '../actions/previewReport';

export const initialState = IMap({
    pdfBytes: '',
    filename: '',
    isGeneratingPreview: false,
    isError: false,
});

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case PREVIEW_REPORT:
            return state.merge(IMap({
                isGeneratingPreview: true,
            }));
        case PREVIEW_REPORT_SUCCESS:
        case PREVIEW_REPORT_FAIL:
            return state.merge(IMap({
                isGeneratingPreview: false,
                ...action.payload,
            }));
        default:
            return state;
    }
}
