import errorMsg from 'js/constants/errorMsg';

export const GET_BRANDING = 'GET_BRANDING';
export const GET_BRANDING_SUCCESS = 'GET_BRANDING_SUCCESS';
export const GET_BRANDING_FAIL = 'GET_BRANDING_FAIL';
export const RESET_HANDLER = 'RESET_HANDLER';
export const ADD_BRANDING = 'ADD_BRANDING';
export const SAVE_BRANDING = 'SAVE_BRANDING';
export const SAVE_BRANDING_SUCCESS = 'SAVE_BRANDING_SUCCESS';
export const SAVE_BRANDING_FAIL = 'SAVE_BRANDING_FAIL';
export const EDIT_BRANDING = 'EDIT_BRANDING';
export const EDIT_BRANDING_SUCCESS = 'EDIT_BRANDING_SUCCESS';
export const EDIT_BRANDING_FAIL = 'EDIT_BRANDING_FAIL';
export const REMOVE_DRAFT = 'REMOVE_DRAFT';
export const DELETE_BRANDING = 'DELETE_BRANDING';
export const DELETE_BRANDING_SUCCESS = 'DELETE_BRANDING_SUCCESS';
export const DELETE_BRANDING_FAIL = 'DELETE_BRANDING_FAIL';
export const ACTIVATE_THEME = 'ACTIVATE_THEME';
export const ACTIVATE_THEME_SUCCESS = 'ACTIVATE_THEME_SUCCESS';
export const ACTIVATE_THEME_FAIL = 'ACTIVATE_THEME_FAIL';
export const VALIDATE_FAIL = 'VALIDATE_FAIL';
export const REMOVE_THEME_LOGO = 'REMOVE_THEME_LOGO';
export const REMOVE_THEME_LOGO_FAIL = 'REMOVE_THEME_LOGO_FAIL';
export const REMOVE_THEME_LOGO_SUCCESS = 'REMOVE_THEME_LOGO_SUCCESS';
export const VALIDATE_THEME_LOGO_FAIL = 'VALIDATE_THEME_LOGO_FAIL';

export const getBranding = payload => ({
    type: GET_BRANDING,
    payload,
});

export const resetHandler = () => ({
    type: RESET_HANDLER,
});

export const addBranding = () => ({
    type: ADD_BRANDING,
});

export const saveBranding = payload => ({
    type: SAVE_BRANDING,
    payload,
});

export const editBranding = payload => ({
    type: EDIT_BRANDING,
    payload,
});

export const removeDraft = () => ({
    type: REMOVE_DRAFT,
});

export const deleteBranding = payload => ({
    type: DELETE_BRANDING,
    payload,
});

export const deleteBrandingSuccess = payload => ({
    type: DELETE_BRANDING_SUCCESS,
    payload,
});

export const deleteBrandingFail = payload => ({
    type: DELETE_BRANDING_FAIL,
    payload,
});

export const activateTheme = payload => ({
    type: ACTIVATE_THEME,
    payload,
});

export const activateThemeSuccess = payload => ({
    type: ACTIVATE_THEME_SUCCESS,
    payload,
});

export const activateThemeFail = payload => ({
    type: ACTIVATE_THEME_FAIL,
    payload,
});

export const validateFail = () => ({
    type: VALIDATE_THEME_LOGO_FAIL,
    payload: {
        isError: true,
        message: errorMsg.FAILED_TO_FETCH,
    },
});

export const removeThemeLogo = payload => ({
    type: REMOVE_THEME_LOGO,
    payload,
});

export default {};
