import PropTypes from 'prop-types';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';

const MapType = (props) => {
    return (
        <MenuItem data-testid={props['data-testid']} sx={{ height: '25px', paddingLeft: '1px', fontSize: '12px' }} onClick={props.onClick}>
            <Checkbox
                color="default"
                checked={props.checked}
                inputProps={{ 'aria-label': 'controlled' }}
            />
            { props.children }
        </MenuItem>
    );
};

MapType.propTypes = {
    onClick: PropTypes.func,
    checked: PropTypes.bool,
};

export default MapType;
