import React from 'react';
import PropTypes from 'prop-types';
import FavouritesCard from '../FavouritesCard'
import { PlaceholderSkeleton } from '../UserFavoritesHome';
import Commons from '../../../../helpers/Commons';

const SavedListCardHome = ({ description, propertiesCount }) => {

    return (
        <FavouritesCard data-testid="saved-list-card-home">
            <div
                style={{
                    fontSize: 14,
                    fontWeight: 500,
                    letterSpacing: 0.1,
                    paddingBottom: '8px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                }}
                title={description}
            >
                {
                    description ? description : PlaceholderSkeleton
                }
            </div>
            <div
                style={{
                    fontSize: 12,
                    fontWeight: 400,
                    letterSpacing: 0.15
                }}
            >
                {
                    propertiesCount >= 0 ? // 0 is considered a valid value
                        `${Commons.numberWithCommas(propertiesCount)} ${Commons.getPropertyLabel(propertiesCount)}` :
                        PlaceholderSkeleton
                }
            </div>
        </FavouritesCard>
    );
};

SavedListCardHome.propTypes = {
    description: PropTypes.string,
    propertiesCount: PropTypes.number,
};

export default SavedListCardHome;