export class JoiningUser {
    constructor() {
        this._clAppAcctUsrGuid = null;
    }

    get clAppAcctUsrGuid() {
        return this._clAppAcctUsrGuid;
    }

    set clAppAcctUsrGuid(value) {
        this._clAppAcctUsrGuid = value;
    }
}

export const joiningUserInstance = new JoiningUser();
