import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import moment from 'moment/moment';
import {
    FormControl,
    InputAdornment,
    TextField,
    InputLabel,
    OutlinedInput,
    styled,
} from '@mui/material';
import EventIcon from '@mui/icons-material/Event';
import CalendarDatePicker from '../common/rc-calendar/CalendarDatePicker';
import DropdownSelect from '../common/DropdownSelect';
import { CruxSwitch } from '../forms/MaterialFormUi';
import Commons from '../../helpers/Commons';
import Colors from '../../constants/colors';
import PriceHelper from '../../helpers/PriceHelper';

const renderDateField = ({ value, handleDateChange, allowFutureDate })  => {
    return (
        <DropdownSelect
            id="saleDate"
            customClassName="report-modal-calendar"
            classNameForEvent="rc-calendar"
            value={value ? Commons.format(moment(value), 'DD/MM/YYYY') : null}
            customIcon={<EventIcon />}
            placeholder="DD/MM/YYYY"
        >
            <CalendarDatePicker
                value={value ? moment(value) : null}
                onSelect={handleDateChange}
                allowFutureDate={allowFutureDate}
            />
        </DropdownSelect>
    );
}

const renderErrorMsg = (isError, errorMessage) => (
    <span
        data-testid="report-field-error-message"
        className={`error-message ${isError ? 'show' : ''}`}
    >
        {errorMessage}
    </span>
);
export const ReportTextField = ({
    handleKeyPress, name, id, placeholder, maxLength, label, autoFocus = false,
}) => (
    <div className="form-group">
        <label htmlFor={id}>{label}</label>
        <Field
            name={name}
            component="input"
            type="text"
            id={id}
            placeholder={placeholder}
            className="form-control"
            maxLength={maxLength}
            onKeyPress={handleKeyPress}
            autoFocus={autoFocus}
        />
    </div>
);

ReportTextField.propTypes = {
    handleKeyPress: PropTypes.func,
    name: PropTypes.string,
    id: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    maxLength: PropTypes.string,
    autoFocus: PropTypes.bool,
};

const StyleFormControl = styled(FormControl)(({ theme }) => ({
    marginBottom: 32,
    '& .error-message': {
        position: 'absolute',
        top: '100%',
        color: theme.palette.error.main,
    },
}));

export const ReportMUIPriceField = ({
    handleKeyPress, name, id, placeholder, maxLength, label,
    setValue, autoFocus = false, valueError,
    value,
}) => {
    const formatValue = (value) => {
        if (value && PriceHelper.validValue(value)) {
            setValue(Commons
                .numberWithCommas(Commons
                    .expandAbbreviatedValue(value)));
        }
    }
    return (
        <StyleFormControl
            fullWidth
            variant="outlined"
            sx={{
                '& .MuiFormLabel-root': {
                    backgroundColor: 'white',
                    paddingRight: '10px',
                    paddingLeft: '2px',
                },
            }}
        >
            <TextField
                data-testid={id}
                id={id}
                label={label}
                variant="outlined"
                name={name}
                placeholder={placeholder}
                inputProps={{ maxLength, autocomplete: 'off' }}
                onKeyDown={handleKeyPress}
                autoFocus={autoFocus}
                onChange={e => setValue(e.target.value)}
                value={value}
                onBlur={e => formatValue(e.target.value)}
                error={!!valueError}
                InputProps={{
                    startAdornment: (
                        <InputAdornment
                            sx={{ '& .MuiTypography-root': {
                                    fontSize: 14
                            }}}
                            position="start"
                        >
                            $
                        </InputAdornment>
                    ),
                }}
                sx={theme => ({
                    '& .MuiInputBase-root': {
                        height: 38,
                        fontSize: 14,
                        paddingTop: '3px',
                        color: Colors.SECONDARY.MAIN,
                    },
                    '& .MuiOutlinedInput-root': {
                        '&:hover': {
                            '& fieldset': {
                                borderColor: theme.palette.primary.main,
                            },
                        },
                        '&.Mui-focused': {
                            '& fieldset': {
                                borderWidth: 1,
                            },
                        },
                        '&.Mui-error': {
                            '&:hover': {
                                '& fieldset': {
                                    borderColor: theme.palette.error.main,
                                },
                            },
                        },
                    },
                })}
            />
            { valueError && renderErrorMsg(!!valueError, valueError) }
        </StyleFormControl>
    )
};

ReportMUIPriceField.propTypes = {
    handleKeyPress: PropTypes.func,
    name: PropTypes.string,
    id: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    maxLength: PropTypes.string,
    autoFocus: PropTypes.bool,
    setAmount: PropTypes.func,
    leaseAmountError: PropTypes.string,
    leaseAmount: PropTypes.string,
};

export const ReportDateField = ({
    name,
    id,
    value,
    label,
    setDate,
    errorMessage,
    isDateError,
    allowFutureDate,
}) => {
    const handleDateChange = (date) => {
        setDate(date);
    };

    return (
        <StyleFormControl
            fullWidth
            variant="outlined"
        >
            <InputLabel
                shrink htmlFor={id}
                sx={{
                    background: '#fff',
                    padding: '0 10px',
                    marginLeft: '-6px',
                }}
                error={!!errorMessage}
            >
                {label}
            </InputLabel>
            <OutlinedInput
                id={id}
                data-testid={id}
                name={name}
                placeholder={label}
                value={value}
                error={!!errorMessage}
                inputComponent={() => renderDateField({
                    value,
                    handleDateChange,
                    allowFutureDate,
                })}
                sx={(theme) => ({
                    height: 40,
                    '& .dropdown-select': {
                        '& .dropdown-heading': {
                            border: 'none',
                            fontSize: 14,
                        },
                        '& .rc-calendar': {
                            '& .rc-calendar-body': {
                                height: 260,
                                '& .rc-calendar-selected-day': {
                                    '& .rc-calendar-date': {
                                        color: Colors.WHITE,
                                        backgroundColor: Colors.PRIMARY.MAIN,
                                        '&:hover': {
                                            backgroundColor: Colors.PRIMARY.MAIN,
                                        }

                                    },
                                },
                                '& .rc-calendar-date': {
                                    fontSize: '14px',
                                    width: '100%',
                                    height: '36px',
                                    lineHeight: '35px',
                                    border: `1px solid transparent`,
                                    '&:hover': {
                                        border: `1px solid ${Colors.PRIMARY.MAIN}`,
                                        backgroundColor: `rgba(51, 153, 255, 0.15)`,
                                    },
                                },
                                '& .rc-calendar-column-header-inner': {
                                    fontSize: 11,
                                },
                                '& .rc-calendar-today': {
                                    '& .rc-calendar-date': {
                                        border: `1px solid ${Colors.PRIMARY.MAIN}`,
                                        borderRadius: '50%',
                                    },
                                },
                            },
                        },
                    },
                    '&.MuiOutlinedInput-root': {
                        '&:hover fieldset': {
                            borderColor: isDateError
                                ? Colors.ERROR.BORDER
                                : theme.palette.primary.main,
                        },
                        '& .open': {
                            '&+ fieldset': {
                                borderColor: theme.palette.primary.main,
                            },
                        },
                    },
                })}
            />
            { isDateError && renderErrorMsg(isDateError, errorMessage) }
        </StyleFormControl>
    );
}

ReportDateField.propTypes = {
    name: PropTypes.string,
    id: PropTypes.string,
    label: PropTypes.string,
    customClass: PropTypes.string,
    errorMessage: PropTypes.string,
    setDate: PropTypes.func,
    isDateError: PropTypes.bool,
    value: PropTypes.string,
};

export const ReportSwitch = ({
    field, fieldValue, onChange, checked,
    stateToChange, disableSwitch, hideSwitch, id,
}) => (
    <div className="form-group flex-row">
        <div className="flex-label">
            <label htmlFor={id || field}>{field}</label>
            <p className="mb-0" title={fieldValue}>{fieldValue}</p>
        </div>
        {
            !hideSwitch &&
            <div className="flex-input switch-container">
                <CruxSwitch
                    id={id || `${field}Switch`}
                    checked={checked}
                    onChange={e => onChange(e, stateToChange)}
                    disabled={disableSwitch}
                />
            </div>
        }
    </div>
);

ReportSwitch.propTypes = {
    onChange: PropTypes.func,
    fieldValue: PropTypes.string,
    field: PropTypes.string,
    id: PropTypes.string,
    checked: PropTypes.bool,
    stateToChange: PropTypes.string,
    disableSwitch: PropTypes.bool,
    hideSwitch: PropTypes.bool,
};

export class ReportFormFields extends Component {

    static propTypes = {
        label: PropTypes.string,
        handleKeyPress: PropTypes.func,
        includeEmail: PropTypes.bool,
        includePhone: PropTypes.bool,
        handleIOSToggle: PropTypes.func,
        email: PropTypes.string,
        phoneNumber: PropTypes.string,
        removeDefaultFields: PropTypes.bool,
        style: PropTypes.object,
    };

    render() {
        const {
            label, handleKeyPress, includeEmail, includePhone,
            handleIOSToggle, email, phoneNumber, children,
            removeDefaultFields, style,
        } = this.props;

        return (
            <div style={style}>
                { removeDefaultFields ? null : (
                    <div>
                        <div className="form-group container-divider">
                            <p>{label}</p>
                        </div>
                        <hr />
                        <ReportTextField
                            label="Prepared for"
                            handleKeyPress={handleKeyPress}
                            name="preparedFor"
                            id="prepared-for"
                            placeholder="Enter recipient's name"
                            maxLength="112"
                        />
                        <ReportTextField
                            label="Prepared by"
                            handleKeyPress={handleKeyPress}
                            name="preparedBy"
                            id="prepared-by"
                            placeholder="Enter your name"
                            maxLength="70"
                        />
                        <ReportSwitch
                            field="email"
                            fieldValue={email}
                            stateToChange="includeEmail"
                            onChange={handleIOSToggle}
                            checked={includeEmail}
                        />
                        <ReportSwitch
                            field="phone"
                            fieldValue={phoneNumber}
                            stateToChange="includePhone"
                            onChange={handleIOSToggle}
                            checked={includePhone}
                        />
                    </div>
                )}
                {children}
            </div>
        );
    }
}

export default ReportFormFields;
