import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '../../../../constants/tooltipText';
import CruxCheckbox from '../../../common/CruxCheckbox';

const ExactSearchCheckbox = ({ checked, onChange }) => (<CruxCheckbox
    checked={checked}
    label="Exact match"
    tooltip={Tooltip.TEXT.BUILDING_SEARCH.EXACT_SEARCH}
    tooltipId="exact-search"
    id="exact-search-checkbox"
    data-testid="exact-search-checkbox"
    onChange={onChange}
/>);

ExactSearchCheckbox.propTypes = {
    checked: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
};

export default ExactSearchCheckbox;
