import React from 'react';
import PropTypes from 'prop-types';

const ValuationRange = ({
    img,
    confidenceLevel,
    lowEstimate,
    estimate,
    highEstimate,
}) => (
    <div data-testid="avm-range" id="avm-range">
        <div className="flex-row valuation-range-header">
            <div className="flex-grow text-left">
                <span className="author">Low</span>
            </div>
            <div className="flex-grow text-center">
                <div className="legend">
                    <span className="author">Estimate</span>
                </div>
            </div>
            <div className="flex-grow text-right">
                <span className="author">High</span>
            </div>
        </div>
        <div className="flex-row valuation-range-visual">
            <img src={encodeURI(img)} alt={confidenceLevel.label} />
        </div>
        <div className="flex-row valuation-range-footer">
            <div className="flex-grow text-left">
                <span className="author">{lowEstimate}</span>
            </div>
            <div className="flex-grow text-center">
                <div className="legend">
                    <span className="author">{estimate}</span>
                </div>
            </div>
            <div className="flex-grow text-right">
                <span className="author">{highEstimate}</span>
            </div>
        </div>
    </div>
);

ValuationRange.propTypes = {
    img: PropTypes.string,
    confidenceLevel: PropTypes.object,
    lowEstimate: PropTypes.string,
    estimate: PropTypes.string,
    highEstimate: PropTypes.string,
};
export default ValuationRange;