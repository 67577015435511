import Alert from '@mui/material/Alert'
import Snackbar from '@mui/material/Snackbar';
import PropTypes from 'prop-types';

const CruxSnackBar = ({
    open,
    message,
    autoHideDuration = 5000,
    anchorOrigin = {
        vertical: 'bottom',
        horizontal: 'left',
    },
    severity = 'success',
    onClose,
    sx = {},
}) => (
    <Snackbar
        open={open}
        autoHideDuration={autoHideDuration}
        onClose={onClose}
        anchorOrigin={anchorOrigin}
        data-testid="crux-snack-bar"
        sx={{
            left: 0,
            right: 0,
            transform: 'none',
            overflowWrap: 'anywhere',
            ...sx,
        }}
    >
        <Alert
            sx={{
                alignItems: 'center',
                fontSize: 13,
            }}
            onClose={onClose}
            severity={severity}
        >
            {message}
        </Alert>
    </Snackbar>
);

CruxSnackBar.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    message: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    severity: PropTypes.string,
    autoHideDuration: PropTypes.number,
    anchorOrigin: PropTypes.shape({
        vertical: PropTypes.string,
        horizontal: PropTypes.string,
    }),
};

export default CruxSnackBar;
