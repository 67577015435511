export const LOGOUT = 'LOGOUT';
export const LOGOUT_FAIL = 'LOGOUT_FAIL';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const GET_USER_DETAILS = 'GET_USER_DETAILS';
export const GET_USER_DETAILS_FAIL = 'GET_USER_DETAILS_FAIL';
export const GET_USER_DETAILS_SUCCESS = 'GET_USER_DETAILS_SUCCESS';

export function logout(pathname) {
    return {
        type: LOGOUT,
        payload: pathname,
    };
}

export function getUserDetail(selectedClAppAccountUserGuid) {
    return {
        type: GET_USER_DETAILS,
        payload: selectedClAppAccountUserGuid,
    };
}
