import { Map as IMap } from 'immutable';
import * as RapidAction from '../actions/rapid';
import * as CLAction from '../actions/clapi';

export const initialState = IMap({
    targetProperty: {
        isFetching: false,
        value: null,
    },
    address: null,
});

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case RapidAction.CLEAR_TARGET_PROPERTY: {
            return state.merge(IMap({
                targetProperty: {
                    isFetching: false,
                    value: null,
                },
            }));
        }
        case RapidAction.GET_PROPERTY_BY_ADDRESS:
        case RapidAction.GET_PROPERTY_BY_ID:
        case RapidAction.GET_PROPERTY_BY_ID_FORKED:
            return state.merge(IMap({
                targetProperty: {
                    isFetching: true,
                    value: null,
                },
            }));
        case RapidAction.GET_PROPERTY_BY_ADDRESS_SUCCESS:
        case RapidAction.GET_PROPERTY_BY_ID_SUCCESS:
            return state.merge(IMap({
                targetProperty: {
                    isFetching: false,
                    value: action.payload,
                },
            }));
        case RapidAction.GET_PROPERTY_BY_ADDRESS_FAIL:
        case RapidAction.GET_PROPERTY_BY_ID_FAIL:
            return state.merge(IMap({
                targetProperty: {
                    isFetching: false,
                    value: {
                        error: true,
                    },
                },
            }));
        case CLAction.GET_ADDRESS_COLLECTIVE:
        case CLAction.CLEAR_ADDRESS_COLLECTIVE:
            return state.merge(IMap({
                address: null,
            }));
        case CLAction.GET_ADDRESS_COLLECTIVE_SUCCESS:
            return state.merge(IMap({
                address: action.payload?.response,
            }));
        default:
            return state;
    }
}
