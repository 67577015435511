import { styled } from '@mui/material';
import PropTypes from 'prop-types';

const Card = styled('div')({
    height: 78,
    color: 'white',
    padding: 15,
    borderRadius: 8,
    backgroundColor: '#212121',
    margin: 2,
    '&:hover': {
        boxShadow: '0 0 5px 1px #3399ff',
        transition: 'all ease 0.3s',
    }
});
const FavouritesCard = ({ overrideStyle, children }) => (
    <Card
        data-testid="favourites-card"
        style={overrideStyle}
    >
        {children}
    </Card>
);

FavouritesCard.propTypes = {
    overrideStyle: PropTypes.object,
};

export default FavouritesCard;
