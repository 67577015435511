import React from 'react';
import PropTypes from 'prop-types';
import '../../../css/crux/components/reports/copy.scss';

const Copy = (props) => {
    const {
        forCopy,
        disabled,
        hidden,
        children,
        onCopyEventLogger,
        onCopySuccess,
        linkProps,
    } = props;

    const handleCopy = (e) => {
        e.clipboardData.setData('text/plain', forCopy);
        e.preventDefault();
        if (onCopySuccess) {
            onCopySuccess();
        }
    };

    const handleClick = (e) => {
        e.preventDefault();
        if (!disabled) {
            if (onCopyEventLogger) {
                onCopyEventLogger();
            }
            document.addEventListener('copy', handleCopy, {
                once: true,
            });

            let elem = document.createElement('span');
            try {
                elem.classList.add('hide');
                const range = document.createRange();
                const selection = document.getSelection();
                elem.textContent = e.target.dataset.copyValue || e.currentTarget.dataset.copyValue;
                document.body.appendChild(elem);
                range.selectNode(elem);
                selection.addRange(range);

                const successful = document.execCommand('copy');
                if (!successful) {
                    throw new Error('copy command was unsuccessful');
                }
            } catch (err) {
                elem = document.createElement('textArea');
                elem.classList.add('hide');
                elem.value = forCopy;
                document.body.appendChild(elem);
                elem.select();
                document.execCommand('copy');
            } finally {
                if (elem) {
                    document.body.removeChild(elem);
                }
            }
        }
    };
    return (
        <a
            href="#!"
            className={`copy ${disabled ? 'disabled' : ''} ${hidden ? 'hide' : ''}`}
            data-testid="copy"
            aria-disabled={disabled}
            disabled={disabled}
            onClick={disabled || hidden ? undefined : handleClick}
            {...linkProps}
        >
            { children }
        </a>
    );
};

Copy.propTypes = {
    forCopy: PropTypes.string,
    linkProps: PropTypes.object,
    onCopyEventLogger: PropTypes.func,
    onCopySuccess: PropTypes.func,
    disabled: PropTypes.bool,
    hidden: PropTypes.bool,
};

export default Copy;
