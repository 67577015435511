import Immutable, { Map as IMap } from 'immutable';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import Serialize from 'remotedev-serialize';
import { createTransform } from 'redux-persist';

const { parse } = Serialize.immutable(Immutable);

const buildTransform = reducer => (createTransform(
    // return value will be stored in local storage; crux store -> local storage
    (inboundState) => {
        switch (reducer) {
            case 'claud': {
                const session = inboundState.get('session');
                return {
                    session: {
                        clusrId: session && session.clusrId,
                        apiKey: session && session.apiKey,
                    },
                };
            }
            case 'config': {
                return {
                    launchDarkly: {
                        ...inboundState.get('launchDarkly'),
                    },
                    segment: {
                        ...inboundState.get('segment'),
                    },
                    appenv: {
                        ...inboundState.get('appenv'),
                    },
                    hash: inboundState.get('hash'),
                    applicationDomain: {
                        ...inboundState.get('applicationDomain'),
                    },
                    clapiDomain: {
                        ...inboundState.get('clapiDomain'),
                    },
                };
            }
            case 'clapi': {
                return {
                    auth: inboundState.get('auth'),
                };
            }
            case 'linkedAccounts': {
                return {
                    auth: inboundState.get('listedAccount'),
                    selectedAccount: inboundState.get('selectedAccount'),
                    selectedClAppAccountUserGuid: inboundState.get('selectedClAppAccountUserGuid'),
                    tncPermanentlyAccepted: inboundState.get('tncPermanentlyAccepted'),
                    tncCurrentlyAccepted: inboundState.get('tncCurrentlyAccepted'),
                    postJoinInProgress: inboundState.get('postJoinInProgress'),
                };
            }
            default:
                return inboundState;
        }
    },
    // return value will be put into crux store; local storage -> crux store
    (outboundState) => {
        try {
            // this is for the old local storage structure; migration
            const parsedVal = parse(outboundState);
            if (parsedVal instanceof IMap) {
                return parsedVal;
            }
        } catch (e) {
        }
        return IMap({
            ...outboundState,
        });
    },
    // define which reducers this transform gets called for.
    {
        whitelist: [reducer],
    },
));

export const persistConfig = {
    key: 'crux',
    storage,
    stateReconciler: autoMergeLevel2,
    transforms: [
        buildTransform('claud'),
        buildTransform('config'),
        buildTransform('clapi'),
        buildTransform('linkedAccounts'),
    ],
    whitelist: ['claud', 'config', 'clapi', 'linkedAccounts'], // will only accept claud and config on the persist
};
