import { styled, Button } from '@mui/material';
import Colors from '../../../constants/colors';

export default styled(Button)({
    marginBottom: 20,
    backgroundColor: Colors.PRIMARY.MAIN,
    borderRadius: 4,
    padding: 5,
    minHeight: 40,
    height: 40,
    cursor: 'pointer',
    minWidth: 40,
    width: 40,
    textAlign: 'center',
    color: Colors.WHITE,
    fontSize: 13,
    '& i': {
        fontSize: 18,
        padding: 5,
    },
    '&:hover': {
        backgroundColor: Colors.PRIMARY.DARK,
    },
    '&.Mui-disabled': {
        backgroundColor: Colors.DISABLED,
    }
});
