export const CONFIG_INIT = 'CONFIG_INIT';
export const CONFIG_INIT_FAIL = 'CONFIG_INIT_FAIL';
export const CONFIG_INIT_SUCCESS = 'CONFIG_INIT_SUCCESS';
export const BLANK_ACTION = 'BLANK_ACTION';
export const PUBLIC_CONFIGURATION_INIT = 'PUBLIC_CONFIGURATION_INIT';
export const PUBLIC_CONFIGURATION_INIT_FAIL = 'PUBLIC_CONFIGURATION_INIT_FAIL';
export const PUBLIC_CONFIGURATION_INIT_SUCCESS = 'PUBLIC_CONFIGURATION_INIT_SUCCESS';

export function initialize(hash) {
    return {
        type: CONFIG_INIT,
        payload: {
            hash,
        },
    };
}

export function initializePublicConfiguration(hash) {
    return {
        type: PUBLIC_CONFIGURATION_INIT,
        payload: {
            hash,
        },
    };
}
