/* eslint-disable no-undef */
import { useEffect } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import UserPreferences from 'js/api/userPreferences';
import getTerritory from 'js/selectors/getTerritory';
import getTerritoryPolygon from 'js/selectors/getTerritoryPolygon';
import Commons from 'js/helpers/Commons';
import { selectSelectedAccount } from 'js/selectors/linkedAccount';
import { getRoute, routeCodes } from 'js/constants/routes';

const toGoogleLatLng = ({ lat, lon }) => ({ lat, lng: lon });
export default (props) => {
    const { selectedClAppAccountUserGuid } = useSelector(selectSelectedAccount, shallowEqual);
    const _territoryId = useSelector(state => state?.territories.get('territories')?.selected);
    const territoryId = props?.territoryId || _territoryId;
    const savedTerritory = useSelector(getTerritory(territoryId));
    const savedTerritoryPolygon = useSelector(getTerritoryPolygon(territoryId));
    const match = useRouteMatch();
    const isPolygonEdited = () => {
        const currentPaths = props.polygonRef?.getPath()
            .getArray()
            .map((latLng) => {
                return { lat: latLng.lat(), lng: latLng.lng() };
            });
        return currentPaths && !Commons.isEqual(savedTerritoryPolygon.map(toGoogleLatLng), currentPaths);
    };
    const isNameEdited = () => savedTerritory.territoryName?.trim() !== props.name?.trim();
    const route = getRoute(match.path);

    const update = (requestBody) => {
        return UserPreferences.updateSavedTerritory(selectedClAppAccountUserGuid,
            savedTerritory?.id,
            requestBody);
    };

    useEffect(() => {
        if (savedTerritoryPolygon?.length
            && !props.polygonRef
            && props.actions?.map
            && !props.editPolygonDeleted) {
            const savedTerritoryBounds = new google.maps.LatLngBounds();
            const paths = savedTerritoryPolygon?.map(({ lat, lon }) => {
                const googleMapLatLng = { lat, lng: lon };
                savedTerritoryBounds.extend(googleMapLatLng);
                return googleMapLatLng;
            });
            props.actions.setPolygonPath(paths);
            props.actions.mapCommands.record(paths);
            props.actions.map.fitBounds(savedTerritoryBounds);
        }
    }, [
        savedTerritoryPolygon?.length,props?.actions?.map,
        props.polygonRef,
        props.editPolygonDeleted,
    ]);

    return {
        update,
        isPolygonEdited,
        isNameEdited,
        forceUpdate: route.id === routeCodes.SAVED_TERRITORY.id,
        territory: {
            ...savedTerritory,
            polygon: savedTerritoryPolygon,
        },
        territoryId,
    }
}
