import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import useDisclaimer from '../../../hooks/useDisclaimer';
import GetDisclaimer from '../../../selectors/getDisclaimer';
import { GET_COPYRIGHTS_FAIL, getCopyRights } from '../../../actions/clapi';
import { FOOTER } from '../../../helpers/Localization';
import Commons from '../../../helpers/Commons';

export const CopyRight = (props) => {
    const { copyRights, dispatch } = props;
    const action = useMemo(() =>
        !Commons.get(copyRights, 'context.disclaimer') &&
        (getCopyRights('copyrights', FOOTER.copyRights)
    ), []);
    const disclaimer = useDisclaimer(copyRights,
        action,
        dispatch
    );
    const disclaimerText = Commons.get(disclaimer, 'context.disclaimer');
    const cleanDisclaimer = useMemo(() => (
        Commons.purifyDomElement(disclaimerText)
    ), [disclaimer]);
    return (
        !!disclaimerText &&
        <p className="copyright-text" dangerouslySetInnerHTML={{ __html: `${cleanDisclaimer}` }} />
    )
};
CopyRight.propTypes = {
    copyRights: PropTypes.object,
}

const ConnectedCopyRight = connect((state) => ({
    copyRights: GetDisclaimer(state,
        GET_COPYRIGHTS_FAIL,
        'copyRights',
        ''
    ),
}))(CopyRight);
ConnectedCopyRight.displayName = 'CopyRight';

export default ConnectedCopyRight;

