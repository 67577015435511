import { takeLatest, put, call } from 'redux-saga/effects';
import {
    GET_NEW_USER_ACTIVITY_FEEDS,
    getNewUserActivityFeedsFailure,
    getNewUserActivityFeedsSuccess,
} from '../actions/favourites';
import favouritesApi from '../api/favouritesApi';
import Commons from '../helpers/Commons';

function getNewUserActivityFeedsFn() {
    return function* () {
        try {
            const response = yield call(favouritesApi.getUnreadSavedSearch);
            const userActivityFeeds = Commons.get(response, 'response.result');
            yield put(getNewUserActivityFeedsSuccess(userActivityFeeds));
        } catch (error) {
            yield put(getNewUserActivityFeedsFailure(error));
        }
    };
}

export const getNewUserActivityFeeds = getNewUserActivityFeedsFn();
export function* getNewUserActivityFeedsWatcher() {
    yield takeLatest(GET_NEW_USER_ACTIVITY_FEEDS, getNewUserActivityFeeds);
}

export default [
    getNewUserActivityFeedsWatcher(),
];
