export const PROPERTY_COMMONS = 'PROPERTY_COMMONS';
export const PROPERTY_COMMONS_FAIL = 'PROPERTY_COMMONS_FAIL';
export const PROPERTY_COMMONS_SUCCESS = 'PROPERTY_COMMONS_SUCCESS';
export const PROPERTY_COMMONS_RETRY = 'PROPERTY_COMMONS_RETRY';
export const RESET_PROPERTY_COMMONS_RETRY_STATE = 'RESET_PROPERTY_COMMONS_RETRY_STATE';
export const CLEAR_PROPERTY_COMMONS = 'CLEAR_PROPERTY_COMMONS';
export const CRUX_LOADER = 'CRUX_LOADER';
export const CRUX_UNLOADER = 'CRUX_UNLOADER';
export const PROPERTY_ACTIVE_IMAGE = 'PROPERTY_ACTIVE_IMAGE';
export const CRUX_POST_JOIN_SUCCESS = 'CRUX_POST_JOIN_SUCCESS';

export function getPropertyCommons(propertyId) {
    return {
        type: PROPERTY_COMMONS,
        payload: {
            propertyId,
        },
    };
}

export function getPropertyCommonsSuccess(commons) {
    return {
        type: PROPERTY_COMMONS_SUCCESS,
        payload: commons,
    };
}

export function retryPropertyCommons() {
    return {
        type: PROPERTY_COMMONS_RETRY,
    };
}

export function resetPropertyCommonsRetryState() {
    return {
        type: RESET_PROPERTY_COMMONS_RETRY_STATE,
    };
}

export function clearPropertyCommons() {
    return {
        type: CLEAR_PROPERTY_COMMONS,
    };
}

export function setPropertyActiveImage(propertyId, index) {
    return {
        type: PROPERTY_ACTIVE_IMAGE,
        payload: {
            propertyId,
            index,
        },
    }
}

export function cruxLoader() {
    return {
        type: CRUX_LOADER,
    };
}

export function cruxUnloader() {
    return {
        type: CRUX_UNLOADER,
    };
}

export function cruxPostJoinSuccess() {
    return {
        type: CRUX_POST_JOIN_SUCCESS,
    };
}
