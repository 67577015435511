import { Component } from 'react';
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import MobileBadges from './MobileBadges';
import CruxFooterDisclaimers from './disclaimers/CruxFooterDisclaimers';
import MarketInsightFooterDisclaimers from './disclaimers/MarketInsightFooterDisclaimers';
import { FOOTER } from '../../helpers/Localization';
import ErrorBoundary from '../errorHandler/ErrorBoundary';
import FeedbackLink from './FeedbackLink';
import '../../../css/crux/components/global/footer.scss';
import { LOGOS } from '../../constants/assets';

const DefaultDisclaimers = () => (
    <div className="disclaimers" id="default-disclaimer">
        <p className="copyright-text">© Copyright {new Date().getFullYear()} RP Data Pty Ltd trading as CoreLogic Asia Pacific (CoreLogic).
            All rights reserved.</p>
    </div>
);

export class Footer extends Component {
    static propTypes = {
        marketInsightsFooter: PropTypes.bool,
        route: PropTypes.object,
        location: PropTypes.object,
    };

    render() {
        const {
            marketInsightsFooter,
            route,
            location,
            selectedClAppAccountUserGuid,
        } = this.props;

        return (
            <div data-testid="footer-component" id="footer">
                <nav className="navbar">
                    <div className="container">
                        <div className="footer-heading">
                            <div className="navbar-header">
                                <div className="navbar-brand">
                                    <img alt="Corelogic RP Data Professional" src={LOGOS.ORANGE_CL_LOGO} data-testid="footer-component-logo" className="footer-logo" />
                                </div>
                            </div>
                            <ul data-testid="footer-component-nav" className="nav navbar-nav navbar-right">
                                <li>
                                    <a href={FOOTER.links.privacyPolicy} target="_blank" rel="noreferrer noopener"
                                       role="button" aria-haspopup="true">
                                        Privacy Policy
                                    </a>
                                </li>
                                <li>
                                    <a href={FOOTER.links.termsOfUse} target="_blank" rel="noreferrer noopener"
                                       role="button" aria-haspopup="true">
                                        Terms of Use
                                    </a>
                                </li>
                                <li>
                                    <a href={FOOTER.links.contactUs} target="_blank" rel="noreferrer noopener"
                                       role="button" aria-haspopup="true">
                                        Contact Us
                                    </a>
                                </li>
                                {selectedClAppAccountUserGuid && (
                                    <li>
                                        <FeedbackLink>Your Feedback</FeedbackLink>
                                    </li>
                                )}
                                <li>
                                    <a href={FOOTER.links.disclaimers} target="_blank" rel="noreferrer noopener"
                                       role="button" aria-haspopup="true">
                                        Disclaimers & Notices
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
                <div className="footer-content">
                    <div className="container">
                        <div className="flex-row">
                            <div className="flex-grow">
                                <ErrorBoundary
                                    DefaultComponent={DefaultDisclaimers}
                                >
                                    {
                                        marketInsightsFooter ?
                                            <MarketInsightFooterDisclaimers /> :
                                            <CruxFooterDisclaimers
                                                route={route}
                                                location={location}
                                            />
                                    }
                                </ErrorBoundary>
                            </div>
                            <MobileBadges route={route} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const selectAuthenticationDetail = ({linkedAccounts}) => ({
    selectedClAppAccountUserGuid: linkedAccounts.get('selectedClAppAccountUserGuid'),
});
export default connect(selectAuthenticationDetail)(Footer);
