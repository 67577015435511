export default (rawParams) => {
    const _params = new URLSearchParams(rawParams);
    const params = {
        addressParcelSuburbState: _params.get('addressParcelSuburbState') === 'null' ? '' :
            _params.get('addressParcelSuburbState'),
        legalDescription: _params.get('legalDescription') === 'null' ?
            '' :
            _params.get('legalDescription'),
        titleReference: _params.get('titleReference') === 'null' ?
            '' :
            _params.get('titleReference'),
    };
    Object.keys(params).forEach((key) => {
        if (!params[key]) delete params[key];
    });
    return params;
};
