import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import LiveAvmModal from './LiveAvmModal';
import CustomValEstimateLink from './CustomValEstimateLink';
import PanelError from '../../common/PanelError';
import { PANEL, VALUATION } from '../../../constants/propertyDetail';
import { isAU } from '../../../constants/crux';

const ValuationNoRange = (props) => {
    const {
        displayError,
        valuation,
        reportRoles,
        isLiveAvmModalOpen,
        openLiveAvmModal,
        closeLiveAvmModal,
    } = props;
    let message = VALUATION.VALUATION_ESTIMATE_UNAVAILABLE;
    if (displayError) {
        message = null; // remove message to display the default error message
    } else if (valuation && valuation.confidenceTooLow) {
        message += `<br />${VALUATION.CONFIDENCE_TOO_LOW}`;
    }
    return (
        <Fragment>
            <PanelError
                message={message}
                panel={PANEL.VALUATION_ESTIMATE}
            />
            {
                isAU &&
                <CustomValEstimateLink
                    reportRoles={reportRoles}
                    openLiveAvmModal={openLiveAvmModal}
                />
            }
            {
                isLiveAvmModalOpen
                && (
                    <LiveAvmModal
                        closeLiveAvmModal={closeLiveAvmModal}
                        isLiveAvmModalOpen={isLiveAvmModalOpen}
                        valuation={valuation}
                    />
                )
            }
        </Fragment>
    );
};

ValuationNoRange.propTypes = {
    valuation: PropTypes.object,
    reportRoles: PropTypes.array.isRequired,
    openLiveAvmModal: PropTypes.func,
    closeLiveAvmModal: PropTypes.func,
    isLiveAvmModalOpen: PropTypes.bool,
};

export default ValuationNoRange;
