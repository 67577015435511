import moment from 'moment';
import SuburbStatConst from '../../constants/suburbStatReport';
import { PROPERTY_TYPE_IDS } from '../Localization';

const defaultBuilder = (locationId, locationTypeId, propertyTypeIds, metricTypeId) => {
    return propertyTypeIds.map(id => ({
        propertyTypeId: id,
        metricTypeId,
        locationTypeId,
        locationId,
    }));
};

const twelveMonthsBuilder = (locationId, locationTypeId, propertyTypeIds, metricTypeId) => {
    const fromDate = moment().subtract(12, 'months').format('YYYY-MM-DD');
    const toDate = moment().format('YYYY-MM-DD');
    return propertyTypeIds.map(id => ({
        propertyTypeId: id,
        metricTypeId,
        locationId,
        fromDate,
        toDate,
        locationTypeId,
        interval: '1',
    }));
};

const salePriceCvRatioBuilder = (locationId, councilAreaIds) => {
    let params = [];
    params.push(...defaultBuilder(
        locationId, '8', PROPERTY_TYPE_IDS, SuburbStatConst.METRIC_TYPE_ID.totalValueOfSales
    ));
    params.push(...twelveMonthsBuilder(
        locationId,
        '8',
        PROPERTY_TYPE_IDS,
        SuburbStatConst.METRIC_TYPE_ID.salesPriceCvRatio,
    ));
    councilAreaIds?.forEach(councilAreaId => {
        params.push(...twelveMonthsBuilder(
            councilAreaId,
            '9',
            PROPERTY_TYPE_IDS,
            SuburbStatConst.METRIC_TYPE_ID.salesPriceCvRatio,
        ));
    });

    return params;
};

export default {
    salePriceCvRatioBuilder,
    twelveMonthsBuilder,
    defaultBuilder,
};
