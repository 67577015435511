import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BlockUi from '../../blockUi/BlockUi';

import Constant from '../../../constants/constant';
import LiveAvmModal from './LiveAvmModal';
import ValuationHelper from '../../../helpers/ValuationHelper';
import ReportHelper from '../../../helpers/report/ReportHelper';
import ValuationNoRange from './ValuationNoRange';
import CustomValEstimateLink from './CustomValEstimateLink';
import { isAU } from '../../../constants/crux';
import ValuationRange from './ValuationRange';

/**
 * Use <Valuation /> component instead.
 * See source/js/components/common/Valuation.jsx
 */
export default class ValuationEstimate extends Component {
    constructor(props) {
        super(props);

        this.state = {
            valuation: {
                avm: null,
                avmHasDetails: true,
                confidenceLevel: null,
                tooltipData: null,
                img: null,
            },
            isLiveAvmModalOpen: false,
        };
    }

    static getDerivedStateFromProps(nextProps) {
        const { tooltipDisclaimer } = nextProps;

        const { avmDetails } = nextProps;

        const nextState = {
            valuation: {
                avm: null,
                avmHasDetails: true,
                confidenceLevel: null,
                tooltipData: null,
                img: null,
            },
        };

        if (!avmDetails) {
            nextState.valuation.avmHasDetails = false;
        } else {
            nextState.valuation.avm = avmDetails;

            if (!nextState.valuation.avm.errors) {
                const valuationRangeProps = ValuationHelper.buildValuationRangeProps(
                    nextState.valuation.avm,
                    tooltipDisclaimer,
                );
                nextState.valuation = Object.assign(nextState.valuation, valuationRangeProps);
            } else {
                nextState.valuation.avmHasDetails = false;
            }
        }

        return nextState;
    }

    openLiveAvmModal = () => this.setState({ isLiveAvmModalOpen: true });

    closeLiveAvmModal = () => this.setState({ isLiveAvmModalOpen: false });

    render() {
        const {
            isFetchingAvm, componentError, clapiUsrDetail, reportSection,
        } = this.props;
        const { valuation, isLiveAvmModalOpen } = this.state;
        const reportRoles = ReportHelper.getReportRoles(clapiUsrDetail);

        if (isFetchingAvm === undefined || isFetchingAvm) {
            return (
                <div className="property-panel-body">
                    <BlockUi blocking containerProps={{ className: 'white-blocker' }} />
                </div>
            );
        }

        // - When no avm details available.
        // always has the ability to create custom valuation estimate.
        if (!valuation.avmHasDetails
            || (valuation.avm.source !== Constant.Intellival.Source)) {
            return (
                <ValuationNoRange
                    displayError={componentError}
                    valuation={valuation}
                    reportRoles={reportRoles}
                    openLiveAvmModal={this.openLiveAvmModal}
                    closeLiveAvmModal={this.closeLiveAvmModal}
                    isLiveAvmModalOpen={isLiveAvmModalOpen}
                />
            );
        }

        return (
            <React.Fragment>
                <div className="property-panel-body">
                    { reportSection({
                        ...this.state, ...this.props, reportRoles,
                    }) }
                    <hr />
                    <ValuationRange {...valuation} />
                    {
                        isAU && <CustomValEstimateLink
                            reportRoles={reportRoles}
                            openLiveAvmModal={this.openLiveAvmModal}
                        />
                    }
                </div>
                {
                    isLiveAvmModalOpen
                    && <LiveAvmModal
                        closeLiveAvmModal={this.closeLiveAvmModal}
                        isLiveAvmModalOpen={isLiveAvmModalOpen}
                        valuation={valuation}
                    />
                }
            </React.Fragment>
        );
    }
}
ValuationEstimate.propTypes = {
    componentError: PropTypes.object,
    avmDetails: PropTypes.object.isRequired,
    route: PropTypes.object,
    clapiUsrDetail: PropTypes.object,
    tooltipDisclaimer: PropTypes.string,
    reportSection: PropTypes.any,
};
