export const GET_USERS = 'GET_USERS';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_FAIL = 'GET_USERS_FAIL';
export const RESET_HANDLER = 'RESET_HANDLER';
export const RESET_MANAGE_USERS = 'RESET_MANAGE_USERS';

export const SET_USER_POI_ROLE = 'SET_USER_PO_ROLE';
export const SET_USER_POI_ROLE_SUCCESS = 'SET_USER_POI_ROLE_SUCCESS';
export const SET_USER_POI_ROLE_FAIL = 'SET_USER_POI_ROLE_FAIL';
export const SET_USER_ADMIN_ROLE = 'SET_USER_ADMIN_ROLE';
export const SET_USER_ADMIN_ROLE_SUCCESS = 'SET_USER_ADMIN_ROLE_SUCCESS';
export const SET_USER_ADMIN_ROLE_FAIL = 'SET_USER_ADMIN_ROLE_FAIL';

export const SET_USER_UPDATE_STATUS = 'SET_USER_UPDATE_STATUS';
export const SEARCH_USERS = 'SEARCH_USERS';
export const SEARCH_USERS_FORCE_END = 'SEARCH_USERS_FORCE_END';
export const SEARCH_USERS_SUCCESS = 'SEARCH_USERS_SUCCESS';
export const SEARCH_USERS_FAIL = 'SEARCH_USERS_FAIL';

export const REMOVE_USER = 'REMOVE_USER';
export const REMOVE_USER_SUCCESS = 'REMOVE_USER_SUCCESS';

export const INVITE_USERS = 'INVITE_USERS';
export const INVITE_USERS_SUCCESS = 'INVITE_USERS_SUCCESS';
export const INVITE_USERS_FAIL = 'INVITE_USERS_FAIL';

export const SET_USER_TITLES = 'SET_USER_TITLES';
export const SET_USER_TITLES_SUCCESS = 'SET_USER_TITLES_SUCCESS';
export const SET_USER_TITLES_FAIL = 'SET_USER_TITLES_FAIL';
export const SET_USER_TITLES_STATUS = 'SET_USER_TITLES_STATUS';

export const SET_USER_POI_STATUS = 'SET_USER_POI_STATUS';

export const getUsers = payload => ({
    type: GET_USERS,
    payload,
});

export const setUserPOIRole = (payload, mixpanel) => ({
    type: SET_USER_POI_ROLE,
    payload,
    mixpanel,
});

export const setUserPOIRoleSuccess = payload => ({
    type: SET_USER_POI_ROLE_SUCCESS,
    payload,
});

export const setUserPOIRoleFailed = payload => ({
    type: SET_USER_POI_ROLE_FAIL,
    payload,
});

export const setUserAdminRole = (payload, mixpanel) => ({
    type: SET_USER_ADMIN_ROLE,
    payload,
    mixpanel,
});

export const setUserAdminRoleSuccess = payload => ({
    type: SET_USER_ADMIN_ROLE_SUCCESS,
    payload,
});

export const setUserAdminRoleFailed = payload => ({
    type: SET_USER_ADMIN_ROLE_FAIL,
    payload,
});

export const setUserUpdateStatus = payload => ({
    type: SET_USER_UPDATE_STATUS,
    payload,
});

export const resetHandler = () => ({
    type: RESET_HANDLER,
});

export const resetManageUsers = () => ({
    type: RESET_MANAGE_USERS,
});

export const searchUsers = payload => ({
    type: SEARCH_USERS,
    payload,
});

export const forceEndSearch = () => ({
    type: SEARCH_USERS_FORCE_END,
});

export const removeUser = () => ({
    type: REMOVE_USER,
});

export const removeUserSuccess = () => ({
    type: REMOVE_USER_SUCCESS,
});

export const inviteUsersSuccess = payload => ({
    type: INVITE_USERS_SUCCESS,
    payload,
});

export const inviteUsersFailed = payload => ({
    type: INVITE_USERS_FAIL,
    payload,
});

export const inviteUsers = payload => ({
    type: INVITE_USERS,
    payload,
});

export const setUserTitles = (payload, mixpanel) => ({
    type: SET_USER_TITLES,
    payload,
    mixpanel,
});

export const setUserTitlesSuccess = payload => ({
    type: SET_USER_TITLES_SUCCESS,
    payload,
});

export const setUserTitlesFailed = payload => ({
    type: SET_USER_TITLES_FAIL,
    payload,
});

export const setUserTitlesStatus = payload => ({
    type: SET_USER_TITLES_STATUS,
    payload,
});

export const setUserPOIStatus = payload => ({
    type: SET_USER_POI_STATUS,
    payload,
});

export default {};
