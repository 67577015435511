import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';

const RetryReport = (props) => {
    const {
        onClick,
    } = props;
    return (
        <div className="retry-report">
            <div className="retry-report__message" style={{ height: 'auto' }}>
                <p>There was an error retrieving the report. Please click retry to try again.</p>
                <Button className="button-primary" onClick={onClick}>Retry</Button>
            </div>
        </div>
    );
};

RetryReport.propTypes = {
    onClick: PropTypes.func,
};

export default RetryReport;
