import getTerritory from './getTerritory';

export default (id) => (state) => {
    if (id) {
        const territory = getTerritory(id)(state);
        if (territory) {
            const coordinatePattern = /(?:(?:[^,()]+),(?:[^,()]+))/g;
            return territory.territoryPolygon
                .match(coordinatePattern)
                .map(polygonVertex => {
                    const [lon, lat] = polygonVertex.split(',');
                    return {
                        lat: parseFloat(lat),
                        lon: parseFloat(lon),
                    }
                });
        }
    }

    return [];
}

