import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import PropType from 'prop-types';
import Typography from '@mui/material/Typography';
import { routeCodes } from '../../../constants/routes';
import { CONTACT_NUMBER } from '../../../helpers/Localization';
import { FAVOURITES_UNSUBSCRIBED_MESSAGE } from '../../../constants/favourites/favourites';


export const ACTIVE_TAB_STATE = 'set-active-tab-state';
const NAVIGATE_TO_MANAGE_USERS_PARAMS = {
    pathname: routeCodes.ACCOUNT.path,
};
const UnsubscribedPage = (props) => {
    const usrDetail = useSelector(state => state?.clapi.get('usrDetail'));
    const isAdmin = usrDetail?.userAccountDetail?.isAdmin;

    const setActiveTabState = () => {
        localStorage.setItem(ACTIVE_TAB_STATE, 'manage-users-tab');
    };

    return (
        <div className="unsubscribed-page-container" data-testid="unsubscribed-page">
            {props.Icon}
            <Typography variant="subtitle1" sx={{ marginBottom: '5px' }}>
                You're out of the loop!
            </Typography>
            <Typography variant="body2">
                The <b>{props.type}</b> feature is not available on your subscription.
            </Typography>
            {
                isAdmin ?
                    <Typography variant="body2">
                        {FAVOURITES_UNSUBSCRIBED_MESSAGE[props.type]}, upgrade today by calling {CONTACT_NUMBER}.
                    </Typography>
                    :
                    <Typography variant="body2">
                        {FAVOURITES_UNSUBSCRIBED_MESSAGE[props.type]}, ask one of your <Link to={NAVIGATE_TO_MANAGE_USERS_PARAMS} onClick={setActiveTabState} target="_blank">account admins</Link> to call us on {CONTACT_NUMBER}.
                    </Typography>
            }
        </div>
    )
};


UnsubscribedPage.propTypes =  {
    Icon: PropType.elementType,
    type: PropType.string,
};
export default UnsubscribedPage;
