import { LOT_PLAN, BLOCK_SECTION } from '../constants/parcelTypes';
import Strings from './Strings';
import Numbers from './Numbers';
import Bugsnag from '../bugsnag';

const CONJUNCTION = '&';
const SECTION_SEPARATOR = ';';
const BLOCK_SECTION_REGEX = /^(.+): Block\s*(\d+), Section\s*(\d+)(, Unit\s*(\d+))?$/;
const LOT_PLAN_REGEX = /(^(.+?)\/)?(.+)$/;
const LOT_SURVEY_PLAN_REGEX = /^Loc \d+ Lot \d+\/.+$/;

const toBlockSectionMeta = (displayValue) => {
    const match = displayValue.match(BLOCK_SECTION_REGEX);
    return {
        suburb: match[1],
        section: Number(match[3]),
        block: Number(match[2]),
        unit: Number(match[5]),
    };
};

const groupBy = (collection, iteratee) => collection.reduce(
    (map, e) => map.set(iteratee(e), [...map.get(iteratee(e)) || [], e]),
    new Map(),
);

const transformEntries = (map, iteratee) => {
    const results = [];
    for (const entry of map.entries()) {
        const result = iteratee(entry[0], entry[1]);
        results.push(result);
    }

    return results;
};

const fallbackValueOrError = (message, concatenator, values, separator = ',') => {
    const error = new Error(message);
    if (typeof concatenator !== 'function') {
        throw error;
    }
    Bugsnag.notify(error);
    return concatenator(values, separator);
};

const concatenateUnits = (section, meta) => {
    const blocks = groupBy(meta, it => it.block);
    return transformEntries(blocks, (key, value) => {
        const quantifier = value.length > 1 ? 'Units' : 'Unit';
        const unitRange = Numbers.formatRange(value.map(it => it.unit));
        return `Block ${key}, Section ${section}, ${quantifier} ${unitRange}`;
    });
};

const concatenateBlocks = (section, meta) => {
    const groups = groupBy(meta, it => Number.isNaN(it.unit));

    const withoutUnits = groups.get(true);
    let blocksWithoutUnits = [];

    const blockDisplayValues = [];
    if (withoutUnits) {
        blocksWithoutUnits = withoutUnits.map(it => it.block);
        const blockRange = Numbers.formatRange(blocksWithoutUnits);
        const quantifier = withoutUnits.length > 1 ? 'Blocks' : 'Block';
        blockDisplayValues.push(`${quantifier} ${blockRange}, Section ${section}`);
    }

    const withUnits = groups.get(false);
    if (withUnits) {
        const blocksWithUnits = withUnits.filter(it => !blocksWithoutUnits.includes(it.block));
        blockDisplayValues.push(...concatenateUnits(section, blocksWithUnits));
    }

    return Strings.conjunct(blockDisplayValues, CONJUNCTION, SECTION_SEPARATOR);
};

const concatenateSections = (meta) => {
    const sections = groupBy(meta, it => it.section);
    const sectionDisplayValues =
        transformEntries(sections, (key, value) => concatenateBlocks(key, value));
    return Strings.conjunct(sectionDisplayValues, CONJUNCTION, SECTION_SEPARATOR);
};

const concatenateSuburbs = (displayValues, concatenateOnFail) => {
    try {
        const meta = displayValues.map(it => toBlockSectionMeta(it));
        const suburbs = groupBy(meta, it => it.suburb);
        const suburbDisplayValues =
            transformEntries(suburbs, (key, value) => `${key}: ${concatenateSections(value)}`);
        return Strings.conjunct(suburbDisplayValues);
    } catch (error) {
        const message = 'Failed to concatenate blocks and sections';
        return fallbackValueOrError(message, concatenateOnFail, displayValues);
    }
};

const toLotPlanMeta = (displayValue) => {
    const match = displayValue.match(LOT_PLAN_REGEX);
    return {
        lot: match[2],
        plan: match[3],
        prefixed: LOT_SURVEY_PLAN_REGEX.test(displayValue),
    };
};

const concatenateLots = (plan, meta) => {
    const lots = meta.map(it => it.lot);
    if (lots.some(it => typeof it === 'undefined')) {
        return plan;
    }

    const lotNumbers = [];
    const lotDisplayValues = [];

    for (const lot of lots) {
        const number = Number(lot);
        if (Number.isNaN(number)) {
            lotDisplayValues.push(lot);
        } else {
            lotNumbers.push(number);
        }
    }

    if (lotNumbers.length) {
        const lotRange = Numbers.formatRange(lotNumbers);
        lotDisplayValues.unshift(lotRange);
    }

    const lotDisplayValue = `${Strings.conjunct(lotDisplayValues)} ${plan}`;
    const prefixed = meta.some(it => it.prefixed);

    if (prefixed) {
        return lotDisplayValue;
    }

    const quantifier = lots.length > 1 ? 'LOTS' : 'LOT';
    return `${quantifier} ${lotDisplayValue}`;
};

const concatenateLotsAndPlans = (displayValues, concatenateOnFail) => {
    try {
        const meta = displayValues.map(it => toLotPlanMeta(it));
        const plans = groupBy(meta, it => it.plan);
        const planDisplayValues =
            transformEntries(plans, (key, value) => concatenateLots(key, value));
        return Strings.conjunct(planDisplayValues);
    } catch (error) {
        const message = 'Failed to concatenate lots and plans';
        return fallbackValueOrError(message, concatenateOnFail, displayValues);
    }
};

const concatenateDisplayValues = (displayName, displayValues, concatenateOnFail) => {
    if (displayName === BLOCK_SECTION) {
        // eslint-disable-next-line no-use-before-define
        return Parcels.concatenateSuburbs(displayValues, concatenateOnFail);
    } else if (displayName === LOT_PLAN) {
        // eslint-disable-next-line no-use-before-define
        return Parcels.concatenateLotsAndPlans(displayValues, concatenateOnFail);
    }

    const message = `Unsupported display name: [${displayName}]`;
    return fallbackValueOrError(message, concatenateOnFail, displayValues);
};

const reduceParcels = (parcels, concatenateOnFail) => {
    if (!parcels.length) {
        return {};
    }

    const { displayName } = parcels[0];
    const displayValues = parcels.map(parcel => parcel.displayValue);

    if (parcels.length === 1) {
        return {
            displayName,
            displayValue: displayValues[0],
        };
    }

    // eslint-disable-next-line no-use-before-define
    const displayValue = Parcels.concatenateDisplayValues(
        displayName,
        displayValues,
        concatenateOnFail,
    );

    return {
        displayName,
        displayValue,
    };
};

const suggestionTypeTooltip = {
    legalDesc: 'Legal',
    legal: 'Legal',
    titleRef: 'Title Reference',
    'Title Reference': 'Title Reference',
    parcel: 'Parcel',
};

const Parcels = {
    concatenateSuburbs,
    concatenateLotsAndPlans,
    concatenateDisplayValues,
    reduceParcels,
    suggestionTypeTooltip,
};

export default Parcels;
