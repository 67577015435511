import { Map as IMap } from 'immutable';
import {
    GET_USERS_SUCCESS, GET_USERS, GET_USERS_FAIL,
    RESET_HANDLER, RESET_MANAGE_USERS,
    SET_USER_ADMIN_ROLE_SUCCESS,
    SET_USER_ADMIN_ROLE_FAIL,
    SET_USER_UPDATE_STATUS,
    SET_USER_TITLES_STATUS,
    SEARCH_USERS,
    SEARCH_USERS_SUCCESS,
    INVITE_USERS_SUCCESS,
    INVITE_USERS_FAIL,
    INVITE_USERS,
    SET_USER_TITLES_SUCCESS,
    SEARCH_USERS_FAIL,
    SET_USER_POI_ROLE_FAIL,
    SET_USER_POI_ROLE_SUCCESS,
    SET_USER_POI_STATUS,
} from '../actions/manageUsers';

export const initialState = IMap({
    userFilters: {
        filter: '',
        offset: 0,
        limit: 10,
        sort: '+firstname,lastname',
    },
    users: null,
    handler: {
        isError: false,
        message: '',
    },
    userInviteStatus: {
        isError: false,
        showToast: false,
        message: '',
        errorInvites: [],
        successInvite: {
            newUsers: [],
            existingUsers: [],
        },
    },
});

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case GET_USERS: case SEARCH_USERS: {
            const { previousFilters, ...rest } = action.payload;
            return state.merge(IMap({
                users: state.get('users'),
                handler: initialState.get('handler'),
                userFilters: {
                    ...state.get('userFilters'),
                    ...rest,
                },
                userInviteStatus: state.get('userInviteStatus'),
            }));
        }
        case GET_USERS_SUCCESS: case SEARCH_USERS_SUCCESS:
            return state.merge(IMap({
                users: action.payload,
            }));
        case SET_USER_ADMIN_ROLE_FAIL:
        case SET_USER_POI_ROLE_FAIL:
        case SEARCH_USERS_FAIL:
        case GET_USERS_FAIL: {
            return state.merge(IMap({
                ...action.payload,
            }));
        }
        case RESET_HANDLER:
            return state.merge(IMap({
                handler: initialState.get('handler'),
            }));
        case RESET_MANAGE_USERS:
            return initialState;
        case SET_USER_POI_ROLE_SUCCESS: {
            const { index, checked } = action.payload;
            const updatedUserList = JSON.parse(JSON.stringify(state.get('users')));
            updatedUserList.data[index].canPayInArrears = checked;
            return state.merge(IMap({
                users: {
                    ...updatedUserList,
                    data: [...updatedUserList.data],
                },
            }));
        }
        case SET_USER_ADMIN_ROLE_SUCCESS: {
            const { index, checked } = action.payload;
            const updatedUserList = JSON.parse(JSON.stringify(state.get('users')));
            updatedUserList.data[index].isAdmin = checked;
            return state.merge(IMap({
                users: {
                    ...updatedUserList,
                    data: [...updatedUserList.data],
                },
            }));
        }
        case SET_USER_UPDATE_STATUS: {
            const { index, ...rest } = action.payload;
            const updatedUserList = JSON.parse(JSON.stringify(state.get('users')));
            updatedUserList.data[index].ui = { ...rest };
            return state.merge(IMap({
                users: {
                    ...updatedUserList,
                    data: [...updatedUserList.data],
                },
            }));
        }
        case SET_USER_TITLES_SUCCESS: {
            const { index, checked } = action.payload;
            const updatedUserList = JSON.parse(JSON.stringify(state.get('users')));
            updatedUserList.data[index].canOrderTitles = checked;
            return state.merge(IMap({
                users: {
                    ...updatedUserList,
                    data: [...updatedUserList.data],
                },
            }));
        }
        case SET_USER_TITLES_STATUS: {
            const { index, ...rest } = action.payload;
            const updatedUserList = JSON.parse(JSON.stringify(state.get('users')));
            updatedUserList.data[index].uiTitles = { ...rest };
            return state.merge(IMap({
                users: {
                    ...updatedUserList,
                    data: [...updatedUserList.data],
                },
            }));
        }
        case SET_USER_POI_STATUS: {
            const { index, ...rest } = action.payload;
            const updatedUserList = JSON.parse(JSON.stringify(state.get('users')));
            updatedUserList.data[index].uiPOI = { ...rest };
            return state.merge(IMap({
                users: {
                    ...updatedUserList,
                    data: [...updatedUserList.data],
                },
            }));
        }
        case INVITE_USERS: {
            return state.merge(IMap({
                userInviteStatus: initialState.get('userInviteStatus'),
            }));
        }
        case INVITE_USERS_FAIL:
        case INVITE_USERS_SUCCESS: {
            return state.merge(IMap({
                userInviteStatus: {
                    ...state.get('userInviteStatus'),
                    ...action.payload,
                },
            }));
        }
        default:
            return state;
    }
};
