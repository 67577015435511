import Strings from './Strings';

const conjunctRange = (range) => {
    if (range.length === 1) {
        return range[0].toString();
    }
    return `${range[0]}-${range[range.length - 1]}`;
};

const formatRange = (numbers) => {
    if (numbers.length < 1) {
        return '';
    }
    const sortedNumbers = [...new Set(numbers)].sort((a, b) => a - b);

    let previousNumber = sortedNumbers[0];
    let previousRange = [previousNumber];
    const ranges = [previousRange];

    for (let i = 1; i < sortedNumbers.length; i += 1) {
        const currentNumber = sortedNumbers[i];
        if (currentNumber === previousNumber + 1) {
            previousRange.push(currentNumber);
        } else {
            previousRange = [currentNumber];
            ranges.push(previousRange);
        }
        previousNumber = currentNumber;
    }

    const conjunctedRanges = ranges.map(range => conjunctRange(range));
    return Strings.conjunct(conjunctedRanges);
};

export default {
    formatRange,
};
