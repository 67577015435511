import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import { useSelector, useDispatch } from 'react-redux';
import {
    getMoreSavedList,
    getSavedList,
    getWatchList,
} from 'js/actions/watchList';
import ToastMessages from 'js/constants/toastMessage';

const SavedListErrorHandler = (props) => {
    const dispatch = useDispatch();
    const savedList = useSelector(state => state?.watchList.get('savedList'));
    const watchList = useSelector(state => state?.watchList.get('watchList'));

    const retry = () => {
        if (!watchList.success && !savedList.success) {
            dispatch(getSavedList());
            dispatch(getWatchList());
        } else if (!savedList.success) {
            dispatch(getSavedList());
        } else if (!watchList.success) {
            dispatch(getWatchList());
        } else if (!savedList.successToFetchMore) {
            dispatch(getMoreSavedList({ params: { page: props.page, } }));
        }
    };


    if ((savedList.success
        && savedList.successToFetchMore
        && watchList.success)
        || savedList.fetching ||
        watchList.fetching ||
        savedList.fetchingMore) return null;

    return (
        <Alert
            severity="error"
            sx={{
                marginTop: 1,
                display: 'flex',
                '& .MuiAlert-message': {
                    display: 'flex',
                    flexGrow: '1',
                },
                '& .MuiAlert-icon': {
                    alignItems: 'center',
                },
                whiteSpace: 'pre-wrap',
            }}
        >
                    <span
                        style={{ flexGrow: 1 }}
                    >
                        {ToastMessages.SAVED_LIST_ERROR}
                    </span>
            <Button
                variant="text"
                size="small"
                onClick={retry}
                sx={{
                    padding: 0,
                    height: 19,
                    alignSelf: 'center',
                }}
            >
                Retry
            </Button>
        </Alert>
    );
}

SavedListErrorHandler.propTypes = {
    page: PropTypes.number,
};

export default SavedListErrorHandler;
