import { Component, Suspense } from 'react';
import { useImage } from 'react-image';
import BlockUi from '../blockUi/BlockUi';
import PropTypes from 'prop-types';
import { getAssetDomain } from '../../constants/assets';

export const DefaultImg = props => (<img
    className="no-image"
    alt="Not Available"
    src={props.src}
    style={props.fitNoImage ? {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
    } : {}}
/>);

function getNoImagePlaceholder(isThumbnail) {
    let asset = 'no-photo-available_light_1000.png';
    if (isThumbnail) {
        asset = 'no-image-placeholder@3x.png';
    }
    return getAssetDomain(asset);
}

export const Loader = ({ style }) => <BlockUi
    backdropProps={{
        loaderSize: 45,
    }}
    containerProps={{
        className: 'loader-container',
        testId: 'image-loader',
        containerCss: style,
        position: 'relative',
    }}
    blocking
/>;
const UnLoader = (props) => {
    const { defaultUnloader, isThumbnail, fitNoImage } = props;
    if (defaultUnloader) {
        return defaultUnloader;
    }
    return <DefaultImg
        src={getNoImagePlaceholder(isThumbnail)}
        fitNoImage={fitNoImage}
    />;
}

const CruxImg = (props) => {
    const { src, defaultUnloader, isThumbnail, ...rest } = props;
    const { src: _src, error } = useImage({
        srcList: src,
    });
    if (!_src || error) {
        return <UnLoader
            defaultUnloader={defaultUnloader}
            isThumbnail={isThumbnail}
        />;
    }
    return <img src={_src} {...rest} />;
}

// Note: We can pass a default handler that can render html
class ImageLoader extends Component {
    static propTypes = {
        src: PropTypes.string.isRequired,
        defaultUnloader: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.object,
        ]),
        isThumbnail: PropTypes.bool,
        loader: PropTypes.any,
        prospectHeight: PropTypes.number,
        noBackground: PropTypes.bool,
        fitNoImage: PropTypes.bool,
    }

    constructor(props) {
        super(props);
        this.state = {
            hasErrorLoadingImage: false,
        }
    }

    static getDerivedStateFromError() {
        return { hasErrorLoadingImage: true };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.src !== this.props.src) {
            this.setState({ hasErrorLoadingImage: false });
        }
    }

    render() {
        const {
            noBackground,
            prospectHeight,
            src,
            defaultUnloader,
            isThumbnail,
            fitNoImage,
            loader,
        } = this.props;
        const { hasErrorLoadingImage } = this.state;

        if (!src || hasErrorLoadingImage) {
            return <UnLoader
                defaultUnloader={defaultUnloader}
                isThumbnail={isThumbnail}
                fitNoImage={fitNoImage}
            />;
        }
        return (
            <Suspense
                fallback={
                    loader
                    || <Loader style={{
                        backgroundColor: noBackground ? 'unset': '#c6c6c6',
                        minHeight: prospectHeight || 82
                    }} />
                }
            >
                <CruxImg {...this.props} />
            </Suspense>
        )
    }
}

export default ImageLoader;
