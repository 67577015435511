import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import Commons from '../../../helpers/Commons';
import SEGMENT from '../../../constants/segment';

const ShareReport = (props) => {
    const {
        dpprLink, dpprLinkExpiry,
        propertyAddress, onClickEventLogger,
    } = props;

    const openMailToFirefox = (mailToUrl) => {
        // create iframe to load mailto link
        // prevent loading mailto on the same page
        // target _blank is known issue for mailto in FF
        const mailToFrame = document.createElement('iframe');
        mailToFrame.className = 'share-report-iframe';
        mailToFrame.src = mailToUrl;
        mailToFrame.style.cssText = 'display: none';
        document.body.appendChild(mailToFrame);
        setTimeout(() => {
            document.body.removeChild(mailToFrame); // cleanup
        }, 1);
    };

    const openMailToDefault = (mailToUrl) => {
        const mailToLink = document.createElement('a');
        mailToLink.className = 'share-report-anchor';
        mailToLink.target = '_blank';
        mailToLink.rel = 'noopener noreferrer';
        mailToLink.style.cssText = 'display: none';
        mailToLink.href = mailToUrl;
        document.body.appendChild(mailToLink);
        mailToLink.click();
        document.body.removeChild(mailToLink);
    };

    const shareReport = () => {
        const expiryDate = Commons.formatDate(dpprLinkExpiry, 'DD MMM YYYY');
        const subject = `Digital Property Report - ${propertyAddress}`;
        const addressHeading = `Digital Property Profile Report - ${propertyAddress}`;
        const footer = `Generated by CoreLogic RP Data. Please note that this link will expire on ${expiryDate}.`;
        const emailBody = [
            addressHeading, dpprLink, '', footer,
        ].join('\r\n');

        const mailToUrl = `mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(emailBody)}`;
        const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') !== -1;
        if (isFirefox) {
            openMailToFirefox(mailToUrl);
        } else {
            openMailToDefault(mailToUrl);
        }
        onClickEventLogger(SEGMENT.EVENT_NAMES.SHARE_REPORT);
    };

    return (
        <Button
            variant="contained"
            color="primary"
            className="share-button"
            onClick={shareReport}
        >
            <span>Share</span>
        </Button>
    );
};

ShareReport.propTypes = {
    propertyAddress: PropTypes.string,
    dpprLink: PropTypes.string,
    dpprLinkExpiry: PropTypes.string,
    onClickEventLogger: PropTypes.func,
};

export default ShareReport;
