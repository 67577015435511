import API from './index';
import methods from '../constants/methods';
import hiddenFormBuilder from '../helpers/hiddenFormBuilder';
import clapi from './clapi';

const getSignatureUrlByType = (propertyId, type = 'cma', clAppAccountUserGuid, requireNewTab = true) => {
    let path = '/api/reports/signature/v3';
    const selectedClAppAccountUserGuid = clapi.getSelectedClAppAccountUserGuid(clAppAccountUserGuid);
    if (propertyId) {
        path = `${path}?propertyId=${propertyId}&reportType=${type}`;
    }
    if (selectedClAppAccountUserGuid) {
        path += (propertyId ? '&' : '?') + `clAppAccountUserGuid=${selectedClAppAccountUserGuid}`;
    }

    const requestOptions = {
        path,
        isProxy: true,
        timeout: API.getTimeouts().large,
    };
    const headerOptions = {
        method: methods.GET.method,
    };
    return API.initiateSessionApiCall(requestOptions, headerOptions)
        .then(({ url, oauthToken }) => {
            // Creating hidden form to trigger a post request
            if (url && oauthToken) {
                const form = hiddenFormBuilder(url, {
                    oauth: {
                        attributes: {
                            type: 'hidden',
                            name: 'oauthToken',
                            value: oauthToken,
                        },
                    },
                }, requireNewTab);
                form.submit();
            } else {
                throw new Error('Unable to launch Signature reports, please try again later...');
            }
        })
        .catch(error => error);
};

export default {
    getSignatureUrlByType,
};
