import { select, put, takeLatest } from 'redux-saga/effects';
import Merge from 'deepmerge';
import UserPreferencesAPI from 'js/api/userPreferences';
import {
    SET_MULTI_SELECT_REPORT_SECTION,
    SET_MULTI_SELECT_REPORT_SECTION_SUCCESS,
    GET_MULTI_SELECT_REPORT_SECTION,
    SSR_PROPERTY_TYPE,
    SSR_PROPERTY_TYPE_SUCCESS,
} from '../actions/customiseReport';
import { COUNTRY, isAU } from '../constants/crux';
import SuburbStatHelper from '../helpers/report/SuburbStatHelper';

function setReportSectionFn() {
    return function* (reportType, successType, action) {
        try {
            const { searchType, eventTab, section } = action.payload;
            const state = yield select();
            const multiSelect = Merge.all([
                state.customiseReport.get(reportType),
                {
                    [searchType]: {
                        [eventTab]: {
                            ...section
                        }
                    }
                }
            ]);
            const setting = {
                name: `REPORT_SECTION_${reportType.toUpperCase()}_${eventTab.toUpperCase()}_${COUNTRY?.toUpperCase() || 'AU'}`,
                value: JSON.stringify(multiSelect[searchType][eventTab]),
            }
            UserPreferencesAPI.updateUserSettings(setting);
            yield put({
                type: successType,
                payload: multiSelect,
            });
        } catch (e) {
            // do nothing
        }
    }
}

function rehydrateReportSectionsFn() {
    return function* (reportType, successType, action) {
        try {
            const { searchType, eventTab } = action.payload;
            const state = yield select();
            const userSettings = state.userPreferences.get('userSettings');
            const reportSectionsSettings = `REPORT_SECTION_${reportType.toUpperCase()}_${eventTab.toUpperCase()}_${COUNTRY?.toUpperCase() || 'AU'}`;
            const userReportSections = userSettings.find(setting => setting.name === reportSectionsSettings);
            if (!!userReportSections) {
                const reportSections = JSON.parse(userReportSections.value);
                const multiSelect = Merge.all([
                    state.customiseReport.get(reportType),
                    {
                        [searchType]: {
                            [eventTab]: reportSections
                        }
                    }
                ]);
                yield put({
                    type: successType,
                    payload: multiSelect,
                });
            }
        } catch(e) {
            // do nothing
        }
    }
}

function setSSRPropertyTypeFn() {
    return function* (action) {
        try {
            const propertyTypes = yield select(state => state.customiseReport.get('ssr')?.propertyTypes);
            const _propertyTypes = {
                ...propertyTypes,
                ...action.payload,
            };
            const propertyTypeEntries = Object.entries(_propertyTypes);
            if (propertyTypeEntries.every(SuburbStatHelper.filters.allPropertyTypeChecked)
                || propertyTypeEntries.every(SuburbStatHelper.filters.allPropertyTypeUnchecked)
                || action.payload.hasOwnProperty('all')) {
                yield put({
                    type: SSR_PROPERTY_TYPE_SUCCESS,
                    payload: isAU ? {
                        all: true,
                        house: false,
                        unit: false,
                        land: false,
                    } : {
                        all: true,
                        residential: false,
                        land: false,
                    },
                });
            } else if (propertyTypeEntries.some(SuburbStatHelper.filters.byCheckedPropertyType)) {
                yield put({
                    type: SSR_PROPERTY_TYPE_SUCCESS,
                    payload: {
                        ..._propertyTypes,
                        all: false,
                    }
                });
            } else {
                yield put({
                    type: SSR_PROPERTY_TYPE_SUCCESS,
                    payload: _propertyTypes,
                });
            }
        } catch (e) {
            // do nothing
        }
    }
}

export const setReportSection = setReportSectionFn();
export const rehydrateReportSections = rehydrateReportSectionsFn();
export const setSSRPropertyType = setSSRPropertyTypeFn();

function* setReportSectionWatcher() {
    yield takeLatest(SET_MULTI_SELECT_REPORT_SECTION, setReportSection, 'multiSelect', SET_MULTI_SELECT_REPORT_SECTION_SUCCESS);
}

function* rehydrateReportSectionsWatcher() {
    yield takeLatest(GET_MULTI_SELECT_REPORT_SECTION, rehydrateReportSections, 'multiSelect', SET_MULTI_SELECT_REPORT_SECTION_SUCCESS);
}

function* setSSRPropertyTypeWatcher() {
    yield takeLatest(SSR_PROPERTY_TYPE, setSSRPropertyType);
}

export default [
    setReportSectionWatcher(),
    rehydrateReportSectionsWatcher(),
    setSSRPropertyTypeWatcher(),
];
