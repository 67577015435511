import { useEffect, useState } from 'react';
import Commons from '../../helpers/Commons';
import { removeRecentSearch } from '../../actions/msgApi';
import { trackEvent } from '../../actions/segment';
import Segment from '../../helpers/Segment';
import ListPropertyPreview from '../../constants/listPropertyPreview';

const useLocationSearch = ({
    location,
    route,
    dispatch,
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isHistoric, setIsHistoric] = useState(false);
    const [suggestionTypeSelected, setSuggestionTypeSelected] = useState('');
    const [hasRecentSearch, setHasRecentSearch] = useState(true);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [keyword, setKeyword] = useState('');

    useEffect(() => {
        const _suggestions = (!location?.state?.addressCollective && location?.state?.suggestion) || [];
        setIsHistoric(!!Commons.isHistoric(location?.search));
        if (_suggestions.length) {
            setSuggestionTypeSelected(_suggestions[0].suggestionType);
            setHasRecentSearch(false);
        }
    }, [location]);

    const clearRecentSearchesMixpanel = () => {
        dispatch(trackEvent(Segment.removeRecentSearch({
            pageContext: route.pageContext,
            objectContext: ListPropertyPreview.MIXPANEL_SEARCH_TYPE['address'],
        })));
    };

    const handleDeleteDialogOpen = (event) => {
        event.preventDefault();
        setOpenDeleteDialog(true);
    }

    const handleDeleteDialogClose = () => {
        setOpenDeleteDialog(false);
    };

    const handleRemoveRecentSearches = () => {
        setOpenDeleteDialog(false);
        dispatch(removeRecentSearch('address'));
        clearRecentSearchesMixpanel();
        setIsOpen(true);
    };

    const getOptionLabel = (option) => {
        return option.suggestion || option.searchString;
    };

    return {
        isHistoric,
        setIsHistoric,
        suggestionTypeSelected,
        setSuggestionTypeSelected,
        hasRecentSearch,
        setHasRecentSearch,
        isOpen,
        setIsOpen,
        handleDeleteDialogOpen,
        handleDeleteDialogClose,
        handleRemoveRecentSearches,
        openDeleteDialog,
        setOpenDeleteDialog,
        getOptionLabel,
        keyword,
        setKeyword,
    };
};

export default useLocationSearch;
