import { SITE_TITLE } from '../helpers/Localization';
import CleanURL from '../helpers/CleanURL';

const SAVED_LIST_PATH = '/saved-list/result';

export const routeCodes = {
    JOIN_CRUX: {
        id: 'joinCrux',
        path: '/join/crux',
        title: `Join - ${SITE_TITLE}`,
        pageContext: 'Join Crux',
    },
    AUTO_LOGIN: {
        id: 'autoLogin',
        path: '/auto-login**',
        title: `Auto Login - ${SITE_TITLE}`,
        pageContext: 'Auto Login',
    },
    AUTO_LOGIN_LEGACY_AU: {
        id: 'autoLoginLegacyAu',
        path: '/rpp**',
        title: `Auto Login - ${SITE_TITLE}`,
        pageContext: 'Auto Login Legacy AU',
    },
    AUTO_LOGIN_LEGACY_NZ: {
        id: 'autoLoginLegacyNz',
        path: '/rpnz**',
        title: `Auto Login - ${SITE_TITLE}`,
        pageContext: 'Auto Login Legacy NZ',
    },
    LEGACY_RPP: {
        id: 'legacyRpp',
        path: '/legacyrpp**',
        title: SITE_TITLE,
        pageContext: 'Home',
    },
    LEGACY_RPNZ: {
        id: 'legacyRpnz',
        path: '/legacyrpnz**',
        title: SITE_TITLE,
        pageContext: 'Home',
    },
    LOGIN_ERROR: {
        id: 'loginError',
        path: '/error-on-login',
        title: `Login Error - ${SITE_TITLE}`,
        pageContext: 'Login Error',
    },
    JOIN_UP_FORM: {
        id: 'joinUpForm',
        path: '/join-up-form',
        title: `Join Up Form - ${SITE_TITLE}`,
        pageContext: 'Join Up Form',
    },
    LINKED_ACCOUNTS: {
        id: 'linkedAccounts',
        path: '/linked-accounts',
        title: `Account Selection - ${SITE_TITLE}`,
        pageContext: 'Account Selection',
    },
    TERMS_AND_CONDITIONS: {
        id: 'termsAndConditions',
        path: '/terms-and-conditions',
        title: `Terms And Conditions - ${SITE_TITLE}`,
        pageContext: 'Terms And Conditions',
    },
    PROPERTY: {
        id: 'property',
        path: (address, propertyId) => {
            const cleanAddress = CleanURL.clean(address);
            return `/property/${cleanAddress}/${propertyId}`;
        },
        title: address => `${address} - ${SITE_TITLE}`, // will accept an address param
        pageContext: 'Property Detail',
    },
    PROPERTY_PRINT: {
        id: 'property-print',
        path: (address, propertyId) => {
            const cleanAddress = CleanURL.clean(address);
            return `/property/print/${cleanAddress}/${propertyId}`;
        },
        title: address => `Custom Print - ${address} - ${SITE_TITLE}`,
        pageContext: 'Property Print',
    },
    SUBURB_STATS_REPORT: {
        id: 'suburb-stats-report',
        path: (localityName, localityId, councilAreaIds, isRegex) => {
            const cleanLocalityName = isRegex ? localityName : CleanURL.cleanByPlus(localityName);
            const cleanCouncilAreaIds = CleanURL.clean(councilAreaIds);
            return `/report/preview/suburb-statistics-report/${cleanLocalityName}/${localityId}/${cleanCouncilAreaIds}`;
        },
        title: SITE_TITLE,
        pageContext: 'Suburb Statistics Report',
    },
    HOME: {
        id: 'home',
        path: '/',
        title: SITE_TITLE,
        pageContext: 'Home',
    },
    DASHBOARD: {
        id: 'dashboard',
        path: '/dashboard',
        title: SITE_TITLE,
        pageContext: 'Home',
    },
    NOTFOUND: {
        id: 'notFound',
        path: '/404',
        title: `Not Found - ${SITE_TITLE}`,
        pageContext: 'Page not found',
    },
    APPLICATION_ERROR: {
        id: 'error',
        path: '/error',
        title: `Application Error - ${SITE_TITLE}`,
        pageContext: 'Application Error',
    },
    NAME_SEARCH_RESULT: {
        staticPath: '/search/name',
        id: 'nameSearchResult',
        title: `Name Search - ${SITE_TITLE}`, // will accept an address param
        pageContext: 'Search',
    },
    COMPANY_SEARCH_RESULT: {
        id: 'companySearchResult',
        path: '/search/company',
        title: `Company Search - ${SITE_TITLE}`,
        pageContext: 'Search',
    },
    BUILDING_SEARCH_RESULT: {
        id: 'buildingSearchResult',
        path: '/search/buildingName',
        title: `Building Name Search - ${SITE_TITLE}`,
        pageContext: 'Search',
    },
    PARCEL_SEARCH_RESULT: {
        id: 'parcelSearchResult',
        path: '/search/parcel',
        title: `Parcel Search - ${SITE_TITLE}`,
        pageContext: 'Search',
    },
    LEGAL_DESCRIPTION_SEARCH_RESULT: {
        id: 'legalDescriptionSearchResult',
        path: '/search/legalDescription',
        title: `Legal Description Search - ${SITE_TITLE}`,
        pageContext: 'Search',
    },
    VOLUME_FOLIO_SEARCH_RESULT: {
        id: 'volumeFolioSearchResult',
        path: '/search/volumeFolio',
        title: `Volume/Folio Search - ${SITE_TITLE}`,
        pageContext: 'Search',
    },
    TITLE_REFERENCE_SEARCH_RESULT: {
        id: 'titleReferenceSearchResult',
        path: '/search/titleReference',
        title: `Title Reference Search - ${SITE_TITLE}`,
        pageContext: 'Search',
    },
    ROLL_VALUATION_SEARCH_RESULT: {
        id: 'rollValuationSearchResult',
        path: '/search/rollValuation',
        title: `Roll/Valuation Search - ${SITE_TITLE}`,
        pageContext: 'Search',
    },
    MULTI_LOCALITY_SEARCH: {
        id: 'multiLocalitySearch',
        path: '/search/multi',
        title: `Multi Locality - ${SITE_TITLE}`,
        pageContext: 'Search',
    },
    SEARCH_RESULT: {
        id: 'searchResult',
        path: (suggestionType, address) => {
            const cleanAddress = CleanURL.clean(address);
            return `/search/${suggestionType}/${cleanAddress}`;
        },
        title: address => `${address} - ${SITE_TITLE}`, // will accept an address param
        pageContext: 'Search',
    },
    RADIUS_SEARCH_RESULT: {
        id: 'radiusSearchResult',
        path: '/search/radius',
        title: `Radius Search - ${SITE_TITLE}`,
        pageContext: 'Search',
    },
    ACCOUNT: {
        id: 'account',
        path: '/account',
        title: `My Account - ${SITE_TITLE}`,
        pageContext: 'My Account',
    },
    PROPERTY_LIST_REPORT: {
        id: 'propertyListReport',
        path: (searchType, eventTab) => {
            if (searchType && eventTab) {
                return `/report/preview/property/${searchType}/${eventTab}`;
            } else if (searchType && !eventTab) {
                return `/report/preview/property/${searchType}`;
            }
            return '/report/preview/property';
        },
        title: SITE_TITLE,
        pageContext: 'Property List Report Preview',
    },
    MARKET_INSIGHTS: {
        id: 'marketInsights',
        path: '/market-insights',
        title: `Market Insights - ${SITE_TITLE}`,
        pageContext: 'Market Insights',
    },
    TITLE_ORDERING: {
        id: 'titleOrdering',
        path: '/titles',
        title: `Titles - ${SITE_TITLE}`,
        pageContext: 'Titles',
    },
    USER_FAVORITES: {
        id: 'favourites',
        path: '/favourites',
        title: `Favourites - ${SITE_TITLE}`,
        pageContext: 'Favourites',
    },
    SAVED_SEARCH: {
        id: 'savedSearch',
        path: itemId => `/saved-search/result/${itemId}`,
        title: `Saved Search - ${SITE_TITLE}`,
        pageContext: 'Saved Search',
    },
    SAVED_LIST: {
        id: 'savedList',
        path: savedListId => savedListId ? `${SAVED_LIST_PATH}/${savedListId}` : SAVED_LIST_PATH,
        title: `Saved List - ${SITE_TITLE}`,
        pageContext: 'Saved List',
    },
    SAVED_TERRITORY: {
        id: 'savedTerritory',
        path: territoryId => `/saved-territory/result/${territoryId}`,
        title: `Saved Territories - ${SITE_TITLE}`,
        pageContext: 'Saved Territory',
    },
};

export function isBeforeTnCPathname() {
    const { pathname } = window.location;
    return pathname === routeCodes.JOIN_UP_FORM.path
        || pathname === routeCodes.LINKED_ACCOUNTS.path
        || pathname === routeCodes.LOGIN_ERROR.path;
}

export function isBeforeHomepagePathname() {
    const { pathname } = window.location;
    return isBeforeTnCPathname()
        || pathname === routeCodes.TERMS_AND_CONDITIONS.path;
}

export function isHomePage(route) {
    return route === routeCodes.HOME || route === routeCodes.DASHBOARD;
}

/**
 * @param {*} path of routeCodes
 * @returns title of route
 */
export function getRoute(path) {
    switch (path) {
        case routeCodes.HOME.path: return routeCodes.HOME;
        case routeCodes.DASHBOARD.path: return routeCodes.DASHBOARD;
        case routeCodes.PROPERTY.path(':address?', ':propertyId([0-9]+)'):
            return routeCodes.PROPERTY;
        case routeCodes.SEARCH_RESULT.path(':suggestionType', ':address'):
            return routeCodes.SEARCH_RESULT;
        case routeCodes.APPLICATION_ERROR.path: return routeCodes.APPLICATION_ERROR;
        case routeCodes.NOTFOUND.path: return routeCodes.NOTFOUND;
        case routeCodes.NAME_SEARCH_RESULT.staticPath: return routeCodes.NAME_SEARCH_RESULT;
        case routeCodes.COMPANY_SEARCH_RESULT.path: return routeCodes.COMPANY_SEARCH_RESULT;
        case routeCodes.BUILDING_SEARCH_RESULT.path: return routeCodes.BUILDING_SEARCH_RESULT;
        case routeCodes.PARCEL_SEARCH_RESULT.path: return routeCodes.PARCEL_SEARCH_RESULT;
        case routeCodes.MULTI_LOCALITY_SEARCH.path: return routeCodes.MULTI_LOCALITY_SEARCH;
        case routeCodes.VOLUME_FOLIO_SEARCH_RESULT.path:
            return routeCodes.VOLUME_FOLIO_SEARCH_RESULT;
        case routeCodes.LEGAL_DESCRIPTION_SEARCH_RESULT.path:
            return routeCodes.LEGAL_DESCRIPTION_SEARCH_RESULT;
        case routeCodes.TITLE_REFERENCE_SEARCH_RESULT.path:
            return routeCodes.TITLE_REFERENCE_SEARCH_RESULT;
        case routeCodes.SUBURB_STATS_REPORT.path(':locality?', ':localityId([0-9]+)', ':lgas', true):
            return routeCodes.SUBURB_STATS_REPORT;
        case routeCodes.RADIUS_SEARCH_RESULT.path: return routeCodes.RADIUS_SEARCH_RESULT;
        case routeCodes.ACCOUNT.path: return routeCodes.ACCOUNT;
        case routeCodes.PROPERTY_LIST_REPORT.path: return routeCodes.PROPERTY_LIST_REPORT;
        case routeCodes.MARKET_INSIGHTS.path: return routeCodes.MARKET_INSIGHTS;
        case routeCodes.TITLE_ORDERING.path: return routeCodes.TITLE_ORDERING;
        case routeCodes.LINKED_ACCOUNTS.path: return routeCodes.LINKED_ACCOUNTS;
        case routeCodes.TERMS_AND_CONDITIONS.path: return routeCodes.TERMS_AND_CONDITIONS;
        case routeCodes.JOIN_UP_FORM.path: return routeCodes.JOIN_UP_FORM;
        case routeCodes.PROPERTY_PRINT.path(':address?', ':propertyId([0-9]+)'): return routeCodes.PROPERTY_PRINT;
        case routeCodes.ROLL_VALUATION_SEARCH_RESULT.path:
            return routeCodes.ROLL_VALUATION_SEARCH_RESULT;
        case routeCodes.USER_FAVORITES.path: return routeCodes.USER_FAVORITES;
        case routeCodes.SAVED_SEARCH.path(':itemId'):
            return routeCodes.SAVED_SEARCH;
        case routeCodes.SAVED_LIST.path(':savedListId?'):
            return routeCodes.SAVED_LIST;
        case routeCodes.SAVED_TERRITORY.path(':itemId'):
            return routeCodes.SAVED_TERRITORY;
        default: return routeCodes.HOME;
    }
}

export const routeConfigs = (route, props) => {
    const ROUTE_SEARCH_TYPE = {
        [routeCodes.NAME_SEARCH_RESULT.id]: 'name',
        [routeCodes.COMPANY_SEARCH_RESULT.id]: 'company',
        [routeCodes.VOLUME_FOLIO_SEARCH_RESULT.id]: 'volume/folio',
        [routeCodes.BUILDING_SEARCH_RESULT.id]: 'building',
    };

    let searchType = ROUTE_SEARCH_TYPE[route.id]?.searchType || 'address';

    const withoutBreadCrumbsConfig = {
        shouldRenderMenuSearchBar: true,
        shouldRenderBreadcrumb: false,
        shouldRenderHistoricalBanner: false,
        shouldRenderActions: false,
        shouldRenderPropertyPrintHeader: false,
        displayRentalEstimate: props.displayRentalEstimate,
        route,
        searchType,
    };
    const configs = {
        [routeCodes.SEARCH_RESULT.id]: { ...withoutBreadCrumbsConfig },
        [routeCodes.MULTI_LOCALITY_SEARCH.id]: { ...withoutBreadCrumbsConfig },
        [routeCodes.RADIUS_SEARCH_RESULT.id]: { ...withoutBreadCrumbsConfig },
        [routeCodes.PARCEL_SEARCH_RESULT.id]: { ...withoutBreadCrumbsConfig },
        [routeCodes.VOLUME_FOLIO_SEARCH_RESULT.id]: { ...withoutBreadCrumbsConfig },
        [routeCodes.LEGAL_DESCRIPTION_SEARCH_RESULT.id]: { ...withoutBreadCrumbsConfig },
        [routeCodes.TITLE_REFERENCE_SEARCH_RESULT.id]: { ...withoutBreadCrumbsConfig },
        [routeCodes.ROLL_VALUATION_SEARCH_RESULT.id]: { ...withoutBreadCrumbsConfig },
        [routeCodes.BUILDING_SEARCH_RESULT.id]: { ...withoutBreadCrumbsConfig },
        [routeCodes.BUILDING_SEARCH_RESULT.id]: { ...withoutBreadCrumbsConfig },
        [routeCodes.SAVED_SEARCH.id]: { ...withoutBreadCrumbsConfig },
        [routeCodes.SAVED_LIST.id]: { ...withoutBreadCrumbsConfig },
        [routeCodes.SAVED_TERRITORY.id]: { ...withoutBreadCrumbsConfig },
        [routeCodes.ACCOUNT.id]: { ...withoutBreadCrumbsConfig },
        [routeCodes.USER_FAVORITES.id]: { ...withoutBreadCrumbsConfig },
        [routeCodes.NAME_SEARCH_RESULT.id]: {
            searchType,
            ...withoutBreadCrumbsConfig,
        },
        [routeCodes.COMPANY_SEARCH_RESULT.id]: {
            searchType,
            ...withoutBreadCrumbsConfig,
        },
        [routeCodes.VOLUME_FOLIO_SEARCH_RESULT.id]: {
            searchType,
            ...withoutBreadCrumbsConfig,
        },
        [routeCodes.BUILDING_SEARCH_RESULT.id]: {
            searchType,
            ...withoutBreadCrumbsConfig,
        },
        [routeCodes.PROPERTY.id]: {
            searchType,
            shouldRenderMenuSearchBar: true,
            shouldRenderBreadcrumb: true,
            shouldRenderHistoricalBanner: true,
            shouldRenderActions: true,
            displayRentalEstimate: props.displayRentalEstimate,
            route,
            isPropertyExist: props.isPropertyExist,
        },
        [routeCodes.PROPERTY_PRINT.id]: {
            route,
            shouldRenderMenuSearchBar: false,
            shouldRenderBreadcrumb: false,
            shouldRenderHistoricalBanner: false,
            shouldRenderActions: false,
            shouldRenderPropertyPrintHeader: !!props.propertyCommonsSuccess,
        },
        default: {
            shouldRenderMenuSearchBar: false,
            shouldRenderBreadcrumb: false,
            shouldRenderHistoricalBanner: false,
            shouldRenderPropertyPrintHeader: false,
            displayRentalEstimate: props.displayRentalEstimate,
            route,
            propertyId: null,
            searchType,
            isPropertyExist: props.isPropertyExist,
        },
    };

    return configs[route.id] || configs.default;
};
