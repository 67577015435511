import React from 'react';
import ReactTooltip from 'react-tooltip';
import PropTypes from 'prop-types';
import Commons from '../../helpers/Commons';
import Copy from './Copy';
import TOOLTIP_TEXT from '../../constants/tooltipText';

const CopyWithTooltip = (props) => {
    const { id, children, ...copyProps } = props;
    const [copyTooltipText, setCopyTooltipText] = React.useState(TOOLTIP_TEXT.TEXT.COPY_TO_CLIPBOARD);
    const handleCopySuccess = () => {
        setCopyTooltipText(TOOLTIP_TEXT.TEXT.COPIED);
        Commons.delay(1000).then(() => {
            setCopyTooltipText(TOOLTIP_TEXT.TEXT.COPY_TO_CLIPBOARD);
        });
    };
    return (
        <React.Fragment>
            <Copy
                {...copyProps}
                onCopySuccess={handleCopySuccess}
                linkProps={{
                    id: 'copy-icon',
                    'data-tip': copyTooltipText,
                    'data-for': id,
                }}
            >
                { children }
            </Copy>
            <ReactTooltip id={id} getContent={() => copyTooltipText} place="right" />
        </React.Fragment>
    );
};

CopyWithTooltip.propTypes = {
    forCopy: PropTypes.string,
    onCopyEventLogger: PropTypes.func,
    onCopySuccess: PropTypes.func,
    disabled: PropTypes.bool,
    hidden: PropTypes.bool,
    id: PropTypes.string,
};

CopyWithTooltip.defaultProps = {
    id: 'copy-with-tooltip',
};

export default CopyWithTooltip;
