import React, { useEffect, useState } from 'react';
import { Button, Grid } from '@mui/material';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import StarOutlineIcon from '@mui/icons-material/StarOutline';

import 'css/crux/components/userFavorites/savedLists/savedLists.scss';
import ScrollableContainer from 'js/components/common/ScrollableContainer';
import { routeCodes } from '../../../../constants/routes';
import { getSavedList } from '../../../../actions/watchList';
import { SAVED_LISTS } from '../../../../constants/favourites/favourites';
import EmptyFavoritesPage from '../EmptyFavoritesPage';
import Colors from '../../../../constants/colors';
import SavedListCardHome from './SavedListCardHome';
import { CONTENT_HEIGHT, StyledButton } from '../UserFavoritesHome';
import Entitlements from '../../../../helpers/Entitlements';
import UnsubscribedPage from '../UnsubscribedPage';

const SAVED_LIST_DISPLAY_LIMIT = 19;
export const CREATE_SAVED_LIST = 'create-saved-list';

const SavedListPanelHome = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const savedList = useSelector(state => state.watchList.get('savedList'));
    const watchList = useSelector(state => state.watchList.get('watchList'));
    const usrDetail = useSelector(state => state.clapi.get('usrDetail'));

    const [loading, setLoading] = useState(true);
    const [withData, setWithData] = useState(true);
    const hasSavedListRole =  Entitlements.hasSavedListRole(usrDetail);

    // strict check on false only
    const isError = savedList?.success === false || watchList?.success === false;

        // check if no saved list and no watchlist
    useEffect(() => {
        // strict check on false only. Do not use falsy condition
        if (savedList?.fetching === false && watchList?.fetching === false) {
            setWithData(savedList?.list?.length || watchList?.propertiesCount);
            setLoading(false);
        }
    }, [savedList, watchList]);

    useEffect((prevProps) => {
        if (usrDetail?.accountDetail?.clAccountGuid && hasSavedListRole) {
            dispatch(getSavedList());
        }
    }, [usrDetail?.accountDetail?.clAccountGuid]);

    const navigateToFavourites = (createSavedList = false) => {
        if (createSavedList) {
            localStorage.setItem(CREATE_SAVED_LIST, '1');
        }

        history.push({
            pathname: routeCodes.USER_FAVORITES.path,
            state: {
                activeTab: SAVED_LISTS,
            },
        });
        window.scrollTo(0, 0);
    };

    if (!hasSavedListRole) {
        return (
            <UnsubscribedPage
                type="Saved Lists"
                message="To create a Saved List and keep up with what matters for you"
                Icon={<StarOutlineIcon
                    sx={{
                        width: '56px',
                        height: '56px',
                        flexShrink: '0',
                        color: Colors.PRIMARY.MAIN,
                    }}
                />}
            />
        );
    };

    return (
        <>
            {
                withData ?
                    <>
                        <ScrollableContainer height={CONTENT_HEIGHT}>
                            <Grid container rowSpacing={1} columnSpacing={2}>
                                {
                                    loading ?
                                        <Grid item xs={6}>
                                            <SavedListCardHome/>
                                        </Grid> :
                                        <>
                                            <Grid item xs={6}>
                                                <Link
                                                    data-testid="search-card-link"
                                                    target="_blank"
                                                    to={routeCodes.SAVED_LIST.path()}
                                                >
                                                    <SavedListCardHome
                                                        description="My Watch List"
                                                        propertiesCount={watchList?.propertiesCount}
                                                    />
                                                </Link>
                                            </Grid>
                                            {
                                                savedList
                                                    ?.list
                                                    ?.slice(0, SAVED_LIST_DISPLAY_LIMIT)
                                                    ?.map(({id, description, propertiesCount}) =>
                                                        (<Grid item xs={6} key={id}>
                                                            <Link
                                                                data-testid="search-card-link"
                                                                target="_blank"
                                                                to={routeCodes.SAVED_LIST.path(id)}
                                                            >
                                                                <SavedListCardHome
                                                                    description={description}
                                                                    propertiesCount={propertiesCount}
                                                                />
                                                            </Link>
                                                        </Grid>))
                                            }
                                        </>
                                }
                            </Grid>
                        </ScrollableContainer>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                            }}
                        >
                            <StyledButton onClick={() => navigateToFavourites(false)}>
                                View All
                            </StyledButton>
                        </div>
                    </>
                    :
                    <EmptyFavoritesPage
                        title={isError ? 'Sorry, there was an error fetching your data.' : 'You\'re out of the loop!'}
                        subTitle={
                            isError ?  'Please refresh the page to try again.'
                            : 'Setup Saved List to keep up with what matters for you.'
                        }
                        actionButton={!isError && <Button
                            variant="contained"
                            onClick={() => navigateToFavourites(true)}
                        >
                            Create
                        </Button>}
                        Icon={<StarOutlineIcon
                            sx={{
                                width: '56px',
                                height: '56px',
                                flexShrink: '0',
                                color: Colors.PRIMARY.MAIN,
                            }}
                        />}
                    />
            }
        </>
    );
};

SavedListPanelHome.propTypes = {};

export default SavedListPanelHome;