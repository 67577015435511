import { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { styled } from '@mui/material';
import { getAssetDomain } from '../../constants/assets';
import { mobileBadgeClickEvent } from '../../actions/segment';
import { FOOTER } from '../../helpers/Localization';
import { isAU } from '../../constants/crux';
import { routeCodes } from '../../constants/routes';

const StyledImageContainer = styled('div')({
    marginBottom: '16px',
    width: '24px',
    '& img': {
        maxWidth: '100%',
        height: 'auto',
    },
});

const { appleStoreLink, googleStoreLink } = FOOTER.mobileBadges;
export const apple = 'App Store';
export const google = 'Google Play';

export class MobileBadges extends Component {
    static propTypes = {
        dispatch: PropTypes.func,
    }

    handleAppClick(brand) {
        const { dispatch, route } = this.props;
        dispatch(mobileBadgeClickEvent(brand, route.pageContext));
    }

    render() {
        const { t, route } = this.props;
        const showLegacyIcon = isAU && route.pageContext === routeCodes.HOME.pageContext;

        return (
            <div data-testid="mobile-badges-component" id="mobile-app">
                <section>
                    {showLegacyIcon && (
                        <StyledImageContainer>
                            <img
                                src={getAssetDomain('legacy-icon.png')}
                                alt="legacy-icon"
                            />
                        </StyledImageContainer>
                    )}
                    <div>
                        <h5>{t('content.mobileBadges.header')}</h5>
                        <p>{t('content.mobileBadges.content') }</p>
                    </div>
                    <div className="mobile-badge-container">
                        <a
                            data-testid="apple-badge"
                            className="mobile-badge apple-badge"
                            href={appleStoreLink}
                            onClick={() => this.handleAppClick(apple)}
                            target="_blank"
                            rel="noreferrer noopener"
                        >
                            <img src={getAssetDomain('app-store-badge.png')} alt="Apple App Store" />
                        </a>
                        <a
                            data-testid="google-badge"
                            className="mobile-badge google-badge"
                            href={googleStoreLink}
                            onClick={() => this.handleAppClick(google)}
                            target="_blank"
                            rel="noreferrer noopener"
                        >
                            <img src={getAssetDomain('google-play-badge.png')} alt="Google Play" />
                        </a>
                    </div>
                </section>
            </div>
        );
    }
}

export default withTranslation()(connect()(MobileBadges));
