import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material';
import { connect } from 'react-redux';
import moment from 'moment';
import FileHelper from '../../helpers/FileHelper';
import { trackEvent } from '../../actions/segment';
import SegmentHelper from '../../helpers/Segment';
import Colors from '../../constants/colors';

const StyledButton = styled('button')({
    background: Colors.PRIMARY.MAIN,
    color: Colors.WHITE,
    padding: '5px 10px',
    height: '32px',
    minHeight: '32px',
    textTransform: 'none',
    transition: 'all .5s ease',
    fontSize: 13,
    borderColor: 'transparent',
    borderRadius: '4px',
    lineHeight: '1.75px',
    '&:hover': {
        backgroundColor: '#005FBE',
    }
});

const _DownloadCustomPrintButton = (props) => {
    const {
        report: { fetching = false, pdfBytes } = {},
        dispatch,
        address = '',
        match: {
            params: {
                propertyId,
            } = {},
        } = {},
        panels,
    } = props;

    const downloadReport = () => {
        if (!pdfBytes) {
            return;
        }

        const filename = `${address.replace(/ /g, '_')}_${moment().format('DD-MM-YYYY')}.pdf`;
        FileHelper.downloadPdfFromBase64(pdfBytes, filename);

        dispatch(trackEvent(SegmentHelper.generateCustomPrintReport({
            reportType: 'Custom Print',
            propertyId,
            reportSections: panels,
        })));
    };

    return (
        <StyledButton
            onClick={downloadReport}
            disabled={fetching}
        >
            Download PDF
        </StyledButton>);
};

_DownloadCustomPrintButton.propTypes = {
    dispatch: PropTypes.func.isRequired,
    report: PropTypes.object.isRequired,
    address: PropTypes.string.isRequired,
    match: PropTypes.object,
    panels: PropTypes.array,
};

const DownloadCustomPrintButton = connect(state => ({
    report: state.printPdp.get('report'),
    panels: state.printPdp.get('panels'),
}))(_DownloadCustomPrintButton);

DownloadCustomPrintButton.displayName = 'DownloadCustomPrintButton';

export default DownloadCustomPrintButton;
